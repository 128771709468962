const defaultMonths = [
  { MonthName: 'January', MonthNumber: 1, CurrentYear: 0, PreviousYear: 0, SortOrder: 1 },
  { MonthName: 'February', MonthNumber: 2, CurrentYear: 0, PreviousYear: 0, SortOrder: 2 },
  { MonthName: 'March', MonthNumber: 3, CurrentYear: 0, PreviousYear: 0, SortOrder: 3 },
  { MonthName: 'April', MonthNumber: 4, CurrentYear: 0, PreviousYear: 0, SortOrder: 4 },
  { MonthName: 'May', MonthNumber: 5, CurrentYear: 0, PreviousYear: 0, SortOrder: 5 },
  { MonthName: 'June', MonthNumber: 6, CurrentYear: 0, PreviousYear: 0, SortOrder: 6 },
  { MonthName: 'July', MonthNumber: 7, CurrentYear: 0, PreviousYear: 0, SortOrder: 7 },
  { MonthName: 'August', MonthNumber: 8, CurrentYear: 0, PreviousYear: 0, SortOrder: 8 },
  { MonthName: 'September', MonthNumber: 9, CurrentYear: 0, PreviousYear: 0, SortOrder: 9 },
  { MonthName: 'October', MonthNumber: 10, CurrentYear: 0, PreviousYear: 0, SortOrder: 10 },
  { MonthName: 'November', MonthNumber: 11, CurrentYear: 0, PreviousYear: 0, SortOrder: 11 },
  { MonthName: 'December', MonthNumber: 12, CurrentYear: 0, PreviousYear: 0, SortOrder: 12 },
];

export const defaultMonthData = (inputMonths) => {
  // data is array of objects with same format as default months
  defaultMonths.forEach((month, i) => {
    let found = inputMonths.find((im) => im.MonthName === month.MonthName);
    if (!found) {
      inputMonths.splice(i, 0, month);
    }
  });
  return inputMonths;
};

export default { defaultMonthData };