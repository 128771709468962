import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const styles = (theme) => ({
  button: {
    padding: theme.spacing(1),
    minWidth: 40,
  },
  headingCell: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    padding: theme.spacing(0, 2, 3, 0),
  },
  cell: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    padding: theme.spacing(0, 2, 3, 0),
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
});

const withEditColumnStyles = withStyles(styles, { name: 'EditColumn' });

// command button
const CommandButtonBase = ({
  onExecute,
  text,
  type,
  icon,
  classes,
  className,
  tableRow,
  IconComponent,
  ...restProps
}) => (
  <Button
    color="primary"
    className={classNames(classes.button, className)}
    onClick={(e) => {
      e.stopPropagation();
      onExecute(tableRow);
    }}
    {...restProps}
  > {text}
    { icon && <FontAwesomeIcon className={classes.icon} icon={icon} />}
  </Button>
);

CommandButtonBase.propTypes = {
  onExecute: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  rowId: PropTypes.string,
  className: PropTypes.string,
};

CommandButtonBase.defaultProps = {
  className: undefined,
};

export const CommandButton = withEditColumnStyles(CommandButtonBase);


// edit command cell
const EditCommandCellBase = ({
  children,
  style,
  classes,
  className,
  onExecute,
  icon,
  text,
  type,
  tableRow, tableColumn,
  IconComponent,
  ...restProps
}) => {
  return (
    <CommandButton tableRow={tableRow} onExecute={onExecute} text={text} type={type} icon={icon} IconComponent={IconComponent} />
  );
};

EditCommandCellBase.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  onExecute: PropTypes.func,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  tableRow: PropTypes.object,
  tableColumn: PropTypes.object,
};

EditCommandCellBase.defaultProps = {
  children: undefined,
  style: null,
  className: undefined,
  tableRow: undefined,
  tableColumn: undefined,
};

export const EditCommandCell = withEditColumnStyles(EditCommandCellBase);