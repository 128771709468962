import {
  GET_EXCEPTIONS_BY_CATEGORY_FAILURE, GET_EXCEPTIONS_BY_CATEGORY_REQUEST, GET_EXCEPTIONS_BY_CATEGORY_SUCCESS,
  GET_EXCEPTIONS_BY_MONTH_FAILURE, GET_EXCEPTIONS_BY_MONTH_REQUEST, GET_EXCEPTIONS_BY_MONTH_SUCCESS,
  GET_EXCEPTIONS_BY_PROGRAM_FAILURE, GET_EXCEPTIONS_BY_PROGRAM_REQUEST, GET_EXCEPTIONS_BY_PROGRAM_SUCCESS,
  GET_OTHER_EXCEPTIONS_FAILURE, GET_OTHER_EXCEPTIONS_REQUEST, GET_OTHER_EXCEPTIONS_SUCCESS,
  GET_PENDING_EXCEPTIONS_FAILURE, GET_PENDING_EXCEPTIONS_REQUEST, GET_PENDING_EXCEPTIONS_SUCCESS,
  GET_PENDING_EXCEPTION_DETAIL_FAILURE, GET_PENDING_EXCEPTION_DETAIL_REQUEST, GET_PENDING_EXCEPTION_DETAIL_SUCCESS,
  UPDATE_EXCEPTION_APPROVAL_FAILURE, UPDATE_EXCEPTION_APPROVAL_REQUEST, UPDATE_EXCEPTION_APPROVAL_SUCCESS,
} from './exceptions.actions';
import Immutable from 'immutable';

import { defaultMonthData } from '../../utilities/monthData';

const initialState = Immutable.Map({
  pendingExceptions: [],
  pendingException: {},
  otherExceptions: [],
  isLoading: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_EXCEPTIONS_REQUEST:
      return state.set('isLoading', true);

    case GET_PENDING_EXCEPTIONS_SUCCESS:
      return state
        .set('pendingExceptions', action.response)
        .set('isLoading', false);

    case GET_PENDING_EXCEPTIONS_FAILURE:
      return state.set('isLoading', false);

    case GET_PENDING_EXCEPTION_DETAIL_REQUEST:
      return state.set('isloading', true);

    case GET_PENDING_EXCEPTION_DETAIL_SUCCESS:
      return state
        .set('pendingException', action.response)
        .set('isLoading', false);

    case GET_PENDING_EXCEPTION_DETAIL_FAILURE:
      return state.set('isLoading', false);

    case GET_OTHER_EXCEPTIONS_REQUEST:
      return state.set('isLoading', true);

    case GET_OTHER_EXCEPTIONS_SUCCESS:
      return state
        .set('otherExceptions', action.response)
        .set('isLoading', false);

    case GET_OTHER_EXCEPTIONS_FAILURE:
      return state.set('isLoading', false);

    case UPDATE_EXCEPTION_APPROVAL_REQUEST:
      return state.set('isLoading', true);

    case UPDATE_EXCEPTION_APPROVAL_SUCCESS:
      return state
        .set('updateApproval', action.response)
        .set('isLoading', false);

    case UPDATE_EXCEPTION_APPROVAL_FAILURE:
      return state.set('isLoading', false);

    case GET_EXCEPTIONS_BY_CATEGORY_REQUEST:
      return state.set('isLoading', true);

    case GET_EXCEPTIONS_BY_CATEGORY_SUCCESS:
      return state
        .set('exceptCountByCategory', action.response)
        .set('isLoading', false);

    case GET_EXCEPTIONS_BY_CATEGORY_FAILURE:
      return state.set('isLoading', false);

    case GET_EXCEPTIONS_BY_PROGRAM_REQUEST:
      return state.set('isLoading', true);

    case GET_EXCEPTIONS_BY_PROGRAM_SUCCESS:
      return state
        .set('exceptCountByProgram', action.response)
        .set('isLoading', false);

    case GET_EXCEPTIONS_BY_PROGRAM_FAILURE:
      return state.set('isLoading', false);

    case GET_EXCEPTIONS_BY_MONTH_REQUEST:
      return state.set('isLoading', true);

    case GET_EXCEPTIONS_BY_MONTH_SUCCESS:
      return state
        .set('exceptCountByMonth', defaultMonthData(action.response))
        .set('isLoading', false);

    case GET_EXCEPTIONS_BY_MONTH_FAILURE:
      return state.set('isLoading', false);

    default:
      return state;
  }
}
