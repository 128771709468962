import {
  GET_AUTH_CF_FAILURE,
  GET_AUTH_CF_REQUEST,
  GET_AUTH_CF_SUCCESS,
  GET_AUTH_COUNT_MONTH_FAILURE,
  GET_AUTH_COUNT_MONTH_REQUEST,
  GET_AUTH_COUNT_MONTH_SUCCESS,
  GET_AUTH_COUNT_PROGRAM_FAILURE,
  GET_AUTH_COUNT_PROGRAM_REQUEST,
  GET_AUTH_COUNT_PROGRAM_SUCCESS,
  GET_AUTH_DOCS_FAILURE,
  GET_AUTH_DOCS_REQUEST,
  GET_AUTH_DOCS_SUCCESS,
  GET_BUS_CODES_FAILURE,
  GET_BUS_CODES_REQUEST,
  GET_BUS_CODES_SUCCESS,
  GET_CLIENT_FIELDS_FAILURE,
  GET_CLIENT_FIELDS_REQUEST,
  GET_CLIENT_FIELDS_SUCCESS,
  GET_CLIENT_FIELDVAL_FAILURE,
  GET_CLIENT_FIELDVAL_REQUEST,
  GET_CLIENT_FIELDVAL_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_PENDING_APPROVAL_AUTHORIZATIONS_FAILURE,
  GET_PENDING_APPROVAL_AUTHORIZATIONS_REQUEST,
  GET_PENDING_APPROVAL_AUTHORIZATIONS_SUCCESS,
  GET_PENDING_AUTHORIZATIONS_FAILURE,
  GET_PENDING_AUTHORIZATIONS_REQUEST,
  GET_PENDING_AUTHORIZATIONS_SUCCESS,
  GET_PENDING_AUTHORIZATION_FAILURE,
  GET_PENDING_AUTHORIZATION_REQUEST,
  GET_PENDING_AUTHORIZATION_SUCCESS,
  GET_RELO_POLICY_FAILURE,
  GET_RELO_POLICY_REQUEST,
  GET_RELO_POLICY_SUCCESS,
  GET_STATES_FAILURE,
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_SUBMITTED_AUTHS_FAILURE,
  GET_SUBMITTED_AUTHS_REQUEST,
  GET_SUBMITTED_AUTHS_SUCCESS,
  INSERT_AUTH_FAILURE,
  INSERT_AUTH_REQUEST,
  INSERT_AUTH_SUCCESS,
} from './authorizations.actions';
import Immutable from 'immutable';

import { defaultMonthData } from '../../utilities/monthData';

const initialState = Immutable.Map({
  pendingAuthorizations: [],
  pendingApprovalAuthorizations: [],
  pendingAuthorization: [],
  countries: [],
  authCustomFields: [],
  clientFields: [],
  clientFieldValues: [],
  isLoading: false,
  reloPolicies: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_AUTHORIZATIONS_REQUEST:
    case GET_PENDING_APPROVAL_AUTHORIZATIONS_REQUEST:
      return state.set('isLoading', true);

    case GET_PENDING_AUTHORIZATIONS_SUCCESS:
      return state
        .set('pendingAuthorizations', action.response)
        .set('isLoading', false);

    case GET_PENDING_APPROVAL_AUTHORIZATIONS_SUCCESS:
      return state
        .set('pendingApprovalAuthorizations', action.response)
        .set('isLoading', false);

    case GET_PENDING_AUTHORIZATIONS_FAILURE:
    case GET_PENDING_APPROVAL_AUTHORIZATIONS_FAILURE:
      return state.set('isLoading', false);

    case GET_SUBMITTED_AUTHS_REQUEST:
      return state.set('isLoading', true);

    case GET_SUBMITTED_AUTHS_SUCCESS:
      return state
        .set('submittedAuths', action.response)
        .set('isLoading', false);

    case GET_SUBMITTED_AUTHS_FAILURE:
      return state.set('isLoading', false);

    case GET_COUNTRIES_REQUEST:
      return state.set('isLoading', true);

    case GET_COUNTRIES_SUCCESS:
      return state.set('countries', action.response).set('isLoading', false);

    case GET_COUNTRIES_FAILURE:
      return state.set('isLoading', false);

    case GET_STATES_REQUEST:
      return state.set('isLoading', true);

    case GET_STATES_SUCCESS:
      return state.set('states', action.response).set('isLoading', false);

    case GET_STATES_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTH_CF_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTH_CF_SUCCESS:
      return state
        .set('authCustomFields', action.response)
        .set('isLoading', false);

    case GET_AUTH_CF_FAILURE:
      return state.set('isLoading', false);

    case GET_CLIENT_FIELDS_REQUEST:
      return state.set('isLoading', true);

    case GET_CLIENT_FIELDS_SUCCESS:
      return state.set('clientFields', action.response).set('isLoading', false);

    case GET_CLIENT_FIELDS_FAILURE:
      return state.set('isLoading', false);

    case GET_CLIENT_FIELDVAL_REQUEST:
      return state.set('isLoading', true);

    case GET_CLIENT_FIELDVAL_SUCCESS:
      return state
        .set('clientFieldValues', action.response)
        .set('isLoading', false);

    case GET_CLIENT_FIELDVAL_FAILURE:
      return state.set('isLoading', false);

    case GET_PENDING_AUTHORIZATION_REQUEST:
      return state.set('isLoading', true);

    case GET_PENDING_AUTHORIZATION_SUCCESS:
      return state
        .set('pendingAuthorization', action.response)
        .set('isLoading', false);

    case GET_PENDING_AUTHORIZATION_FAILURE:
      return state.set('isLoading', false);

    case GET_RELO_POLICY_REQUEST:
      return state.set('isLoading', true);

    case GET_RELO_POLICY_SUCCESS:
      return state.set('reloPolicies', action.response).set('isLoading', false);

    case GET_RELO_POLICY_FAILURE:
      return state.set('isLoading', false);

    case GET_BUS_CODES_REQUEST:
      return state.set('isLoading', true);

    case GET_BUS_CODES_SUCCESS:
      return state.set('busCodes', action.response).set('isLoading', false);

    case GET_BUS_CODES_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTH_DOCS_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTH_DOCS_SUCCESS:
      return state
        .set('authDocuments', action.response)
        .set('isLoading', false);

    case GET_AUTH_DOCS_FAILURE:
      return state.set('isLoading', false);

    case INSERT_AUTH_REQUEST:
      return state.set('isLoading', true);

    case INSERT_AUTH_SUCCESS:
      return state.set('insertResult', action.response).set('isLoading', false);

    case INSERT_AUTH_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTH_COUNT_PROGRAM_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTH_COUNT_PROGRAM_SUCCESS:
      return state
        .set('authCountsByProgram', action.response)
        .set('isLoading', false);

    case GET_AUTH_COUNT_PROGRAM_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTH_COUNT_MONTH_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTH_COUNT_MONTH_SUCCESS:
      return state
        .set('authCountsByMonth', defaultMonthData(action.response))
        .set('isLoading', false);

    case GET_AUTH_COUNT_MONTH_FAILURE:
      return state.set('isLoading', false);

    default:
      return state;
  }
}
