import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

import {
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import { Button } from '@material-ui/core';
import { currencyFilterPredicate, currencyFormatter } from '../../../utilities/currencyFormatter';
import { dateFilterPredicate, dateFormatter } from '../../../utilities/dateFormatter';

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={dateFormatter}
    {...props}
  />
);

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={currencyFormatter}
    {...props}
  />
);

class VendorExpensesGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: '', title: '', getCellValue: (row) => (<Button
          variant="contained"
          color="primary"
          size="small"
          // className={this.props.classes.viewButton}
          onClick={() => this.props.onLineSelected(row)}
        >
          View Details
        </Button>)},
        { name: 'ExpDate', title: 'Date' },
        { name: 'ReportCategory', title: 'Category' },
        { name: 'ExpDesc', title: 'Description' },
        { name: 'PayAmount', title: 'Amount' },
        { name: 'Type', title: 'Expense Type' },
        { name: 'VendorName', title: 'Vendor' },
      ],
      columnWidths: [
        { columnName: '', width: 150 },
        { columnName: 'ExpDate', width: 150 },
        { columnName: 'ReportCategory', width: 175 },
        { columnName: 'ExpDesc', width: 250 },
        { columnName: 'PayAmount', width: 150 },
        { columnName: 'Type', width: 150 },
        { columnName: 'VendorName', width: 150 },
      ],
      integratedFilteringColumnExtensions: [
        { columnName: 'ExpDate', predicate: dateFilterPredicate },
        { columnName: 'PayAmount', predicate: currencyFilterPredicate },
      ],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['ExpDate'],
      currencyColumns: ['PayAmount'],
      sorting: [{ columnName: 'ExpDate', direction: 'desc' }],
      filters: [],
      totalExpenses: 0,
    };

    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.changeFilters = (filters) => this.setState({ filters });

    this.TableRow = ({ row, ...restProps }) => {
      return (<Table.Row
        {...restProps}
        style={{
          cursor: 'default',
        }}
      />);
    };

    this.changeColumnWidths = (columnWidths) => {
      this.setState({ columnWidths });
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  componentWillMount() {
    this.setState({ rows: this.props.rows });
  }

  // required for editing state
  commitChanges() {

  }

  render() {
    const { rows, columns, pageSize, pageSizes, currentPage, dateColumns, filters, sorting, currencyColumns, columnWidths, integratedFilteringColumnExtensions } = this.state;

    let { totalExpenses } = this.state;

    if (!rows) {
      return <div />;
    } else {
      rows.map((row) => {
        return totalExpenses += row.PayAmount;
      });
    }

    return (
      <Paper style={{ padding: '1rem' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ padding: '1rem', fontSize: '1.5rem', width: '50%' }}>Vendor Expenses</div>
        </div>
        <div>
          <Grid
            rows={rows}
            columns={columns}
          >
            <DateTypeProvider
              for={dateColumns}
            />
            <CurrencyTypeProvider
              for={currencyColumns}
            />
            <EditingState
              onEditingRowIdsChange={this.changeEditingRowIds}
              onCommitChanges={this.commitChanges}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedSorting />
            <FilteringState
              filters={filters}
              onFiltersChange={this.changeFilters}
            />
            <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
            <IntegratedPaging />
            <Table rowComponent={this.TableRow} />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={this.changeColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <PagingPanel
              pageSizes={pageSizes}
            />
          </Grid>
        </div>
      </Paper>
    );
  }
}

export default VendorExpensesGrid;