import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import ReportCard from './reportCard.component';

import { reports } from './reportDefinitions.js';
import { userHasPermission } from '../../utilities/authUtils';

class ContactsContainer extends Component {
  constructor(props) {
    super(props);
    props.setPageTitle('My Reporting');
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  handleNavigation(link) {
    if (link) {
      this.props.history.push(`reporting/${  link}`);
    } else {
      this.props.history.push('reporting');
    }
  }

  render() {
    let { clientDivisions } = this.props;

    return (
      <div style={{ padding: '2rem' }}>
        <Grid container spacing={3} style={{
          rowGap: '0rem',
        }}>
          {reports.map((report, i) => {
            // show coming soon but don't hide card altogether for certain reports
            let { showComingSoon } = report;
            let isAuthorized = false;

            // check for permission-specific report and dont show unless permissionName matches
            if (report.permissionName) {
              if (!userHasPermission(report.permissionName, clientDivisions)) {
                // could not find required permission for report
                if (!showComingSoon) {
                  return null;
                }
              } else {
                isAuthorized = true;
              }
            }

            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <ReportCard key={i} report={report} showComingSoon={showComingSoon && !isAuthorized} handleNavigation={this.handleNavigation} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts.get('contacts'),
    clientDivisions: state.layout.get('clientDivisions'),
  };
};

export default withRouter(connect(mapStateToProps, {
  setPageTitle,
})(ContactsContainer));
