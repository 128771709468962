import { Avatar, Link, Step, StepLabel, Stepper, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const useStyles = makeStyles((theme) => ({
  avatarActive: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  avatarActiveError: {
    backgroundColor: theme.palette.error.main,
    cursor: 'pointer',
  },
  avatar: {
    background: 'none',
    color: theme.palette.primary.main,
    border: '2px solid',
    cursor: 'pointer',
  },
  avatarError: {
    background: 'none',
    color: theme.palette.error.main,
    border: 'none',
    cursor: 'pointer',
  },
  stepper: {
    backgroundColor: 'inherit',
  },
}));

const StepIcon = (props) => {
  const { active, idx, completed, error, isAddEdit } = props;
  const showStepNumber = !isAddEdit || (!error && !active && !completed);

  const theme = useTheme();
  const classes = useStyles();

  const icon = active ? 'pen' : 
    (error ? 'exclamation-triangle' : 'check');

  const iconColor = active ? theme.palette.common.white : 
    error ? theme.palette.error.main :  theme.palette.primary.main;
    
  const avatarClass = active ?  
    (error ? classes.avatarActiveError :  classes.avatarActive) : 
    (error ? classes.avatarError :  classes.avatar);

  return (
    <Avatar classes={{ root: avatarClass }}>
      {
        showStepNumber ? (idx + 1) : <FontAwesomeIcon icon={['fas', icon]} color={iconColor} />
      }
    </Avatar>);
};

const StepLink = forwardRef((props, ref) => {
  const { to, idx, step, className, isAddEdit, ...rest } = props;

  return (
    <RouterLink ref={ref} to={to} className={className} >
      <StepLabel {...rest} StepIconComponent={StepIcon} error={step.hasError} StepIconProps={{ idx, completed: step.isComplete, isAddEdit }}>{step.label}</StepLabel>
    </RouterLink>
  );
});

const PolicyStepper = (props) => {
  const { activeStep, steps, basePath, isAddEdit } = props;
  const classes = useStyles();

  return (
    <Stepper classes={{ root: classes.stepper }} activeStep={activeStep}>
      {steps.map((step, idx) => (
        <Step key={step.label}>
          <Link step={step} idx={idx} isAddEdit={isAddEdit} component={StepLink} to={`${basePath}${step.path}`} underline="none" ><></></Link>
        </Step>
      ))}
    </Stepper>
  );
};

PolicyStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired,
  isAddEdit: PropTypes.bool.isRequired,
};

StepLink.propTypes = {
  to: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  step: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  isAddEdit: PropTypes.bool.isRequired,
};

StepIcon.propTypes = {
  completed: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  idx: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
  isAddEdit: PropTypes.bool.isRequired,
};

export default PolicyStepper;
