import { Button, Chip, IconButton, Typography, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { mergeClientAndMasterQuestion } from './versionUtil';
import ClientBenefitTextInput from 'common/form/ClientBenefitTextInput';
import Dialog from 'common/dialog.component';
import TRCInputValidator from 'common/form/TRCInputValidator';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 350,
  },
  okButton: {
    marginRight: 16,
    marginLeft: 16,
  },
  answersInputRoot: {
    paddingBottom: 14,
  },
};

const initQuestion = () => {
  return {
    tempId: uuidv4(),
    answers: [],
    masterQuestionDetail: {},
  };
};

const formRef = React.createRef();

const BenefitQuestionDialog = (props) => {
  const { open, close: propsClose, classes, selectedQuestion: propsSelectedQuestion, setBenefitQuestion } = props;

  const [selectedQuestion, setSelectedQuestion] = useState(initQuestion());
  const [mergedQuestion, setMergedQuestion] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const hasOverride = !isEmpty(selectedQuestion.masterQuestionDetail) && selectedQuestion.text != null;

  const submit = () => {
    setBenefitQuestion(selectedQuestion);
    close();
  };

  const close = () => {
    setSelectedQuestion(initQuestion());
    propsClose();
  };

  const handleAdd = (answerText) => {
    setSelectedQuestion({
      ...selectedQuestion,
      answers: [...mergedQuestion.answers, answerText],
      text: mergedQuestion.text,
    });
  };

  const handleDelete = (ans) => {
    setSelectedQuestion({
      ...selectedQuestion,
      answers: [...mergedQuestion.answers.filter((a) => a !== ans)],
      text: mergedQuestion.text,
    });
  };

  const handleRevert = () => {
    setSelectedQuestion({
      ...selectedQuestion,
      answers: [],
      text: null,
    });
  };

  useEffect(() => {
    setMergedQuestion(mergeClientAndMasterQuestion(selectedQuestion));
  }, [selectedQuestion]);

  useEffect(() => {
    if (propsSelectedQuestion) {
      setSelectedQuestion({ ...propsSelectedQuestion });
    }
  }, [propsSelectedQuestion]);

  useEffect(() => {
    (async () => {
      if (!formRef.current) {
        return;
      }
      let formValid = await formRef.current.isFormValid();
      formValid = formValid && mergedQuestion.answers.length > 1;
      setIsFormValid(formValid);
    })();
  }, [mergedQuestion]);

  return (
    <Dialog
      open={open}
      title={
        <div className="row-no-basis justify-space-between">
          <Typography variant="h5">
            Benefit Question
          </Typography>
          {hasOverride &&
            <IconButton
              color="primary"
              size="small"
              onClick={handleRevert}
            >
              <FontAwesomeIcon
                icon={['fas', 'undo']}
              />
            </IconButton>
          }
        </div>
      }
      content={
        <ValidatorForm ref={formRef} className={classes.root} onSubmit={() => {}}>
          {!hasOverride &&
            <ClientBenefitTextInput
              clientVersion={selectedQuestion}
              masterVersion={selectedQuestion.masterQuestionDetail}
              versionField="text"
              label="Question"
              name="question"
              onBlur={(value) => setSelectedQuestion({ ...selectedQuestion, answers: [...mergedQuestion.answers], text: value })}
              required
              fullWidth
            />
          }
          {hasOverride &&
            <TRCInputValidator
              component={TextValidator}
              label="Question"
              onChange={(value) => setSelectedQuestion({ ...selectedQuestion, text: value })}
              name="question"
              value={selectedQuestion.text}
              required
              fullWidth
            />
          }
          <ChipInput
            value={mergedQuestion.answers}
            label="Answers"
            InputProps={{ inputProps: { maxlength: 20 }, readOnly: mergedQuestion.answers && mergedQuestion.answers.length > 9 }}
            classes={{ inputRoot: classes.answersInputRoot }}
            chipRenderer={({ chip, handleDelete }) => (
              <Chip
                className="mr"
                key={chip}
                label={chip}
                onDelete={handleDelete}
              />
            )}
            newChipKeys={['Enter', 'Tab']}
            onAdd={handleAdd}
            onDelete={handleDelete}
            margin="normal"
            variant="outlined"
            required
            fullWidth
          />
          <Typography variant="caption">Press Enter after typing an answer</Typography>
        </ValidatorForm>
      }
      actions={
        <div className="row-no-grow justify-end my">
          <Button size="large" onClick={close}>Cancel</Button>
          <Button
            className={classes.okButton}
            size="large"
            variant="contained"
            color="primary"
            onClick={submit}
            disabled={!isFormValid}
          >
            Ok
          </Button>
        </div>
      }
    />
  );
};

BenefitQuestionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  setBenefitQuestion: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedQuestion: PropTypes.object,
};

export default withStyles(styles)(BenefitQuestionDialog);