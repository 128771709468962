import './react-datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import React, { Component } from 'react';
import moment from 'moment';

const styles = (theme) => ({
  datePicker: {
    marginTop: 3,
    border: 0,
    fontSize: 16,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
  },
  datePickerLabel: {
    fontSize: '12px',
    lineHeight: 1,
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
});

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(momentObj) {
    let formatted = momentObj ? momentObj.format('YYYY-MM-DD') : '';
    this.props.onChange(formatted);
  }

  render() {
    const { selected, label, required, classes, disabled } = this.props;

    return (
      <div>
        <label className={classes.datePickerLabel}>{label + (required ? ' *' : '')}</label>
        <DatePicker
          disabled={disabled}
          placeholderText="mm/dd/yyyy"
          className={classes.datePicker}
          selected={(selected ? moment(selected) : null)}
          onChange={this.handleChange} style={{ width: '200px' }}
        />
      </div>
    );
  }
}

CustomDatePicker = withStyles(styles)(CustomDatePicker);

export default CustomDatePicker;