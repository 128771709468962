import {
  GET_AUTHORIZATIONS_SEARCH_FAILURE,
  getAuthorizationSearchResults,
  setActiveTransfereeTab,
} from './transferees.actions';
import {
  TOAST_MESSAGE_SEVERITY_ERROR,
  setPageTitle,
  showToast,
} from '../layout/layout.actions';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import { Box, Button } from '@material-ui/core';
import { getSelectedClientInformation } from 'utilities/authUtils';
import Transferees from './transferees.component';

class TransfereesContainer extends Component {
  constructor(props) {
    super(props);

    this.handleViewSubmittedAuth = this.handleViewSubmittedAuth.bind(this);
  }

  async componentDidMount() {
    this.props.setPageTitle('My Transferees');
    const action = await this.props.getAuthorizationSearchResults();
    if (action.type === GET_AUTHORIZATIONS_SEARCH_FAILURE) {
      this.props.showToast('Failed to load transferees.', {
        severity: TOAST_MESSAGE_SEVERITY_ERROR,
      });
    }
    this.props.setActiveTransfereeTab(0);
  }

  handleViewSubmittedAuth(id) {
    this.props.history.push(`/transferees/${encodeURIComponent(id)}`);
  }

  render() {
    const { authorizationSearchResults, location } = this.props;
    const initialFilters = get(location.state, 'filterActive', false) ? [{
      columnName: 'StatusGrouping',
      value: 'Active',
      operation: 'contains',
    }] : [];

    return (
      <div style={{ padding: '2rem' }}>
        { getSelectedClientInformation().id === '238' ? (
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ float: 'right' }}
            onClick={() =>
              this.props.history.push('reporting/mpcauthorizations?StatusGrouping=Active')
            }
          >
            Export
          </Button>
        ) : null 
        }
        <Box padding="3rem 0 0" height="100%" width="100%">
          <Transferees
            rows={authorizationSearchResults}
            handleViewSubmittedAuth={this.handleViewSubmittedAuth}
            initialFilters={initialFilters}
          />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authorizationSearchResults: state.transferees.get('authorizationSearchResults'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getAuthorizationSearchResults,
    setActiveTransfereeTab,
  })(TransfereesContainer),
);
