import { Box, Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DETECT_INTENT_FAILURE } from './dialogFlow.types';
import { RequestStatusEnum } from 'index.types';
import { TOAST_MESSAGE_SEVERITY_ERROR, showToast } from 'modules/layout/layout.actions';
import { UPDATE_POLICY_BOT_FAILURE } from '../policy.types';
import { detectIntent } from './dialogFlow.actions';
import { toDialogFlowVersion, updatePolicyBot } from 'modules/policies/policy.actions';
import BotUpdateProgress from './botUpdateProgress.component';
import Log from 'utilities/log';
import MessageCompose from './messageCompose.component';
import MessageList from './messageList.component';
import PolicyContext from 'modules/policies/policyContext';

const MY_USER_ID = 'apple';

const INITIAL_MESSAGE = 'I am Mobi and am here in partnership with your employer. I will be helping provide you with recommended services to ensure you have a positive relocation experience.';
const WAKE_UP_BOT_REPLY = 'Refresh our conversation ⏰';
const BOT_SLEEPING_MESSAGE = 'Oops, you caught me sleeping 💤';
const BOT_OUT_OF_DATE_MESSAGE = 'I seem to have forgotten the details for this policy. Refresh the page to get me up to speed.';
const BOT_TIMEOUT = 1000 * 60 * 15;
const BOT_DELETE_TIMEOUT = 1000 * 60 * 60;
const REQUIRES_USER_INPUT = 'REQUIRES_USER_INPUT';

const useStyles = makeStyles((theme) => ({
  botWindow: {
    minHeight: '60vh',
    width: 500,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  messageComposeWrapper : {
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.divider,
  },
  messageComposeWrapperEnabled : {
    boxShadow: '0px 4px 8px 3px #67a8859e',
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
}));

const MessengerContainer = (props) => {
  const [messages, setMessages] = useState([]);
  const [quickReplies, setQuickReplies] = useState([]);
  const [cardOptions, setCardOptions] = useState([]);
  const [replyTimeout, setReplyTimeout] = useState(null);
  const [isReplying, setIsReplying] = useState(false);
  const [isBotCreated, setIsBotCreated] = useState(false);
  const [isUserInputEnabled, setIsUserInputEnabled] = useState(false);
  const [showBotUpdateProgress, setShowBotUpdateProgress] = useState(false);
  const [isBotInitialized, setIsBotInitialized] = useState(false);
  const [botTimeout, setBotTimeout] = useState(null);
  const [botDeleteTimeout, setBotDeleteTimeout] = useState(null);

  const { version, setVersion, setIsBotUpToDate, isBotUpToDate, updateBotStatus, setUpdateBotStatus, updateBotResponse, setUpdateBotResponse, isDirty, basePath } = useContext(PolicyContext);

  const { detectIntent, showToast, updatePolicyBot, isActive, isValid, costsNeedCalculating, sessionId, resetSessionId, areAnyBenefitsInUse } = props;
  const classes = useStyles();

  const addMessageFromBot = (message) => {
    setMessages((m) => [{
      author: 'bot;',
      message,
      timestamp: new Date().getTime(),
    }, ...m]);
  };

  const resetMessages = useCallback(() => {
    setMessages([]);
    addMessageFromBot(INITIAL_MESSAGE);
  }, []);

  const resetChatOptions = useCallback(() => {
    setCardOptions(null);
    setQuickReplies(null);
  }, []);

  const addTimeoutMessage = useCallback(() => {
    resetChatOptions();
    setMessages((msgs) => {
      msgs.pop();
      return [...msgs];
    });
    addMessageFromBot(BOT_SLEEPING_MESSAGE);
    setQuickReplies([WAKE_UP_BOT_REPLY]);
  }, [resetChatOptions]);

  const addBotOutOfDateMessage = useCallback(() => {
    resetChatOptions();
    setMessages((msgs) => {
      msgs.pop();
      return [...msgs];
    });
    addMessageFromBot(BOT_OUT_OF_DATE_MESSAGE);
  }, [resetChatOptions]);

  const resetBot = useCallback(() => {
    resetSessionId();
    resetChatOptions();
    resetMessages();
    setIsBotInitialized(false);
  }, [resetMessages, resetChatOptions, resetSessionId]);

  const parseResponse = useCallback((messages) => {
    const cards = [];
    let requiresUserInput = false;
    messages.forEach((message) => {
      if (message.quickReplies) {
        addMessageFromBot(message.quickReplies.title);
        setQuickReplies(message.quickReplies.quickReplies);
      } else if (message.basicCard) {
        cards.push(message.basicCard);
      } else if (message.text) {
        const messageText = message.text.text[0];
        // ES agent can't persist the requiresUserInput parameter for multiple conversation turns, so must check the messages for it
        if (messageText === REQUIRES_USER_INPUT) {
          requiresUserInput = true;
        } else {
          addMessageFromBot(messageText);
        }
      } else {
        Log.error('unrecognized message type');
      }
    });
    if (cards.length) {
      setCardOptions(cards);
    }
    setIsUserInputEnabled(requiresUserInput);
    return cards;
  }, []);

  const addQuickReply = useCallback((reply) => {
    resetChatOptions();
    setMessages((m) => [{
      author: MY_USER_ID,
      message: reply,
      timestamp: new Date().getTime(),
    }, ...m]);
  }, [resetChatOptions]);

  const addSelectedCard = useCallback((option) => {
    resetChatOptions();
    setMessages((m) => [{
      author: MY_USER_ID,
      isCardOption: true,
      message: option,
      timestamp: new Date().getTime(),
    }, ...m]);
  }, [resetChatOptions]);

  const callDetectIntent = useCallback(async (body, parser) => {
    setIsReplying(true);
    setIsUserInputEnabled(false);
    if (botTimeout) {
      clearTimeout(botTimeout);
    }
    if (botDeleteTimeout) {
      clearTimeout(botDeleteTimeout);
    }
    setBotTimeout(setTimeout(addTimeoutMessage, BOT_TIMEOUT));
    setBotDeleteTimeout(setTimeout(addBotOutOfDateMessage, BOT_DELETE_TIMEOUT));
    const start = Date.now();
    const action = await detectIntent(sessionId, version.intentName, body);
    if (action.type === DETECT_INTENT_FAILURE) {
      Log.error('Failed to connect to bot');
      return;
    }
    const end = Date.now();
    const reply = () => {
      setIsReplying(false);
      parser(action.response.queryResult);
    };
    if (replyTimeout) clearTimeout(replyTimeout);
    const elapsed = end - start;
    if (elapsed < 750) {
      setReplyTimeout(setTimeout(reply, 750 - elapsed));
    } else {
      reply();
    }
  }, [detectIntent, replyTimeout, sessionId, addTimeoutMessage, addBotOutOfDateMessage, botTimeout, botDeleteTimeout, version.intentName]);

  const initializeChat = useCallback((versionId) => {
    resetChatOptions();
    setMessages([]);
    addMessageFromBot(INITIAL_MESSAGE);
    const body = { event: { name: versionId, languageCode: 'en' } };
    callDetectIntent(body, (queryResult) => parseResponse(queryResult.fulfillmentMessages));
  }, [parseResponse, callDetectIntent, resetChatOptions]);
 
  const handleUserResponse = useCallback((userResponse) => {
    const body = { text: { text: userResponse, languageCode: 'en' } };
    callDetectIntent(body, (queryResult) => parseResponse(queryResult.fulfillmentMessages));
  }, [callDetectIntent, parseResponse]);

  const quickReplyChosen = useCallback(async (reply) => {
    if (reply === WAKE_UP_BOT_REPLY) {
      setIsBotInitialized(false);
    } else {
      addQuickReply(reply);
      await handleUserResponse(reply);
    }
  }, [handleUserResponse, addQuickReply]);

  const optionChosen = useCallback(async (option) => {
    addSelectedCard(option);
    await handleUserResponse(option.title);
  }, [handleUserResponse, addSelectedCard]);

  const botWindowErrorMessage = useMemo(() => {
    if (!isValid) {
      return 'Validation errors need to be resolved prior to loading Mobi.';
    } else if (isDirty) {
      return (<>The policy has changed since Mobi was last updated.  Please <strong>save the draft</strong> before updating Mobi.</>);
    } else if (!areAnyBenefitsInUse) {
      return (<>No benefits are enabled. Go <Link to={basePath}>here</Link> to enable benefits.</>);
    }
  }, [isValid, isDirty, areAnyBenefitsInUse, basePath]);

  const loadBot = async () => {
    setShowBotUpdateProgress(true);
    setUpdateBotStatus(RequestStatusEnum.PENDING);
    let dialogFlowVersion;
    try {
      dialogFlowVersion = await toDialogFlowVersion(version);
    } catch (e) {
      setUpdateBotStatus(RequestStatusEnum.FAILED);
      return;
    }
    const updateBotAction = await updatePolicyBot(dialogFlowVersion);
    if (updateBotAction.type === UPDATE_POLICY_BOT_FAILURE) {
      setUpdateBotStatus(RequestStatusEnum.FAILED);
    } else {
      setUpdateBotResponse(updateBotAction.response);
      setUpdateBotStatus(RequestStatusEnum.SUCCESS);
    }
  };

  const handleUpdateBotStatusChange = useCallback((requestStatus, response) => {
    switch (requestStatus){
      case RequestStatusEnum.PENDING:
        setShowBotUpdateProgress(true);
        setIsBotUpToDate(false);
        setIsBotCreated(false);
        break;
      case RequestStatusEnum.FAILED:
        setShowBotUpdateProgress(false);
        setIsBotUpToDate(false);
        setIsBotCreated(false);
        showToast('Failed to update Mobi. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        break;
      case RequestStatusEnum.SUCCESS:
        setVersion((v) => {
          v.modifiedDate = response.modifiedDate;
          v.intentName = response.intentName;
          return v;
        });
        setIsBotCreated(true);
        setIsBotUpToDate(true);
        break;
      default:
    }
  }, [setIsBotUpToDate, setVersion, showToast]);

  useEffect(() => {
    handleUpdateBotStatusChange(updateBotStatus, updateBotResponse);
  }, [updateBotStatus, updateBotResponse, handleUpdateBotStatusChange]);

  useEffect(() => {
    setIsBotCreated(isBotUpToDate);
  }, [isBotUpToDate]);

  useEffect(() => {
    if (!isBotCreated) {
      resetBot();
    }
  }, [isBotCreated, resetBot]);

  useEffect(() => {
    setIsBotInitialized(false);
  }, [sessionId]);

  useEffect(() => {
    if (!isBotInitialized && isBotCreated && isActive && !costsNeedCalculating && version.versionId) {
      initializeChat(version.versionId);
      setIsBotInitialized(true);
    }
  }, [isBotCreated, isActive, isBotInitialized, costsNeedCalculating, version.versionId, initializeChat]);

  return (
    <Box width={500}>
      <Grid container direction="column" alignItems="center">
        {(isBotCreated && !showBotUpdateProgress) &&
          <Grid item>
            <Button size="large" color="primary" variant="contained" onClick={resetBot}>Reset Mobi</Button>
          </Grid>
        }
        <Grid item>
          <Paper className={classes.botWindow}>

            {
              showBotUpdateProgress ?
                <Grid container direction="row" wrap="nowrap">
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <BotUpdateProgress setShowBotUpdateProgress={setShowBotUpdateProgress} />
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                :
                (
                  !isBotCreated ?
                    (
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Button size="large" color="primary" variant="contained" disabled={!isValid || showBotUpdateProgress || isDirty || !areAnyBenefitsInUse} onClick={loadBot}>Update Mobi</Button>
                        </Grid>
                        {botWindowErrorMessage &&
                          <Grid item>
                            <Typography variant="subtitle2" color="primary">{botWindowErrorMessage}</Typography>
                          </Grid>
                        }
                      </Grid>
                    ) :
                    !costsNeedCalculating ?
                      <Grid item container direction="column" justify="flex-end">
                        <Grid item>
                          <MessageList
                            messages={messages}
                            quickReplies={quickReplies}
                            cardOptions={cardOptions}
                            currentUser={MY_USER_ID}
                            isReplying={isReplying}
                            quickReplyChosen={quickReplyChosen}
                            optionChosen={optionChosen}
                          />
                        </Grid>
                        <Grid container item alignItems="center" direction="row" className={isUserInputEnabled ? classes.messageComposeWrapperEnabled : classes.messageComposeWrapper}>
                          <MessageCompose
                            disabled={!isUserInputEnabled}
                            addMessageFromUser={(message)=> { quickReplyChosen(message); }}
                          />
                        </Grid>
                      </Grid> :
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography variant="subtitle2" color="primary">The authorization info must be saved before Mobi can be previewed.</Typography>
                        </Grid>
                      </Grid>
                )
            }
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

MessengerContainer.propTypes = {
  detectIntent : PropTypes.func.isRequired,
  showToast : PropTypes.func.isRequired,
  updatePolicyBot : PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  costsNeedCalculating: PropTypes.bool.isRequired,
  sessionId: PropTypes.string.isRequired,
  resetSessionId: PropTypes.func.isRequired,
  areAnyBenefitsInUse: PropTypes.bool.isRequired,
};

export default connect(null, {
  detectIntent,
  showToast,
  updatePolicyBot,
})(MessengerContainer);