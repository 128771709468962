import { CALL_API } from '../../middleware/api';
import {getSelectedClientInformation} from '../../utilities/authUtils';

export const GET_PENDING_EXCEPTIONS_REQUEST = 'GET_EXCEPTIONS_REQUEST';
export const GET_PENDING_EXCEPTIONS_SUCCESS = 'GET_EXCEPTIONS_SUCCESS';
export const GET_PENDING_EXCEPTIONS_FAILURE = 'GET_EXCEPTIONS_FAILURE';
export const GET_PENDING_EXCEPTION_DETAIL_REQUEST = 'GET_PENDING_EXCEPTION_DETAUL_REQUEST';
export const GET_PENDING_EXCEPTION_DETAIL_SUCCESS = 'GET_PENDING_EXCEPTION_DETAUL_SUCCESS';
export const GET_PENDING_EXCEPTION_DETAIL_FAILURE = 'GET_PENDING_EXCEPTION_DETAUL_FAILURE';
export const GET_OTHER_EXCEPTIONS_REQUEST = 'GET_OTHER_EXCEPTIONS_REQUEST';
export const GET_OTHER_EXCEPTIONS_SUCCESS = 'GET_OTHER_EXCEPTIONS_SUCCESS';
export const GET_OTHER_EXCEPTIONS_FAILURE = 'GET_OTHER_EXCEPTIONS_FAILURE';
export const UPDATE_EXCEPTION_APPROVAL_REQUEST = 'UPDATE_EXCEPTION_APPROVAL_REQUEST';
export const UPDATE_EXCEPTION_APPROVAL_SUCCESS = 'UPDATE_EXCEPTION_APPROVAL_SUCCESS';
export const UPDATE_EXCEPTION_APPROVAL_FAILURE = 'UPDATE_EXCEPTION_APPROVAL_FAILURE';
export const GET_EXCEPTIONS_BY_PROGRAM_REQUEST = 'GET_EXCEPTIONS_BY_PROGRAM_REQUEST';
export const GET_EXCEPTIONS_BY_PROGRAM_SUCCESS = 'GET_EXCEPTIONS_BY_PROGRAM_SUCCESS';
export const GET_EXCEPTIONS_BY_PROGRAM_FAILURE = 'GET_EXCEPTIONS_BY_PROGRAM_FAILURE';
export const GET_EXCEPTIONS_BY_CATEGORY_REQUEST = 'GET_EXCEPTIONS_BY_CATEGORY_REQUEST';
export const GET_EXCEPTIONS_BY_CATEGORY_SUCCESS = 'GET_EXCEPTIONS_BY_CATEGORY_SUCCESS';
export const GET_EXCEPTIONS_BY_CATEGORY_FAILURE = 'GET_EXCEPTIONS_BY_CATEGORY_FAILURE';
export const GET_EXCEPTIONS_BY_MONTH_REQUEST = 'GET_EXCEPTIONS_BY_MONTH_REQUEST';
export const GET_EXCEPTIONS_BY_MONTH_SUCCESS = 'GET_EXCEPTIONS_BY_MONTH_SUCCESS';
export const GET_EXCEPTIONS_BY_MONTH_FAILURE = 'GET_EXCEPTIONS_BY_MONTH_FAILURE';




export const getPendingExceptions = () => {
  return {
    [CALL_API]: {
      types: [GET_PENDING_EXCEPTIONS_REQUEST, GET_PENDING_EXCEPTIONS_SUCCESS, GET_PENDING_EXCEPTIONS_FAILURE],
      authenticated: true,
      endpoint: 'Exception/GetPendingExceptions',
      method: 'POST',
      payload: {},
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getPendingException = (exceptionID) => {
  return {
    [CALL_API]: {
      types: [GET_PENDING_EXCEPTION_DETAIL_REQUEST, GET_PENDING_EXCEPTION_DETAIL_SUCCESS, GET_PENDING_EXCEPTION_DETAIL_FAILURE],
      authenticated: true,
      endpoint: 'Exception/GetPendingExceptionDetail',
      method: 'POST',
      payload: {
        ExceptionID: exceptionID,
        ClientId: parseInt(getSelectedClientInformation().id)
      },
    },
  };
};

export const getOtherExceptions = (exceptionID) => {
  return {
    [CALL_API]: {
      types: [GET_OTHER_EXCEPTIONS_REQUEST, GET_OTHER_EXCEPTIONS_SUCCESS, GET_OTHER_EXCEPTIONS_FAILURE],
      authenticated: true,
      endpoint: 'Exception/GetOtherExceptions',
      method: 'POST',
      payload: {
        ExceptionID: exceptionID,
      },
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const updateExceptionApprovalStatus = (payload) => {
  return {
    [CALL_API]: {
      types: [UPDATE_EXCEPTION_APPROVAL_REQUEST, UPDATE_EXCEPTION_APPROVAL_SUCCESS, UPDATE_EXCEPTION_APPROVAL_FAILURE],
      authenticated: true,
      endpoint: 'Exception/UpdateExceptionApprovalStatus',
      method: 'POST',
      payload,
    },
  };
};

export const getExceptionsByCategory = () => {
  return {
    [CALL_API]: {
      types: [GET_EXCEPTIONS_BY_CATEGORY_REQUEST, GET_EXCEPTIONS_BY_CATEGORY_SUCCESS, GET_EXCEPTIONS_BY_CATEGORY_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetExceptionByCategory',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getExceptionsByProgram = () => {
  return {
    [CALL_API]: {
      types: [GET_EXCEPTIONS_BY_PROGRAM_REQUEST, GET_EXCEPTIONS_BY_PROGRAM_SUCCESS, GET_EXCEPTIONS_BY_PROGRAM_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetExceptionByProgram',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getExceptionByMonth = () => {
  return {
    [CALL_API]: {
      types: [GET_EXCEPTIONS_BY_MONTH_REQUEST, GET_EXCEPTIONS_BY_MONTH_SUCCESS, GET_EXCEPTIONS_BY_MONTH_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetExceptionByMonth',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};
