import React from 'react';

import Spinner from './logoSpinner.component';

function FullscreenSpinner(props) {
  const { size } = props;
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Spinner logo size={size} />
    </div>
  );
}

export default FullscreenSpinner;
