import { Bar, Line } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import ChartDetailGridByMonth from './chartDetailGridByMonth.component';
import ChartDetailGridByProgram from './chartDetailGridByProgram.component';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const defaultDataset = {
  fill: true,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

let byMonthOptions = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: 'Total Authorizations By Month',
    fontSize: 18,
  },
  // tooltips: {
  //   mode: 'index',
  //   intersect: false,
  // },
  // hover: {
  //   mode: 'nearest',
  //   intersect: true
  // },
  scales: {
    xAxes: [{
      display: true,
      scaleLabel: {
        display: false,
        labelString: 'Month',
      },
    }],
    yAxes: [{
      display: true,
      ticks: {
        min: 0,
        callback: (value, index, values) => {
          if (Math.floor(value) === value) {
            return value;
          }
        },
      },
      // scaleLabel: {
      //   display: true,
      //   labelString: 'Authorizations'
      // }
    }],
  },
};

let byProgramOptions = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: 'Total Authorizations By Program',
    fontSize: 18,
  },
  // tooltips: {
  //   mode: 'index',
  //   intersect: false,
  // },
  // hover: {
  //   mode: 'nearest',
  //   intersect: true
  // },
  scales: {
    xAxes: [{
      display: true,
      ticks: {
        fontSize: 8,
      },
      scaleLabel: {
        display: false,
        labelString: 'Program',
      },
    }],
    yAxes: [{
      display: true,
      ticks: {
        min: 0,
        callback: (value, index, values) => {
          if (Math.floor(value) === value) {
            return value;
          }
        },
      },
      // scaleLabel: {
      //   display: true,
      //   labelString: 'Authorizations'
      // }
    }],
  },
};

const styles = (theme) => ({
  chartDetailPaper: {
    height: 'calc(100% - 48px)',
  },
  tab: {
    width: 130,
  },
});

class AuthorizationChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedChartTabIndex: 0,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.getMonthChartDataSets = this.getMonthChartDataSets.bind(this);
    this.getProgramChartDataSets = this.getProgramChartDataSets.bind(this);
  }

  handleTabChange = (event, value) => {
    this.setState({ selectedChartTabIndex: value });
  };

  getMaxValue(ary) {
    return Math.max(...ary);
  }

  getMonthChartDataSets(rows) {
    if (rows) {
      rows.sort(function (a, b) {
        return a.MonthNumber - b.MonthNumber;
      });
      const labels = rows.map((row) => row.MonthName);
      const prevData = rows.map((row) => row.PreviousYear);
      const curData = rows.map((row) => row.CurrentYear);

      // set max y axis value dynamically
      const maxValue = this.getMaxValue(prevData.concat(curData));
      byMonthOptions.scales.yAxes[0].ticks.max = maxValue + Math.ceil(maxValue * .1);

      const datasets = [
        {
          ...defaultDataset,
          label: 'Current Year',
          backgroundColor: 'rgba(	54, 156, 144,0.4)',
          borderColor: 'rgba(54, 156, 144, 1)',
          pointBorderColor: 'rgba(54, 156, 144, 1)',
          pointHoverBackgroundColor: 'rgba(54, 156, 144, 1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: curData,
        },
        {
          ...defaultDataset,
          label: 'Prior Year',
          backgroundColor: 'rgba(7.8%, 27.5%, 29.8%, 0.4)',
          borderColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointBorderColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointHoverBackgroundColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: prevData,
        },
      ];

      return {
        datasets,
        labels,
      };
    }
  }

  getProgramChartDataSets(rows) {
    if (rows) {
      // rows.sort(function (a, b) {
      //   return a.MonthNumber - b.MonthNumber;
      // });
      const labels = rows.map((row) => row.ReloPolicy);
      const prevData = rows.map((row) => row.PreviousYear);
      const curData = rows.map((row) => row.CurrentYear);

      // set max y axis value dynamically
      const maxValue = this.getMaxValue(prevData.concat(curData));
      byProgramOptions.scales.yAxes[0].ticks.max = maxValue + Math.ceil(maxValue * .1);

      const datasets = [
        {
          ...defaultDataset,
          label: 'Current Year',
          backgroundColor: 'rgba(	54, 156, 144,0.4)',
          borderColor: 'rgba(54, 156, 144, 1)',
          pointBorderColor: 'rgba(54, 156, 144, 1)',
          pointHoverBackgroundColor: 'rgba(54, 156, 144, 1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: curData,
        },
        {
          ...defaultDataset,
          label: 'Prior Year',
          backgroundColor: 'rgba(7.8%, 27.5%, 29.8%, 0.4)',
          borderColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointBorderColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointHoverBackgroundColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: prevData,
        },
      ];

      return {
        datasets,
        labels,
      };
    }
  }

  render() {
    const { chartData, classes } = this.props;
    const { selectedChartTabIndex } = this.state;

    if (!chartData.authCountsByMonth || !chartData.authCountsByProgram) {
      return <div />;
    }

    const monthRows = chartData.authCountsByMonth;
    const programRows = chartData.authCountsByProgram;

    const monthChartDataSets = this.getMonthChartDataSets(monthRows);
    const programChartDataSets = this.getProgramChartDataSets(programRows);

    return (
      <Paper style={{ padding: '1rem' }}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Paper style={{ width: '100%', height: '100%', minHeight: '225px', overflow: 'hidden', position: 'relative' }}>
              {selectedChartTabIndex === 0 &&
                <Line data={monthChartDataSets} options={byMonthOptions} />
              }
              {selectedChartTabIndex === 1 &&
                <Bar data={programChartDataSets} options={byProgramOptions} />
              }
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppBar position="static">
              <Tabs
                value={selectedChartTabIndex}
                onChange={this.handleTabChange}
                style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}
              >
                <Tab label="By Month" classes={{ root: classes.tab }} />
                <Tab label="By Program" />
              </Tabs>
            </AppBar>
            {selectedChartTabIndex === 0 &&
              <Paper className={classes.chartDetailPaper}>
                <ChartDetailGridByMonth rows={monthRows} />
              </Paper>
            }
            {selectedChartTabIndex === 1 &&
              <Paper className={classes.chartDetailPaper}>
                <ChartDetailGridByProgram rows={programRows} />
              </Paper>
            }
          </Grid>
        </Grid>
      </Paper >
    );
  }
}

AuthorizationChart = withStyles(styles, { withTheme: true })(AuthorizationChart);

export default AuthorizationChart;