import {
  SET_LOGGED_IN,
} from './login.actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  isLoggedIn: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOGGED_IN:
      return state.set('isLoggedIn', action.payload);

    default:
      return state;
  }
}
