import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDateTime } from '../../../utilities/dateFormatter';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
// import { BASE_URL } from '../../../middleware/api';
import ReadOnlyText from '../../../common/readOnlyText.component';

const styles = (theme) => ({
  dialogPaper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  row: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
});

class NoteDetailDialog extends React.Component {
  handleClose = () => {
    this.props.handleResponse(this.props.type);
  };


  render() {
    const { open, title, classes, noteData, downloadAttachment } = this.props;

    if (!noteData) {
      return <div />;
    }

    const {
      CommentText,
      Service, // category
      Subject,
      FullName, // author
      CommentDate,
      HasAttachment,
      CommentID,
      AttachmentFilename,
    } = noteData;

    return (
      <div>
        <Dialog
          classes={{
            paper: classes.dialogPaper,
          }}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <div className={classes.row}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <ReadOnlyText
                    id="Author"
                    label="Author"
                    value={FullName ? FullName : ' '}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ReadOnlyText
                    id="Date"
                    label="Date"
                    value={CommentDate ? getDateTime(CommentDate) : ' '}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <ReadOnlyText
                id="Category"
                label="Category"
                value={Service ? Service : ' '}
                margin="normal"
              />
            </div>
            {Subject &&
              <div className={classes.row}>
                <ReadOnlyText
                  id="Subject"
                  label="Subject"
                  value={Subject ? Subject : ' '}
                  margin="normal"
                />
              </div>
            }
            {HasAttachment &&
              <div className={classes.row} style={{ marginTop: '1rem' }}>
                {/* eslint-disable-next-line */}
                <a style={{ textDecoration: 'none', color: 'rgb(148,148,148)' }}
                  onClick={() => { downloadAttachment(CommentID); }}
                >
                  <IconButton className={classes.button} aria-label="Download">
                    <FontAwesomeIcon icon="paperclip" />
                  </IconButton>
                  {AttachmentFilename || 'Download Attachment'}
                </a>
              </div>
            }
            <div className={classes.row}>
              <ReadOnlyText
                id="Note"
                label=""
                multiline
                value={CommentText ? CommentText : ' '}
                margin="normal"
                className={classes.textField}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

NoteDetailDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  downloadAttachment: PropTypes.func.isRequired,
};

NoteDetailDialog = withStyles(styles, { withTheme: true })(NoteDetailDialog);

export default withMobileDialog()(NoteDetailDialog);