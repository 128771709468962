import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

const styles = (theme) => ({
  paperSection: {
    width: '100%',
    padding: '1rem',
    marginBottom: '0.75rem',
  },
  errorText: {
    color: 'red',
    fontSize: '1.25rem',
    display: 'inline',
    verticalAlign: 'middle',
  },
  errorList: {
    fontSize: '1rem',
  },
  readyText: {
    color: 'green',
    fontSize: '1.25rem',
    display: 'inline',
    marginLeft: '0.25rem',
    verticalAlign: 'middle',
  },
  readyIcon: {
    color: 'green',
    fontSize: '1.25rem',
    verticalAlign: 'middle',
  },
  errorIcon: {
    color: 'red',
    marginRight: '0.25rem',
    fontSize: '1.25rem',
    verticalAlign: 'middle',
  },
});

class SummaryForm extends Component {
  render() {
    const {
      classes,
      formData,
      isSubmitted,
      authWasSubmittedForApproval,
      clientRequiresApproval,
      userIsApprover
    } = this.props;
    const { errors } = formData;

    let title = 'Submit Authorization';
    let message =
      'Any edits to your submitted authorizations can be made by contacting your TRC agent.';
    let readyText = 'You are now ready to submit your authorization';

    if(isSubmitted){
      readyText = 'Your authorization has been submitted and is pending approval by TRC';
    }
    else if (clientRequiresApproval) {
      if (!userIsApprover) {
        if (!authWasSubmittedForApproval) {
          title = 'Submit For Approval';
          message = 'Your authorization will be submitted for appproval';
          readyText =
            'You are now ready to submit your authorization for approval';
        } else {
          title = 'Authorization Submitted For Approval';
          message =
          'Any edits to your submitted authorizations can still be saved.';
          readyText = 'Your authorization has been submitted and is pending approval';
        }
      } else {
        if (authWasSubmittedForApproval) {
          title = 'Authorization Submitted For Approval';
          message =
          'Please review and approve this authorization.  Any edits can still be saved.';
          readyText = 'This authorization has been submitted and is pending your approval';
        }
      }
    }

    return (
      <Paper>
        <div style={{ padding: '1rem' }}>
          <h3>{title}</h3>
          <div style={{ marginBottom: '2rem' }}>{message}</div>

          <h3>Authorization Completion Status</h3>
          {errors.length > 0 && (
            <div>
              <div>
                <Hidden smDown>
                  <FontAwesomeIcon
                    className={classes.errorIcon}
                    icon="exclamation-triangle"
                  />
                </Hidden>
                <div className={classes.errorText}>
                  Your authorization has the following errors:
                </div>
              </div>
              <ul>
                {errors.map((e, i) => {
                  return (
                    <li key={i} className={classes.errorList}>
                      {e}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {errors.length <= 0 && (
            <div>
              <FontAwesomeIcon icon="check" className={classes.readyIcon} />
              <div className={classes.readyText}>{readyText}</div>
            </div>
          )}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedAuth: state.authorizations.get('pendingAuthorization'),
    authDocuments: state.authorizations.get('authDocuments'),
  };
};

SummaryForm = withStyles(styles)(SummaryForm);

export default connect(mapStateToProps, {})(SummaryForm);
