import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TextFit from 'react-textfit';

import { Getter } from '@devexpress/dx-react-core';

import {
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import { EditCommandCell } from '../../../common/tableEditCommandCell';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS, showToast } from '../../layout/layout.actions';
import { currencyFilterPredicate, currencyFormatter } from '../../../utilities/currencyFormatter';
import { dateFormatter } from '../../../utilities/dateFormatter';
import { getExceptionsByAuthorizationId } from '../transferees.actions';
import { updateExceptionApprovalStatus } from '../../exceptions/exceptions.actions';
import ConfirmModal from '../../../common/confirmModal.component';

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={dateFormatter}
    {...props}
  />
);

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={currencyFormatter}
    {...props}
  />
);

class ExceptionsGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'FirstName', title: 'First Name' },
        { name: 'LastName', title: 'Last Name' },
        { name: 'ExceptDate', title: 'Request Date' },
        { name: 'ExceptAmount', title: 'Requested Amount' },
        { name: 'ExceptCategory', title: 'Category' },
        { name: 'Program', title: 'Program' },
        { name: 'ApprovedAmount', title: 'Approved Amount' },
        { name: 'ActualAmount', title: 'Actual Amount' },
        { name: 'Status', title: 'Status' },
      ],
      tableColumnExtensions: [
        { columnName: 'FirstName', width: 125 },
        { columnName: 'LastName', width: 125 },
        { columnName: 'ExceptDate', width: 125 },
        { columnName: 'ExceptAmount', width: 150 },
        { columnName: 'ExceptCategory', width: 125 },
        { columnName: 'Program', width: 125 },
        { columnName: 'ApprovedAmount', width: 150 },
        { columnName: 'ActualAmount', width: 150 },
        { columnName: 'Status', width: 125 },
      ],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['ExceptDate'],
      integratedFilteringColumnExtensions: [
        { columnName: 'ExceptAmount', predicate: currencyFilterPredicate },
        { columnName: 'ApprovedAmount', predicate: currencyFilterPredicate },
        { columnName: 'ActualAmount', predicate: currencyFilterPredicate },
      ],
      currencyColumns: ['ExceptAmount', 'ApprovedAmount', 'ActualAmount'],
      sorting: [{ columnName: 'ExceptDate', direction: 'desc' }],
      filters: [],
      modal: {
        selectedRow: null,
        modalState: false,
        modalType: '',
      },
      totalApproved: 0,
    };

    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.changeFilters = (filters) => this.setState({ filters });

    this.TableRow = ({ row, ...restProps }) => {
      return (<Table.Row
        {...restProps}
        style={{
          cursor: 'default',
        }}
      />);
    };

    this.handleDenyModalToggle = this.handleDenyModalToggle.bind(this);
    this.handleApprovalModalToggle = this.handleApprovalModalToggle.bind(this);
    this.handleExceptionApproval = this.handleExceptionApproval.bind(this);
    this.handleExceptionDeny = this.handleExceptionDeny.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  componentWillMount() {
    this.setState({ rows: this.props.rows });
  }

  handleApprovalModalToggle(props) {
    this.setState((prevState) => ({
      modal: {
        modalState: !prevState.modal.modalState,
        modalType: 'approve',
        selectedRow: props.row,
      },
    }));
  }

  handleDenyModalToggle(props) {
    this.setState((prevState) => ({
      modal: {
        modalState: !prevState.modal.modalState,
        modalType: 'deny',
        selectedRow: props.row,
      },
    }));
  }

  async handleExceptionApproval() {
    const { selectedRow } = this.state.modal;

    const approved = Object.assign({}, selectedRow, {
      Comment: 'Approved from Employee Expenses',
      ApprovalAmount: selectedRow.ExceptAmount,
      ExceptionId: selectedRow.ExceptID,
      IsApproved: true,
    });

    let response = await this.props.updateExceptionApprovalStatus(approved);

    if (response.type === 'UPDATE_EXCEPTION_APPROVAL_SUCCESS') {
      this.props.showToast('Successfully approved exception.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });

      let response = await this.props.getExceptionsByAuthorizationId(selectedRow.AuthorizationID);
      this.setState({
        modal: { modalState: false },
        rows: response.response,
      });

    } else {
      this.props.showToast('Error approving exception. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  async handleExceptionDeny() {
    const { selectedRow } = this.state.modal;
    const unapproved = Object.assign({}, selectedRow, {
      Comment: 'Unapproved from Employee Expenses',
      ExceptionId: selectedRow.ExceptID,
      IsApproved: false,
    });

    let response = await this.props.updateExceptionApprovalStatus(unapproved);

    if (response.type === 'UPDATE_EXCEPTION_APPROVAL_SUCCESS') {
      this.props.showToast('Successfully denied exception.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });

      let response = await this.props.getExceptionsByAuthorizationId(selectedRow.AuthorizationID);
      this.setState({
        modal: { modalState: false },
        rows: response.response,
      });
    } else {
      this.props.showToast('Error denying exception. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  // required for editing state
  commitChanges() {

  }

  actionsCell = (props) => {
    const exceptionStatus = props.tableRow.row.Status;

    if (exceptionStatus === 'Pending') {
      return (
        <TableCell>
          <EditCommandCell id="deny" text="Deny" onExecute={this.handleDenyModalToggle} tableRow={props.tableRow}>
            {React.Children.toArray(props.children)}
          </EditCommandCell>
          <EditCommandCell id="approve" text="Approve" onExecute={this.handleApprovalModalToggle} tableRow={props.tableRow}>
            {React.Children.toArray(props.children)}
          </EditCommandCell>
        </TableCell>
      );
    } else {
      return <TableCell />;
    }
  }

  render() {
    const { rows, columns, pageSize, pageSizes, currentPage, dateColumns, filters, sorting, currencyColumns, modal, integratedFilteringColumnExtensions, tableColumnExtensions } = this.state;
    let { totalApproved } = this.state;

    if (!rows) {
      return <div />;
    } else {
      rows.map((row) => {
        return row.Status === 'Approved' ? (row.ApprovedAmount !== null ? totalApproved += row.ApprovedAmount : '') : '';
      });
    }

    return (
      <div>
        <Paper style={{ padding: '1rem' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ padding: '1rem', fontSize: '1.5rem', width: '50%' }}>Exceptions</div>
            <div style={{ width: '50%', justifyContent: 'flex-end', display: 'flex', fontSize: '1.25rem' }}>
              <div style={{ padding: '1rem', fontSize: '1.5rem', width: '50%', textAlign: 'right' }}><TextFit>Total Exceptions: {currencyFormatter({ value: totalApproved })} USD</TextFit></div>
            </div>
          </div>
          <div>
            <Grid rows={rows} columns={columns}>
              <DateTypeProvider
                for={dateColumns}
              />
              <CurrencyTypeProvider
                for={currencyColumns}
              />
              <EditingState
                onEditingRowIdsChange={this.changeEditingRowIds}
                onCommitChanges={this.commitChanges}
              />
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <IntegratedSorting />
              <FilteringState
                filters={filters}
                onFiltersChange={this.changeFilters}
              />
              <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
              <IntegratedPaging />
              <Table rowComponent={this.TableRow} columnExtensions={tableColumnExtensions} />
              <TableHeaderRow showSortingControls />
              <TableEditColumn
                style={{ marginRight: '2rem' }}
                cellComponent={this.actionsCell}
              />
              <TableFilterRow />
              <Getter
                name="tableColumns"
                computed={({ tableColumns }) => {
                  return [
                    ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
                    { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 150 },
                  ];
                }}
              />
              <PagingPanel
                pageSizes={pageSizes}
              />
            </Grid>
          </div>
        </Paper>
        {modal.modalState && modal.modalType === 'approve' &&
          <ConfirmModal
            cancelText="Cancel"
            confirmText="Confirm"
            modalState
            titleText="Approve Exception"
            handleClose={this.handleApprovalModalToggle}
            handleConfirm={this.handleExceptionApproval}
          />
        }
        {modal.modalState && modal.modalType === 'deny' &&
          <ConfirmModal
            cancelText="Cancel"
            confirmText="Confirm"
            modalState
            titleText="Deny Exception"
            handleClose={this.handleDenyModalToggle}
            handleConfirm={this.handleExceptionDeny}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(connect(mapStateToProps, {
  showToast,
  updateExceptionApprovalStatus,
  getExceptionsByAuthorizationId,
})(ExceptionsGrid));