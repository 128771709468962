import PropTypes from 'prop-types';
import React from 'react';

import ConfirmModal from 'common/confirmModal.component';

export const CONFLICT_PUBLISHED_DRAFT_MESSAGE =
    'Someone else has already published this draft. ' +
    'After closing this dialog, make sure to note any unsaved changes you have made. ' +
    'Then you can safely refresh the page and create a new draft.';

export const CONFLICT_CHANGED_DRAFT_MESSAGE =
    'Someone else has changed this draft since you started editing it. ' +
    'After closing this dialog, make sure to note any unsaved changes you have made. ' +
    'Then you can safely refresh the page and edit the latest draft.';

export const CONFLICT_DRAFT_EXISTS_MESSAGE = 'Draft already exists. '
  + 'If you want to edit the existing draft, refresh the page';

export const CONFLICT_NOT_IN_USE_MESSAGE = 'This version is no longer in use. Returning to the list page...';

export const CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE = 'Draft has been published and therefore cannot be deleted. Refresh the page to see the latest.';

export const CONFLICT_DRAFT_DELETED_MESSAGE =
         'Someone else has deleted this draft. ' +
         'After closing this dialog, make sure to note any unsaved changes you have made. ' +
         'Then you can safely refresh the page and create a new draft.';

const ConflictDialog = (props) => {
  const { message, handleConfirm } = props;

  return (
    <ConfirmModal
      confirmText="Ok"
      titleText="Editing Conflict"
      bodyText={message}
      handleConfirm={handleConfirm}
      hideCancel
    />
  );
};

ConflictDialog.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default ConflictDialog;
