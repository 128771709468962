import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Textfit from 'react-textfit';
import history from '../../history';

import { COUNTRY_CODES } from '../../utilities/countryCodes';
import { connect } from 'react-redux';
import { getSelectedClientInformation } from 'utilities/authUtils';
import { setBackButtonPath } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import LogoSpinner from '../../common/logoSpinner.component';

const mapOptions = {
  region: 'US',
  resolution: 'provinces',
  colorAxis: { colors: ['#57DCD2', '#2D5758', '#223D40'], minValue: 0 },
  datalessRegionColor: '#57DCD2',
  defaultColor: '#57DCD2',
};

const styles = (theme) => ({
  headerPaper: {
    height: '40px',
    width: '90%',
    position: 'absolute',
    top: '-10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    zIndex: 100,
    borderRadius: '0.5rem',
    background: 'linear-gradient(45deg,#6B6A6A,#393939)',
  },
  headerText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
  },
  resetButton: {
    position: 'absolute',
    top: 35,
    right: 5,
    zIndex: 100,
  },
  selectRoot: {
    color: '#8d8d8d',
  },
  filterItem: {
    color: '#8d8d8d',
  },
  menu: {
    width: 100,
    color: 'white',
  },
});

class HeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapCountType: 'RollingCount',
      isWorldView: false,
    };

    this.renderTimer = null;

    this.renderHeatMap = this.renderHeatMap.bind(this);
    this.handleRegionClick = this.handleRegionClick.bind(this);
    this.handleMapReset = this.handleMapReset.bind(this);
    this.debounceRender = this.debounceRender.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.debounceRender);
    window.addEventListener('orientationchange', this.debounceRender);
    window.google.charts.load('current', { 'packages': ['geochart'], 'mapsApiKey': 'AIzaSyAk85ndvAU-TV1aGZc0mevZobGlMcJ-fQA' });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceRender);
    window.removeEventListener('orientationchange', this.debounceRender);
  }

  componentDidUpdate(prevProps, prevState) {
    this.debounceRender();
  }

  debounceRender() {
    clearTimeout(this.renderTimer);
    this.renderTimer = setTimeout(this.renderHeatMap, 150);
  }

  handleRegionClick = (evt) => {
    if (mapOptions.region !== undefined) {
      getSelectedClientInformation().id !== '238'? 
        history.push(`/reporting/authorizations/?state=${evt.region}&DateRange=${this.state.mapCountType}`) 
        : 
        history.push(`/reporting/mpcauthorizations/?state=${evt.region}&DateRange=${this.state.mapCountType}`);
    } 
    // ignore sub-region clicks
    if (evt.region.indexOf('-') === -1) {
      mapOptions.region = evt.region;
      mapOptions.resolution = 'provinces';
      this.setState({
        isWorldView: false,
      });
    }
  }

  handleMapReset() {
    if (mapOptions.region !== 'world') {
      delete mapOptions.region;
      delete mapOptions.resolution;
      this.setState({
        isWorldView: true,
      });
    }
  }

  // example chart input data:
  // [['State', 'Authorizations'],
  // ['Iowa', 202],
  // ['North Carolina', 600]]
  renderHeatMap() {
    const authData = this.props.authData || [];
    const provinces = this.props.states || [];

    const isWorldView = !mapOptions.region;

    // init map data headers
    const mapData = isWorldView ? [['Country', 'Authorizations']] : [['State/Province', 'Authorizations']];

    // init country counts for world view
    const countries = [];
    authData.forEach((item) => {
      const foundProvince = provinces.find((p) => (p.CountryCode === item.Country && p.Code === item.State));
      if (isWorldView) {
        // world view
        if (foundProvince) {
          const country = foundProvince.CountryCode;
          const { YTDCount, RollingCount } = item;
          const counts = { country, YTDCount, RollingCount };

          const foundCountryCountIndex = countries.findIndex((item) => item.country === country);
          if (foundCountryCountIndex > -1) {
            const newCounts = countries[foundCountryCountIndex];
            countries.splice(foundCountryCountIndex, 1);

            newCounts.YTDCount += YTDCount;
            newCounts.RollingCount += RollingCount;

            countries.push(newCounts);
          } else {
            countries.push(counts);
          }
        }
      } else {
        // region (country) view
        if (foundProvince) {
          if (item[this.state.mapCountType] > 0) mapData.push([foundProvince.Description, item[this.state.mapCountType]]);
        }
      }
    });

    if (isWorldView) {
      // use country data to build country counts
      countries.forEach((c) => {
        const foundCountryMapping = COUNTRY_CODES.find((item) => item['alpha-3'] === c.country);
        if (c[this.state.mapCountType] > 0) mapData.push([foundCountryMapping ? foundCountryMapping.name : c.country, c[this.state.mapCountType]]);
      });
    }

    if (window.google.visualization && this.props.isLoading === false) {
      const data = window.google.visualization.arrayToDataTable(mapData);
      if (document && document.getElementById('map-div')){
        const geochart = new window.google.visualization.GeoChart(document.getElementById('map-div'));
        window.google.visualization.events.addListener(geochart, 'regionClick', this.handleRegionClick);
        geochart.draw(data, mapOptions);
      }
    }
  }

  mapCountTypeChange = (e) => {
    this.setState({ mapCountType: e.target.value }, this.debounceRender);
  }

  render() {
    const { classes, isLoading } = this.props;
    const { isWorldView } = this.state;

    return (
      <Paper style={{ height: '100%', width: '100%', position: 'relative', borderRadius: '0.5rem' }}>
        <Paper className={classes.headerPaper}>
          <div className={classes.headerText}>Activity by Destination</div>
        </Paper>
        {!isWorldView && <div className={classes.resetButton}><Button onClick={this.handleMapReset}>World</Button></div>}
        <div id="map-div" style={{ height: '93%', width: '95%', margin: 'auto', cursor: 'pointer' }} />
        {isLoading &&
          <div style={{ position: 'absolute', top: '3rem', left: '1rem' }}>
            <LogoSpinner />
          </div>
        }
        <Grid container style={{ marginTop: -15, paddingLeft: 10, paddingRight: 10 }}>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left', color: '#8a8a8a', paddingTop: 7, height: '1.5rem', whiteSpace: 'nowrap', overflow: 'hidden' }}><Textfit max={16}>{'Transferee Destinations'}</Textfit></div>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <TextField
              id="heatMapFilter"
              select
              className={classes.textField}
              onChange={this.mapCountTypeChange}
              value={this.state.mapCountType}
              style={{ color: '#8a8a8a' }}
              margin="normal"
              SelectProps={{
                classes: {
                  root: classes.selectRoot,
                },
                MenuProps: {
                  className: classes.menu,
                },
              }}
            >
              <MenuItem key={0} value="RollingCount" className={classes.filterItem}>Last 12 Months</MenuItem>
              <MenuItem key={1} value="YTDCount" className={classes.filterItem}>Year to Date</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setBackButtonPath,
  })(withStyles(styles)(HeatMap)));
