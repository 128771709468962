import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { BotAvatar } from './message.component';
import chatReplying from '../../../images/chatReplying.gif';

const useStyles = makeStyles((theme) => ({
  image:{
    display: 'inline-block',
    height: 30,
    width: 30,
  },
}
));

const ReplyingPlaceholder = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0.5} alignItems="flex-end">
      <Grid item xs={1}>
        <BotAvatar />
      </Grid>
      <Grid item xs={11}>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Box marginX={0} marginY="1px" paddingX={2} paddingY={0.5} maxWidth="75%" borderRadius="20px 20px 20px 2px" bgcolor="#f4f4f8">
              <Box display="flex" flex={1} justifyContent="center" alignItems="center">
                <img src={chatReplying} className={classes.image} alt="loading" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReplyingPlaceholder; 