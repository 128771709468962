import 'chart.piecelabel.js/build/Chart.PieceLabel.min.js';
import { Doughnut } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Parser from 'html-react-parser';
import React, { Component } from 'react';
import history from '../../history';

const labels = ['Initiated', 'Offer Outstanding', 'Inventory', 'Pending', 'Listing'];

let chartDefinition = {
  labels,
  datasets: [
    {
      label: 'Current Year',
      labels,
      backgroundColor: ['#4182ca', '#3d939b', '#fd6120', '#f0b11d', '#67a783'],
      data: [], // array of values in label order
    },
  ],
};

const chartOptions = {
  type: 'pie',
  title: {
    display: false,
    text: 'Home Sale Status, Year to Date',
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: (tooltipItem, data) => {
        const label = data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index];
        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return `${label  }: ${  value}`;
      },
    },
  },
  pieceLabel: {
    render: 'percentage',
    fontSize: 10,
    arc: false,
    fontColor: '#fff',
    fontStyle: 'bold',
  },
  cutoutPercentage: 25,
  maintainAspectRatio: false,
  legend: {
    // position: 'bottom',
    display: false, // use custom legend instead
  },
  legendCallback: (chart) => {
    let container = document.createElement('div');
    container.style.display = 'flex';
    container.style.margin = 'auto';
    container.style.width = '85%';
    // container.style.flexDirection = 'column';
    // container.style.justifyContent = 'space-between';
    container.style.flexWrap = 'wrap';
    // container.style.marginTop = '1rem';
    let backgroundColors = chart.data.datasets[0].backgroundColor;

    if (chart.data.labels) {
      chart.data.labels.forEach((label, index) => {
        let div = `
          <div style="display: flex; align-items: center; flex-basis: 50%; justify-content: left">
            <div style="background-color: ${backgroundColors[index]}; width: 1rem; height: 1rem; margin-right: 0.25rem; margin-left: 1rem;"></div>
            <div style="font-size: 0.9rem;">${label}</div>
          </div>`;
        container.innerHTML += div;
      });
    }

    if (container.hasChildNodes()) {
      return container.outerHTML;
    } else {
      return '<div />';
    }
  },
  responsive: true,
  onClick: (evt, chartElement) => {
    const { _index } = chartElement[0];
    const label = chartDefinition.labels[_index];
    const redirect = chartElement[0] ? `/reporting/activeproperties/?Homesalestatus=${label}` : '';
    return redirect !== '' && history.push(redirect);
  },
  onHover: (event, chartElement) => {
    return event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
  },
};

const styles = {
  headerPaper: {
    height: '40px',
    width: '90%',
    position: 'absolute',
    top: '-20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    zIndex: 100,
    borderRadius: '0.5rem',
    background: 'linear-gradient(45deg,#6B6A6A,#393939)',
  },
  headerText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

class HomeSaleChart extends Component {
  componentDidMount() {
    this.handleChartClick = this.handleChartClick.bind(this);
    this.getChartData = this.getChartData.bind(this);

    if (this.refs.chart) {
      const canvas = this.refs.chart.chartInstance.canvas;
      canvas.onclick = this.handleChartClick;
    }
  }
  
  // sample data:
  // { Status: 'Initiated', Count: 42 }
  getChartData() {
    let { data } = this.props;
    let chartData = [];
    if (data) {
      labels.forEach((label) => {
        let foundItem = data.find((item) => item.Status === label);
        if (foundItem) {
          chartData.push(foundItem.Count);
        } else {
          chartData.push(0);
        }
      });

      let sum = 0;
      chartData.forEach((count) => sum += count);
      if (sum > 0) {
        chartDefinition.datasets[0].data = chartData;
        return chartDefinition;
      }
    }
    return null;
  }
  
  handleChartClick(evt) {
  // TODO: implement navigation based on homesale status clicked in chart
  // const activePoints = this.refs.chart.chartInstance.getElementsAtEvent(evt);
  // if (activePoints.length > 0) {
    //   const activeIndex = activePoints[0]._index;
    //   console.log(labels[activeIndex]);
    // }
  }
    
  render() {
    let { classes } = this.props;
    const chartData = this.getChartData();

    return (
      <Paper style={{ height: '100%', borderRadius: '0.5rem', position: 'relative' }}>
        <Paper className={classes.headerPaper}>
          <div className={classes.headerText}>Active Home Sale Status</div>
        </Paper>
        <div style={{ padding: '1rem', height: '100%' }}>
          <div style={{ position: 'relative', height: '70%', width: '100%', marginTop: '0.5rem' }}>
            {!chartData && <div style={{ fontSize: '1rem', textAlign: 'center', color: '#8a8a8a', marginTop: '1rem' }}>No active home sales</div>}
            {chartData && <Doughnut data={chartData} options={chartOptions} ref="chart" />}
            {this.refs.chart && chartData && Parser(this.refs.chart.chartInstance.generateLegend())}
          </div>
        </div>
      </Paper>
    );
  }
}

HomeSaleChart = withStyles(styles)(HomeSaleChart);
export default HomeSaleChart;
