import {useCallback, useState} from "react";
import {isObject, isEmpty} from "lodash";

export const useRefCallback = () =>  {
  const [refNode, setRefNode] = useState(null);
  const setRef = useCallback((node) => {
    if (node)
      setRefNode(node);
  }, []);

  return [refNode, setRef];
};

export const isEmptyDeep = (obj) => {
  if (isObject(obj)) {
    if (Object.keys(obj).length === 0)
      return true;
    return Object.keys(obj).every((k) => isEmptyDeep(obj[k]) );
  }
  return isEmpty(obj);
};

export const getAutoCompleteChipProps = (isReadOnly) => {
  const chipProps = { disabled: false };
  if (isReadOnly) {
    chipProps.onDelete = null;
  }
  return chipProps;
};

