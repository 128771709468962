import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

const styles = {
  center: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  headerPaper: {
    height: '40px',
    width: '90%',
    position: 'absolute',
    top: '-20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    left: 0,
    right: 0,
    zIndex: 100,
    borderRadius: '0.5rem',
    background: 'linear-gradient(45deg,#6B6A6A,#393939)',
  },
  headerText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  favoritesContainer: {
    boxSizing: 'border-box',
    paddingTop: '2.2rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '100%',
  },
  cardIcon: {
    height: '50px',
    width: '50px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      height: '35px',
      width: '35px',
    },
    borderRadius: '0.5rem 0 0 0.5rem',
  },
};

const FavoriteItem = withStyles(styles)((props) => {
  const { children, classes, handleClick } = props;
  return (
    <div style={{ marginBottom: '1rem' }} onClick={handleClick}>
      <Paper style={{ borderRadius: '0.5rem', cursor: 'pointer' }}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #F1491F, #CD3F1F)' }}>
            <FontAwesomeIcon icon="heart" color="white" size="4x" />
          </Paper>
          <div style={{ marginLeft: '1rem', fontWeight: 'bold', width: '100%', color: '#8a8a8a' }}>
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {children}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
});

class FavoritesList extends Component {
  navigate = (path) => {
    this.props.history.push(path);
  }

  renderFavoritesList() {
    const { classes, favorites, isLoading } = this.props;

    if (!favorites || isLoading) {
      return (
        <div className={classes.center}>
          <CircularProgress color="primary" size={60} />
        </div>
      );
    }

    if (favorites.length === 0) {
      return (
        <FavoriteItem handleClick={() => this.navigate('/transferees')}>
          <Typography variant="subtitle1">No Favorites</Typography>
          <Typography variane="body1">View Transferees</Typography>
        </FavoriteItem>
      );
    }

    return favorites.map((item, i) => {
      return (
        <FavoriteItem
          handleClick={() => this.navigate(`/transferees/${item.AuthorizationID}`)}
          key={item.AuthorizationID}
        >
          <Typography variant="subtitle1">{`${item.FirstName} ${item.LastName}`}</Typography>
          <Typography variane="body1">{item.ReloPolicy}</Typography>
        </FavoriteItem>
      );
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper style={{ height: '100%', borderRadius: '0.5rem', position: 'relative' }}>
        <Paper className={classes.headerPaper}>
          <div className={classes.headerText}>VIP/Favorites</div>
        </Paper>
        <div style={{ height: '100%', overflow: 'scroll' }}>
          <div className={classes.favoritesContainer}>
            {this.renderFavoritesList()}
          </div>
        </div>
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(FavoritesList);
