import {
  GET_AUTHORIZATIONS_PENDING_FAILURE, GET_AUTHORIZATIONS_PENDING_REQUEST, GET_AUTHORIZATIONS_PENDING_SUCCESS,
  GET_AUTHORIZATIONS_SUBMITTED_FAILURE, GET_AUTHORIZATIONS_SUBMITTED_REQUEST, GET_AUTHORIZATIONS_SUBMITTED_SUCCESS,
  GET_AUTHORIZATION_CURRENT_PREVIOUS_FAILURE, GET_AUTHORIZATION_CURRENT_PREVIOUS_REQUEST, GET_AUTHORIZATION_CURRENT_PREVIOUS_SUCCESS,
  GET_AUTHORIZATION_SEARCH_RESULTS_FAILURE, GET_AUTHORIZATION_SEARCH_RESULTS_REQUEST, GET_AUTHORIZATION_SEARCH_RESULTS_SUCCESS,
  GET_AUTHS_BY_STATE_FAILURE, GET_AUTHS_BY_STATE_REQUEST, GET_AUTHS_BY_STATE_SUCCESS,
  GET_BLOG_DATA_FAILURE, GET_BLOG_DATA_REQUEST, GET_BLOG_DATA_SUCCESS,
  GET_CLIENT_CONTACT_FAILURE, GET_CLIENT_CONTACT_REQUEST, GET_CLIENT_CONTACT_SUCCESS,
  GET_EXCEPTIONS_CURRENT_PREVIOUS_FAILURE, GET_EXCEPTIONS_CURRENT_PREVIOUS_REQUEST, GET_EXCEPTIONS_CURRENT_PREVIOUS_SUCCESS,
  GET_EXPENSES_CURRENT_PREVIOUS_FAILURE, GET_EXPENSES_CURRENT_PREVIOUS_REQUEST, GET_EXPENSES_CURRENT_PREVIOUS_SUCCESS,
  GET_HOMESALE_STATUS_DATA_FAILURE, GET_HOMESALE_STATUS_DATA_REQUEST, GET_HOMESALE_STATUS_DATA_SUCCESS,
  GET_STATES_FAILURE, GET_STATES_REQUEST, GET_STATES_SUCCESS,
  GET_SURVEY_CURRENT_PREVIOUS_FAILURE, GET_SURVEY_CURRENT_PREVIOUS_REQUEST, GET_SURVEY_CURRENT_PREVIOUS_SUCCESS,
} from './dashboard.actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  blog: {
    blogLoading: false,
    blogFeed: {},
    blogError: false,
  },
  activeAuthFiles: [],
  authPending: 0,
  authSubmitted: 0,
  authorizationsYTD: 0,
  exceptions: 0,
  expenses: 0,
  clientContact: null,
  isLoading: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BLOG_DATA_REQUEST:
      return state.set('blogLoading', true);

    case GET_BLOG_DATA_SUCCESS:
      return state.set('blogLoading', false)
        .set('blogFeed', action.payload)
        .set('blogError', false);

    case GET_BLOG_DATA_FAILURE:
      return state.set('blogLoading', false)
        .set('blogError', true);
    case GET_AUTHORIZATION_CURRENT_PREVIOUS_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTHORIZATION_CURRENT_PREVIOUS_SUCCESS:
      return state
        .set('authorizationsYTD', action.response)
        .set('isLoading', false);

    case GET_AUTHORIZATION_CURRENT_PREVIOUS_FAILURE:
      return state.set('isLoading', false);

    case GET_EXPENSES_CURRENT_PREVIOUS_REQUEST:
      return state.set('isLoading', true);

    case GET_EXPENSES_CURRENT_PREVIOUS_SUCCESS:
      return state
        .set('expenses', action.response)
        .set('isLoading', false);

    case GET_EXPENSES_CURRENT_PREVIOUS_FAILURE:
      return state.set('isLoading', false);

    case GET_EXCEPTIONS_CURRENT_PREVIOUS_REQUEST:
      return state.set('isLoading', true);

    case GET_EXCEPTIONS_CURRENT_PREVIOUS_SUCCESS:
      return state
        .set('exceptions', action.response)
        .set('isLoading', false);

    case GET_EXCEPTIONS_CURRENT_PREVIOUS_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTHORIZATION_SEARCH_RESULTS_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTHORIZATION_SEARCH_RESULTS_SUCCESS:
      return state
        .set('activeAuthFiles', action.response.filter((item) => item.Status === 'Active' || item.Status === 'Init' || item.Status === 'On Hold' || item.Status === 'Ops Closed' || item.Status === 'On Assignment' || item.Status === 'PENDING'))
        .set('isLoading', false);

    case GET_AUTHORIZATION_SEARCH_RESULTS_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTHORIZATIONS_PENDING_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTHORIZATIONS_PENDING_SUCCESS:
      return state
        .set('authPending', action.response.length)
        .set('isLoading', false);

    case GET_AUTHORIZATIONS_PENDING_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTHORIZATIONS_SUBMITTED_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTHORIZATIONS_SUBMITTED_SUCCESS:
      return state
        .set('authSubmitted', action.response.length)
        .set('isLoading', false);

    case GET_AUTHORIZATIONS_SUBMITTED_FAILURE:
      return state.set('isLoading', false);

    case GET_CLIENT_CONTACT_REQUEST:
      return state.set('isLoading', true);

    case GET_CLIENT_CONTACT_SUCCESS:
      return state
        .set('clientContact', action.response)
        .set('isLoading', false);

    case GET_CLIENT_CONTACT_FAILURE:
      return state.set('isLoading', false);

    case GET_SURVEY_CURRENT_PREVIOUS_REQUEST:
      return state.set('isLoading', true);

    case GET_SURVEY_CURRENT_PREVIOUS_SUCCESS:
      return state
        .set('surveyScore', action.response)
        .set('isLoading', false);

    case GET_SURVEY_CURRENT_PREVIOUS_FAILURE:
      return state.set('isLoading', false);

    case GET_AUTHS_BY_STATE_REQUEST:
      return state.set('authsByStateLoading', true);

    case GET_AUTHS_BY_STATE_SUCCESS:
      return state
        .set('authorizationsByState', action.response)
        .set('authsByStateLoading', false);

    case GET_AUTHS_BY_STATE_FAILURE:
      return state.set('authsByStateLoading', false);

    case GET_HOMESALE_STATUS_DATA_REQUEST:
      return state.set('isLoading', true);

    case GET_HOMESALE_STATUS_DATA_SUCCESS:
      return state
        .set('homesaleData', action.response)
        .set('isLoading', false);

    case GET_HOMESALE_STATUS_DATA_FAILURE:
      return state.set('isLoading', false);

    case GET_STATES_REQUEST:
      return state.set('isLoading', true);

    case GET_STATES_SUCCESS:
      return state.set('states', action.response).set('isLoading', false);

    case GET_STATES_FAILURE:
      return state.set('isLoading', false);

    default:
      return state;
  }
}
