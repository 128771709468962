import { connect } from 'react-redux';
import { setNumPendingExceptions, setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import {
  getExceptionByMonth,
  getExceptionsByCategory,
  getExceptionsByProgram,
  getPendingException,
  getPendingExceptions,
} from './exceptions.actions';

import ExceptionChart from './exceptionChart.component';
import PendingException from './exceptionApproval/pendingException.component';
import PendingExceptions from './pendingExceptions.component';

class ExceptionsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedException: null,
    };

    this.handleViewSubmittedException = this.handleViewSubmittedException.bind(this);
  }

  async componentWillMount() {
    this.props.setPageTitle('My Exceptions');

    this.props.getExceptionsByCategory();
    this.props.getExceptionsByProgram();
    this.props.getExceptionByMonth();
    let getPendingExceptionsResponse = await this.props.getPendingExceptions();

    if (getPendingExceptionsResponse.type === 'GET_EXCEPTIONS_SUCCESS') {
      this.props.setNumPendingExceptions(getPendingExceptionsResponse.response.length);
    }
  }

  handleViewSubmittedException(id) {
    this.props.history.push(`/exceptions/submitted/${  encodeURIComponent(id)}`);
  }

  render() {
    let { exceptCountByCategory, exceptCountByMonth, exceptCountByProgram, pendingExceptions } = this.props;
    let { selectedException } = this.state;

    const chartData = {
      exceptCountByCategory,
      exceptCountByMonth,
      exceptCountByProgram,
    };

    return (
      <div style={{ padding: '2rem' }}>
        {selectedException &&
          <PendingException />
        }
        {!selectedException &&
          <div>
            <div style={{ marginBottom: '1rem' }}>
              <PendingExceptions rows={pendingExceptions} handleViewSubmittedException={this.handleViewSubmittedException} />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <ExceptionChart chartData={chartData} />
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pendingExceptions: state.exceptions.get('pendingExceptions'),
    pendingException: state.exceptions.get('pendingException'),
    exceptCountByCategory: state.exceptions.get('exceptCountByCategory'),
    exceptCountByProgram: state.exceptions.get('exceptCountByProgram'),
    exceptCountByMonth: state.exceptions.get('exceptCountByMonth'),
  };
};

export default withRouter(connect(mapStateToProps, {
  setPageTitle,
  getPendingExceptions,
  getPendingException,
  getExceptionsByCategory,
  getExceptionsByProgram,
  getExceptionByMonth,
  setNumPendingExceptions,
})(ExceptionsContainer));
