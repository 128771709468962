import { applyMiddleware, createStore } from 'redux';
import api from '../middleware/api';
import benefitApi from 'middleware/selfServeApi';
import rootReducer from '../index.reducer';
import thunk from 'redux-thunk';

const configureStore = (preloadedState) => createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunk, api, benefitApi),
);

export default configureStore;