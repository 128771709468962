const PERMANENT_MOVE_TYPES = ['PERMASSIGNLOCAL', 'DOMPERMTRANS', 'CAND', 'INTLPERMTRANS'];

export const isProposedEndDateRequired = (moveType) => {
  // require end date if the moveType is specified, and not permanent
  return moveType && moveType !== '' && !PERMANENT_MOVE_TYPES.includes(moveType);
};

export const formatPhoneNumber = (phoneNumber) => {
  //AB#675: Formatting is being ignored for the time being to allow international dial codes to be entered instead of forcing
  //domestic numbers
  //AB#660 will bring in all the international number formatting when it's deployed
  if(!phoneNumber || phoneNumber.length === 0) return phoneNumber;
  
  if(!phoneNumber.startsWith('+')) phoneNumber = '+' + phoneNumber;

  return phoneNumber;
};