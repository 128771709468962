const titleMap = {
  dashboard: 'My Dashboard',
  transferees: 'My Transferees',
  authorizations: 'My Authorizations',
  mpcauthorizations: 'MPC Authorizations',
  exceptions: 'My Exceptions',
  invoices: 'My Invoices',
  // notes: 'My Notes',
  reporting: 'My Reporting',
  contacts: 'My Contacts',
  // scout: 'Neighborhood Scout',
  login: 'Login',
  default: 'My Dashboard',
  benefits: 'My Benefits',
  policies: 'My Policies',
};

const MapRouteTitle = (path) => {
  let title = null;
  Object.keys(titleMap).forEach((key) => {
    if (path.indexOf(key) !== -1) {
      title = titleMap[key];
    }
  });

  return title ? title : titleMap.default;
};

export default MapRouteTitle;
