import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow, 
  Tooltip, 
  Typography,
  makeStyles, 
  useTheme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {  ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Truncate from 'react-truncate';

import { generateRule } from './policyRuleUtil';
import { getMergedBenefit, isOptionEnabled } from './policyBenefitUtil';
import { getObjectId, isRuleQuestionStillValid, mergeClientAndMaster, mergeClientAndMasterOption } from 'modules/benefits/versionUtil';
import { useCallback } from 'react';
import { useRefCallback } from 'utilities/formUtils';
import AddPhoto from 'modules/benefits/addPhoto.component';
import ClientBenefitTextInput from 'common/form/ClientBenefitTextInput';
import CostPlanning from './costPlanning.component';
import OptionRule from './optionRule.component';
import PolicyContext from './policyContext';

const imageHeight = 70;

const useStyles = makeStyles((theme) => ({
  headerImage: {
    height: imageHeight,
    width: Math.round(imageHeight/0.7), // matches 7/10 aspect ratio defined in the .media class
  },
  accordionSummary: {
    height: imageHeight + 10,
    padding: `0 ${theme.spacing(2)}px 0 0`,
  },
  qaTableHeader: {
    borderBottom: 'none',
  },
  accordionSummaryRootOverride:{
    '&.Mui-disabled' : {
      opacity: 1,
    },
    '&.MuiButtonBase-root.Mui-disabled': {
      pointerEvents: 'all',
    },
  },
}));

const PolicyRuleForm = (props) => {
  const { modifyBenefit, isReadOnly, hasValidated } = useContext(PolicyContext);
  const { index, benefit, mergedBenefit, clientAndMasterMerged, toggleBenefitValid, isExpanded, onChange, isSorting, displayBenefitSortingControls } = props;

  const [benefitIsValid, setBenefitIsValid] = useState(true);
  const [allRulesValid, setAllRulesValid] = useState(true);
  const [enabledOptions, setEnabledOptions] = useState([]);

  const theme = useTheme();
  const classes = useStyles();
  const [formRef, setFormRef] = useRefCallback();
  const textContainerRef = useRef();

  const displayExpanded = isExpanded && !isSorting;

  // force form to mark invalid fields after publish attempted
  useEffect(() => {
    if (formRef && hasValidated) {
      formRef.isFormValid(false);
    }
  }, [hasValidated, formRef, benefit]);

  useEffect(() => {
    if (!isReadOnly) {
      (async () => {
        if (!formRef) {
          return;
        }
        let valid = await formRef.isFormValid();
        valid = valid && allRulesValid;
        if (benefitIsValid !== valid){
          setBenefitIsValid(valid);
          toggleBenefitValid(benefit.id, valid);
        }
      })();
    }
  });

  useEffect(() => { // eslint-disable-line
    setAllRulesValid(enabledOptions.every((opt) => {
      return opt.rules.every((rule) => rule.questions.every((ruleQuestion) => isRuleQuestionStillValid(ruleQuestion, clientAndMasterMerged.questionDetails)));
    }));
  });

  useEffect(() => {
    const onlyEnabledOptions = benefit.options.filter(isOptionEnabled);
    onlyEnabledOptions.sort((opt1, opt2) => {
      const matchingMergedOpt1 = mergedBenefit.options.find((mergedOpt) => opt1.id === mergedOpt.id);
      const matchingMergedOpt2 = mergedBenefit.options.find((mergedOpt) => opt2.id === mergedOpt.id);
      return matchingMergedOpt1.order - matchingMergedOpt2.order;
    });
    setEnabledOptions(onlyEnabledOptions);
  }, [benefit.options, mergedBenefit.options]);

  const displayRuleQuestions = (option, rule, ruleIdx) => {

    const isRuleValid = rule.questions.every((ruleQuestion) => isRuleQuestionStillValid(ruleQuestion, clientAndMasterMerged.questionDetails));
    const icon = isRuleValid ? 
      <FontAwesomeIcon icon="trash" />
      :
      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} color={theme.palette.error.main} size={isReadOnly ? '2x' : '1x'} />;

    const tooltipTitle = isRuleValid ? '' : `Rule may no longer be valid.${isReadOnly ? '' : ' Click to delete.'}`;

    return (
      <Fragment key={getObjectId(rule)}>
        <Grid item xs={12} container>
          <Grid item xs={11}>
            <OptionRule
              rule={rule}
              questionDetails={clientAndMasterMerged.questionDetails}
              modifyBenefit={() => {
                modifyBenefit(benefit);
              }}
              isReadOnly={isReadOnly}
              isRuleValid={isRuleValid}
              validateOnBlur={hasValidated}
              validateOnChange={hasValidated}
            />
          </Grid>
          <Grid item xs={1} >
            {
              <Tooltip title={tooltipTitle}>
                <div> {/* div necessary here to make the hovering action display the tooltip */}
                  {isReadOnly ?
                    icon :
                    <IconButton 
                      onClick={() => {
                        option.rules.splice(ruleIdx, 1);
                        modifyBenefit(benefit);
                      }}
                    >
                      {icon}
                    </IconButton>
                  }
                </div>
              </Tooltip>
            }
          </Grid>
        </Grid>
        {ruleIdx < option.rules.length - 1 &&
          <Box ml={3} py={2} borderColor={theme.palette.divider} borderLeft="1px solid">
            <Box ml={-1} bgcolor={theme.palette.common.white}>
              <Typography variant="button" color="primary">Or</Typography>
            </Box>
          </Box>
        }
      </Fragment>
    );
  };

  return (
    <Accordion key={benefit.id} expanded={displayExpanded} onChange={onChange}>
      <AccordionSummary
        className={classes.accordionSummary}
        style={{ paddingLeft: displayExpanded ? theme.spacing(2) : 0 }}
        disabled={isSorting}
        classes={{
          root:classes.accordionSummaryRootOverride,
        }}
        expandIcon={
          isSorting ? 
            null :
            <Box color={theme.palette.primary.main}>
              <ExpandMoreIcon color="inherit" />
            </Box>
        }
      >
        <Grid container alignItems="center">
          <Grid item xs={11} container alignItems="center">
            <Grid item style={{ display: displayExpanded ? 'none' : 'flex' }}>
              <AddPhoto
                imageId={mergedBenefit.imageId}
                classes={{ cardRoot: classes.headerImage }}
                setImageId={() => {}}
                isReadOnly={true}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6" color="inherit" ref={textContainerRef}>
                {displayExpanded ?
                  mergedBenefit.name :
                  <Truncate lines={1} width={textContainerRef.current ? textContainerRef.current.clientWidth : 0}>
                    {mergedBenefit.name}
                  </Truncate>
                }
              </Typography>
            </Grid>
            {!displayExpanded &&
              <Grid item>
                <Typography
                  variant="subtitle1"
                >{`${enabledOptions.length} option${enabledOptions.length > 1 ? 's' : ''}`}</Typography>
              </Grid>
            }
          </Grid>
          {
            isSorting && 
              <Grid item xs={1} container justify="center">
                {
                  isSorting ? 
                    displayBenefitSortingControls(index)
                    :
                    (!benefitIsValid && hasValidated) &&
                      <Grid container spacing={1}>
                        <Grid item>
                          <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} size="lg" color={theme.palette.error.main} />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" color="error">Errors</Typography>
                        </Grid>
                      </Grid>
                
                }
              </Grid>
          }
        </Grid>
      </AccordionSummary>
      {
        !isSorting && 
          <AccordionDetails>
            <ValidatorForm ref={setFormRef} onSubmit={() => {}} instantValidate={false}>
              <Grid container>
                <Grid item xs={2}>
                  <AddPhoto
                    imageId={mergedBenefit.imageId}
                    setImageId={(imageId) => {
                      benefit.imageId = imageId;
                      modifyBenefit(benefit);
                    }}
                    isReadOnly={isReadOnly}
                  />
                </Grid>
                <Grid container item xs={10}>
                  <Grid item xs={4}>
                    <ClientBenefitTextInput
                      clientVersion={benefit}
                      masterVersion={clientAndMasterMerged}
                      versionField="name"
                      label="Benefit Name"
                      onBlur={(value) => {
                        benefit.name = value;
                        modifyBenefit(benefit);
                      }}
                      InputProps={{ readOnly: isReadOnly }}
                      fullWidth
                    />
                  </Grid>
                  <CostPlanning
                    benefit={benefit}
                    isReadOnly={isReadOnly}
                    validateOnChange={hasValidated}
                    validateOnBlur={hasValidated}
                    modifyBenefit={modifyBenefit}
                  />
                  <Grid item xs={12}>
                    <ClientBenefitTextInput
                      clientVersion={benefit}
                      masterVersion={clientAndMasterMerged}
                      versionField="secondaryText"
                      label="Secondary Text"
                      onBlur={(value) => {
                        benefit.secondaryText = value;
                        modifyBenefit(benefit);
                      }}
                      InputProps={{ readOnly: isReadOnly }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ClientBenefitTextInput
                      clientVersion={benefit}
                      masterVersion={clientAndMasterMerged}
                      versionField="description"
                      label="Description"
                      onBlur={(value) => {
                        benefit.description = value;
                        modifyBenefit(benefit);
                      }}
                      InputProps={{ readOnly: isReadOnly }}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {
                    enabledOptions.map((option) => {
                      const clientAndMasterOptionMerged = mergeClientAndMasterOption(option.benefitOptionDetail);
                      const hasQuestions = clientAndMasterMerged.questionDetails.length !== 0;

                      return (
                        <Box key={option.id} p={2}>
                          <Paper elevation={3} className="px-2">
                            <Grid container>
                              <Grid item xs={2}>
                                <AddPhoto
                                  imageId={option.imageId || clientAndMasterOptionMerged.imageId}
                                  setImageId={(imageId) => {
                                    option.imageId = imageId;
                                    modifyBenefit(benefit);
                                  }}
                                  isReadOnly={isReadOnly}
                                />
                              </Grid>
                              <Grid container item xs={10}>
                                <Grid item xs={4}>
                                  <ClientBenefitTextInput
                                    clientVersion={option}
                                    masterVersion={clientAndMasterOptionMerged}
                                    versionField="name"
                                    label="Option Name"
                                    onBlur={(value) => {
                                      option.name = value;
                                      modifyBenefit(benefit);
                                    }}
                                    InputProps={{ readOnly: isReadOnly }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <ClientBenefitTextInput
                                    clientVersion={option}
                                    masterVersion={clientAndMasterOptionMerged}
                                    versionField="description"
                                    label="Option Description"
                                    onBlur={(value) => {
                                      option.description = value;
                                      modifyBenefit(benefit);
                                    }}
                                    InputProps={{ readOnly: isReadOnly }}
                                    fullWidth
                                    multiline
                                    rows={3}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item container xs={12}>
                                <Box ml={4} py={3} width="100%">
                                  <Typography variant="subtitle2">Present the transferee this option if...</Typography>
                                  <Box ml={3} mb={2} mt={1}>
                                    <Grid item xs={12} container>
                                      <Grid item xs={11}>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="center" padding="none" width="50%" className={classes.qaTableHeader}>Question</TableCell>
                                              <TableCell align="center" padding="none" width="40%" className={classes.qaTableHeader}>Answers</TableCell>
                                              <TableCell align="center" padding="none" width="10%" className={classes.qaTableHeader} />
                                            </TableRow>
                                          </TableHead>
                                        </Table>
                                      </Grid>
                                      <Grid />
                                    </Grid>
                                    {
                                      option.rules.map((rule, ruleIdx) => displayRuleQuestions(option, rule, ruleIdx))
                                    }
                                  </Box>
                                  {
                                    !isReadOnly &&
                                      <Button
                                        color="primary"
                                        disabled={!hasQuestions}
                                        onClick={() => {
                                          option.rules.push(generateRule());
                                          modifyBenefit(benefit);
                                        }}
                                      >
                                  Add Rule
                                      </Button>
                                  }
                                  {!hasQuestions && <FormHelperText error={true}>Please add questions to the benefit before adding rules</FormHelperText>}
                                </Box>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                      );
                    })
                  }
                </Grid>
              </Grid>
            </ValidatorForm>
          </AccordionDetails>
      }
    </Accordion>
  );
};

const PolicyRulesContainer = (props) => {
  const { toggleStepStatus, basePath, isReadOnly, benefitsInUse: benefits, updateVersionBenefits } = useContext(PolicyContext);
  const { formStep } = props;
  const [benefitsValid, setBenefitsValid] = useState([]);
  const [benefitCount, setBenefitCount] = useState(0);
  const [expandedBenefitId, setExpandedBenefitId] = useState(null);
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule('notEmpty', (value) => !isEmpty(value));
    return () => ValidatorForm.removeValidationRule('notEmpty');
  }, []);

  //detect add or remove benefits
  useEffect(() => {
    if (benefitCount !== benefits.length){
      setBenefitsValid((benefitsValid) => {
        return benefits.map((benefit) => {
          const benValid = benefitsValid.find((benValid) => benefit.id === benValid.id);
          return { id: benefit.id, isValid: benValid ? benValid.isValid : true };
        });
      });
      setBenefitCount(benefits.length);
    }
  }, [benefits, benefitCount]);
  
  useEffect(() => {
    const allBenefitsValid = benefitsValid ? benefitsValid.every((b) => b.isValid) : true;
    toggleStepStatus(formStep, allBenefitsValid);
  }, [benefitsValid, toggleStepStatus, formStep]);

  const toggleBenefitValid = useCallback((benefitId, isValid) => {
    setBenefitsValid((ben) => ben.map((b) => {
      b.isValid = b.id === benefitId ? isValid : b.isValid;
      return b;
    }));
  }, []);

  const moveBenefitUp = useCallback((index) => {
    const newBenefits = benefits;
    const benefitToMove = newBenefits[index];
    const benefitToReplace = newBenefits[index - 1];
    newBenefits[index - 1] = benefitToMove;
    newBenefits[index] = benefitToReplace;
    updateVersionBenefits(newBenefits);
  }, [benefits, updateVersionBenefits]);

  const moveBenefitDown = useCallback((index) => {
    const newBenefits = benefits;
    const benefitToMove = newBenefits[index];
    const benefitToReplace = newBenefits[index + 1];
    newBenefits[index + 1] = benefitToMove;
    newBenefits[index] = benefitToReplace;
    updateVersionBenefits(newBenefits);
  }, [benefits, updateVersionBenefits]);

  const displayBenefitSortingControls = (index) => {
    return (
      <Fragment>
        <Grid container direction="column" spacing={1} alignItems="center" justify="center">
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              disabled={index === 0}
              onClick={(e) => { 
                // keep click from registering with accordion
                e.preventDefault();
                e.stopPropagation();
                moveBenefitUp(index); }
              }
            >
              <FontAwesomeIcon icon="arrow-up" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              disabled={index === benefits.length - 1}
              onClick={(e) => { 
                // keep click from registering with accordion
                e.preventDefault();
                e.stopPropagation();
                moveBenefitDown(index); }
              }
            >
              <FontAwesomeIcon icon="arrow-down" />
            </IconButton>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
  
  return (
    <Box>
      <Box display={isSorting ? 'block' : 'none'} top={0} left={0} 
        position="absolute" width="100%" height="100%" zIndex="4000" 
        bgcolor="rgba(255, 255, 255, 0.6)"
      />
      <Box p={2} zIndex={isSorting ? 5000 : null} position={isSorting ? 'relative' : 'relative'}>
        <Grid container direction="column" spacing={2}>
          {
            !isReadOnly && 
              <Grid item container justify="flex-end">
                <Button variant="outlined" color="primary" onClick={() => {setIsSorting(!isSorting);}}>
                  {
                    isSorting ? 'End Sorting' : 'Sort Benefits'
                  }
                </Button>
              </Grid>
          }
          <Grid item>
            <div>
              {benefits.length === 0 &&
                <Paper>
                  <Grid container alignItems="center" justify="center">
                    <Box p={4}>
                      <Typography variant="subtitle2" color="primary">No benefits are enabled. Go <Link to={basePath}>here</Link> to enable benefits.</Typography>
                    </Box>
                  </Grid>
                </Paper>
              }
              {benefits.length > 0 &&
          benefits.map((benefit, index) => {
            const mergedBenefit = getMergedBenefit(benefit);
            const clientAndMasterMerged = mergeClientAndMaster(benefit.clientBenefitVersion);
            const isExpanded = expandedBenefitId === benefit.id;
            return (
              <PolicyRuleForm 
                key={benefit.id}
                index={index}
                benefit={benefit}
                isExpanded={isExpanded}
                isSorting={isSorting}
                displayBenefitSortingControls={displayBenefitSortingControls}
                onChange={() => {
                  if (!isSorting)
                    isExpanded ? setExpandedBenefitId(null) : setExpandedBenefitId(benefit.id);
                }}
                mergedBenefit={mergedBenefit}
                clientAndMasterMerged={clientAndMasterMerged}
                toggleBenefitValid={toggleBenefitValid}
              />
            );
          })
              }
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

PolicyRuleForm.propTypes = {
  index: PropTypes.number.isRequired,
  benefit: PropTypes.object.isRequired,
  mergedBenefit: PropTypes.object.isRequired,
  clientAndMasterMerged: PropTypes.object.isRequired,
  toggleBenefitValid: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSorting: PropTypes.bool.isRequired,
  displayBenefitSortingControls: PropTypes.func.isRequired,
};

PolicyRulesContainer.propTypes = {
  formStep: PropTypes.number.isRequired,
};

export default PolicyRulesContainer;
