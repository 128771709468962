import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { CLONE_POLICY_FAILURE, CREATE_POLICY_FAILURE, GET_POLICY_SUMMARIES_FAILURE } from './policy.types';
import { TOAST_MESSAGE_SEVERITY_ERROR, setPageTitle, showToast } from 'modules/layout/layout.actions';
import { clonePolicy, createPolicy, getSummaries } from './policy.actions';
import { sortSummaries } from './summaryUtil';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import PolicySummaryRow from './policySummaryRow.component';

const PoliciesContainer = (props) => {
  const { isLoading, summaries, history, showToast: propsShowToast, createPolicy: propsCreatePolicy, clonePolicy: propsClonePolicy } = props;

  useEffect(() => {
    (async () => {
      props.setPageTitle('My Policies');
      const action = await props.getSummaries();
      if (action.type === GET_POLICY_SUMMARIES_FAILURE) {
        props.showToast('Failed to load policies', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    })();
  }, []); // eslint-disable-line

  const viewPolicy = useCallback((policySummary) => {
    const version = policySummary.mostRecentPublish || policySummary.draft;
    history.push(`/policies/${policySummary.id}/v/${version.versionId}`);
  }, [history]);

  const clonePolicy = useCallback(async (policySummary) => {
    const action = await propsClonePolicy(policySummary.id);
    if (action.type === CLONE_POLICY_FAILURE) {
      propsShowToast('Failed to clone policy', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
    history.push(`/policies/${action.response.id}/v/${action.response.versions[0].versionId}`);
  }, [propsClonePolicy, history, propsShowToast]);

  const addNewPolicy = useCallback(async () => {
    const action = await propsCreatePolicy();
    if (action.type === CREATE_POLICY_FAILURE) {
      propsShowToast('Failed to create policy', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      history.push(`/policies/add/${action.response.id}/v/${action.response.versions[0].versionId}`);
    }
  }, [history, propsCreatePolicy, propsShowToast]);

  if (isLoading || !summaries) {
    return <FullscreenSpinner />;
  }

  return (
    <Box mx={2} mt={2}>
      <Box p={1} mb={2}>
        <Grid container item justify="flex-end">
          <Button variant="outlined" onClick={addNewPolicy}>
            Add
          </Button>
        </Grid>
      </Box>
      <Paper>
        {summaries.length === 0 &&
          <Grid container alignItems="center" justify="center">
            <Box p={4}>
              <Typography variant="subtitle2" color="primary">No policies have been created yet. Click <strong>Add</strong> to start building a policy.</Typography>
            </Box>
          </Grid>
        }
        {summaries.length > 0 &&
          <TableContainer component={Paper}>
            <Table style={{ width: '100%', tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row" width="auto">Policy Name</TableCell>
                  <TableCell component="th" scope="row" width={150} align="right">Budget Amount</TableCell>
                  <TableCell component="th" scope="row" width={400} />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {summaries.map((summary) => (
                  <PolicySummaryRow key={summary.id} summary={summary} onViewPolicy={viewPolicy} onClonePolicy={clonePolicy} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    summaries: state.policy.policySummaries ? sortSummaries(state.policy.policySummaries) : null,
    isLoading: state.policy.isLoadingSummaries,
  };
};

PoliciesContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  summaries: PropTypes.array,
  history: PropTypes.object.isRequired,

  getSummaries: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  createPolicy: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { getSummaries, setPageTitle, createPolicy, clonePolicy, showToast },
)(PoliciesContainer);