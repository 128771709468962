import { clearBackButtonPath } from './layout.actions';
import { connect } from 'react-redux';
import { getSelectedClientInformation, getSelectedDivisions, logout } from '../../utilities/authUtils';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import BackArrow from '@material-ui/icons/ChevronLeft';
import Badge from '@material-ui/core/Badge';
import ChangePasswordDialog from './changePasswordDialog.component';
import ClientSelectDialog from './clientSelectDialog.component';
import DivisionSelectDialog from './divisionSelectDialog.component';
import FilterList from '@material-ui/icons/FilterList';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import reloSourceWhite from '../../images/ReloSource_wht.png';

const styles = (theme) => ({
  flex: {
    flex: 1,
  },
  appBar: {
    position: 'absolute',
    height: 56,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  clientNameLabel: {
    fontSize: '1rem',
    display: 'inline',
  },
  badge: {
    margin: 'auto',
    top: 0,
    height: 21,
    width: 21,
    right: -5,
  },
});

class MobileAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      isShowClientSelect: false,
      isShowDivisionSelect: false,
      isShowChangePassword: false,
    };

    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleCloseClientSelect = this.handleCloseClientSelect.bind(this);
    this.handleShowClientSelect = this.handleShowClientSelect.bind(this);
    this.handleCloseDivisionSelect = this.handleCloseDivisionSelect.bind(this);
    this.handleShowDivisionSelect = this.handleShowDivisionSelect.bind(this);
    this.handleCloseChangePassword = this.handleCloseChangePassword.bind(this);
    this.handleShowChangePassword = this.handleShowChangePassword.bind(this);
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose() {
    this.setState({ anchorEl: null });
  }

  handleLogout() {
    logout();
  }

  handleShowClientSelect() {
    this.setState({
      anchorEl: null,
      isShowClientSelect: true,
    });
  }

  handleCloseClientSelect() {
    this.setState({
      isShowClientSelect: false,
    });
  }

  handleShowDivisionSelect() {
    this.setState({
      anchorEl: null,
      isShowDivisionSelect: true,
    });
  }

  handleCloseDivisionSelect() {
    this.setState({
      isShowDivisionSelect: false,
    });
  }

  handleShowChangePassword() {
    this.setState({
      isShowChangePassword: true,
    });
  }
  handleCloseChangePassword() {
    this.setState({
      isShowChangePassword: false,
    });
  }

  backNavigation = () => {
    if (this.props.backButtonPath) {
      this.props.history.push(this.props.backButtonPath);
    } else {
      this.props.history.push('/dashboard');
    }
    this.props.clearBackButtonPath();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, handleMobileDrawerToggle, pageTitle, clientOptions, divisionOptions } = this.props;
    const appBarMenuOpen = Boolean(anchorEl);

    const selectedClient = getSelectedClientInformation();
    const selectedDivisions = getSelectedDivisions();
    const useBackButton = !!this.props.backButtonPath;

    const clientDisplayName = selectedClient.description;
    const isShowDivisionFilter = divisionOptions.length > selectedDivisions.length;

    return (
      <div>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            {useBackButton &&
              <BackArrow style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={this.backNavigation} />
            }
            {!useBackButton &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleMobileDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            }
            <Typography variant="h6" color="inherit" noWrap style={{ flex: '1' }}>
              {pageTitle}
            </Typography>
            <Hidden xsDown>
              <div style={{ height: '50px', marginTop: '2px' }}>
                <img src={reloSourceWhite} alt="" style={{ maxHeight: '100%' }} />
              </div>
            </Hidden>
            <Hidden smUp>
              <div style={{ height: '30px', marginTop: '2px' }}>
                <img src={reloSourceWhite} alt="" style={{ maxHeight: '100%' }} />
              </div>
            </Hidden>
            <div>
              <Hidden smDown>
                {clientDisplayName &&
                  <Typography variant="h6" color="inherit" noWrap className={classes.clientNameLabel}>
                    {clientDisplayName}
                  </Typography>
                }
              </Hidden>
              {isShowDivisionFilter &&
                <Badge classes={{ badge: classes.badge }} color="secondary" badgeContent={selectedDivisions.length}>
                  <IconButton
                    onClick={this.handleShowDivisionSelect}
                    color="inherit"
                  >
                    <FilterList />
                  </IconButton>
                </Badge>
              }
              <IconButton
                aria-owns={appBarMenuOpen ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenuOpen}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={appBarMenuOpen}
                onClose={this.handleMenuClose}
              >
                {clientOptions.length > 1 &&
                  <MenuItem onClick={this.handleShowClientSelect}>Change Client</MenuItem>
                }
                {divisionOptions.length > 1 &&
                  <MenuItem onClick={this.handleShowDivisionSelect}>Change Divisions</MenuItem>
                }
                {/* <MenuItem onClick={this.handleChangePassword}>Change Password</MenuItem> */}
                {/* {showAdminMenu && <MenuItem onClick={this.handleUserAdmin}>User Administration</MenuItem>} */}
                <MenuItem onClick={this.handleShowChangePassword}>Change Password</MenuItem>
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <ClientSelectDialog title={'Select Client'}
          open={this.state.isShowClientSelect}
          handleClose={this.handleCloseClientSelect}
          handleShowDialog={this.handleShowDialog}
          handleChangeClient={this.props.handleChangeClient}
          clientOptions={clientOptions}
          contentText={'You have access to more than one client. You may switch between clients.'}
          selectedClient={selectedClient ? selectedClient.id : null}
        />
        <DivisionSelectDialog title={'Select Divisions'}
          open={this.state.isShowDivisionSelect}
          handleClose={this.handleCloseDivisionSelect}
          handleChangeDivisions={this.props.handleChangeDivisions}
          divisionOptions={divisionOptions}
          contentText={'You have access to more than one division. You may choose to view one or more divisions. At least one is required.'}
          selectedDivisions={selectedDivisions ? selectedDivisions : null}
        />
        <ChangePasswordDialog title={'Change Password'}
          open={this.state.isShowChangePassword}
          handleClose={this.handleCloseChangePassword}
          contentText={'Your password will be reset and an email will be sent with instructions on how to change it.'}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pageTitle: state.layout.get('pageTitle'),
    backButtonPath: state.layout.get('backButtonPath'),
  };
};

MobileAppBar = withStyles(styles, { withTheme: true })(MobileAppBar);

export default withRouter(connect(mapStateToProps, {
  clearBackButtonPath,
})(MobileAppBar));