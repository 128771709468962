import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = (theme) => ({
  dialogPaper: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  row: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  listItemTextRoot: {
    whiteSpace: 'pre-line',
  },
});

class DivisionSelectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDivisions: props.selectedDivisions ? props.selectedDivisions : [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectNone = this.handleSelectNone.bind(this);
  }

  handleClose() {
    this.props.handleClose();
    this.setState({
      selectedDivisions: this.props.selectedDivisions,
    });
  }

  handleToggle = (id) => {
    let updateDivisions = [].concat(this.state.selectedDivisions);

    let index = updateDivisions.indexOf(id);
    if (index > -1) {
      updateDivisions.splice(index, 1);
    } else {
      updateDivisions.push(id);
    }
    updateDivisions = updateDivisions.sort((a, b) => a - b);
    this.setState({ selectedDivisions: updateDivisions });
  };

  handleSelectAll() {
    let divs = this.props.divisionOptions;
    this.setState({ selectedDivisions: divs.map((div) => div.id) });
  }

  handleSelectNone() {
    this.setState({ selectedDivisions: [] });
  }

  handleSubmit() {
    this.props.handleChangeDivisions(this.state.selectedDivisions);
  }

  render() {
    const { title, divisionOptions, open, classes, handleClose, showAsModal, contentText } = this.props;
    const { selectedDivisions } = this.state;

    return (
      <div>
        <Dialog
          classes={{
            paper: classes.dialogPaper,
          }}
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown={showAsModal}
          disableBackdropClick={showAsModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent style={{ paddingBottom: '0px' }}>
            <div>{contentText}</div>
            <div style={{ float: 'right' }}>
              <Button onClick={this.handleSelectAll} style={{ padding: '8px', fontSize: '0.75rem' }}>Select All</Button>
              <Button onClick={this.handleSelectNone} style={{ padding: '8px', fontSize: '0.75rem' }}>Select None</Button>
            </div>
            <div className={classes.row} style={{ maxHeight: '300px', overflow: 'auto' }}>
              {divisionOptions.map((division) => {
                // -1 not selectable as a division - implies user can see all division data
                if (division.id !== '-1') {
                  return (<MenuItem key={division.id} value={division.id} onClick={() => this.handleToggle(division.id)}>
                    <Checkbox checked={selectedDivisions.indexOf(division.id) > -1} />
                    <ListItemText classes={{ root: classes.listItemTextRoot }} primary={division.description} />
                  </MenuItem>);
                } else {
                  return null;
                }
              },
              )}
            </div>
          </DialogContent>
          <DialogActions>
            {!showAsModal && <Button onClick={() => this.handleClose()} color="primary" autoFocus>
              Cancel
            </Button>}
            <Button disabled={selectedDivisions.length < 1} onClick={() => this.handleSubmit()} color="primary" autoFocus>
              Set Divisions
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

DivisionSelectDialog = withStyles(styles, { withTheme: true })(DivisionSelectDialog);

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {

})(withMobileDialog()(DivisionSelectDialog));