import { Box, Divider, Grid, List, ListItem, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { dateFormatter } from 'utilities/dateFormatter';

export const prepareHistoryEntries = (history) => {
  const sortedHistory = history.sort((a, b) => {
    //sort by publishedDate desc
    //if either is a draft, it ranks at top
    return !a.isPublished ? -1 : !b.isPublished ? 1 : new Date(b.publishedDate) - new Date(a.publishedDate);
  });

  const versionedHistory = sortedHistory.map((h, i) => {
    h.versionId = history.length - i;
    return h;
  });

  return versionedHistory;
};

const HistoryRow = (props) => {
  const { historyRow } = props;
  return (
    <ListItem>
      <Grid container>
        <Grid item>
          <Box color="secondary.main">{`V${historyRow.versionId} |`}</Box>
        </Grid>
        {!historyRow.isPublished ? (
          <Grid item>DRAFT</Grid>
        ) : (
          <>
            <Grid item xs={2}>
              {dateFormatter({ value: historyRow.publishedDate })}
            </Grid>
            <Grid item xs={3}>
              {historyRow.publishedBy ? historyRow.publishedBy.moveTrackUserName : ''}
            </Grid>
            <Grid item xs={6}>
              {historyRow.publishNotes}
            </Grid>
          </>
        )}
      </Grid>
    </ListItem>
  );
};

HistoryRow.propTypes = {
  historyRow: PropTypes.object.isRequired,
};

const VersionHistoryRows = (props) => {
  const { history } = props;
  return (
    history && (
      <Paper elevation={1}>
        <Box maxHeight={250} overflow="auto">
          <List>
            {history.map((h, i) => {
              return (
                <Fragment key={i}>
                  <HistoryRow key={i} historyRow={h} />
                  {i + 1 < history.length && <Divider />}
                </Fragment>
              );
            })}
          </List>
        </Box>
      </Paper>
    )
  );
};

VersionHistoryRows.propTypes = {
  history: PropTypes.array.isRequired,
};

export default VersionHistoryRows;
