import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const DOCTYPE_WORD = 'DOCTYPE_WORD';
export const DOCTYPE_EXCEL = 'DOCTYPE_EXCEL';
export const DOCTYPE_PDF = 'DOCTYPE_PDF';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

class FormSaveAsButton extends Component {
  constructor(props){
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  closeMenu = () => {
    this.setState({ anchorEl: null });
  }

  handleMenuClick = (type) => {
    let exportType;

    switch (type) {
      case DOCTYPE_WORD:
        exportType = 'Reporting/RenderWordReport';
        break;

      case DOCTYPE_EXCEL:
        exportType = 'Reporting/RenderExcelReport';
        break;

      case DOCTYPE_PDF:
        exportType = 'Reporting/RenderPdfReport';
        break;

      default:
        exportType = null;
        break;
    }

    this.closeMenu();
    this.props.onClick(exportType);
  }

  render() {
    const { classes, disabled } = this.props;

    return (
      <div style={{ margin: 'auto' }}>
        <Button
          variant="contained"
          color="primary"
          aria-owns={this.state.anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.openMenu}
          disabled={disabled}
          className={classes.button}
        >Save As <ArrowDropDown className={classes.rightIcon} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.closeMenu}
        >
          <MenuItem onClick={() => this.handleMenuClick(DOCTYPE_WORD)}>Word</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(DOCTYPE_EXCEL)}>Excel</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(DOCTYPE_PDF)}>PDF</MenuItem>
        </Menu>
      </div>
    );
  }
}

FormSaveAsButton = withStyles(styles)(FormSaveAsButton);

export default FormSaveAsButton;