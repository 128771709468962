import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Menu from 'common/menu.component';

import Box from '@material-ui/core/Box';

const FormButtons = (props) => {
  const {
    version,
    summary,
    isSubmitting,
    enableSave,
    enablePublish,
    publishAction,
    publishAndEnableAction,
    saveAction,
    cancelAction,
    deleteDraftAction,
    deleteAction,
    createDraftAction,
    editDraftAction,
  } = props;

  const renderButtons = () => {
    const buttons = [];
    if (version.isPublished) {
      buttons.push(
        <Grid item key="delete">
          <Box color="error.main" width={100}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={deleteAction}
              disabled={isSubmitting}
            >
              Delete
            </Button>
          </Box>
        </Grid>,
      );
      if (!summary.draft) {
        buttons.push(
          <Grid item key="createDraft">
            <Button
              variant="contained"
              color="primary"
              onClick={createDraftAction}
              disabled={isSubmitting}
            >
              Create Draft
            </Button>
          </Grid>,
        );
      } else {
        buttons.push(
          <Grid item key="editDraft">
            <Button
              variant="contained"
              color="primary"
              onClick={editDraftAction}
              disabled={isSubmitting}
            >
              Edit Draft
            </Button>
          </Grid>,
        );
      }
    } else {
      buttons.push(
        <Grid item key="publish">
          <Menu
            label="Publish"
            disabled={isSubmitting || !enablePublish}
            items={
              [
                {
                  label: 'Publish',
                  action: publishAction,
                },
                {
                  label: 'Publish & Enable',
                  action: publishAndEnableAction,
                },
              ]
            }
          />
        </Grid>,
      );
      buttons.push(
        <Grid item key="saveDraft">
          <Button
            color="primary"
            variant="outlined"
            onClick={saveAction}
            disabled={isSubmitting || !enableSave}
          >
            Save Draft
          </Button>
        </Grid>,
      );
      if (version.versionId) {
        buttons.push(
          <Grid item key="deleteDraft">
            <Box color="error.main">
              <Button
                color="inherit"
                variant="outlined"
                onClick={deleteDraftAction}
                disabled={isSubmitting}
              >
                Delete Draft
              </Button>
            </Box>
          </Grid>,
        );
      }
    }
    return buttons;
  };
  
  return (
    <Grid container justify="flex-end" spacing={1}>
      {renderButtons()}
      {cancelAction &&
        <Grid item key="cancel">
          <Button
            color="primary"
            onClick={cancelAction}
          >
            Cancel
          </Button>
        </Grid>
      }
    </Grid>
  );
};

FormButtons.defaultProps = {
  summary: {},
};

FormButtons.propTypes = {
  createDraftAction: PropTypes.func.isRequired,
  editDraftAction: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  deleteDraftAction: PropTypes.func.isRequired,
  enablePublish: PropTypes.bool.isRequired,
  enableSave: PropTypes.bool.isRequired,
  publishAction: PropTypes.func.isRequired,
  publishAndEnableAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func,
  version: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  summary: PropTypes.object,
};

export default FormButtons;
