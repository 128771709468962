import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SpinnerButton = (props) => {
  const classes = useStyles(props);
  const {
    buttonComponent,
    isLoading,
  } = props;

  return (
    <div className={classes.wrapper}>
      {buttonComponent}
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

export default SpinnerButton;