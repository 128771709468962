import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

import { PhoneNumberFormatText } from '../../common/numberFormatCustom.component';
import { Textfit } from 'react-textfit';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';

import coverImage from '../../images/globe.png';

const styles = {
  cover: {
    position: 'relative',
    height: '30%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  coverAvatar: {
    position: 'absolute',
    bottom: -20,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
  },
  contactHeading: {
    marginTop: '5px',
    height: '4rem',
    textAlign: 'center',
    fontWeight: 'bold',
    overflow: 'hidden',
    color: '#8a8a8a',
  },
  contactInfo: {
    marginTop: '5px',
    textAlign: 'center',
    color: '#8a8a8a',
  },
};

class ContactCard extends Component {
  constructor(props) {
    super(props);

    this.sendTo = this.sendTo.bind(this);
  }

  sendTo(path) {
    this.props.history.push(path);
  }

  render() {
    const { classes, contact } = this.props;
    const { EmailAddress, FirstName, LastName, OfficePhone, Photo } = contact;

    return (
      <Paper style={{ height: '100%', borderRadius: '0.5rem', cursor: 'pointer' }} onClick={(evt) => this.sendTo('/contacts')}>
        <div className={classes.cover} style={{ backgroundImage: `url(${coverImage})` }}>
          <Avatar
            className={classes.coverAvatar}
            style={{ height: '5rem', width: '5rem' }}
          >
            {Photo && <img src={`data:image/jpeg;base64,${  Photo}`} style={{ width: '100%' }} alt="" />}
            {!Photo &&
              <AccountCircle style={{ height: '100%', width: '100%' }} />
            }
          </Avatar>
        </div>
        <div style={{ padding: '1rem', overflow: 'hidden' }}>
          <div className={classes.contactHeading}>
            <Textfit>{`${FirstName  } ${  LastName}`}</Textfit>
          </div>
          <div className={classes.contactInfo}>
            {/* <Phone /> */}
            <a style={{ textDecoration: 'underline', color: '#8a8a8a' }} href={`tel:${OfficePhone}`} onClick={(evt) => evt.stopPropagation()}>
              <PhoneNumberFormatText value={OfficePhone} />
            </a>
          </div>
          <div className={classes.contactInfo}>
            {/* <Email /> */}
            <a style={{ textDecoration: 'underline', color: '#8a8a8a' }} href={`mailto:${EmailAddress}`} onClick={(evt) => evt.stopPropagation()}>
              <Textfit>{EmailAddress}</Textfit>
            </a>
          </div>
        </div>
      </Paper>
    );
  }
}

ContactCard = withStyles(styles)(ContactCard);

export default withRouter(ContactCard);
