import { isEmpty } from 'lodash';

import { COST_TYPES } from 'modules/benefits/constants';
import { isOptionEnabled } from './policyBenefitUtil';

// return a range object {highest, lowest}
export const calculateBenefitCost = (benefit, { costList, api }) => {
  const enabledOptions = benefit.options.filter(isOptionEnabled);
  if (enabledOptions.length === 0) {
    return null;
  }
  if (benefit.costType === COST_TYPES.AMOUNT) {
    return getMinMax(enabledOptions.map((opt) => ({ lowest: opt.cost, highest: opt.cost })));
  } else if (benefit.costType === COST_TYPES.TABLE_LOOKUP) {
    if (isEmpty(costList)) {
      return null;
    }
    return getMinMax(enabledOptions.map((opt) => calculateOptionCost(opt, costList)));
  } else if (benefit.costType === COST_TYPES.API_CALC) {
    if (isEmpty(api) || !(benefit.minCost != null && benefit.maxCost != null)) {
      return null;
    }
    return { lowest: benefit.minCost, highest: benefit.maxCost };
  }
};

const getMinMax = (costRanges) => {
  return costRanges.reduce((minMax, costRange) => {
    if (costRange.lowest < minMax.lowest) {
      minMax.lowest = costRange.lowest;
    }
    if (costRange.highest > minMax.highest) {
      minMax.highest = costRange.highest;
    }
    return minMax;
  }, { lowest: Number.POSITIVE_INFINITY, highest: 0 });
};

const calculateOptionCost = (option, costList) => {
  const costEntries = costList.filter((costEntry) => {
    return Object.keys(option.costVariables).every((costVariableKey) => {
      return option.costVariables[costVariableKey] === costEntry[costVariableKey];
    });
  });
  return getMinMax(costEntries.map((entry) => ({ lowest: entry.cost, highest: entry.cost })));
};
