import { LinearProgress, withStyles } from '@material-ui/core';

const ProgressBar = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export default ProgressBar;
