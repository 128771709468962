/* eslint-disable array-callback-return */

export const mapStatusCategories = (authorizations) => { 
    authorizations.map((auth) => { 
        if(auth.Status === 'Active' || auth.Status === 'Init' || auth.Status === 'On Hold' || auth.Status === 'Ops Closed' || auth.Status === 'On Assignment' || auth.Status === 'PENDING'){
            auth.StatusGrouping = 'Active';
        }
        else
        {
            auth.StatusGrouping = auth.Status;
        }
    });
    return authorizations;
}