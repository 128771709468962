import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { dateFormatter } from 'utilities/dateFormatter';

const SummaryMetaData = (props) => {
  const { summary } = props;
  const theme = useTheme();

  return (
    <Box mx={2} py={1}>
      <Grid container item spacing={0}> 
        <Box mr={1}>
          <FontAwesomeIcon
            icon={['fas', 'circle']}
            color={summary.mostRecentPublish ? theme.palette.secondary.main : theme.palette.warning.main}
          />
        </Box>
        <Box mr={1}>
          <Typography color="textSecondary" variant="subtitle2">
            {summary.mostRecentPublish ? 'Published' : 'Unpublished'}
          </Typography>
        </Box>
        {summary.mostRecentPublish &&
          <Box mr={1}>
            <Typography color="textSecondary" variant="body2">
              {dateFormatter({value: summary.mostRecentPublish.publishedDate})}
            </Typography>
          </Box>
        }
        {summary.draft && summary.mostRecentPublish &&
          <>
            <Box mr={1}>
              <Divider orientation="vertical" />
            </Box>
            <Typography color="textSecondary" variant="subtitle2">
              Active Draft
            </Typography>
          </>
        }
      </Grid>
    </Box>
  );
};

SummaryMetaData.propTypes = {
  summary: PropTypes.object.isRequired,
};

export default SummaryMetaData;
