
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { Component } from 'react';
import classnames from 'classnames';

import { LEGACY_ICON_MAP } from '../../layout/icons';
import { detectIE } from '../../../utilities/browserUtils';
import { getShortDayOfWeek } from '../../../utilities/dateFormatter';

const styles = (theme) => ({
  avatarRoot: {
    height: '50px',
    width: '50px',
  },
  timelineTaskContainer: {
    display: 'flex',
    minHeight: '4rem',
    width: '100%',
    borderBottom: '1px solid white',
    borderTop: '1px solid white',
  },
  timelineTaskHeader: {
    backgroundColor: 'gray',
    width: '25%',
    padding: '0.5rem',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  timelineTaskContent: {
    width: '75%',
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  completeButtonContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdownArrow: {
    marginLeft: theme.spacing(1),
  },
});

class TimelineTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timelineMenuAnchorEl: null,
    };

    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }
  // callback to set first overdue item for scroll
  componentDidMount() {
    if (this.props.isFirstOverdueItem) {
      this.props.setFirstOverdueElem(this);
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      timelineMenuAnchorEl: event.currentTarget,
    });
  };

  handleMenuClose = (event) => {
    this.setState({
      timelineMenuAnchorEl: null,
    });
  }

  handleAction(action, event) {
    this.props.handleAction(action, event);
    this.setState({
      timelineMenuAnchorEl: null,
    });
  }

  renderHeader() {
    const {
      classes,
      isBeforeToday,
      isToday,
      completed,
      icon,
      taskDate,
    } = this.props;
    const isIE = detectIE();
    const isOverdue = isBeforeToday && !completed;

    return (
      <div className={classes.timelineTaskHeader}
        style={{
          backgroundColor: isOverdue ?
            'red' : isBeforeToday ?
              'lightgray' : isToday ?
                '#5B9B9D' : 'darkgray',
        }}
      >

        <Avatar
          classes={{ root: classes.avatarRoot }}
          style={{ backgroundColor: 'white', color: 'black', margin: isIE ? 'auto' : 'unset' }}
        >
          {completed ? <FontAwesomeIcon icon="check" color={isToday ? '#5B9B9D' : '#313131'} /> :
            isOverdue ?
              <div style={{ fontSize: '2.5rem', fontWeight: 'bold', color: 'red' }}>{'!'}</div> :
              <FontAwesomeIcon icon={LEGACY_ICON_MAP[icon]} color={isToday ? '#5B9B9D' : '#313131'} size="lg" />
          }
        </Avatar>

        {taskDate &&
          <div style={{ fontSize: '1.2rem', textAlign: 'center' }}>
            {isToday ? 'Today': getShortDayOfWeek(taskDate.day())}
            <div style={{ fontSize: '1.3rem' }}>{taskDate.month() + 1}/{taskDate.date()}</div>
          </div>
        }
      </div>
    );
  }

  render() {
    const { classes,
      text,
      title,
      colorKey,
      completed,
      isToday,
      actions,
      isLastItem,
    } = this.props;

    const { timelineMenuAnchorEl } = this.state;
    const isEven = colorKey % 2 === 0;

    // filter out non-actions
    const filteredActions = actions ? actions.filter((action) => {
      return action.type !== 'none';
    }) : [];

    return (
      <div className={classes.timelineTaskContainer} style={{ opacity: completed ? 0.3 : 1.0, borderBottom: isLastItem ? 'unset' : null }}>
        {this.renderHeader()}

        <Grid container style={{ backgroundColor: isEven ? 'lightgray' : 'white', margin: '0px' }}>
          <Grid item xs={12}>
            <div style={{ fontSize: '1.2rem' }}>
              {title}
            </div>
          </Grid>

          <Grid item xs={12}>{text}</Grid>

          {!completed &&
            <Grid item xs={6}>
              {filteredActions.length === 1 &&
                <Button
                  color={isToday ? 'primary': 'default'}
                  variant="outlined"
                  onClick={(evt) => this.handleAction(filteredActions[0], evt)}
                >
                  {filteredActions[0].buttonLabel}
                </Button>
              }

              {filteredActions.length > 1 &&
                <div>
                  <Button
                    aria-haspopup="true"
                    aria-owns={timelineMenuAnchorEl ? 'render-props-menu' : undefined}
                    color={isToday ? 'primary': 'default'}
                    onClick={(event) => this.handleMenuOpen(event)}
                    variant="outlined"
                  >
                    Actions <FontAwesomeIcon className={classes.dropdownArrow} icon="caret-down" />
                  </Button>
                  <Menu
                    anchorEl={timelineMenuAnchorEl}
                    id="render-props-menu"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={!!timelineMenuAnchorEl}
                    onClose={this.handleMenuClose}
                  >
                    {filteredActions.map((action, i) => {
                      return <MenuItem key={i} onClick={(evt) => this.handleAction(action, evt)}>{action.buttonLabel}</MenuItem>;
                    })}
                  </Menu>
                </div>
              }

            </Grid>
          }

          {/* Show button to match what the transferee sees, but don't allow user to take action on it */}
          {!completed &&
            <Grid item xs={6} className={classnames(classes.cardSpacing, classes.completeButtonContainer)}>
              <Button
                color={isToday ? 'primary': 'default'}
                disabled={true}
                variant={isToday ? 'contained': 'outlined'}
              >
                Mark Complete
              </Button>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(TimelineTask);
