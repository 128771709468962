import React, { Component } from 'react';

import {
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

class ChartDetailGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'ReloPolicy', title: 'Program' },
        { name: 'CurrentYear', title: 'This Year' },
        { name: 'PreviousYear', title: 'Last Year' },
      ],
      columnWidths: [
        { columnName: 'ReloPolicy', width: 230 },
        { columnName: 'CurrentYear', width: 88 },
        { columnName: 'PreviousYear', width: 88 },
      ],
      rows: [],
      sorting: [{ columnName: 'CurrentYear', direction: 'desc' }],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.TableRow = ({ row, handleViewSubmittedAuth, ...restProps }) => {
      return (<VirtualTable.Row
        {...restProps}
        style={{
          cursor: 'default',
        }}
              />);
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  componentWillMount() {
    this.setState({ rows: this.props.rows });
  }

  render() {
    const { rows, columns, sorting, columnWidths } = this.state;

    if (!rows) {
      return <div />;
    }

    return (
      <Grid
        rows={rows}
        columns={columns}
      >
        <SortingState
          sorting={sorting}
          onSortingChange={this.changeSorting}
        />
        <IntegratedSorting />
        <VirtualTable rowComponent={this.TableRow} height={300} />
        <TableColumnResizing
          columnWidths={columnWidths}
        // onColumnWidthsChange={this.changeColumnWidths}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    );
  }
}

export default ChartDetailGrid;