import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React  from 'react';

const PolicyStep = (props) => {
  const { activeStep, formStep, component: Component } = props;
  return (
    <Box style={{ display: activeStep === formStep ? '' : 'none' }} >
      <Component {...props} />
    </Box>
  );
};

PolicyStep.propTypes = {
  component: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  formStep: PropTypes.number.isRequired,
};

export default PolicyStep;