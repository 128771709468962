import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import CustomDatePicker from '../../../common/customDatePicker.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';

import { detectIE } from '../../../utilities/browserUtils';
import { downloadReport } from '../../../utilities/reportingUtils';

import { getClientFieldListValues } from '../reporting.actions';
import FormGetReportButton from './formGetReportButton.component';
import FormInstructions from './formInstructions.component';
import FormSaveAsButton from './formSaveAsButton.component';
import RenderHtmlForm from './renderHtmlForm.component';

import { getReportByLink } from '../reportDefinitions';

const styles = (theme) => ({
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    marginTop: '0.5rem',
    marginRight: '1rem',
  },
});

const initialFormState = {
  StartDate: '',
  EndDate: '',
  EmployeeType: '',
  Resource: '',
  Status: '',
};

const resourceOptions = [
  { value: 'Domestic', description: 'Domestic' },
  { value: 'International', description: 'International' },
];

const statusOptions = [
  { value: 'Active', description: 'Active' },
  { value: 'Closed', description: 'Closed' },
  { value: 'Canceled', description: 'Canceled' },
];

class CostFormWithTaxAssist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: initialFormState,
      submittedFormData: null,
      showRenderedForm: false,
      criteriaExpanded: true,
    };

    this.reportDefinition = null;

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.formHasErrors = this.formHasErrors.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.handleShowRenderedForm = this.handleShowRenderedForm.bind(this);
    this.handleEmptyResult = this.handleEmptyResult.bind(this);
  }

  async componentWillMount() {
    const reportLink = this.props.match.params.name;

    let StartDate = '';
    let EndDate = '';

    if (this.props.location.state !== undefined) {
      StartDate = this.props.location.state.startDate;
      EndDate = this.props.location.state.endDate;
    }

    this.reportDefinition = getReportByLink(reportLink);

    this.props.getClientFieldListValues();

    if (StartDate && EndDate) {
      await this.setState({
        formData: {
          StartDate,
          EndDate,
        },
      });

      this.handleShowRenderedForm();
    }
  }

  handleFormChange = (name) => (event) => {
    let updateValue = '';
    if (event && !event.target) {
      updateValue = event;
    } else if (event && event.target) {
      updateValue = event.target.value;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: updateValue,
      },
    });
  };

  resetFormValues() {
    this.setState({
      formData: initialFormState,
    });
  }

  formHasErrors() {
    const { formData } = this.state;
    const { StartDate, EndDate } = formData;
    let datesAreValid = false;

    if (StartDate && EndDate) {
      try {
        const endDate = new Date(EndDate).getTime();
        const startDate = new Date(StartDate).getTime();
        if (endDate >= startDate) datesAreValid = true;
      } catch (e) {
        // do nothing
      }
    }
    return (!formData.StartDate || !formData.EndDate) || !datesAreValid;
  }

  handleSaveAs(formAction) {
    if (formAction) {
      downloadReport(formAction, this.state.formData, this.reportDefinition.reportPath, this.reportDefinition.reportFileName);
    }
  }

  handleShowRenderedForm() {
    this.setState({
      submittedFormData: this.state.formData,
      criteriaExpanded: false,
    });
  }

  handleExpandClick() {
    this.setState({ criteriaExpanded: !this.state.criteriaExpanded });
  }

  handleEmptyResult() {
    this.setState({
      criteriaExpanded: true,
    });
  }

  render() {
    const { classes, clientFieldValues } = this.props;
    const { submittedFormData, criteriaExpanded } = this.state;
    const { StartDate, EndDate, EmployeeType, Resource, Status } = this.state.formData;

    const empTypeOptions = clientFieldValues.filter((item) => item.PageFieldId === 6);
    const formDisabled = this.formHasErrors();

    const isIE = detectIE();

    return (
      <div>
        <Card style={{ marginBottom: '1rem' }}>
          <CardHeader
            action={
              <Button
                className={classes.expandButton}
                onClick={this.handleExpandClick}
                variant="contained"
                size="small"
                color="secondary"
              >
                {criteriaExpanded ? 'Collapse' : 'Expand'}
                <ExpandMoreIcon
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.criteriaExpanded,
                  })}
                />
              </Button>
            }
            title="Report Criteria"
          />
          <CardContent>
            <Collapse in={this.state.criteriaExpanded} timeout="auto" unmountOnExit style={{ marginBottom: '1rem' }}>
              <Grid container style={{ flexDirection: 'row', display: 'flex' }}>

                <Grid item xs={12} md={3}>
                  <div className={classes.column} style={{ width: '100%', flexWrap: 'wrap' }}>
                    {isIE && <CustomDatePicker selected={StartDate} onChange={this.handleFormChange('StartDate')} label={'Start Date'} required={true} />}
                    {!isIE &&
                      <TextField
                        id="startDate"
                        label="Start Date"
                        type="date"
                        onChange={this.handleFormChange('StartDate')}
                        value={StartDate ? StartDate : ''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={isIE ? 'yyyy-mm-dd' : null}
                        className={classes.textField}
                        required
                      />
                    }
                    <TextField
                      id="Resource"
                      select
                      label="Domestic/International"
                      className={classes.textField}
                      value={Resource ? Resource : ''}
                      onChange={this.handleFormChange('Resource')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="" />
                      {resourceOptions.map((res, i) => {
                        return (<MenuItem key={i} value={res.value}>
                          {res.description}
                        </MenuItem>);
                      })}
                    </TextField>
                  </div>
                </Grid>

                <Grid item xs={12} md={1}>
                  <div style={{ marginTop: '2rem', textAlign: 'center' }}>To</div>
                </Grid>

                <Grid item xs={12} md={3}>
                  <div className={classes.column}>
                    {isIE && <CustomDatePicker selected={EndDate} onChange={this.handleFormChange('EndDate')} label={'End Date'} required={true} />}
                    {!isIE && <TextField
                      id="endDate"
                      label="End Date"
                      type="date"
                      onChange={this.handleFormChange('EndDate')}
                      value={EndDate ? EndDate : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={isIE ? 'yyyy-mm-dd' : null}
                      className={classes.textField}
                      required
                    />
                    }
                    <TextField
                      id="Status"
                      select
                      label="Status"
                      className={classes.textField}
                      value={Status ? Status : ''}
                      onChange={this.handleFormChange('Status')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="" />
                      {statusOptions.map((status, i) => {
                        return (<MenuItem key={i} value={status.value}>
                          {status.description}
                        </MenuItem>);
                      })}
                    </TextField>
                  </div>
                </Grid>

                <Grid item xs={12} md={3}>
                  {empTypeOptions.length > 0 &&
                    <div className={classes.column}>
                      <TextField
                        id="EmployeeType"
                        select
                        label="Employee Type"
                        className={classes.textField}
                        value={EmployeeType ? EmployeeType : ''}
                        onChange={this.handleFormChange('EmployeeType')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value="" />
                        {empTypeOptions.map((empType, i) => {
                          return (<MenuItem key={i} value={empType.FieldListValue}>
                            {empType.FieldListDescription}
                          </MenuItem>);
                        })}
                      </TextField>
                    </div>
                  }
                </Grid>

                <Grid item xs={12} md={2}>
                  <div className={classes.column}>
                    <FormGetReportButton onClick={() => this.handleShowRenderedForm()} disabled={formDisabled} />
                    <FormSaveAsButton onClick={this.handleSaveAs} disabled={formDisabled} />
                    <Button color="secondary" onClick={() => this.resetFormValues()} className={classes.button}>Reset</Button>
                  </div>
                </Grid>

              </Grid>

            </Collapse>
            {
              submittedFormData &&
                <RenderHtmlForm formData={submittedFormData} reportPath={this.reportDefinition.reportPath} handleEmptyResult={this.handleEmptyResult} />
            }
          </CardContent>
        </Card>

        {!submittedFormData &&
          <FormInstructions />
        }

      </div>
    );
  }
}

CostFormWithTaxAssist = withStyles(styles)(CostFormWithTaxAssist);

const mapStateToProps = (state) => {
  return {
    clientFieldValues: state.reporting.get('clientFieldValues'),
  };
};

export default withRouter(connect(mapStateToProps, {
  getClientFieldListValues,
})(CostFormWithTaxAssist));