import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import React from 'react';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 500,
  },
  title: {
    paddingBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function Dialog(props) {
  const classes = useStyles();
  const { title, content, actions, showTitleClose, onClose, ...rest } = props;

  return (
    <MuiDialog
      classes={{ paper: classes.dialog }}
      disableBackdropClick
      disableEscapeKeyDown
      {...rest}
    >
      <DialogTitle classes={{ root: classes.title }}>
        {title}
        {(onClose && showTitleClose) &&
          (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </MuiDialog>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node,
  showTitleClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Dialog;
