import { memoize } from 'lodash';

export const getVersionFromSummary = (summary) => {
  return summary.mostRecentPublish || summary.draft;
};

export const getVersionFromSummaryById = (summary, versionId) => {
  return [summary.mostRecentPublish || {}, summary.draft || {}]
    .find((version) => version.versionId === versionId);
};

export const sortSummaries = memoize((summaries) => {
  const sorted = [...summaries];
  // sort enabled to the top alphabetically, nulls at the top
  sorted.sort((summary1, summary2) => {
    return  summary1.enabled !== summary2.enabled ? (summary1.enabled ? -1 : 1) : (
      !summary1.name ? 1 :
        (
          !summary2.name ? -1 : 
            getVersionFromSummary(summary1).name.localeCompare(getVersionFromSummary(summary2).name, undefined, { sensitivity: 'base' })
        )
    );
  });
  return sorted;
});