import { Alert, AlertTitle } from '@material-ui/lab';
import { Snackbar, Typography, makeStyles } from '@material-ui/core';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_INFO, hideToast } from './layout.actions';

const useStyles = makeStyles((theme) => (
  {
    alertRoot:{
      width: 400,
    },
    anchorOriginTopCenter: {
      top: 10,
    },
    alertMessage: {
      fontSize: '0.9rem',
      minWidth: 300,
      textAlign: 'center',
    },
    reference: {
      fontSize: '0.6rem',
    },
  }
));

const Toast = (props) => {
  const { shouldShowToast, hideToast: propsHideToast, userMustDismissToast, toastSeverity, toastMessage, errorEventId } = props;
  const classes = useStyles();

  // force user dismiss by default for error
  const showDismiss = userMustDismissToast !== undefined ? userMustDismissToast : 
    toastSeverity === TOAST_MESSAGE_SEVERITY_ERROR ? true : false;

  return (
    <div>
      <Snackbar
        classes={{
          anchorOriginTopCenter: classes.anchorOriginTopCenter,
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={shouldShowToast}
        onClose={(event, reason) => {
          if(reason !== 'clickaway')
            propsHideToast();
        }}
        autoHideDuration={showDismiss ? null : 3000}
      >
        <Alert
          classes={{
            message: classes.alertMessage,
          }}
          onClose={showDismiss ? propsHideToast : null}
          severity={toastSeverity}
        >
          <AlertTitle><div dangerouslySetInnerHTML={{ __html: toastMessage }} /></AlertTitle>
          {(toastSeverity === TOAST_MESSAGE_SEVERITY_ERROR && errorEventId) && <Typography className={classes.reference}>Reference: {errorEventId}</Typography> }
        </Alert>
      </Snackbar>
    </div>
  );
};

Toast.propTypes = {
  shouldShowToast: PropTypes.bool.isRequired,
  toastMessage: PropTypes.string.isRequired,
  hideToast: PropTypes.func.isRequired,
  userMustDismissToast: PropTypes.bool,
  toastSeverity: PropTypes.string,
  errorEventId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    errorEventId: state.apiError.errorEventId,
    shouldShowToast: state.layout.get('shouldShowToast'),
    toastMessage: state.layout.get('toastMessage'),
    userMustDismissToast: state.layout.get('userMustDismissToast'),
    toastSeverity: state.layout.get('toastSeverity') ? state.layout.get('toastSeverity') : TOAST_MESSAGE_SEVERITY_INFO,
  };
};

export default connect(mapStateToProps, { hideToast })(Toast);
