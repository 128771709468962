import { Box, Button, Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import { setPageTitle } from 'modules/layout/layout.actions';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import AuthorizationsPage from './authorizations.container';
import PropTypes from 'prop-types';
import React from 'react';
import TableauReportWrapper from 'modules/reporting/tableauReportWrapper.component';

const MPCAuthorizations = (props) => {
  const { setPageTitle, history } = props;
  useEffect(() => {
    setPageTitle('MPC Authorizations');
  }, [setPageTitle]);

  const handleNewAuthorization = () => {
    history.push('/authorizations/new');
  };

  const tableauReport = 'views/MPCAuthorizations{{env}}/Authorizations';
  return (
    <Box>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleNewAuthorization}
        style={{ float: 'right', marginTop: '1rem', marginRight: '4rem' }}
      >
        <Add />
        <Hidden mdUp>New</Hidden>
        <Hidden smDown>Add New</Hidden>
      </Button>
      <Box padding="4rem 2rem 2rem" height="100%" width="100%">
        <TableauReportWrapper reportPath={tableauReport} />
      </Box>
      <Box padding="4rem 2rem 2rem" height="100%" width="100%">
        <AuthorizationsPage />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

MPCAuthorizations.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
  })(MPCAuthorizations),
);
