import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import SpinnerButton from '../../common/spinnerButton.component';
import TextField from '@material-ui/core/TextField';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = (theme) => ({
  dialogPaper: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  row: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
});

class ClientSelectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedClient: props.selectedClient,
      isLoading: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.selectedClient && this.props.clientOptions.length > 1) {
      this.setState({
        selectedClient: this.props.clientOptions[0].id,
      });
    }
  }

  handleFormFieldChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handleClose() {
    this.props.handleClose();
    this.setState({
      selectedClient: this.props.selectedClient,
    });
  }

  // return null if all are selected, otherwise return full client object
  handleSubmit() {
    this.setState({ isLoading: true });
    let newClient = this.state.selectedClient;
    newClient = this.props.clientOptions.find((item) => item.id === newClient);
    this.props.handleChangeClient(newClient);
  }

  render() {
    // const { clients } = this.props;
    const { title, clientOptions, open, classes, handleClose, showAsModal, contentText } = this.props;
    const { selectedClient, isLoading } = this.state;

    return (
      <div>
        <Dialog
          classes={{
            paper: classes.dialogPaper,
          }}
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown={showAsModal}
          disableBackdropClick={showAsModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <div>{contentText}</div>
            <div className={classes.row}>
              {clientOptions &&
                <TextField
                  id="Client"
                  select
                  label="Client"
                  className={classes.textField}
                  value={selectedClient ? selectedClient : ''}
                  onChange={this.handleFormFieldChange('selectedClient')}
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      className: classes.textField,
                    },
                  }}
                >
                  {clientOptions.map((client, i) => {
                    return (<MenuItem key={i} value={client.id}>
                      {client.description}
                    </MenuItem>);
                  })}
                </TextField>
              }
            </div>
          </DialogContent>
          <DialogActions>
            {!showAsModal && <Button onClick={() => this.handleClose()} disabled={isLoading} color="primary" autoFocus>
              Cancel
            </Button>}

            <SpinnerButton
              isLoading={isLoading}
              buttonComponent={
                <Button onClick={() => this.handleSubmit()} color="primary" disabled={isLoading} autoFocus>
                  Set Client</Button>}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ClientSelectDialog = withStyles(styles, { withTheme: true })(ClientSelectDialog);

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {

})(withMobileDialog()(ClientSelectDialog));