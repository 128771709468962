import { CALL_API } from '../../middleware/api';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_CONTENT_WIDTH = 'SET_CONTENT_WIDTH';
export const SET_PENDING_TRANSFEREE_COUNT = 'SET_PENDING_TRANSFEREE_COUNT';
export const SET_PENDING_EXCEPTION_COUNT = 'SET_PENDING_EXCEPTION_COUNT';

export const SET_RESPONSIVE_BREAKPOINT = 'SET_RESPONSIVE_BREAKPOINT';
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN';
export const SET_MOBILE_DRAWER_OPEN = 'SET_MOBILE_DRAWER_OPEN';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';
export const SET_TOAST_POSITION = 'SET_TOAST_POSITION';
export const SET_USER_INFO = 'SET_USER_INFO';

export const GET_CLIENT_INFO_REQUEST = 'GET_CLIENT_INFO_REQUEST';
export const GET_CLIENT_INFO_SUCCESS = 'GET_CLIENT_INFO_SUCCESS';
export const GET_CLIENT_INFO_FAILURE = 'GET_CLIENT_INFO_FAILURE';

export const GET_NS_URL_REQUEST = 'GET_NS_URL_REQUEST';
export const GET_NS_URL_SUCCESS = 'GET_NS_URL_SUCCESS';
export const GET_NS_URL_FAILURE = 'GET_NS_URL_FAILURE';

export const GET_INEO_COST_ESTIMATOR_URL_REQUEST = 'GET_INEO_COST_ESTIMATOR_URL_REQUEST';
export const GET_INEO_COST_ESTIMATOR_URL_SUCCESS = 'GET_INEO_COST_ESTIMATOR_URL_SUCCESS';
export const GET_INEO_COST_ESTIMATOR_URL_FAILURE = 'GET_INEO_COST_ESTIMATOR_URL_FAILURE';

export const GET_CLIENT_DIVISIONS_REQUEST = 'GET_CLIENT_DIVISIONS_REQUEST';
export const GET_CLIENT_DIVISIONS_SUCCESS = 'GET_CLIENT_DIVISIONS_SUCCESS';
export const GET_CLIENT_DIVISIONS_FAILURE = 'GET_CLIENT_DIVISIONS_FAILURE';

export const SET_BACK_BUTTON_PATH = 'SET_BACK_BUTTON_PATH';
export const CLEAR_BACK_BUTTON_PATH = 'CLEAR_BACK_BUTTON_PATH';

export const TOAST_MESSAGE_SEVERITY_ERROR = 'error';
export const TOAST_MESSAGE_SEVERITY_WARNING = 'warning';
export const TOAST_MESSAGE_SEVERITY_INFO = 'info';
export const TOAST_MESSAGE_SEVERITY_SUCCESS = 'success';

export const setPageTitle = (title) => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
  };
};

export const setContentWidth = (width) => {
  return {
    type: SET_CONTENT_WIDTH,
    payload: width,
  };
};

export const setNumPendingTransferees = (num) => {
  return {
    type: SET_PENDING_TRANSFEREE_COUNT,
    payload: num,
  };
};

export const setNumPendingExceptions = (num) => {
  return {
    type: SET_PENDING_EXCEPTION_COUNT,
    payload: num,
  };
};

export const setResponsiveBreakpoint = (breakpoint) => {
  return {
    type: SET_RESPONSIVE_BREAKPOINT,
    payload: breakpoint,
  };
};

export const setDrawerOpen = (isOpen) => {
  return {
    type: SET_DRAWER_OPEN,
    payload: isOpen,
  };
};

export const setMobileDrawerOpen = (isOpen) => {
  return {
    type: SET_MOBILE_DRAWER_OPEN,
    payload: isOpen,
  };
};

export const showToast = (message, options) => {
  return {
    type: SHOW_TOAST_MESSAGE,
    message,
    userMustDismiss: options ? options.userMustDismiss : null,
    severity: options ? options.severity : null,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

export const setUserInformation = (userName, fullName) => {
  return {
    type: SET_USER_INFO,
    payload: {
      UserName: userName,
      FullName: fullName,
    },
  };
};

export const getClientInformation = (clientId) => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_INFO_REQUEST, GET_CLIENT_INFO_SUCCESS, GET_CLIENT_INFO_FAILURE],
      authenticated: true,
      endpoint: `Account/GetClientInformation?clientId=${clientId}`,
      method: 'GET',
    },
  };
};

export const getNeighborhoodScoutUrl = () => {
  return {
    [CALL_API]: {
      types: [GET_NS_URL_REQUEST, GET_NS_URL_SUCCESS, GET_NS_URL_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetNeighborhoodScoutUrl',
      method: 'GET',
    },
  };
};

export const getIneoCostEstimatorUrl = (clientId) => {
  return {
    [CALL_API]: {
      types: [GET_INEO_COST_ESTIMATOR_URL_REQUEST, GET_INEO_COST_ESTIMATOR_URL_SUCCESS, GET_INEO_COST_ESTIMATOR_URL_FAILURE],
      authenticated: true,
      endpoint: `Resource/GetIneoCostEstimatorUrl?clientId=${clientId}`,
      method: 'GET',
    },
  };
};

export const getClientDivisions = () => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_DIVISIONS_REQUEST, GET_CLIENT_DIVISIONS_SUCCESS, GET_CLIENT_DIVISIONS_FAILURE],
      authenticated: true,
      endpoint: 'Account/GetClientDivisions',
      method: 'GET',
    },
  };
};

export const setBackButtonPath = (path) => {
  return {
    type: SET_BACK_BUTTON_PATH,
    payload: path,
  };
};

export const clearBackButtonPath = () => {
  return {
    type: CLEAR_BACK_BUTTON_PATH,
  };
};
