import { CALL_API } from '../../middleware/api';

/* global RSSParser */
import 'rss-parser/dist/rss-parser.min.js';
const rssParser = new RSSParser();

export const GET_BLOG_DATA_REQUEST = 'GET_BLOG_DATA_REQUEST';
export const GET_BLOG_DATA_SUCCESS = 'GET_BLOG_DATA_SUCCESS';
export const GET_BLOG_DATA_FAILURE = 'GET_BLOG_DATA_FAILURE';
export const GET_AUTHORIZATION_CURRENT_PREVIOUS_REQUEST = 'GET_AUTHORIZATION_CURRENT_PREVIOUS_REQUEST';
export const GET_AUTHORIZATION_CURRENT_PREVIOUS_SUCCESS = 'GET_AUTHORIZATION_CURRENT_PREVIOUS_SUCCESS';
export const GET_AUTHORIZATION_CURRENT_PREVIOUS_FAILURE = 'GET_AUTHORIZATION_CURRENT_PREVIOUS_FAILURE';
export const GET_EXPENSES_CURRENT_PREVIOUS_REQUEST = 'GET_EXPENSE_CURRENT_PREVIOUS_REQUEST';
export const GET_EXPENSES_CURRENT_PREVIOUS_SUCCESS = 'GET_EXPENSE_CURRENT_PREVIOUS_SUCCESS';
export const GET_EXPENSES_CURRENT_PREVIOUS_FAILURE = 'GET_EXPENSE_CURRENT_PREVIOUS_FAILURE';
export const GET_EXCEPTIONS_CURRENT_PREVIOUS_REQUEST = 'GET_EXCEPTIONS_CURRENT_PREVIOUS_REQUEST';
export const GET_EXCEPTIONS_CURRENT_PREVIOUS_SUCCESS = 'GET_EXCEPTIONS_CURRENT_PREVIOUS_SUCCESS';
export const GET_EXCEPTIONS_CURRENT_PREVIOUS_FAILURE = 'GET_EXCEPTIONS_CURRENT_PREVIOUS_FAILURE';
export const GET_AUTHORIZATION_SEARCH_RESULTS_REQUEST = 'GET_AUTHORIZATION_SEARCH_RESULTS_REQUEST';
export const GET_AUTHORIZATION_SEARCH_RESULTS_SUCCESS = 'GET_AUTHORIZATION_SEARCH_RESULTS_SUCCESS';
export const GET_AUTHORIZATION_SEARCH_RESULTS_FAILURE = 'GET_AUTHORIZATION_SEARCH_RESULTS_FAILURE';
export const GET_AUTHORIZATIONS_SUBMITTED_REQUEST = 'GET_AUTHORIZATIONS_SUBMITTED_REQUEST';
export const GET_AUTHORIZATIONS_SUBMITTED_SUCCESS = 'GET_AUTHORIZATIONS_SUBMITTED_SUCCESS';
export const GET_AUTHORIZATIONS_SUBMITTED_FAILURE = 'GET_AUTHORIZATIONS_SUBMITTED_FAILURE';
export const GET_AUTHORIZATIONS_PENDING_REQUEST = 'GET_AUTHORIZATIONS_PENDING_REQUEST';
export const GET_AUTHORIZATIONS_PENDING_SUCCESS = 'GET_AUTHORIZATIONS_PENDING_SUCCESS';
export const GET_AUTHORIZATIONS_PENDING_FAILURE = 'GET_AUTHORIZATIONS_PENDING_FAILURE';
export const GET_CLIENT_CONTACT_REQUEST = 'GET_CLIENT_CONTACT_REQUEST';
export const GET_CLIENT_CONTACT_SUCCESS = 'GET_CLIENT_CONTACT_SUCCESS';
export const GET_CLIENT_CONTACT_FAILURE = 'GET_CLIENT_CONTACT_FAILURE';
export const GET_SURVEY_CURRENT_PREVIOUS_REQUEST = 'GET_SURVEY_CURRENT_PREVIOUS_REQUEST';
export const GET_SURVEY_CURRENT_PREVIOUS_SUCCESS = 'GET_SURVEY_CURRENT_PREVIOUS_SUCCESS';
export const GET_SURVEY_CURRENT_PREVIOUS_FAILURE = 'GET_SURVEY_CURRENT_PREVIOUS_FAILURE';
export const GET_AUTHS_BY_STATE_REQUEST = 'GET_AUTHS_BY_STATE_REQUEST';
export const GET_AUTHS_BY_STATE_SUCCESS = 'GET_AUTHS_BY_STATE_SUCCESS';
export const GET_AUTHS_BY_STATE_FAILURE = 'GET_AUTHS_BY_STATE_FAILURE';
export const GET_HOMESALE_STATUS_DATA_REQUEST = 'GET_HOMESALE_STATUS_DATA_REQUEST';
export const GET_HOMESALE_STATUS_DATA_SUCCESS = 'GET_HOMESALE_STATUS_DATA_SUCCESS';
export const GET_HOMESALE_STATUS_DATA_FAILURE = 'GET_HOMESALE_STATUS_DATA_FAILURE';
export const GET_STATES_REQUEST = 'GET_STATES_REQUEST';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';

export const getBlogData = (feedUrl) => {
  return (dispatch) => {
    dispatch({ type: GET_BLOG_DATA_REQUEST });
    rssParser.parseURL(feedUrl)
      .then((res, err) => {
        dispatch({ type: GET_BLOG_DATA_SUCCESS, payload: res });
      })
      .catch((e) => {
        dispatch({ type: GET_BLOG_DATA_FAILURE });
      });
  };
};

export const getAuthorizationCurrentPrevious = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTHORIZATION_CURRENT_PREVIOUS_REQUEST, GET_AUTHORIZATION_CURRENT_PREVIOUS_SUCCESS, GET_AUTHORIZATION_CURRENT_PREVIOUS_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetAuthorizationCurrentPrevious',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getExpenseCurrentPrevious = () => {
  return {
    [CALL_API]: {
      types: [GET_EXPENSES_CURRENT_PREVIOUS_REQUEST, GET_EXPENSES_CURRENT_PREVIOUS_SUCCESS, GET_EXPENSES_CURRENT_PREVIOUS_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetExpenseCurrentPrevious',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getExceptionCurrentPrevious = () => {
  return {
    [CALL_API]: {
      types: [GET_EXCEPTIONS_CURRENT_PREVIOUS_REQUEST, GET_EXCEPTIONS_CURRENT_PREVIOUS_SUCCESS, GET_EXCEPTIONS_CURRENT_PREVIOUS_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetExceptionCurrentPrevious',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getAuthorizationSearchResults = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTHORIZATION_SEARCH_RESULTS_REQUEST, GET_AUTHORIZATION_SEARCH_RESULTS_SUCCESS, GET_AUTHORIZATION_SEARCH_RESULTS_FAILURE],
      authenticated: true,
      endpoint: 'AuthorizationFile/GetAuthorizationSearchResults',
      method: 'POST',
      payload: {},
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getAuthorizationsSubmitted = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTHORIZATIONS_SUBMITTED_REQUEST, GET_AUTHORIZATIONS_SUBMITTED_SUCCESS, GET_AUTHORIZATIONS_SUBMITTED_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetAuthorizationsSubmitted',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getAuthorizationsPending = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTHORIZATIONS_PENDING_REQUEST, GET_AUTHORIZATIONS_PENDING_SUCCESS, GET_AUTHORIZATIONS_PENDING_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetAuthorizationsPending',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getClientContact = () => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_CONTACT_REQUEST, GET_CLIENT_CONTACT_SUCCESS, GET_CLIENT_CONTACT_FAILURE],
      authenticated: true,
      endpoint: 'Client/GetClientRepresentative',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getSurveyCurrentPrevious = () => {
  return {
    [CALL_API]: {
      types: [GET_SURVEY_CURRENT_PREVIOUS_REQUEST, GET_SURVEY_CURRENT_PREVIOUS_SUCCESS, GET_SURVEY_CURRENT_PREVIOUS_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetSurveyCurrentPrevious',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getAuthorizationsByState = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTHS_BY_STATE_REQUEST, GET_AUTHS_BY_STATE_SUCCESS, GET_AUTHS_BY_STATE_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetAuthorizationsByState',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getHomesaleStatusData = () => {
  return {
    [CALL_API]: {
      types: [GET_HOMESALE_STATUS_DATA_REQUEST, GET_HOMESALE_STATUS_DATA_SUCCESS, GET_HOMESALE_STATUS_DATA_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetHomesalesAllTime',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getStates = () => {
  return {
    [CALL_API]: {
      types: [GET_STATES_REQUEST, GET_STATES_SUCCESS, GET_STATES_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetStates',
      method: 'GET',
    },
  };
};