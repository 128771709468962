import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';

import { EditCommandCell } from '../../common/tableEditCommandCell';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS, showToast } from '../layout/layout.actions';
import { currencyFormatter } from '../../utilities/currencyFormatter';
import { dateFilterPredicate, dateFormatter } from '../../utilities/dateFormatter';
import { updateExceptionApprovalStatus } from './exceptions.actions';
import ConfirmModal from '../../common/confirmModal.component';

import { Getter } from '@devexpress/dx-react-core';

import {
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={currencyFormatter}
    {...props}
  />
);

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return `${s4() + s4()  }-${  s4()  }-${  s4()  }-${  s4()  }-${  s4()  }${s4()  }${s4()}`;
};

const getRowId = (row) => {
  return guid();
};

class PendingExceptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'FirstName', title: 'First Name' },
        { name: 'LastName', title: 'Last Name' },
        { name: 'AuthorizationID', title: 'File Number' },
        { name: 'ExceptDate', title: 'Date' },
        { name: 'RequestedAmount', title: 'Requested Amount' },
        { name: 'Program', title: 'Program Name' },
      ],
      integratedFilteringColumnExtensions: [
        { columnName: 'ExceptDate', predicate: dateFilterPredicate },
      ],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['ExceptDate'],
      currencyColumns: ['RequestedAmount'],
      sorting: [{ columnName: 'ExceptDate', direction: 'desc' }],
      filters: [],
      modal: {
        selectedRow: null,
        modalState: false,
        modalType: '',
      },
    };

    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeFilters = (filters) => this.setState({ filters });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });

    this.TableRow = ({ row, ...restProps }) => {
      return (
        <Table.Row
          {...restProps}
          onClick={() => props.handleViewSubmittedException(row.ExceptID)}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    };

    this.handleDenyModalToggle = this.handleDenyModalToggle.bind(this);
    this.handleApprovalModalToggle = this.handleApprovalModalToggle.bind(this);
    this.handleExceptionApproval = this.handleExceptionApproval.bind(this);
    this.handleExceptionDeny = this.handleExceptionDeny.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  handleApprovalModalToggle(props) {
    this.setState((prevState) => ({
      modal: {
        modalState: !prevState.modal.modalState,
        modalType: 'approve',
        selectedRow: props.row,
      },
    }));
  }

  handleDenyModalToggle(props) {
    this.setState((prevState) => ({
      modal: {
        modalState: !prevState.modal.modalState,
        modalType: 'deny',
        selectedRow: props.row,
      },
    }));
  }

  async handleExceptionApproval() {
    const { selectedRow } = this.state.modal;
    const approved = Object.assign({}, selectedRow, {
      Comment: 'Approved from Pending Exceptions',
      ApprovalAmount: selectedRow.RequestedAmount,
      ExceptionId: selectedRow.ExceptID,
      IsApproved: true,
    });

    let response = await this.props.updateExceptionApprovalStatus(approved);
    if (response.type === 'UPDATE_EXCEPTION_APPROVAL_SUCCESS') {
      this.props.showToast('Successfully approved exception.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      this.props.history.push('/exceptions');
    } else {
      this.props.showToast('Error approving exception. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  async handleExceptionDeny() {
    const { selectedRow } = this.state.modal;
    const unapproved = Object.assign({}, selectedRow, {
      Comment: 'Unapproved from Pending Exceptions',
      ExceptionId: selectedRow.ExceptID,
      IsApproved: false,
    });


    let response = await this.props.updateExceptionApprovalStatus(unapproved);
    if (response.type === 'UPDATE_EXCEPTION_APPROVAL_SUCCESS') {
      this.props.showToast('Successfully denied exception.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      this.props.history.push('/exceptions');
    } else {
      this.props.showToast('Error denying exception. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  // required for editing state
  commitChanges() {

  }

  actionsCell = (props) => {
    return (
      <TableCell>
        <EditCommandCell id="deny" text="Deny" onExecute={this.handleDenyModalToggle} tableRow={props.tableRow}>
          {React.Children.toArray(props.children)}
        </EditCommandCell>
        <EditCommandCell id="approve" text="Approve" onExecute={this.handleApprovalModalToggle} tableRow={props.tableRow}>
          {React.Children.toArray(props.children)}
        </EditCommandCell>
      </TableCell>
    );
  }

  render() {
    const { rows,
      columns,
      pageSize,
      pageSizes,
      currentPage,
      dateColumns,
      sorting,
      filters,
      currencyColumns,
      integratedFilteringColumnExtensions,
      modal } = this.state;

    return (
      <div>
        <Paper style={{ padding: '1rem' }}>
          <div style={{ padding: '1rem', fontSize: '1.25rem' }}>Pending Exceptions</div>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <DateTypeProvider for={dateColumns} />
            <CurrencyTypeProvider
              for={currencyColumns}
            />
            <EditingState
              onEditingRowIdsChange={this.changeEditingRowIds}
              onCommitChanges={this.commitChanges}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedSorting />
            <FilteringState
              filters={filters}
              onFiltersChange={this.changeFilters}
            />
            <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
            <IntegratedPaging />
            <Table rowComponent={this.TableRow} />
            <TableHeaderRow showSortingControls />
            <TableEditColumn
              style={{ marginRight: '2rem' }}
              cellComponent={this.actionsCell}
            />
            <TableFilterRow />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                return [
                  ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
                  { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 200 },
                ];
              }}
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        {modal.modalState && modal.modalType === 'approve' &&
          <ConfirmModal
            cancelText="Cancel"
            confirmText="Confirm"
            modalState
            titleText="Approve Exception"
            handleClose={this.handleApprovalModalToggle}
            handleConfirm={this.handleExceptionApproval}
          />
        }
        {modal.modalState && modal.modalType === 'deny' &&
          <ConfirmModal
            cancelText="Cancel"
            confirmText="Confirm"
            modalState
            titleText="Deny Exception"
            handleClose={this.handleDenyModalToggle}
            handleConfirm={this.handleExceptionDeny}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(connect(mapStateToProps, {
  showToast,
  updateExceptionApprovalStatus,
})(PendingExceptions));
