import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  contentRoot: {
    boxShadow: theme.shadows[1],
    padding: `${theme.spacing(1)}px 0`,
    maxHeight: 175,
    overflow: 'auto',
  },
  actionsRoot: {
    flexGrow: 0,
  },
  answer: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

const BenefitQuestionCard = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { benefitQuestion, onEdit, onDelete, isReadOnly } = props;

  return (
    <Card className={classNames(classes.root, 'column', 'justify-space-between')}>
      <CardHeader title={benefitQuestion.text} />
      <CardContent className={classes.contentRoot}>
        {benefitQuestion.answers.map((answer, idx) => (
          <Fragment key={idx}>
            <Typography className={classes.answer} gutterBottom>{answer}</Typography>
            {idx !== benefitQuestion.answers.length - 1 && <Divider className={classes.divider} variant="fullWidth" />}
          </Fragment>
        ))}
      </CardContent>
      <CardActions className={classNames(classes.actionsRoot, 'row-no-basis', 'justify-end')}>
        {!isReadOnly && 
          <>
            <IconButton onClick={onDelete}>
              <FontAwesomeIcon color={theme.palette.error.main} icon="trash" />
            </IconButton>
            <IconButton color="primary" onClick={onEdit}>
              <FontAwesomeIcon icon={['fas', 'pen']} color={theme.palette.primary.main} />
            </IconButton>
          </>
        }
      </CardActions>
    </Card>
  );
};

BenefitQuestionCard.propTypes = {
  benefitQuestion: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default BenefitQuestionCard;
