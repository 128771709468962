import { Box, Grid, makeStyles } from '@material-ui/core';
import { DateTimeFormatter, Instant, LocalDateTime } from 'js-joda';
import { Locale } from '@js-joda/locale_en-us';
import PropTypes from 'prop-types';
import React from 'react';

import { CardOption } from './cardOptionCarousel.component';
import logo from '../../../images/trc-logo-mini.png';

const DATE_FORMATTER = DateTimeFormatter.ofPattern('EEEE MMM d, yyyy K:mm a').withLocale(Locale.US);

const useStyles = makeStyles((theme) => ({
  avatar: {
    width:40, 
    height: 40, 
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
}
));

export function BotAvatar(props) {
  const classes = useStyles();
  return (
    <div className={classes.avatar} style={{ backgroundImage: `url(${logo})` }} />
  );
}

export default function Message(props) {
  const { data, isUser, startsSequence, endsSequence, showTimestamp } = props;
  const friendlyTimestamp = LocalDateTime.ofInstant(Instant.ofEpochMilli(data.timestamp)).format(DATE_FORMATTER);

  return (
    <Box display="flex" flexDirection="column" marginTop={startsSequence ? 1 : 0}>
      <Grid container spacing={0.5} alignItems="flex-end">
        {
          showTimestamp &&
            <Box display="flex" justifyContent="center" color="#999" fontWeight={600} marginY={2}>
              { friendlyTimestamp }
            </Box>
        }
        <Grid item xs={1}>
          {(!isUser && endsSequence) && <BotAvatar />}
        </Grid>
        <Grid item xs={11}>
          <Grid container item spacing={0} justify={isUser ? 'flex-end' : 'flex-start'}>
            {
              data.isCardOption ? 
                <CardOption isMessage={true} option={data.message} onOptionChosen={()=>{}} /> :
                <Box marginY="1px" marginX={0} paddingY={1} paddingX={2} maxWidth="75%" borderRadius="20px" 
                  bgcolor={isUser ? '#007aff' : '#f4f4f8'} color={isUser ? 'common.white' : ''}
                  title={friendlyTimestamp}
                >{ data.message }
                </Box>
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

Message.propTypes = {
  data : PropTypes.object.isRequired,
  isUser : PropTypes.bool.isRequired,
  startsSequence : PropTypes.bool.isRequired,
  endsSequence : PropTypes.bool.isRequired,
  showTimestamp : PropTypes.bool.isRequired,
};