import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import { canToggleBenefit, getMergedBenefit, isBenefitEnabled } from './policyBenefitUtil';
import PolicyBenefitCard from './policyBenefitCard.component';
import PolicyContext from './policyContext';

import Box from '@material-ui/core/Box';

const PolicyBenefits = (props) => {
  const { version, modifyBenefit, isReadOnly } = useContext(PolicyContext);
  const benefits = version.benefits || [];

  const renderBenefit = (benefit, idx) => {
    const mergedBenefit = getMergedBenefit(benefit);
    const benefitIsToggleable = canToggleBenefit(benefit);
    const enabled = isBenefitEnabled(benefit);
    return (
      <Grid item className="column-no-grow" key={benefit.id}>
        <PolicyBenefitCard
          mergedBenefit={mergedBenefit}
          enabled={enabled}
          isReadOnly={isReadOnly}
          benefitIsToggleable={benefitIsToggleable}
          toggleEnabled={(enabled) => {
            benefit.enabled = enabled;
            benefit.options.forEach((opt) => {
              opt.enabled = enabled;
            });
            modifyBenefit(benefit);
          }}
          toggleOption={(optToToggle, enabled) => {
            benefit.options.forEach((opt) => {
              if (opt.id === optToToggle.id) {
                opt.enabled = enabled;
              }
            });
            modifyBenefit(benefit);
          }}
        />
      </Grid>
    );
  };

  return (
    <Box px={3}>
      <Grid container spacing={3}>
        {isReadOnly ? benefits.filter(isBenefitEnabled).map(renderBenefit) : benefits.map(renderBenefit)}
      </Grid>
    </Box>
  );
};

export default PolicyBenefits;