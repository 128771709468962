import {
  CALC_POLICY_COSTS_FAILURE,
  CALC_POLICY_COSTS_REQUEST,
  CALC_POLICY_COSTS_SUCCESS,
  CLONE_POLICY_FAILURE,
  CLONE_POLICY_REQUEST,
  CLONE_POLICY_SUCCESS,
  CREATE_POLICY_DRAFT_FAILURE,
  CREATE_POLICY_DRAFT_REQUEST,
  CREATE_POLICY_DRAFT_SUCCESS,
  CREATE_POLICY_FAILURE,
  CREATE_POLICY_REQUEST,
  CREATE_POLICY_SUCCESS,
  DELETE_POLICY_DRAFT_FAILURE,
  DELETE_POLICY_DRAFT_REQUEST,
  DELETE_POLICY_DRAFT_SUCCESS,
  DELETE_POLICY_FAILURE,
  DELETE_POLICY_REQUEST,
  DELETE_POLICY_SUCCESS,
  GET_POLICIES_FAILURE,
  GET_POLICIES_REQUEST,
  GET_POLICIES_SUCCESS,
  GET_POLICY_BENEFITS_FAILURE,
  GET_POLICY_BENEFITS_REQUEST,
  GET_POLICY_BENEFITS_SUCCESS,
  GET_POLICY_FAILURE,
  GET_POLICY_HISTORY_FAILURE,
  GET_POLICY_HISTORY_REQUEST,
  GET_POLICY_HISTORY_SUCCESS,
  GET_POLICY_REQUEST,
  GET_POLICY_SUCCESS,
  GET_POLICY_SUMMARIES_FAILURE,
  GET_POLICY_SUMMARIES_REQUEST,
  GET_POLICY_SUMMARIES_SUCCESS,
  PUBLISH_POLICY_FAILURE,
  PUBLISH_POLICY_REQUEST,
  PUBLISH_POLICY_SUCCESS,
  UPDATE_POLICY_BOT_FAILURE,
  UPDATE_POLICY_BOT_REQUEST,
  UPDATE_POLICY_BOT_SUCCESS,
  UPDATE_POLICY_FAILURE,
  UPDATE_POLICY_REQUEST,
  UPDATE_POLICY_SUCCESS,
  UPDATE_POLICY_VERSION_FAILURE,
  UPDATE_POLICY_VERSION_REQUEST,
  UPDATE_POLICY_VERSION_SUCCESS,
} from './policy.types';
import { CALL_SELF_SERVE_API } from 'middleware/selfServeApi';
import { downloadImage } from 'utilities/firebase';
import { getMergedBenefit, isBenefitInUse, isOptionEnabled } from './policyBenefitUtil';
import { isRuleValid, sanitizeRule } from './policyRuleUtil';

import { cloneDeep } from 'lodash';

export const getSummaries = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_POLICY_SUMMARIES_REQUEST, GET_POLICY_SUMMARIES_SUCCESS, GET_POLICY_SUMMARIES_FAILURE],
      authenticated: true,
      endpoint: 'policies/summaries',
      method: 'GET',
    },
  };
};

export const getPolicies = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_POLICIES_REQUEST, GET_POLICIES_SUCCESS, GET_POLICIES_FAILURE],
      authenticated: true,
      endpoint: 'policies',
      method: 'GET',
    },
  };
};

export const updatePolicy = (policy) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [UPDATE_POLICY_REQUEST, UPDATE_POLICY_SUCCESS, UPDATE_POLICY_FAILURE],
      authenticated: true,
      endpoint: `policies/${policy.id}`,
      method: 'PUT',
      payload: policy,
    },
  };
};

export const getBenefits = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_POLICY_BENEFITS_REQUEST, GET_POLICY_BENEFITS_SUCCESS, GET_POLICY_BENEFITS_FAILURE],
      authenticated: true,
      endpoint: 'policies/benefits',
      method: 'GET',
    },
  };
};

export const getPolicy = (policyId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_POLICY_REQUEST, GET_POLICY_SUCCESS, GET_POLICY_FAILURE],
      authenticated: true,
      endpoint: `policies/${policyId}`,
      method: 'GET',
    },
  };
};

export const createPolicy = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CREATE_POLICY_REQUEST, CREATE_POLICY_SUCCESS, CREATE_POLICY_FAILURE],
      authenticated: true,
      endpoint: 'policies',
      method: 'POST',
    },
  };
};

export const clonePolicy = (policyId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CLONE_POLICY_REQUEST, CLONE_POLICY_SUCCESS, CLONE_POLICY_FAILURE],
      authenticated: true,
      endpoint: `policies/${policyId}/clone`,
      method: 'POST',
    },
  };
};

export const updatePolicyVersion = (version) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [UPDATE_POLICY_VERSION_REQUEST, UPDATE_POLICY_VERSION_SUCCESS, UPDATE_POLICY_VERSION_FAILURE],
      authenticated: true,
      endpoint: `policies/${version.clientPolicyId}/v/${version.versionId}`,
      method: 'PUT',
      payload: toWire(version),
    },
  };
};

export const updatePolicyBot = (dialogFlowVersion) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [UPDATE_POLICY_BOT_REQUEST, UPDATE_POLICY_BOT_SUCCESS, UPDATE_POLICY_BOT_FAILURE],
      authenticated: true,
      endpoint: `policies/${dialogFlowVersion.clientPolicyId}/v/${dialogFlowVersion.versionId}/bot`,
      method: 'PUT',
      payload: toWire(dialogFlowVersion),
    },
  };
};

export const publishPolicy = (version, dialogFlowVersion, enable) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [PUBLISH_POLICY_REQUEST, PUBLISH_POLICY_SUCCESS, PUBLISH_POLICY_FAILURE],
      authenticated: true,
      endpoint: `policies/${version.clientPolicyId}/v/${version.versionId}/publish?enable=${enable}`,
      method: 'PUT',
      payload: {
        version: toWire(version),
        dialogFlowVersion: toWire(dialogFlowVersion),
      },
    },
  };
};

export const createPolicyDraft = (policyId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CREATE_POLICY_DRAFT_REQUEST, CREATE_POLICY_DRAFT_SUCCESS, CREATE_POLICY_DRAFT_FAILURE],
      authenticated: true,
      endpoint: `policies/${policyId}/draft/`,
      method: 'POST',
    },
  };
};

export const deletePolicy = (policyId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [DELETE_POLICY_REQUEST, DELETE_POLICY_SUCCESS, DELETE_POLICY_FAILURE],
      authenticated: true,
      endpoint: `policies/${policyId}`,
      method: 'DELETE',
    },
  };
};

export const deletePolicyDraft = (version) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [DELETE_POLICY_DRAFT_REQUEST, DELETE_POLICY_DRAFT_SUCCESS, DELETE_POLICY_DRAFT_FAILURE],
      authenticated: true,
      endpoint: `policies/${version.clientPolicyId}/v/${version.versionId}`,
      method: 'DELETE',
    },
  };
};

export const getPolicyHistory = (policyId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_POLICY_HISTORY_REQUEST, GET_POLICY_HISTORY_SUCCESS, GET_POLICY_HISTORY_FAILURE],
      authenticated: true,
      endpoint: `policies/${policyId}/history`,
      method: 'GET',
    },
  };
};

export const calculatePolicyCosts = (sessionId, flattenedVersion, authSummary) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CALC_POLICY_COSTS_REQUEST, CALC_POLICY_COSTS_SUCCESS, CALC_POLICY_COSTS_FAILURE],
      authenticated: true,
      endpoint: `policies/calculateCosts?sessionId=${sessionId}`,
      method: 'POST',
      payload: {
        policy: toWire(flattenedVersion),
        authSummary,
      },
    },
  };
};

const toWire = (version) => {
  return {
    ...version,
    isBotUpToDate: undefined,
    benefits: version.benefits.map((benefit, idx) => {
      return {
        ...benefit,
        order: idx,
        costPlanningType: undefined,
        clientBenefitVersion: undefined,
        options: benefit.options.map((opt) => (
          {
            ...opt,
            benefitOptionDetail: undefined,
            rules: opt.rules.map((rule, idx) => sanitizeRule(rule, idx)).filter(isRuleValid),
          }
        )),
      };
    }),
  };
};

export const toFlattenedVersion = (version) => {
  const enabledBenefits = version.benefits.filter(isBenefitInUse);
  const mergedBenefits = enabledBenefits.map((ben) => cloneDeep(ben)).map(getMergedBenefit);
  for (const benefit of mergedBenefits) {
    benefit.options = benefit.options.filter(isOptionEnabled);
  }
  return {
    ...version,
    benefits: mergedBenefits,
  };
};

// todo the image downloading here is to help support old benefits that do not store the imageUrl.
//  Can be removed once all benefits have an imageUrl
export const toDialogFlowVersion = async (version) => {
  const enabledBenefits = version.benefits.filter(isBenefitInUse);
  const mergedBenefits = enabledBenefits.map((ben) => cloneDeep(ben)).map(getMergedBenefit);
  for (const benefit of mergedBenefits) {
    if (!benefit.imageUrl) {
      benefit.imageUrl = await downloadImage(benefit.imageId); //eslint-disable-line
    }
    const enabledOptions = benefit.options.filter(isOptionEnabled);
    for (const opt of enabledOptions) {
      if (!opt.imageUrl) {
        opt.imageUrl = await downloadImage(opt.imageId); //eslint-disable-line
      }
    }
    benefit.options = enabledOptions;
  }
  return {
    ...version,
    benefits: mergedBenefits,
  };
};
