import { isNil } from 'lodash';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { REQUIRED_ERROR_MESSAGE } from 'common/constants';
import { useRefCallback } from 'utilities/formUtils';

const useStyles = makeStyles((theme) => ({
  readOnlySelect: {
    cursor: 'auto',
  },
  readOnlySelectIcon: {
    display: 'none',
  },
}));

const TRCInputValidator = (props) => {
  const { component: Component, value, onBlur, onChange, validateOnBlur, validateOnChange, required, isValid, variant, ...rest } = props;
  const [ref, setRef] = useRefCallback();
  const classes = useStyles();

  const handleBlur = useCallback(() => {
    if (rest.InputProps.readOnly) {
      return;
    }
    let newVal = value;
    if (typeof newVal === 'string') {
      newVal = newVal.trim();
    }
    if (ref && validateOnBlur) {
      ref.validate(newVal, true);
    }
    onBlur(newVal);
    onChange(newVal);
  }, [ref, onBlur, onChange, validateOnBlur, value, rest.InputProps]);

  const handleChange = useCallback((newVal) => {
    if (rest.InputProps.readOnly) {
      return;
    }
    newVal = (newVal && newVal.target) ? newVal.target.value : newVal;
    if (ref && validateOnChange) {
      ref.validate(newVal, true);
    }
    onChange(newVal);
  }, [ref, validateOnChange, onChange, rest.InputProps]);

  useEffect(() => {
    if (ref && typeof isValid === 'boolean') {
      if (isValid) {
        ref.makeValid();
      } else {
        ref.makeInvalid();
      }
    }
  }, [isValid, ref]);

  return (
    <Component
      ref={setRef}
      value={!isNil(value) ? value : ''}
      margin="normal"
      variant={variant || 'outlined'}
      onBlur={handleBlur}
      onChange={handleChange}
      required={required}
      validators={required ? ['required'] : []}
      errorMessages={required ? [REQUIRED_ERROR_MESSAGE] : []}
      SelectProps={rest.InputProps.readOnly ? { classes: { select: classes.readOnlySelect, icon: classes.readOnlySelectIcon } } : {}}
      {...rest}
    />
  );
};

TRCInputValidator.defaultProps = {
  value: '',
  required: false,
  InputProps: {},
  onBlur: () => {},
  onChange: () => {},
  validateOnBlur: true,
  validateOnChange: false,
};

TRCInputValidator.propTypes = {
  component: PropTypes.func.isRequired,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  InputProps: PropTypes.object,
  variant: PropTypes.string,
};

export default TRCInputValidator;
