import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { REQUIRED_ERROR_MESSAGE } from 'common/constants';
import { useRefCallback } from 'utilities/formUtils';

const ClientBenefitInput = (props) => {
  const { component: Component, onChange, onBlur, clientVersion, masterVersion, versionField, InputProps, required, validateOnBlur, validateOnChange, showAdornment, ...rest } = props;
  const readOnly = InputProps ? InputProps.readOnly : false;

  const [ref, setRef] = useRefCallback();

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (clientVersion[versionField] != null) {
      setValue(clientVersion[versionField]);
    } else if (masterVersion[versionField] != null) {
      setValue(masterVersion[versionField]);
    } else {
      setValue('');
    }
  }, [clientVersion, masterVersion, versionField]);

  useEffect(() => {
    setIsEditing(
      (!value || value !== masterVersion[versionField]) &&
      !readOnly,
    );
  }, [value, masterVersion, readOnly, versionField]);

  const handleChange = (newValue) => {
    if (ref && validateOnChange) {
      ref.validate(newValue, true);
    }
    if (newValue !== '') {
      syncToClientVersion(newValue, onChange);
    }
  };

  const handleBlur = (newValue) => {
    if (ref && validateOnBlur) {
      ref.validate(newValue, true);
    }
    syncToClientVersion(newValue, onBlur);
  };

  const syncToClientVersion = (newValue, cb) => {
    let clientVersionValue;
    if (newValue === '' || newValue === masterVersion[versionField]) {
      clientVersionValue = null;
    } else {
      clientVersionValue = newValue;
    }
    cb(clientVersionValue);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setValue(masterVersion[versionField]);
  };

  const handleRevertClick = () => {
    setIsEditing(false);
    const newValue = masterVersion[versionField];
    setValue(newValue);
    syncToClientVersion(newValue, onChange || onBlur);
  };

  const renderAdornment = () => {
    if (readOnly || rest.disabled || !showAdornment) {
      return null;
    }
    if (!isEditing) {
      return (
        <InputAdornment position="end">
          <IconButton
            color="primary"
            size="small"
            onClick={handleEditClick}
          >
            <FontAwesomeIcon
              icon={['fas', 'pen']}
            />
          </IconButton>
        </InputAdornment>
      );
    } else if (masterVersion[versionField]) {
      return (
        <InputAdornment position="end">
          <IconButton
            color="primary"
            size="small"
            onClick={handleRevertClick}
          >
            <FontAwesomeIcon
              icon={['fas', 'undo']}
            />
          </IconButton>
        </InputAdornment>
      );
    }
  };

  return (
    <Component
      ref={setRef}
      onChange={(newValue) => {
        if (readOnly) {
          return;
        }
        newValue = newValue && newValue.target ? newValue.target.value : newValue;
        newValue = newValue || '';
        setValue(newValue);
        if (onChange) {
          handleChange(newValue);
        }
      }}
      onBlur={() => {
        if (onBlur && !readOnly) {
          let newValue = value;
          if (typeof value === 'string') {
            newValue = value.trim();
            setValue(newValue);
          }
          handleBlur(newValue);
        }
      }}
      value={value}
      variant={!clientVersion[versionField] && !isEditing ? 'standard' : 'outlined'}
      InputProps={{
        ...InputProps,
        readOnly: !isEditing || readOnly,
        endAdornment: renderAdornment(),
      }}
      margin="normal"
      required={required}
      validators={required ? ['required'] : []}
      errorMessages={required ? [REQUIRED_ERROR_MESSAGE] : []}
      {...rest}
    />
  );
};

ClientBenefitInput.defaultProps = {
  masterVersion: {},
  required: false,
  InputProps: {
    readOnly: false,
  },
  validateOnBlur: true,
  validateOnChange: false,
  showAdornment: true,
};

ClientBenefitInput.propTypes = {
  component: PropTypes.func.isRequired,
  clientVersion: PropTypes.object.isRequired,
  versionField: PropTypes.string.isRequired,
  required: PropTypes.bool,
  masterVersion: PropTypes.object,
  InputProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showAdornment: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
};

export default ClientBenefitInput;
