import { matchPath } from 'react-router-dom';
const TRC_APP_RECENTS = 'TRC_APP_RECENTS';

export const getRecents = (userId) => {
  let lsRecents = localStorage.getItem(TRC_APP_RECENTS);

  if (lsRecents) {
    try {
      let recents = JSON.parse(lsRecents)[userId].recents;
      return recents;
    } catch (e) {
      return [];
    }
  }
  return [];
};

export const clearRecents = () => {
  localStorage.removeItem(TRC_APP_RECENTS);
};

export const removeRecentItem = (path, type, userId) => {
  let lsRecents = localStorage.getItem(TRC_APP_RECENTS);

  if (lsRecents) {
    try {
      let recents = JSON.parse(lsRecents)[userId].recents;
      recents = recents.filter((item) => {
        return item.path === path && item.type === type ? false : true;
      });
      let setItemObject = {};
      setItemObject[userId] = { recents };
      localStorage.setItem(TRC_APP_RECENTS, JSON.stringify(setItemObject));
    } catch (e) {
    }
  }
};

export const trackRecentItem = (path, name, type, userId) => {
  // max number of items to store in localstorage
  const RECENT_ITEM_LIMIT = 5;

  // this list of routes will trigger storage of an item
  const recentsRoutes = [
    '/authorizations/:id',
    '/transferees/:id',
  ];

  let lsRecents = localStorage.getItem(TRC_APP_RECENTS);
  let recents = [];

  if (!lsRecents) {
    let initialRecents = {};
    initialRecents[userId] = { recents: [] };
    localStorage.setItem(TRC_APP_RECENTS, JSON.stringify(initialRecents));
  } else {
    try {
      recents = JSON.parse(localStorage.getItem(TRC_APP_RECENTS))[userId].recents;
    } catch (e) {
      recents = [];
    }
  }

  for (let route of recentsRoutes) {
    if (matchPath(path, {
      path: route,
      exact: true,
      strict: false,
    })) {
      let foundIndex = recents.findIndex((recent) => recent.path === path);
      let recentItem = { path, name, type };
      if (foundIndex === -1) {
        recents.unshift(recentItem);
      } else {
        recents.splice(foundIndex, 1);
        recents.unshift(recentItem);
      }
      recents = recents.slice(0, RECENT_ITEM_LIMIT);
      let setItemObject = {};
      setItemObject[userId] = { recents };
      localStorage.setItem(TRC_APP_RECENTS, JSON.stringify(setItemObject));
    }
  }
};

export default { trackRecentItem, removeRecentItem, getRecents, clearRecents };