import { getRedirectPath, setRedirectPath, setSession } from '../utilities/authUtils';
import auth0 from 'auth0-js';
import history from '../history';
import { submitChangePasswordRequest } from 'modules/authentication/authentication.actions';

const HOST_URL = process.env.REACT_APP_HOST_URL || 'http://localhost:3000';
const AUTH_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || 'trc-relosource-test.auth0.com';
const AUTH_CLIENTID =  process.env.REACT_APP_AUTH0_CLIENTID || 'Q1ohBoG39Rs8KywCA0EwqRPHHGdwqBBj';
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://relosource-auth.trcgm.com';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: AUTH_DOMAIN,
      clientID: AUTH_CLIENTID,
      redirectUri: `${HOST_URL}/callback`,
      audience: AUTH0_AUDIENCE,
      responseType: 'token id_token',
      scope: 'openid profile',
    });

    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.checkAuthResult = this.checkAuthResult.bind(this);
    this.logout = this.logout.bind(this);
  }

  login() {
    setRedirectPath(window.location.pathname);
    this.auth0.authorize();
  }

  logout() {
    this.auth0.logout({
      returnTo: window.location.origin,
    });

    window.location = `https://${this.auth0.baseOptions.domain}/v2/logout?returnTo=${encodeURIComponent(window.location.origin)}`;
  }

  async handleAuthentication() {
    await new Promise((resolve, reject) => {
      this.auth0.parseHash(this.checkAuthResult.bind(this, { resolve, reject }));
    });
    const redirectPath = getRedirectPath();
    if (redirectPath) {
      history.push(redirectPath);
    }
    else {
      history.push('/');
    }
  }

  checkAuthResult({ resolve, reject }, err, authResult) {
    // auth0 login unsuccessful
    if (!authResult || err) {
      this.login();
      reject(err);
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      setSession(authResult);
      resolve();
    }
  }

  renewSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, this.checkAuthResult.bind(this, { resolve, reject }));
    });
  }
}

export const changePassword = (email, callback) =>  {
  let webAuth = new auth0.WebAuth({
    domain: AUTH_DOMAIN,
    clientID: AUTH_CLIENTID,
  });

  submitChangePasswordRequest(email);

  webAuth.changePassword({
    connection: 'Username-Password-Authentication',
    email,
  }, function (err, resp) {
    if (err) {
      return false;
    } else {
      if (callback) callback();
    }
  });
};

export default new Auth(); // Singleton
