import { Button, Grid, makeStyles, useTheme } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Dialog from 'common/dialog.component';
import TRCInputValidator from './TRCInputValidator';
import SpinnerButton from 'common/spinnerButton.component';

const useStyles = makeStyles((theme) => ({
  publishButton: {
    marginLeft: theme.spacing(2),
  },
  buttonProgress: {
    marginLeft: -6,
  }
}));

export const PublishDialog = (props) => {
  const { open, version, setVersion, title, isSubmitting, cancelAction, publishAction, warning } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      title={title}
      content={
        <>
          {warning &&
            <Grid container spacing={0}>
              <Grid item xs={1} container alignItems="center">
                <FontAwesomeIcon color={theme.palette.warning.dark} icon={['fas', 'exclamation-circle']} size="2x" />
              </Grid>
              <Grid item xs={11}>
                { warning }
              </Grid>
            </Grid>
          }
        <ValidatorForm onSubmit={() => {}}>
          <TRCInputValidator
            component={TextValidator}
            label="Notes"
            onChange={(value) => setVersion({ ...version, publishNotes: value })}
            name="benefitPublishNotes"
            value={version.publishNotes}
            fullWidth
            multiline
            rows={3}
            variant="outlined"
          />
        </ValidatorForm>
        </>
      }
      actions={
        <div className="row-no-grow justify-end my">
          <Button onClick={cancelAction}>Cancel</Button>
            <SpinnerButton
              isLoading={isSubmitting}
              classes={{buttonProgress: classes.buttonProgress}}
              buttonComponent={
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className={classes.publishButton}
                  onClick={publishAction}
                >
                  Publish
                </Button>
              }
            />
        </div>
      }
    />
  );
};

PublishDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  version: PropTypes.object.isRequired,
  setVersion: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  cancelAction: PropTypes.func.isRequired,
  publishAction: PropTypes.func.isRequired,
  warning: PropTypes.node,
};

export default PublishDialog;
