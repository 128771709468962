import { MenuItem, withStyles } from '@material-ui/core';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { getAutocompleteSessionToken, getCityPredictions, getPlaceDetails } from 'utilities/googleMapsClient';
import googleLogo from 'images/powered_by_google_on_white_hdpi.png';

import CustomAutocomplete from './customAutocomplete.component';

const styles = (theme) => ({
  logoContainer: {
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    textAlign: 'right',
    width: '100%',
  },
  logo: {
    width: '75%',
  },
});

class GooglePlacesAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      sessionToken: null,
    };
  }

  async componentDidMount() {
    const sessionToken = await getAutocompleteSessionToken();
    this.setState({ sessionToken });
  }

  getSuggestionValue(suggestion) {
    return suggestion.description;
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    this.fetchCitySuggestions(inputValue);
  };

  fetchCitySuggestions = debounce(async (input) => {
    if (input.length > 2) {
      let suggestions = await getCityPredictions(input);
      this.setState({ suggestions });
    }
  }, 300);

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.description}
      </MenuItem>
    );
  };

  handleSelection = async (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    if (method === 'enter') {
      event.preventDefault();
    }

    const details = await getPlaceDetails(suggestion);
    this.props.onSelect(event, details);
  };

  render() {
    const { classes } = this.props;
    return (
      <CustomAutocomplete
        getSuggestionValue={this.getSuggestionValue}
        handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestion={this.renderSuggestion}
        handleSelection={this.handleSelection}
        suggestions={this.state.suggestions}
        value={this.props.value}
        label={this.props.label}
        placeholder={this.props.placeholder}
        onInputClear={this.props.onInputClear}
        onInputEdit={this.props.onInputEdit}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
        required={this.props.required}
        logo={
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={googleLogo} alt="Powered by Google" />
          </div>
        }
      />
    );
  }
}

GooglePlacesAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onInputClear: PropTypes.func,
  onInputEdit: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(GooglePlacesAutocomplete);
