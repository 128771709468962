export const requiredField = (value) => {
  if (typeof value === 'string') {
    return value.trim().length > 0 ? undefined : 'Required';
  } else {
    return value != null ? undefined : 'Required';
  }
};

export const requiredSelection = (value) => (value && (value !== -1 && value !== '-1')) ? undefined : 'Required';

export const greaterThanZero = (value) => (value > 0 ? undefined : 'Must Be Greater Than Zero');

export const numeric = (value) => value && !/^[0-9]\d*(\.\d+)?$/.test(value) ? 'Must Be a Number' : undefined;

export const validUserId = (value) => value && !/^[a-zA-Z0-9]{3,100}$/.test(value) ? 'Must Be Alphanumeric and At Least 3 Characters' : undefined;

export const validEmail = (value) => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,32}$/i.test(value) ? 'Invalid email address' : undefined;

export const phoneNumber = (value) => value && !/^(\+1\d{10})$|^(\d{10})$/.test(value) ? 'Invalid phone number' : undefined;

export const requiredFieldArraySelection = (value) => value && !value.length > 0 ? 'Must have at least one' : undefined;

export const validPhoneNumber = (value) => value && (value.length < 5 || value.length > 20) ? 'Invalid phone number' : undefined;

export const validDate = (value) => {
  if (!value) {
    return undefined;
  }

  if (value.length > 0) {
    // format yyyy-mm-dd
    let regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(value) ? undefined : 'Invalid format. yyyy-mm-dd';
  } else {
    return undefined;
  }
};