import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';

import trcLogo from '../../../images/logo-eoc-trc-global-mobility.png';
import trcLogoMini from '../../../images/trc-logo-mini.png';

const FormInstructions = (props) => {
  return (
    <Grid container justify={'center'}>
      <Grid item xs={4} md={4}>
        <Hidden smDown>
          <img src={trcLogo} alt="" style={{ float: 'right' }} />
        </Hidden>
        <Hidden mdUp>
          <img src={trcLogoMini} alt="" style={{ float: 'right' }} />
        </Hidden>
      </Grid>
      <Grid item xs={6} md={4}>
        <div><strong>To Get Started</strong></div>
        <div>Find the data you desire by entering the parameters for the report above.</div>
      </Grid>
    </Grid>
  );
};

export default FormInstructions;