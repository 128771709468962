import { CALL_SELF_SERVE_API } from 'middleware/selfServeApi';
import {
  CLONE_MANY_FAILURE,
  CLONE_MANY_REQUEST,
  CLONE_MANY_SUCCESS,
  CLONE_MASTER_FAILURE,
  CLONE_MASTER_REQUEST,
  CLONE_MASTER_SUCCESS,
  CREATE_BENEFIT_FAILURE,
  CREATE_BENEFIT_REQUEST,
  CREATE_BENEFIT_SUCCESS,
  CREATE_DRAFT_FAILURE,
  CREATE_DRAFT_REQUEST,
  CREATE_DRAFT_SUCCESS,
  DELETE_BENEFIT_DRAFT_FAILURE,
  DELETE_BENEFIT_DRAFT_REQUEST,
  DELETE_BENEFIT_DRAFT_SUCCESS,
  DELETE_BENEFIT_FAILURE,
  DELETE_BENEFIT_REQUEST,
  DELETE_BENEFIT_SUCCESS,
  ENABLE_ALL_FAILURE,
  ENABLE_ALL_SUCCESS,
  GET_BENEFITS_FAILURE,
  GET_BENEFITS_REQUEST,
  GET_BENEFITS_SUCCESS,
  GET_BENEFIT_FAILURE,
  GET_BENEFIT_HISTORY_FAILURE,
  GET_BENEFIT_HISTORY_REQUEST,
  GET_BENEFIT_HISTORY_SUCCESS,
  GET_BENEFIT_REQUEST,
  GET_BENEFIT_SUCCESS,
  GET_COST_LOOKUP_APIS_FAILURE,
  GET_COST_LOOKUP_APIS_REQUEST,
  GET_COST_LOOKUP_APIS_SUCCESS,
  GET_COST_LOOKUP_API_FAILURE,
  GET_COST_LOOKUP_API_REQUEST,
  GET_COST_LOOKUP_API_SUCCESS,
  GET_COST_LOOKUP_FAILURE,
  GET_COST_LOOKUP_REQUEST,
  GET_COST_LOOKUP_SUCCESS,
  GET_LOOKUP_TABLES_FAILURE,
  GET_LOOKUP_TABLES_REQUEST,
  GET_LOOKUP_TABLES_SUCCESS,
  GET_MASTER_BENEFIT_RECENT_CHANGES_FAILURE,
  GET_MASTER_BENEFIT_RECENT_CHANGES_REQUEST,
  GET_MASTER_BENEFIT_RECENT_CHANGES_SUCCESS,
  PUBLISH_BENEFIT_FAILURE,
  PUBLISH_BENEFIT_REQUEST,
  PUBLISH_BENEFIT_SUCCESS,
  UPDATE_BENEFITS_FAILURE,
  UPDATE_BENEFITS_REQUEST,
  UPDATE_BENEFITS_SUCCESS,
  UPDATE_BENEFIT_FAILURE,
  UPDATE_BENEFIT_REQUEST,
  UPDATE_BENEFIT_SUCCESS, UPDATE_BENEFIT_VERSION_FAILURE, UPDATE_BENEFIT_VERSION_REQUEST, UPDATE_BENEFIT_VERSION_SUCCESS,
} from './benefit.types';
import { isMasterBenefit, transformSummaryIntoBenefit } from 'modules/benefits/summaryUtil';
import { toWire } from './versionUtil';

export const getBenefits = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_BENEFITS_REQUEST, GET_BENEFITS_SUCCESS, GET_BENEFITS_FAILURE],
      authenticated: true,
      endpoint: 'benefits',
      method: 'GET',
    },
  };
};

export const getBenefit = (id) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_BENEFIT_REQUEST, GET_BENEFIT_SUCCESS, GET_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${id}`,
      method: 'GET',
    },
  };
};

export const createBenefit = (version, createOptions) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CREATE_BENEFIT_REQUEST, CREATE_BENEFIT_SUCCESS, CREATE_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits?publish=${createOptions.publish}&enable=${createOptions.enable}`,
      method: 'POST',
      payload: toWire(version),
    },
  };
};

export const updateBenefit = (benefit) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [UPDATE_BENEFIT_REQUEST, UPDATE_BENEFIT_SUCCESS, UPDATE_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefit.id}`,
      method: 'PUT',
      payload: benefit,
    },
  };
};

const updateBenefits = (benefits) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [UPDATE_BENEFITS_REQUEST, UPDATE_BENEFITS_SUCCESS, UPDATE_BENEFITS_FAILURE],
      authenticated: true,
      endpoint: 'benefits',
      method: 'PUT',
      payload: benefits,
    },
  };
};

export const cloneMasterBenefit = (masterBenefitVersion, enable) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CLONE_MASTER_REQUEST, CLONE_MASTER_SUCCESS, CLONE_MASTER_FAILURE],
      authenticated: true,
      endpoint: `benefits/clone?enable=${enable}`,
      method: 'POST',
      payload: masterBenefitVersion,
    },
  };
};

const cloneMasterBenefits = (masterBenefitVersions, enable) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CLONE_MANY_REQUEST, CLONE_MANY_SUCCESS, CLONE_MANY_FAILURE],
      authenticated: true,
      endpoint: `benefits/clone-many?enable=${enable}`,
      method: 'POST',
      payload: masterBenefitVersions,
    },
  };
};

export const enableAll = (summaries) => {
  return async (dispatch, getState) => {
    const masterBenefits = [];
    const clientBenefits = [];
    summaries.forEach((summary) => {
      if (isMasterBenefit(summary)) {
        masterBenefits.push(summary.mostRecentPublish);
      } else {
        clientBenefits.push(transformSummaryIntoBenefit(summary));
      }
    });
    const results = await(Promise.all([
      masterBenefits.length ? dispatch(cloneMasterBenefits(masterBenefits, true)) : Promise.resolve({ type: CLONE_MANY_SUCCESS }),
      clientBenefits.length ? dispatch(updateBenefits(clientBenefits)) : Promise.resolve({ type: UPDATE_BENEFITS_SUCCESS }),
    ]));
    if (results[0].type === CLONE_MANY_SUCCESS && results[1].type === UPDATE_BENEFITS_SUCCESS) {
      dispatch(getBenefits());
      return { type: ENABLE_ALL_SUCCESS };
    } else {
      return { type: ENABLE_ALL_FAILURE };
    }
  };
};

export const createDraft = (benefitId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [CREATE_DRAFT_REQUEST, CREATE_DRAFT_SUCCESS, CREATE_DRAFT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefitId}/draft`,
      method: 'POST',
    },
  };
};

export const updateBenefitVersion = (version) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [UPDATE_BENEFIT_VERSION_REQUEST, UPDATE_BENEFIT_VERSION_SUCCESS, UPDATE_BENEFIT_VERSION_FAILURE],
      authenticated: true,
      endpoint: `benefits/${version.clientBenefitId}/v/${version.versionId}`,
      method: 'PUT',
      payload: toWire(version),
    },
  };
};

export const publishBenefit = (version, enable) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [PUBLISH_BENEFIT_REQUEST, PUBLISH_BENEFIT_SUCCESS, PUBLISH_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${version.clientBenefitId}/v/${version.versionId}/publish?enable=${enable}`,
      method: 'PUT',
      payload: toWire(version),
    },
  };
};

export const getLookupTables = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_LOOKUP_TABLES_REQUEST, GET_LOOKUP_TABLES_SUCCESS, GET_LOOKUP_TABLES_FAILURE],
      authenticated: true,
      endpoint: 'tableCostLookup',
      method: 'GET',
    },
  };
};

export const getCostLookupByTable = (tableName) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_COST_LOOKUP_REQUEST, GET_COST_LOOKUP_SUCCESS, GET_COST_LOOKUP_FAILURE],
      authenticated: true,
      endpoint: `tableCostLookup/${tableName}`,
      method: 'GET',
      context: {
        tableName,
      },
    },
  };
};

export const getCostLookupApis = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_COST_LOOKUP_APIS_REQUEST, GET_COST_LOOKUP_APIS_SUCCESS, GET_COST_LOOKUP_APIS_FAILURE],
      authenticated: true,
      endpoint: 'apiCostLookup',
      method: 'GET',
    },
  };
};

export const getCostLookupByApi = (apiId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_COST_LOOKUP_API_REQUEST, GET_COST_LOOKUP_API_SUCCESS, GET_COST_LOOKUP_API_FAILURE],
      authenticated: true,
      endpoint: `apiCostLookup/${apiId}`,
      method: 'GET',
      context: {
        apiId,
      },
    },
  };
};

export const getMasterBenefitRecentChanges = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_MASTER_BENEFIT_RECENT_CHANGES_REQUEST, GET_MASTER_BENEFIT_RECENT_CHANGES_SUCCESS, GET_MASTER_BENEFIT_RECENT_CHANGES_FAILURE],
      authenticated: true,
      endpoint: 'masterBenefits/recentChanges',
      method: 'GET',
    },
  };
};

export const getBenefitHistory = (benefitId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_BENEFIT_HISTORY_REQUEST, GET_BENEFIT_HISTORY_SUCCESS, GET_BENEFIT_HISTORY_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefitId}/history`,
      method: 'GET',
    },
  };
};

export const deleteBenefitDraft = (version) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [DELETE_BENEFIT_DRAFT_REQUEST, DELETE_BENEFIT_DRAFT_SUCCESS, DELETE_BENEFIT_DRAFT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${version.clientBenefitId}/v/${version.versionId}`,
      method: 'DELETE',
    },
  };
};

export const deleteBenefit = (benefitId) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [DELETE_BENEFIT_REQUEST, DELETE_BENEFIT_SUCCESS, DELETE_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefitId}`,
      method: 'DELETE',
    },
  };
};
