// key: route that requires a permission
// value: permission required in order to use the route

// TODO: update map to include correct permissions
export const authorizedRouteMap = {
  // '/authorizations': 'read:authorizations',
  // '/authorizations/:id': 'create:authorizations',
  // '/transferees': 'read:transferees',
  // '/transferees/:id': 'read:transferees',
  // '/exceptions': 'read:exceptions',
  // '/invoices': 'read:invoices',
  // '/reporting': 'read:reporting',
  // '/reporting/:id': 'read:reporting'
  '/benefits': 'selfService:benefits',
  '/benefits/add': 'selfService:benefits',
  '/benefits/:id/v/:versionId': 'selfService:benefits',
  '/policies': 'selfService:policies',
  '/policies/:id/v/:versionId': 'selfService:policies',
  '/policies/:id/v/:versionId/rules': 'selfService:policies',
  '/policies/:id/v/:versionId/bot': 'selfService:policies',
  '/policies/add/:id/v/:versionId': 'selfService:policies',
  '/policies/add/:id/v/:versionId/rules': 'selfService:policies',
  '/policies/add/:id/v/:versionId/bot': 'selfService:policies',
};