import { Autocomplete } from '@material-ui/lab';
import { Button, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, makeStyles } from '@material-ui/core';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { REQUIRED_ERROR_MESSAGE } from 'common/constants';
import { generateRuleQuestion } from './policyRuleUtil';
import { getAutoCompleteChipProps, isEmptyDeep } from 'utilities/formUtils';
import { getObjectId, mergeClientAndMasterQuestion } from 'modules/benefits/versionUtil';
import TRCInputValidator from 'common/form/TRCInputValidator';

const useStyles = makeStyles((theme) => (
  {
    questionReadOnly: { // needed to get the question field to line up with the answers field in a read only state
      '& label + .MuiInput-root': {
        marginTop: '23px',
      },
    },
    ruleQuestionSelect:{
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.divider,
    },
    ruleTableCell:{
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: theme.spacing(8),
    },
  }
));

const OptionRule = (props) => {
  const { rule, questionDetails, modifyBenefit, isReadOnly, validateOnBlur, validateOnChange, isRuleValid } = props;
  const classes = useStyles();

  const getRemainingQuestions = useCallback((selectedQuestion) => {
    const remainingQuestions = questionDetails.filter((questionDetail) =>
      rule.questions.every(
        (ruleQuestion) => ruleQuestion.benefitQuestionDetail.questionId !== questionDetail.questionId,
      ),
    );
    if (!isEmptyDeep(selectedQuestion.benefitQuestionDetail)) {
      remainingQuestions.push(mergeClientAndMasterQuestion(selectedQuestion.benefitQuestionDetail));
    }
    return remainingQuestions;
  }, [rule, questionDetails]);

  const addQuestion = useCallback(() => {
    rule.questions.push(generateRuleQuestion());
    modifyBenefit();
  }, [rule, modifyBenefit]);

  const displayAddRuleButton = useCallback((questionIdx) => {
    const isLastAvailableQuestion = questionIdx === questionDetails.length - 1;
    const isLastRuleQuestion = rule.questions.length - 1 === questionIdx;

    const showAddQuestionsButton = !isLastAvailableQuestion && !(isReadOnly && isLastRuleQuestion);

    if (!showAddQuestionsButton) return null;

    const disableAddQuestionsButton = !isRuleValid || !isLastRuleQuestion;

    return (<Button size="small" variant="text" color="primary" onClick={addQuestion} disabled={disableAddQuestionsButton}>And</Button>);

  }, [isReadOnly, rule, questionDetails, addQuestion, isRuleValid]);

  const getChipProps = useMemo(() => {
    return getAutoCompleteChipProps(isReadOnly);
  }, [isReadOnly]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {
            rule.questions.map((ruleQuestion, idx) => {
              const clientAndMasterRuleQuestionMerged = mergeClientAndMasterQuestion(ruleQuestion.benefitQuestionDetail);
              const isQuestionReadOnly = !isRuleValid || isReadOnly;
              return (
                <TableRow key={getObjectId(ruleQuestion)}>
                  <TableCell padding="none" className={classes.ruleTableCell} width="50%">
                    <TRCInputValidator
                      component={SelectValidator}
                      className={classes.ruleQuestionSelect}
                      variant="standard"
                      InputProps={{
                        readOnly: isQuestionReadOnly,
                        disableUnderline: true,
                      }}
                      disabled={isReadOnly}
                      value={ruleQuestion.benefitQuestionDetail.questionId}
                      onChange={(value) => {
                        if (isQuestionReadOnly || !value) {
                          return;
                        }
                        ruleQuestion.benefitQuestionDetail = questionDetails.find((qd) => qd.questionId === value);
                        ruleQuestion.answers = [];
                        modifyBenefit();
                      }}
                      required
                      validateOnBlur={validateOnBlur}
                      validateOnChange={validateOnChange}
                      fullWidth
                      classes={{ root: isQuestionReadOnly ? classes.questionReadOnly : '' }}
                    >
                      {
                        getRemainingQuestions(ruleQuestion).map((questionDetail) => (
                          <MenuItem key={questionDetail.text} value={questionDetail.questionId}>
                            {questionDetail.text}
                          </MenuItem>
                        ))
                      }
                    </TRCInputValidator>
                  </TableCell>
                  <TableCell padding="none" className={classes.ruleTableCell} width="40%">
                    <Autocomplete
                      value={ruleQuestion.answers}
                      multiple
                      filterSelectedOptions
                      options={
                        clientAndMasterRuleQuestionMerged.answers
                      }
                      disabled={!isRuleValid || isQuestionReadOnly || isEmpty(clientAndMasterRuleQuestionMerged.answers)}
                      onChange={(e, newVal) => {
                        ruleQuestion.answers = newVal;
                        modifyBenefit();
                      }}
                      renderInput={(params) => {
                        params.disabled = !isRuleValid; // removes the styling added when disabled = true
                        return (
                          <TRCInputValidator
                            {...params}
                            variant="standard"
                            component={TextValidator}
                            InputProps={{ 
                              ...params.InputProps, 
                              margin: 'dense',
                              readOnly: isQuestionReadOnly,
                              disableUnderline: true,
                            }}
                            value={ruleQuestion.answers}
                            validators={['notEmpty']}
                            errorMessages={[REQUIRED_ERROR_MESSAGE]}
                            validateOnBlur={validateOnBlur}
                            validateOnChange={validateOnChange}
                          />
                        );
                      }}
                      ChipProps={getChipProps}
                    />
                  </TableCell>
                  <TableCell padding="none" width="10%">
                    {  displayAddRuleButton(idx) }
                  </TableCell>

                </TableRow>
               
              );
            })
          }
        </TableBody>
      </Table>
    </TableContainer> 
  );
};

OptionRule.propTypes = {
  rule: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  validateOnBlur: PropTypes.bool.isRequired,
  validateOnChange: PropTypes.bool.isRequired,
  modifyBenefit: PropTypes.func.isRequired,
  questionDetails: PropTypes.array.isRequired,
  isRuleValid: PropTypes.bool.isRequired,
};

export default OptionRule;
