import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  optionPill: {
    cursor: 'pointer',
    padding: '10px 15px',
    margin: '5px 5px',
    minWidth: '100px',
    textAlign: 'center',
    color: '#007aff',
    background: theme.palette.common.white,
    borderColor: '#007aff',
    border: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    '&:hover': {
      color: theme.palette.common.white,
      background: '#007aff',
    },
  },
}
));

export function OptionPill(props) {
  const { reply, onReplyChosen } = props;
  const classes = useStyles();

  return (
    <Box className={classes.optionPill} onClick={() => onReplyChosen(reply)}>
      { reply }
    </Box>
  );
}

export default function QuickRepliesContainer(props) {
  const { quickReplies, onReplyChosen } = props;
  return (
    <Box display="flex" alignContent="center" justifyContent="center" flexWrap="wrap" marginTop={2}>
      { quickReplies.map((r, i) => <OptionPill key={i} reply={r} onReplyChosen={onReplyChosen} /> ) }
    </Box>
  );
}

OptionPill.propTypes = {
  reply : PropTypes.object.isRequired,
  onReplyChosen: PropTypes.func.isRequired,
};

QuickRepliesContainer.propTypes = {
  quickReplies : PropTypes.array.isRequired,
  onReplyChosen: PropTypes.func.isRequired,
};