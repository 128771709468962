import { connect } from 'react-redux';
import { currencyFormatter } from 'utilities/currencyFormatter';
import { getOtherExceptions, getPendingException } from '../exceptions.actions';
import { 
  TOAST_MESSAGE_SEVERITY_ERROR, 
  setBackButtonPath,
  showToast }
  from '../../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ApprovalForm from './approvalForm.component';
import Grid from '@material-ui/core/Grid';
import LogoSpinner from '../../../common/logoSpinner.component';
import OtherExceptions from './otherExceptions.component';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import ReadOnlyText from '../../../common/readOnlyText.component';




const styles = (theme) => ({
  textFieldWide: {
    marginRight: theme.spacing(1),
    width: '100%',
    'max-width': 400,
  },
});



class PendingException extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingException: false,
    };
  }

  componentWillMount() {
    this.setState({ isLoadingException: true });
  }

  async componentDidMount() {
    this.props.setBackButtonPath('/exceptions');
    const selectedExceptionId = this.props.match.params.id;

    if (selectedExceptionId) {
      var response = await this.props.getPendingException(selectedExceptionId);
      if(response.hasErrors){
        this.props.showToast(response.messages, { severity: TOAST_MESSAGE_SEVERITY_ERROR })
        this.props.history.push('/exceptions');
      }
      this.setState({ selectedException: this.props.selectedException });
      response = await this.props.getOtherExceptions(selectedExceptionId);
      if(response.hasErrors) this.props.showToast(response.messages, { severity: TOAST_MESSAGE_SEVERITY_ERROR })
      this.setState({ otherExceptions: this.props.otherExceptions });
    }

    this.setState({ isLoadingException: false });
  }

  formatDate(date) {
    let d = new Date(date);
    let userTimezoneOffset = d.getTimezoneOffset() * 60000;
    let offsetDate = new Date(d.getTime() + userTimezoneOffset);

    let month = `${  offsetDate.getMonth() + 1}`,
      day = `${  offsetDate.getDate()}`,
      year = offsetDate.getFullYear();

    if (month.length < 2) month = `0${  month}`;
    if (day.length < 2) day = `0${  day}`;

    return [month, day, year].join('/');
  }

  render() {
    let {
      FirstName,
      LastName,
      Program,
      ExceptAmount,
      ExceptCategory,
      ExceptDate,
      RequestType,
      ExceptDesc,
      ExceptID,
      ExceptReason,
    } = this.props.selectedException;
    const otherExceptions = this.props.otherExceptions;

    ExceptDate = this.formatDate(ExceptDate);

    if (!ExceptCategory) {
      ExceptCategory = 'N/A';
    }

    const { isLoadingException } = this.state;
    if (isLoadingException) {
      return (
        <div style={{ margin: '2rem' }}>
          <LogoSpinner />
        </div>
      );
    }

    return (
      <div>
        <div style={{ padding: '2rem 2rem 1rem 2rem' }}>
          <Paper style={{ padding: '1rem' }}>
            <h3>Requested Exception</h3>
            <Grid container>
              <Grid item xs={12} md={6} lg={3}>
                <Grid>
                  <ReadOnlyText
                    label="Transferee Name"
                    value={(FirstName && LastName) ? `${FirstName} ${LastName}` : ' '}
                  />
                </Grid>
                <Grid>
                  <ReadOnlyText
                    label="Program Name"
                    value={(Program) ? Program : ' '}
                  />
                </Grid>
                <Grid>
                  <ReadOnlyText
                    label="Requested Amount"
                    value={(ExceptAmount) ? currencyFormatter({ value: ExceptAmount }) : ' '}
                  />
                </Grid>
                <Grid>
                  <ReadOnlyText
                    label="Exception Category"
                    value={(ExceptCategory) ? ExceptCategory : ' '}
                  />
                </Grid>
                <Grid>
                  <ReadOnlyText
                    label="Exception Request Date"
                    value={(ExceptDate) ? ExceptDate : ' '}
                  />
                </Grid>
                <Grid>
                  <ReadOnlyText
                    label="Request Type"
                    value={(RequestType) ? RequestType : ' '}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid>
                  <ReadOnlyText
                    label="Description"
                    multiline
                    value={(ExceptDesc) ? ExceptDesc : ' '}
                  />
                </Grid>
                <Grid>
                  <ReadOnlyText
                    label="Exception Reason"
                    multiline
                    value={(ExceptReason) ? ExceptReason : ' '}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5}>
                <ApprovalForm exceptID={ExceptID} requestAmount={ExceptAmount} />
              </Grid>
            </Grid>
          </Paper>
        </div>
        <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
          <OtherExceptions rows={otherExceptions} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedException: state.exceptions.get('pendingException'),
    otherExceptions: state.exceptions.get('otherExceptions'),
  };
};

PendingException = withStyles(styles)(PendingException);

export default withRouter(connect(mapStateToProps, {
  getPendingException,
  getOtherExceptions,
  setBackButtonPath,
  showToast
})(PendingException));

