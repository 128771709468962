import 'pure-react-carousel/dist/react-carousel.es.css';
import { Box, Card, CardMedia, Grid, Typography, makeStyles } from '@material-ui/core';
import { ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Truncate from 'react-truncate';

const CARD_HEIGHT = 240;
const CARD_WIDTH = 180;

const useStyles = makeStyles((theme) => ({
  root:{
    width: CARD_WIDTH,
    minHeight: 200,
    border: 2,
    borderRadius: 20,
    cursor: 'pointer',
    '&:hover': {
      background: '#f4f4f8',
    },
  },
  carousel: {
    '& .carousel__inner-slide':{
      left: '10px',
      top: '10px',
    },
  },
  header:{
    fontSize: '1rem',
  },
  optionImage: {
    width: CARD_WIDTH,
    height: '125px',
  },
  optionText:{
    display: 'flex', 
    flexGrow: 1, 
    minHeight: '60px',
    width: '95%',
    paddingTop: '4px',
    paddingBottom: '8px',
  },
  optionName:{
    fontWeight: 500,
    fontSize: '0.75rem',
  },
  optionSubtitle:{
    fontWeight: 400,
    fontSize: '0.6rem',
  },
  optionFormattedText:{
    fontWeight: 400,
    fontSize: '0.75rem',
  },
  selectOptionFooter:{
    height: 40,
    padding: '4px',
    borderColor: theme.palette.divider,
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    width: '100%',
  },
  selectOption: {
    marginTop: 4,
    marginBottom: 4,
    fontSize: '0.75rem',
    color: '#01838F',
  },
  controlButton: {
    border: '0px',
    paddingTop: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: '#01838F',
    '&:focus': {
      outline: 'none',
    },
  },
  dotGroup: {
    display:'flex',
    justifyContent: 'center',
    '& > .carousel__dot': {
      outline: 'none',
      border: '1px',
      borderRadius: 20,
      borderColor: '#01838F',
      borderStyle: 'solid',
      backgroundColor: 'transparent',
      padding: 0,
      width: '8px;',
      height: '8px',
      margin: '1px',
    },
    '& .carousel__dot--selected' :{
      backgroundColor: '#01838F',
    },
  },
  slider: {
    flex: 1,
    padding: '.5rem 0',
    height: '300px',
  },
}
));

export function CardOption(props) {
  const { option, onOptionChosen, isMessage } = props;
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const [selected, setSelected] = useState(false);

  const onClick = () => {
    setSelected(true);
    onOptionChosen(option);
  };

  return (
    <Card className={classes.root}
      onClick={onClick} 
      onMouseOver={()=>{!isMessage && setHover(true);}}
      onMouseOut={()=>{!isMessage && setHover(false);}}
      elevation={(hover || selected) && !isMessage ? 6 : 1}
    >
      <Grid container direction="column" alignItems="center" spacing={0}>
        <Grid item>
          <CardMedia image={option.image.imageUri} className={classes.optionImage} />
        </Grid>
        <Grid item container className={classes.optionText} direction="column" spacing={1}>
          <Grid item>
            <Typography className={classes.optionName}>
              <Truncate lines={1}>
                {option.title}
              </Truncate>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.optionSubtitle} style={{ height: option.formattedText ? '35px' : '55px' }}>
              <Truncate lines={option.formattedText ? 2 : 3}>
                {option.subtitle}
              </Truncate>
            </Typography>
          </Grid>
          {option.formattedText && //used for the cost of options
            <Grid item>
              <Typography className={classes.optionFormattedText}>
                {`${option.formattedText}`}
              </Typography>
            </Grid>
          }
        </Grid>
        {
          !isMessage &&
            <Grid item className={classes.selectOptionFooter}>
              <Typography className={classes.selectOption} align="center">
                {selected ? 'Selected': 'Select this item'}
              </Typography>
            </Grid>
        }
      </Grid>
    </Card>
  );
}

export default function CardOptionCarousel(props) {
  const { cardOptions } = props;
  const classes = useStyles();

  return (
    <Box marginTop={2}>
      {
        cardOptions.length > 2 ?
          <CarouselProvider
            totalSlides={cardOptions.length}
            visibleSlides={2}
            step={2}
            naturalSlideHeight={CARD_HEIGHT}
            naturalSlideWidth={CARD_WIDTH}
            lockOnWindowScroll
            infinite
            className={classes.carousel}
          >
            <Grid container direction="column" spacing={1}>
              <Grid item container alignItems="center" spacing={0}>
                <Grid item xs={1}>
                  <ButtonBack className={classes.controlButton}>
                    <ChevronLeft />
                  </ButtonBack>
                </Grid>
                <Grid item xs={10}>
                  <Slider className={classes.slider}>
                    {cardOptions.map((option, idx) => (
                      <Slide key={option.id} index={idx}>
                        <CardOption option={option} onOptionChosen={props.onOptionChosen} />
                      </Slide>
                    ))}
                  </Slider>
                </Grid>
                <Grid item xs={1}>
                  <ButtonNext className={classes.controlButton}>
                    <ChevronRight />
                  </ButtonNext>
                </Grid>
              </Grid>
              <Grid item><DotGroup className={classes.dotGroup} /></Grid>
            </Grid>
          </CarouselProvider>
          :
          <Grid container justify="center">
            {cardOptions.map((option, idx) => (
              <Grid item key={idx}>
                <CardOption option={option} onOptionChosen={props.onOptionChosen} />
              </Grid>
            ))}
          </Grid>
      }
    </Box>
  );
}

CardOption.propTypes = {
  option : PropTypes.object.isRequired,
  onOptionChosen: PropTypes.func.isRequired,
  isMessage: PropTypes.bool,
};

CardOptionCarousel.propTypes = {
  cardOptions : PropTypes.array.isRequired,
  onOptionChosen: PropTypes.func.isRequired,
};