// Return a new object with the master benefit version and client benefit version merged together. Client benefits can override props of master benefits
import { isEmpty, isEqual, memoize, mergeWith } from 'lodash';
import { isEmptyDeep } from 'utilities/formUtils';

export const mergeClientAndMaster = memoize((version) => {
  if (isEmpty(version.masterVersion)) {
    return version;
  }
  // props that will always win out over the corresponding master benefit prop
  const alwaysOverrideProps = [
    'versionId',
    'isPublished',
    'createdDate',
    'modifiedDate',
    'publishedDate',
    'publishedBy',
    'publishNotes',
  ];
  const toReturn = {};
  Object.keys(version).forEach((prop) => {
    if (
      alwaysOverrideProps.includes(prop) ||
      (version[prop] != null && version[prop] !== '')
    ) {
      toReturn[prop] = version[prop];
    } else if (
      version.masterVersion[prop] != null &&
      version.masterVersion[prop] !== ''
    ) {
      toReturn[prop] = version.masterVersion[prop];
    }
  });
  toReturn.optionDetails = version.optionDetails.map((optDetail) => mergeClientAndMasterOption(optDetail));
  toReturn.optionDetails.sort((opt1, opt2) =>  opt1.order - opt2.order);
  toReturn.questionDetails = version.questionDetails.map((question) => mergeClientAndMasterQuestion(question));
  return toReturn;
});

export const mergeClientAndMasterOption = memoize((optionDetail) => {
  if (isEmpty(optionDetail.masterOptionDetail)) {
    return optionDetail;
  }
  const toReturn = {};
  const objectProps = ['costVariables'];
  Object.keys(optionDetail).forEach((prop) => {
    if (
      optionDetail[prop] != null &&
      optionDetail[prop] !== ''
    ) {
      if (objectProps.includes(prop)) {
        toReturn[prop] = mergeWith({}, optionDetail.masterOptionDetail[prop], optionDetail[prop], (targetVal, sourceVal) => {
          if (sourceVal != null && sourceVal !== '') {
            return sourceVal;
          } else {
            return targetVal;
          }
        });
      } else {
        toReturn[prop] = optionDetail[prop];
      }
    } else if (
      optionDetail.masterOptionDetail[prop] != null &&
      optionDetail.masterOptionDetail[prop] !== ''
    ) {
      toReturn[prop] = optionDetail.masterOptionDetail[prop];
    }
  });
  return toReturn;
});

export const mergeClientAndMasterQuestion = memoize((question) => {
  if (isEmpty(question.masterQuestionDetail)) {
    return question;
  }
  const toReturn = {};
  Object.keys(question).forEach((prop) => {
    if (
      question[prop] != null &&
      question[prop] !== ''
    ) {
      toReturn[prop] = question[prop];
    } else if (
      question.masterQuestionDetail[prop] != null &&
      question.masterQuestionDetail[prop] !== ''
    ) {
      toReturn[prop] = question.masterQuestionDetail[prop];
    }
  });
  if (isEmpty(question.answers)) {
    toReturn.answers = question.masterQuestionDetail.answers;
  }
  return toReturn;
});

export const getObjectId = (obj) => {
  return obj.id != null ? obj.id : obj.tempId;
};

export const isClientSpecificBenefit = (version) => {
  return isEmpty(version.masterBenefitId) && isEmpty(version.masterVersion);
};

export const isModifiedClientBenefit = (version) => {
  return isEmpty(version.masterBenefitId) && version.isVersionOne === false;
};

export const areAllQuestionsEqual = (questionsList1, questionsList2) => {
  if (questionsList1.length !== questionsList2.length) {
    return false;
  }
  return questionsList1.every((list1Question) => {
    const matchingQuestion = questionsList2.find((qd) => qd.questionId === list1Question.questionId);
    if (!matchingQuestion) {
      return false;
    }
    return areQuestionsEqual(list1Question, matchingQuestion);
  });
};

export const isRuleQuestionStillValid = (ruleQuestion, questionDetails) => {
  if (isEmptyDeep(ruleQuestion.benefitQuestionDetail)) {
    return true;
  }
  const matchingQuestionDetail = questionDetails.find((questionDetail) => {
    return questionDetail.questionId === ruleQuestion.benefitQuestionDetail.questionId;
  });
  if (!matchingQuestionDetail) {
    return false;
  }
  return areQuestionsEqual(matchingQuestionDetail, ruleQuestion.benefitQuestionDetail);
};

export const areQuestionsEqual = (questionDetail1, questionDetail2) => {
  questionDetail1 = mergeClientAndMasterQuestion(questionDetail1);
  questionDetail2 = mergeClientAndMasterQuestion(questionDetail2);
  return questionDetail1.text === questionDetail2.text &&
    isEqual(questionDetail1.answers, questionDetail2.answers);
};

// remove any props that middleware does not expect to receive here
export const toWire = (version) => {
  return {
    ...version,
    masterVersion: undefined,
    optionDetails: version.optionDetails.map((optDetail) => (
      {
        ...optDetail,
        tempId: undefined,
        masterOptionDetail: undefined,
      }
    )),
    questionDetails: version.questionDetails.map((questionDetail) => (
      {
        ...questionDetail,
        tempId: undefined,
        masterQuestionDetail: undefined,
      }
    )),
    vendorQuestionDetails: version.vendorQuestionDetails.map((vq) => (
      {
        ...vq,
        tempId: undefined,
        masterQuestionDetail: undefined,
      }
    )),
  };
};
