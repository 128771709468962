import React, { Component } from 'react';

import { currencyFormatter } from '../../utilities/currencyFormatter';

import {
  DataTypeProvider,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={currencyFormatter}
    {...props}
  />
);

class ChartDetailGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'ExceptCategory', title: 'Category' },
        { name: 'CurrentYear', title: 'This Year' },
        { name: 'PreviousYear', title: 'Last Year' },
      ],
      columnWidths: [
        { columnName: 'ExceptCategory', width: 230 },
        { columnName: 'CurrentYear', width: 88 },
        { columnName: 'PreviousYear', width: 88 },
      ],
      rows: [],
      currencyColumns: ['CurrentYear', 'PreviousYear'],
      sorting: [],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.TableRow = ({ row, ...restProps }) => {
      return (<VirtualTable.Row
        {...restProps}
        style={{
          cursor: 'default',
        }}
              />);
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  componentWillMount() {
    this.setState({ rows: this.props.rows });
  }

  render() {
    const { rows, columns, sorting, columnWidths, currencyColumns } = this.state;

    if (!rows) {
      return <div />;
    }

    return (
      <Grid rows={rows} columns={columns}>
        <CurrencyTypeProvider
          for={currencyColumns}
        />
        <SortingState
          sorting={sorting}
          onSortingChange={this.changeSorting}
        />
        <IntegratedSorting />
        <VirtualTable rowComponent={this.TableRow} height={300} />
        <TableColumnResizing
          columnWidths={columnWidths}
        // onColumnWidthsChange={this.changeColumnWidths}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    );
  }
}

export default ChartDetailGrid;