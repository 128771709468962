import moment from 'moment';

export const dateFormatter = ({ value }) => {
  if(!value) return '';
  return moment(value).format('MMM DD, YYYY');
};

export const dateFilterPredicate = (value, filter) => {
  let filterTokens = filter.value.split(' ');

  let matchArray = filterTokens.map((token) => {
    const formattedDate = String(dateFormatter({ value })).toLowerCase();
    const rawValue = String(value).toLowerCase();
    const searchToken = String(token).toLowerCase();
    return rawValue.indexOf(searchToken) >= 0 || formattedDate.indexOf(searchToken) >= 0;
  });

  return matchArray.findIndex((item) => item === false) === -1;
};

export const getLocaleDateString = (date) => {
  return new Date(date).toLocaleDateString('en-us');
};

export const getDateTime = (date) => {
  let d = new Date(date);
  return `${d.toLocaleDateString('en-us')  } ${  d.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit' })}`;
};

export const dateDiffDays = (date1, date2) => {
  return Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
};

export const formatDateWithDashes = (date) => {
  let d = Date.parse(date);

  if (!isNaN(d)) {
    let dateObj = new Date(date);
    let userTimezoneOffset = dateObj.getTimezoneOffset() * 60000;
    let offsetDate = new Date(dateObj.getTime() + userTimezoneOffset);

    let month = `${  offsetDate.getMonth() + 1}`,
      day = `${  offsetDate.getDate()}`,
      year = `${  offsetDate.getFullYear()}`;

    if (month.length < 2) month = `0${  month}`;
    if (day.length < 2) day = `0${  day}`;

    while (year.length < 4) {
      year = `0${  year}`;
    }

    if (year.length > 4) {
      year = year.substring(0, 4);
    }

    if (year && month && day) {
      return [year, month, day].join('-');
    }
  }
  return date;
};

export const formatDateWithSlashes = (date) => {
  let d = Date.parse(date);

  if (!isNaN(d)) {
    let dateObj = new Date(date);
    let userTimezoneOffset = dateObj.getTimezoneOffset() * 60000;
    let offsetDate = new Date(dateObj.getTime() + userTimezoneOffset);

    let month = `${  offsetDate.getMonth() + 1}`,
      day = `${  offsetDate.getDate()}`,
      year = `${  offsetDate.getFullYear()}`;

    if (month.length < 2) month = `0${  month}`;
    if (day.length < 2) day = `0${  day}`;

    while (year.length < 4) {
      year = `0${  year}`;
    }

    if (year.length > 4) {
      year = year.substring(0, 4);
    }

    if (year && month && day) {
      return [month, day, year].join('/');
    }
  }
  return date;
};

export const getShortDayOfWeek = (num) => {
  switch (num) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    default:
      return '';
  }
};

export default { dateFormatter, dateFilterPredicate, getLocaleDateString, getDateTime, dateDiffDays, formatDateWithDashes, formatDateWithSlashes };