import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Textfit } from 'react-textfit';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import classNames from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import { getDelimitedValue } from '../../utilities/currencyFormatter';
import { userHasPermission } from '../../utilities/authUtils';

const styles = (theme) => ({
  paperSection: {
    height: '100%',
    position: 'relative',
    borderRadius: '0.5rem',
    cursor: 'pointer',
  },
  cardIcon: {
    height: '75px',
    width: '75px',
    position: 'absolute',
    left: '5%',
    top: '-10%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      height: '55px',
      width: '55px',
    },
    borderRadius: '0.5rem',
  },
  description: {
    position: 'absolute',
    bottom: '8px',
    width: '100%',
  },
  stat: {
    position: 'absolute',
    bottom: '62px',
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    height: '4rem',
    lineHeight: '4rem',
  },
  percentageContainer: {
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    position: 'absolute',
    right: 5,
    top: 0,
    height: '3rem',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  actionButton: {
    margin: theme.spacing(1),
    float: 'right',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class StatsCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: {
        percentage: 0,
        trending: '',
      },
      excep: {
        percentage: 0,
        trending: '',
      },
    };

    this.trendingPercentage = this.trendingPercentage.bind(this);
    this.sendTo = this.sendTo.bind(this);
    this.handleCreateNewAuthorization = this.handleCreateNewAuthorization.bind(this);
    this.renderStatCardDescription = this.renderStatCardDescription.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { data, type } = nextProps;

    if (type === 'auth' && data) {
      this.trendingPercentage(type, data);
    } else if (type === 'excep' && data) {
      this.trendingPercentage(type, data);
    }
  }

  trendingPercentage(type, data) {
    const { CurrentYear, PreviousYear } = data;

    const difference = CurrentYear - PreviousYear;

    if (type === 'auth') {
      difference > 0
        ? this.setState({
          auth: {
            percentage: difference / PreviousYear,
            trending: true,
          },
        })
        : this.setState({
          auth: {
            percentage: Math.round(100 * Math.abs(difference) / PreviousYear),
            trending: false,
          },
        });
    } else if (type === 'excep') {
      difference > 0
        ? this.setState({
          excep: {
            percentage: difference / PreviousYear,
            trending: true,
          },
        })
        : this.setState({
          excep: {
            percentage: Math.round(100 * Math.abs(difference) / PreviousYear),
            trending: false,
          },
        });
    }
  }

  renderIcon(type) {
    const { classes } = this.props;

    switch (type) {
      case 'auth':
        return (
          <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #67a783, #4d8766)' }}>
            <FontAwesomeIcon icon="star" color="white" size="3x" />
          </Paper>
        );

      case 'expense':
        return (
          <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #3d939b, #15585e)' }}>
            <FontAwesomeIcon icon="money-bill-alt" color="white" size="3x" />
          </Paper>
        );

      case 'excep':
        return (
          <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #224d88, #4182ca)' }}>
            <FontAwesomeIcon icon="exclamation-triangle" color="white" size="3x" />
          </Paper>
        );

      case 'services':
        return (
          <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #fd6120, #ef5f2f)' }}>
            <FontAwesomeIcon icon="thumbs-up" color="white" size="3x" />
          </Paper>
        );

      case 'transferees':
        return (
          <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #ba8a00, #f0b11d)' }}>
            <FontAwesomeIcon icon="users" color="white" size="3x" />
          </Paper>
        );
      default:
        return (
          <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #67a783, #4d8766)' }}>
            <FontAwesomeIcon icon="star" color="white" size="3x" />
          </Paper>
        );
    }
  }

  sendTo() {
    this.props.history.push({
      pathname: this.props.sendTo,
      state: this.props.sendToState,
    });
  }

  handleCreateNewAuthorization(evt) {
    evt.stopPropagation();
    this.props.history.push('authorizations/new');
  }

  renderStatCardDescription(type, expensesAreEnabled) {
    let { classes, color, data } = this.props;

    // provide default values
    if (!data && type === 'transferees') {
      data = 0;
    } else if (!data) {
      data = { CurrentYear: 0 };
    }

    switch (type) {
      case 'auth':
        return <div className={classes.stat} style={{ color }}><Textfit max={48}>{getDelimitedValue(data.CurrentYear, 0, 3)}</Textfit></div>;
      case 'excep':
        return <div className={classes.stat} style={{ color }}><Textfit max={26}>{`$${  getDelimitedValue(data.CurrentYear, 0, 3)}`}</Textfit></div>;
      case 'expense':
        return expensesAreEnabled ?
          <div className={classes.stat} style={{ color }}><Textfit max={26}>{`$${  getDelimitedValue(data.CurrentYear, 0, 3)}`}</Textfit></div> :
          <div className={classes.stat} style={{ color }}><Textfit max={26}>{'Coming Soon!'}</Textfit></div>;
      case 'transferees':
        return <div className={classes.stat} style={{ color }}><Textfit max={48}>{getDelimitedValue(data, 0, 3)}</Textfit></div>;
      case 'services':
        return <div className={classes.stat} style={{ color }}><Textfit max={38}>{`${(((data.CurrentYear || 0) / 6) * 100).toFixed(1)  }%`}</Textfit></div>;
      default:
        return <div />;
    }
  }

  render() {
    const { classes, text, type, clientDivisions } = this.props;
    const expensesAreEnabled = userHasPermission('expenses:read', clientDivisions);

    return (
      <Paper className={classes.paperSection} onClick={!expensesAreEnabled && type === 'expense' ? null : this.sendTo}>
        {this.renderIcon(type)}
        {/* {
          type === 'auth' || type === 'excep'
            ? (
              <div className={classes.percentageContainer} style={{color}}>
                {this.state[type].trending
                  ? <CaretUp style={{ color }} />
                  : <CaretDown style={{ color }} />
                }
                  <Textfit>{this.state[type].percentage}%</Textfit>
              </div>
            ) : ''
        } */}

        {type === 'auth' &&
          <Button
            className={classes.actionButton}
            variant="contained"
            size="small"
            style={{ backgroundColor: '#67A783', color: 'white' }}
            onClick={this.handleCreateNewAuthorization}
          >
            <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
            New
          </Button>
        }

        <div className={classes.description}>
          {this.renderStatCardDescription(type, expensesAreEnabled)}
          <div style={{ fontSize: '1.25rem', textAlign: 'center', color: '#8a8a8a', width: '150px', margin: '0 auto' }}>{text}</div>
        </div>
      </Paper>
    );
  }
}

StatsCards = withStyles(styles)(StatsCards);

const mapStateToProps = (state) => {
  return {
    clientDivisions: state.layout.get('clientDivisions'),
  };
};

export default withRouter(connect(mapStateToProps, {

})(StatsCards));
