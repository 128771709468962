import { Box, Typography } from '@material-ui/core';
import LightBulb from '../../images/Lightbulb.png';
import React from 'react';
const TableauErrorPage = () => {
  return (
    <Box position="relative" display="flex" sx={{ flexDirection: 'column' }} alignItems="center" justify="center" height="100%">
      <Typography variant="h3" sx={{ m: '2000px' }}>Oops!</Typography>
      <img src={LightBulb} style={{ 'width': '25%', margin: '4% auto' }} className="" alt="Lightbulb" />
      <Typography variant="h5">Sorry, We cannot load this page <br /> Please contact an administrator</Typography>
    </Box>
  );
};

export default TableauErrorPage;