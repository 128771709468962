import { Grid, MenuItem } from '@material-ui/core';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { TwoDecimalFormat } from 'common/numberFormatCustom.component';
import TRCInputValidator from 'common/form/TRCInputValidator';

const COST_PLANNING_TYPES = [
  { display: 'None', value: 'none' }, 
  { display: 'Budget', value: 'budget' }, 
  { display: 'Cap Move', value: 'capMove' },
];

const CostPlanning = (props) => {
  const { benefit, validateOnBlur, validateOnChange, isReadOnly, modifyBenefit } = props;
  const costPlanningType = benefit.costPlanningType || { display: 'None', value: 'none' };

  useEffect(() => {
    if (benefit.budget != null) {
      benefit.costPlanningType = COST_PLANNING_TYPES.find((cpt) => cpt.value === 'budget');
    } else if (benefit.capMove != null) {
      benefit.costPlanningType = COST_PLANNING_TYPES.find((cpt) => cpt.value === 'capMove');
    }
    else 
      benefit.costPlanningType = COST_PLANNING_TYPES.find((cpt) => cpt.value === 'none');
  }, []); //eslint-disable-line

  return (
    <>
      <Grid item xs={4}>
        <TRCInputValidator
          component={SelectValidator}
          InputProps={{ readOnly: isReadOnly }}
          label="Cost Planning Type"
          value={benefit.costPlanningType}
          onChange={(value) => {
            benefit.costPlanningType = value;
            benefit.budget = null;
            benefit.capMove = null;
            modifyBenefit(benefit);
          }}
          validateOnBlur={validateOnBlur}
          validateOnChange={validateOnChange}
          fullWidth
        >
          {COST_PLANNING_TYPES.map((ct) => (
            <MenuItem key={ct.value} value={ct}>
              {ct.display}
            </MenuItem>
          ))}
        </TRCInputValidator>
      </Grid>
      <Grid item xs={4}>
        { costPlanningType.value !== 'none' &&
          <TRCInputValidator
            component={TextValidator}
            InputProps={{ readOnly: isReadOnly, inputComponent: TwoDecimalFormat }}
            label={costPlanningType.display}
            name="costPlanningValue"
            onChange={(value) => {
              if (costPlanningType.value === 'budget') {
                benefit.budget = value;
                benefit.capMove = null;
              } else {
                benefit.budget = null;
                benefit.capMove = value;
              }
              modifyBenefit(benefit);
            }}
            value={benefit[costPlanningType.value]}
            validateOnBlur={validateOnBlur}
            validateOnChange={validateOnChange}
            required
            fullWidth
          /> 
        }
      </Grid>
    </>
  );
};

CostPlanning.propTypes = {
  benefit: PropTypes.object.isRequired,
  validateOnChange: PropTypes.bool.isRequired,
  validateOnBlur: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  modifyBenefit: PropTypes.func.isRequired,
};

export default CostPlanning;
