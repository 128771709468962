import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class ResponsiveDialog extends React.Component {
  handleClose = (response, type, selectedRow) => {
    this.props.handleResponse(response, type, selectedRow);
  };

  render() {
    const { fullScreen, open, contentText, contentElem, type, selectedRow, title, cancelButtonOnly, maxWidth, PaperProps } = this.props;
    const cancelText = this.props.cancelText || 'Cancel';
    const confirmText = this.props.confirmText || 'Confirm';

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={maxWidth}
          PaperProps={PaperProps}
        >
          {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
          <DialogContent>
            {contentElem}
            <DialogContentText>
              {contentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(false, type, selectedRow)} color="primary">
              {cancelText}
            </Button>
            {!cancelButtonOnly &&
              <Button onClick={() => this.handleClose(true, type, selectedRow)} color="primary" autoFocus>
                {confirmText}
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);