import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { getSelectedClientInformation } from 'utilities/authUtils';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TableauReportWrapper from 'modules/reporting/tableauReportWrapper.component';

const Invoices = (props) => {
  const { setPageTitle } = props;
  useEffect(() => {
    setPageTitle('My Invoices');
  }, [setPageTitle]);

  let environment = process.env.REACT_APP_ANALYTICS_ENV;
  let clientId = getSelectedClientInformation().id;
  let reportPath = '';
  if(clientId === '238') {
    reportPath = 'views/MPCMyInvoices{{env}}/SelectMPCInvoice';
  } else if (clientId === '232' && environment === 'production') {
    reportPath = 'views/MyInvoicesCRH/SelectInvoice';
  } else if (clientId === '232' && (environment === 'dev' || environment === 'test')) {
    reportPath = 'views/MyInvoicesTestCRH/SelectInvoice';
  } else {
    reportPath = 'views/MyInvoices{{env}}/SelectInvoice';
  }

  const tableauReport = reportPath;

  return (
    <Box padding="2rem" height="100%">
      <TableauReportWrapper reportPath={tableauReport} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

Invoices.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
  })(Invoices),
);
