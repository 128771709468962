import { Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getBenefitHistory } from './benefit.actions';
import Dialog from 'common/dialog.component';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import VersionHistoryRows, { prepareHistoryEntries } from 'common/form/versionHistoryRows.component';

const BenefitHistoryDialog = (props) => {
  const { open: propsOpen, onClose: propsOnClose, benefitId, getBenefitHistory: propsGetBenefitHistory } = props;
  const [history, setHistory] = useState(null);
  const [clientHistory, setClientHistory] = useState(null);
  const [masterHistory, setMasterHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    (async () => {
      if (propsOpen && !history) {
        const r = await propsGetBenefitHistory(benefitId);
        const historyObj = r.response;
        if (isEmpty(historyObj)) {
          setErrorMessage('There was a problem loading the history. Please try again');
        } else {
          setClientHistory(prepareHistoryEntries(historyObj.clientHistory));
          if (historyObj.masterHistory)
            setMasterHistory(prepareHistoryEntries(historyObj.masterHistory));
        }
        setIsLoading(false);
      }
    })();
  }, [propsOpen, history, benefitId, propsGetBenefitHistory]);

  return (
    <Dialog
      open={propsOpen}
      title="History"
      maxWidth="lg"
      showTitleClose={true}
      onClose={propsOnClose}
      onExited={() => {
        setErrorMessage(null);
        setHistory(null);
      }}
      content={
        <Box width={700} px={1}>
          {isLoading ? (
            <FullscreenSpinner />
          ) : errorMessage ? (
            <Typography variant="h5" color="error">
              {errorMessage}
            </Typography>
          ) : (
            <>
              <Box mb={1} mt={2} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                Client Updates
              </Box>
              <VersionHistoryRows history={clientHistory} />

              {
                masterHistory &&
                  <>
                    <Box mb={1} mt={2} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                      TRC Updates
                    </Box>
                    <VersionHistoryRows history={masterHistory} />
                  </>
              }

            </>
          )}
        </Box>
      }
    />
  );
};

BenefitHistoryDialog.propTypes = {
  benefitId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  getBenefitHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    getBenefitHistory: state.getBenefitHistory,
  };
};

export default connect(mapStateToProps, {
  getBenefitHistory,
})(BenefitHistoryDialog);