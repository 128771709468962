/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { GET_REPORT_URL_SUCCESS, generateReportURL } from './reporting.actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
//import TableauReport from 'tableau-react-embed';
import useResizeAware from 'react-resize-aware';

import { getSelectedClientInformation, getSelectedDivisions } from 'utilities/authUtils';

import FullscreenSpinner from 'common/fullscreenSpinner.component';
import TableauErrorPage from './tableauErrorPage.component';

//const TABLEAU_REFRESH_INTERVAL_IN_SECONDS = 30 * 60;

const TableauReportWrapper = (props) => {
  const { reportPath, reportParameters } = props;

  const targetRef = useRef();
  const [ sizes] = useResizeAware();
  
  const [reportUrl, setReportUrl] = useState(null);
  const [reportToken, setReportToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState(true);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [tableauError, setTableauError] = useState(false);

  useEffect(() => {
    if (!reportUrl) {
      (async () => {
        setSelectedClient(getSelectedClientInformation());
        setSelectedDivisions(getSelectedDivisions());
  
        const response = await props.generateReportURL({ reportPath });
        // console.log(`generateReportURL() response = `);
        // console.log(response);
        // console.log(`generateReportURL() response.response.ReportUrl = ` + response.response.ReportUrl);
        // console.log(`generateReportURL() response.response.ReportToken = ` + response.response.ReportToken);
        if (response.type === GET_REPORT_URL_SUCCESS && response.response.ReportUrl && response.response.ReportToken){
          setReportToken(response.response.ReportToken);
          setReportUrl(response.response.ReportUrl);
        } else {
          setTableauError(true);
        }
        setIsLoading(false);
      })();
    }
  }, []);

  console.log('rerender');
  return (
    <Box ref={targetRef} position="relative" height="100%">
      {
        tableauError ? (<TableauErrorPage /> ) :
          isLoading || !reportUrl? 
            <FullscreenSpinner /> : 

            <tableau-viz id="tableauViz" 
              src={reportUrl} 
              token={reportToken} 
              height={sizes.height < 750 ? 750: sizes.height} 
              width={sizes.width} 
              toolbar="hidden" 
              hide-tabs="true" 
              hide-edit-button="true" 
              hide-edit-in-desktop-button="true" 
              suppress-default-edit-behavior="true" 
            >
              <custom-parameter name="Clientid" value={selectedClient.id}></custom-parameter>
              <custom-parameter name="Buscodekey1" value={selectedDivisions.join(',')}></custom-parameter>
              {reportParameters && Object.keys(reportParameters).map(key => {
                return (<custom-parameter key={key} name={key} value={reportParameters[key]}></custom-parameter>);
              })}
            </tableau-viz>
            // <TableauReport
            //   url={reportUrl}
            //   token={reportToken}
            //   options={{
            //     height: sizes.height < 750 ? 750: sizes.height,
            //     width: sizes.width,
            //     hideTabs: true,
            //     hideToolbar: false,
            //     toolbarPosition: 'TOP',
            //   }}
            //   refreshSeconds={TABLEAU_REFRESH_INTERVAL_IN_SECONDS}
            //   parameters={{
            //     Clientid: selectedClient.id,
            //     Buscodekey1: selectedDivisions.join(','),
            //     ...(reportParameters ? reportParameters : null),
            //   } 
            //   }
            // />
      }
    </Box>
  );
};

TableauReportWrapper.propTypes = {
  reportPath: PropTypes.string.isRequired,
  reportParameters: PropTypes.object,
};

export default withRouter(
  connect(null, {
    generateReportURL,
  })(TableauReportWrapper),
);
