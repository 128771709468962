import { getAuthToken, getSelectedClientInformation, getSelectedDivisions, logout } from '../utilities/authUtils';
import { jsonToQueryString } from '../utilities/browserUtils';
import Log from 'utilities/log';
import auth from 'auth/auth';

import Symbol from 'es6-symbol';

import { SET_SELF_SERVICE_ERROR_EVENT_ID } from './apiError.types';

let SERVER_URL = process.env.REACT_APP_API_URL || 'https://testrelosourcevvapi.trcgm.com/';

export const BASE_URL = `${SERVER_URL}`;

function callApi(token, endpoint, authenticated, method = 'GET', payload) {
  Log.trace(`Requesting ${endpoint}`);

  // include credentials
  // let config = { headers: {}, credentials: "include" };

  // no credentials
  let config = { headers: {} };

  if (authenticated) {
    Log.trace('Auth required');
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      Log.error('No token available.');
      throw new Error('No token saved!');
    }
  }
  config.method = method;
  if (typeof payload !== 'undefined') {
    //Is this form data?
    if (Object.prototype.toString.call(payload) === '[object FormData]') {
      config.body = payload;
    } else {
      config.body = JSON.stringify(payload);
      config.headers['Content-Type'] = 'application/json; charset=utf-8';
    }
  }

  Log.trace(`Fetching ${BASE_URL}${endpoint}`);
  // add cookie to request
  // config.headers['Cookie'] = '__RequestVerificationToken=3qAJQGsCkbWAevorravGz91GwEmGvXXYDMouEBM_SrOXlLnN5PAfiYJGfxzhSVm57OqM82I0KhyFMJBDQmS3L_5IoVNsA5AhkmZXAKLStyw1';
  return fetch(BASE_URL + endpoint, config)
    .then((response) => {
      if (response.status > 399) {
        Log.error(`Error during fetch to ${BASE_URL + endpoint}: ${response.status}`);
      }
      return response.json()
        .then((json) => {
          Log.trace(`Response body for ${BASE_URL + endpoint}: ${JSON.stringify(json)}`);
          return ({ json, response });
        })
        .catch((e) => {
          return ({
            json: [],
            response,
          });
        });
    })
    .then(({ json, response }) => {
      if (response.status === 401) {
        return Promise.reject({ authenticationRequired: true });
      } else if (response.status === 403) {
        return Promise.reject({ forceOut: true });
      }
      if (!response.ok) {
        return Promise.reject(json);
      }
      let metadata = {};
      if (response.headers.get('X-Total-Count')) {
        metadata.totalCount = Number(response.headers.get('X-Total-Count'));
      }
      return { json, metadata };
    });
}

export const CALL_API = Symbol('Call API');

export default ({ dispatch, getState }) => (next) => (action) => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, types, authenticated, method, payload, options } = callAPI;

  const [requestType, successType, errorType] = types;

  next({
    type: requestType,
    payload,
  });

  let jwt;
  if (authenticated) {
    try {
      jwt = getAuthToken();
    } catch (e) {
      Log.error(`ERROR GETTING TOKEN ${e}`);
    }
  }

  const clientInfo = getSelectedClientInformation();
  const divisions = getSelectedDivisions().join(',');

  if (method === 'GET') {
    let queryParams = {};
    if (options) {
      // client specific
      if (options.isClientSpecific) {
        if (clientInfo && clientInfo.id) {
          queryParams.clientId = clientInfo.id;
        }
      }
      // division specific
      if (options.isDivisionSpecific) {
        if (divisions) {
          queryParams.divisionList = divisions;
        }
      }
      endpoint += jsonToQueryString(queryParams);
    }
  }

  if (method === 'POST') {
    if (options) {
      // client specific
      if (options.isClientSpecific) {
        if (clientInfo && clientInfo.id) {
          payload = { 'ClientID': clientInfo.id, ...payload };
        }
      }
      // division specific
      if (options.isDivisionSpecific) {
        if (divisions) {
          payload = { 'DivisionList': divisions, ...payload };
        }
      }
    }
  }

  // Passing the authenticated boolean back in our data will let us distinguish
  // between normal and secret quotes
  return callApi(jwt, endpoint, authenticated, method, payload).then(
    (success) => {
      return next({
        response: success.json,
        metadata: success.metadata,
        authenticated,
        payload,
        type: successType,
        options,
      });
    },
    (error) => {
      let resp = {
        type: errorType,
        hasErrors: true
      };
      if (error.messages) {
        resp.messages = error.messages;
      } else if (error.message) {        
        resp.messages = error.message;
      }
      else if (error !== undefined) {        
        resp.messages = error;
      } else {
        resp.messages = ['There was an error.'];
      }
      
      //check if there are any form errors. If so, add them to resp
      if(error.formErrors !== undefined) resp.formErrors = JSON.parse(error.formErrors);

      dispatch({ type: SET_SELF_SERVICE_ERROR_EVENT_ID, payload: error.eventId });


      if (error.authenticationRequired) {
        auth.renewSession();
      } else if (error.forceOut) {
        logout();
      }
      return next(resp);
    },
  );

};
