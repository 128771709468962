import { CALL_API } from '../../middleware/api';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const changePassword = (payload) => {
  return {
    [CALL_API]: {
      types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
      authenticated: true,
      endpoint: 'Account/ChangeUserPassword',
      method: 'POST',
      payload,
    },
  };
};

export const submitChangePasswordRequest = (emailAddress) => {
  return {
    [CALL_API]: {
      types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
      authenticated: true,
      endpoint: 'Client/SubmitChangePasswordRequest/submitchangepassword',
      method: 'POST',
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`,
      },
      emailAddress,
    },
  };
    
}
