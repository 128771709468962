import {  Button, Grid, Switch, TableCell, TableRow  } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TOAST_MESSAGE_SEVERITY_ERROR, showToast } from 'modules/layout/layout.actions';
import { UPDATE_POLICY_FAILURE } from './policy.types';
import { getDelimitedValue } from 'utilities/currencyFormatter';
import { getVersionFromSummary } from './summaryUtil';
import { updatePolicy } from './policy.actions';
import SummaryMetaData from '../../common/summaryMetadata.component';

const PolicySummaryRow = (props) => {
  const { summary, onViewPolicy, onClonePolicy } = props;

  const summaryVersion = getVersionFromSummary(summary);
  
  const [enabled, setEnabled] = useState(summary.enabled);
  const [isUpdating, setIsUpdating] = useState(false);

  const toggleEnabled = async () => {
    setIsUpdating(true);
    const action = await props.updatePolicy({ id: summary.id, enabled: !enabled });
    if (action.type === UPDATE_POLICY_FAILURE) {
      props.showToast('Failed to update policy', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
    setEnabled(!enabled);
    setIsUpdating(false);
  };

  return (
    <TableRow key={summaryVersion.id}>
      <TableCell>{summaryVersion.name}</TableCell>
      <TableCell align="right">{
        summaryVersion.budget != null ? getDelimitedValue(summaryVersion.budget, 0, 3) : ''
      }</TableCell>
      <TableCell><SummaryMetaData summary={summary} /></TableCell>
      <TableCell>
        <Grid container alignItems="center">
          <Grid item>Active</Grid>
          <Grid item>
            <Switch
              checked={enabled}
              onChange={toggleEnabled}
              disabled={isUpdating}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          onClick={() => { onViewPolicy(summary); }}
        >
          View
        </Button>
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          onClick={() => { onClonePolicy(summary); }}
        >
          Clone
        </Button>
      </TableCell>
    </TableRow>
  );
};

PolicySummaryRow.propTypes = {
  updatePolicy: PropTypes.func.isRequired,
  summary: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
  onViewPolicy: PropTypes.func.isRequired,
  onClonePolicy: PropTypes.func.isRequired,
};

export default connect(
  null,
  { updatePolicy, showToast },
)(PolicySummaryRow);
