import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Duration, Instant, LocalDateTime } from 'js-joda';
import { useRefCallback } from 'utilities/formUtils';
import CardOptionCarousel from './cardOptionCarousel.component';
import Message from './message.component';
import QuickRepliesContainer from './quickReplies.component';
import ReplyingPlaceholder from './replyingPlaceholder.component';

const MessageList = (props) =>  {
  const { messages, quickReplies, cardOptions, currentUser, isReplying, quickReplyChosen, optionChosen } = props;

  const [messagesEndRef, setMessagesEndRef] = useRefCallback();

  useEffect(() => {
    if (messagesEndRef) messagesEndRef.scrollIntoView({ behavior: 'smooth' });
  }, [messages, quickReplies, cardOptions, messagesEndRef]);

  const renderMessages = () => {
    let sortedMessages = messages;
    sortedMessages.sort((a, b) => a.timestamp - b.timestamp);

    const messagesDisplay = sortedMessages.map((current, i) => {
      const previous = messages[i - 1];
      const next = messages[i + 1];
      const isUser = current.author === currentUser;

      const startsSequence = previous ? previous.author !== current.author : false;
      const endsSequence = next ? next.author !== current.author : true;

      const showTimestamp = next && Duration.between(LocalDateTime.ofInstant(Instant.ofEpochMilli(current.timestamp)), LocalDateTime.ofInstant(Instant.ofEpochMilli(current.timestamp))).toHours() >= 1;

      return (
        <Message
          key={i}
          isUser={isUser}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          data={current}
          showTimestamp={showTimestamp}
        />
      );
    });

    return messagesDisplay;
  };

  const renderQuickReplies = () => {
    return <QuickRepliesContainer quickReplies={quickReplies} onReplyChosen={quickReplyChosen} />;
  };

  const renderCardOptions = () => {
    return <CardOptionCarousel cardOptions={cardOptions} onOptionChosen={optionChosen} />;
  };

  return (
    <Box height={'60vh'} marginBottom={1} display="flex" flexDirection="column" justifyContent="flex-end">
      <Box width="100%" className="overflow-y">
        {renderMessages()}
        {cardOptions && renderCardOptions()}
        {quickReplies && renderQuickReplies()}
        {isReplying && <ReplyingPlaceholder />}
        <div ref={setMessagesEndRef} />
      </Box>
    </Box>
  );
};

MessageList.propTypes = {
  messages : PropTypes.array.isRequired,
  quickReplies: PropTypes.array,
  cardOptions: PropTypes.array,
  currentUser: PropTypes.string.isRequired,
  isReplying: PropTypes.bool.isRequired,
  quickReplyChosen: PropTypes.func.isRequired,
  optionChosen: PropTypes.func.isRequired,
};

export default MessageList;