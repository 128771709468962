import { CALL_API } from '../../middleware/api';

export const GET_PENDING_AUTHORIZATIONS_REQUEST = 'GET_PENDING_AUTHORIZATIONS_REQUEST';
export const GET_PENDING_AUTHORIZATIONS_SUCCESS = 'GET_PENDING_AUTHORIZATIONS_SUCCESS';
export const GET_PENDING_AUTHORIZATIONS_FAILURE = 'GET_PENDING_AUTHORIZATIONS_FAILURE';

export const GET_SUBMITTED_AUTHS_REQUEST = 'GET_SUBMITTED_AUTHS_REQUEST';
export const GET_SUBMITTED_AUTHS_SUCCESS = 'GET_SUBMITTED_AUTHS_SUCCESS';
export const GET_SUBMITTED_AUTHS_FAILURE = 'GET_SUBMITTED_AUTHS_FAILURE';

export const GET_PENDING_AUTHORIZATION_REQUEST = 'GET_PENDING_AUTHORIZATION_REQUEST';
export const GET_PENDING_AUTHORIZATION_SUCCESS = 'GET_PENDING_AUTHORIZATION_SUCCESS';
export const GET_PENDING_AUTHORIZATION_FAILURE = 'GET_PENDING_AUTHORIZATION_FAILURE';

export const GET_PENDING_APPROVAL_AUTHORIZATIONS_REQUEST = 'GET_PENDING_APPROVAL_AUTHORIZATION_REQUEST';
export const GET_PENDING_APPROVAL_AUTHORIZATIONS_SUCCESS = 'GET_PENDING_APPROVAL_AUTHORIZATION_SUCCESS';
export const GET_PENDING_APPROVAL_AUTHORIZATIONS_FAILURE = 'GET_PENDING_APPROVAL_AUTHORIZATION_FAILURE';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const GET_AUTH_CF_REQUEST = 'GET_AUTH_CF_REQUEST';
export const GET_AUTH_CF_SUCCESS = 'GET_AUTH_CF_SUCCESS';
export const GET_AUTH_CF_FAILURE = 'GET_AUTH_CF_FAILURE';

export const GET_CLIENT_FIELDS_REQUEST = 'GET_CLIENT_FIELDS_REQUEST';
export const GET_CLIENT_FIELDS_SUCCESS = 'GET_CLIENT_FIELDS_SUCCESS';
export const GET_CLIENT_FIELDS_FAILURE = 'GET_CLIENT_FIELDS_FAILURE';

export const GET_CLIENT_FIELDVAL_REQUEST = 'GET_CLIENT_FIELDVAL_REQUEST';
export const GET_CLIENT_FIELDVAL_SUCCESS = 'GET_CLIENT_FIELDVAL_SUCCESS';
export const GET_CLIENT_FIELDVAL_FAILURE = 'GET_CLIENT_FIELDS_FAILURE';

export const GET_RELO_POLICY_REQUEST = 'GET_RELO_POLICY_REQUEST';
export const GET_RELO_POLICY_SUCCESS = 'GET_RELO_POLICY_SUCCESS';
export const GET_RELO_POLICY_FAILURE = 'GET_RELO_POLICY_FAILURE';

export const GET_BUS_CODES_REQUEST = 'GET_BUS_CODES_REQUEST';
export const GET_BUS_CODES_SUCCESS = 'GET_BUS_CODES_SUCCESS';
export const GET_BUS_CODES_FAILURE = 'GET_BUS_CODES_FAILURE';

export const UPDATE_AUTH_REQUEST = 'UPDATE_AUTH_REQUEST';
export const UPDATE_AUTH_SUCCESS = 'UPDATE_AUTH_SUCCESS';
export const UPDATE_AUTH_FAILURE = 'UPDATE_AUTH_FAILURE';

export const GET_AUTH_DOCS_REQUEST = 'GET_AUTH_DOCS_REQUEST';
export const GET_AUTH_DOCS_SUCCESS = 'GET_AUTH_DOCS_SUCCESS';
export const GET_AUTH_DOCS_FAILURE = 'GET_AUTH_DOCS_FAILURE';

export const INSERT_AUTH_REQUEST = 'INSERT_AUTH_REQUEST';
export const INSERT_AUTH_SUCCESS = 'INSERT_AUTH_SUCCESS';
export const INSERT_AUTH_FAILURE = 'INSERT_AUTH_FAILURE';

export const SUBMIT_AUTH_REQUEST = 'SUBMIT_AUTH_REQUEST';
export const SUBMIT_AUTH_SUCCESS = 'SUBMIT_AUTH_SUCCESS';
export const SUBMIT_AUTH_FAILURE = 'SUBMIT_AUTH_FAILURE';

export const SUBMIT_AUTH_FOR_APPROVAL_REQUEST = 'SUBMIT_AUTH_FOR_APPROVAL_REQUEST';
export const SUBMIT_AUTH_FOR_APPROVAL_SUCCESS = 'SUBMIT_AUTH_FOR_APPROVAL_SUCCESS';
export const SUBMIT_AUTH_FOR_APPROVAL_FAILURE = 'SUBMIT_AUTH_FOR_APPROVAL_FAILURE';

export const GET_AUTH_COUNT_PROGRAM_REQUEST = 'GET_AUTH_COUNT_PROGRAM_REQUEST';
export const GET_AUTH_COUNT_PROGRAM_SUCCESS = 'GET_AUTH_COUNT_PROGRAM_SUCCESS';
export const GET_AUTH_COUNT_PROGRAM_FAILURE = 'GET_AUTH_COUNT_PROGRAM_FAILURE';

export const GET_AUTH_COUNT_MONTH_REQUEST = 'GET_AUTH_COUNT_MONTH_REQUEST';
export const GET_AUTH_COUNT_MONTH_SUCCESS = 'GET_AUTH_COUNT_MONTH_SUCCESS';
export const GET_AUTH_COUNT_MONTH_FAILURE = 'GET_AUTH_COUNT_MONTH_FAILURE';

export const CANCEL_AUTHORIZATION_REQUEST = 'CANCEL_AUTHORIZATION_REQUEST';
export const CANCEL_AUTHORIZATION_SUCCESS = 'CANCEL_AUTHORIZATION_SUCCESS';
export const CANCEL_AUTHORIZATION_FAILURE = 'CANCEL_AUTHORIZATION_FAILURE';

export const GET_STATES_REQUEST = 'GET_STATES_REQUEST';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';

export const GET_DUPLICATE_AUTHS_REQUEST = 'GET_DUPLICATE_AUTHS_REQUEST'; 
export const GET_DUPLICATE_AUTHS_SUCCESS = 'GET_DUPLICATE_AUTHS_SUCCESS'; 
export const GET_DUPLICATE_AUTHS_FAILURE = 'GET_DUPLICATE_AUTHS_FAILURE';

export const FAILURE_TYPES = [GET_AUTH_COUNT_PROGRAM_FAILURE, GET_AUTH_COUNT_MONTH_FAILURE, GET_SUBMITTED_AUTHS_FAILURE, GET_PENDING_AUTHORIZATIONS_FAILURE, GET_PENDING_APPROVAL_AUTHORIZATIONS_FAILURE];

export const getPendingAuthorizations = () => {
  return {
    [CALL_API]: {
      types: [GET_PENDING_AUTHORIZATIONS_REQUEST, GET_PENDING_AUTHORIZATIONS_SUCCESS, GET_PENDING_AUTHORIZATIONS_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetAuthorizationsPending',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getApprovalPendingAuthorizations = () => {
  return {
    [CALL_API]: {
      types: [GET_PENDING_APPROVAL_AUTHORIZATIONS_REQUEST, GET_PENDING_APPROVAL_AUTHORIZATIONS_SUCCESS, GET_PENDING_APPROVAL_AUTHORIZATIONS_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetAuthorizationsPendingApproval',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getSubmittedAuthorizations = () => {
  return {
    [CALL_API]: {
      types: [GET_SUBMITTED_AUTHS_REQUEST, GET_SUBMITTED_AUTHS_SUCCESS, GET_SUBMITTED_AUTHS_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetAuthorizationsSubmitted',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getPendingAuthorization = (id) => {
  return {
    [CALL_API]: {
      types: [GET_PENDING_AUTHORIZATION_REQUEST, GET_PENDING_AUTHORIZATION_SUCCESS, GET_PENDING_AUTHORIZATION_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetPendingAuthorization',
      method: 'POST',
      payload: { AuthorizationID: id },
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getCountries = () => {
  return {
    [CALL_API]: {
      types: [GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetCountries',
      method: 'GET',
    },
  };
};

export const getStates = () => {
  return {
    [CALL_API]: {
      types: [GET_STATES_REQUEST, GET_STATES_SUCCESS, GET_STATES_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetStates',
      method: 'GET',
    },
  };
};

export const getAuthorizationCustomFields = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTH_CF_REQUEST, GET_AUTH_CF_SUCCESS, GET_AUTH_CF_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetAuthorizationCustomFields',
      method: 'POST',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getClientSpecificFields = (payload) => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_FIELDS_REQUEST, GET_CLIENT_FIELDS_SUCCESS, GET_CLIENT_FIELDS_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetClientSpecificFields',
      method: 'POST',
      payload,
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getClientFieldListValues = () => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_FIELDVAL_REQUEST, GET_CLIENT_FIELDVAL_SUCCESS, GET_CLIENT_FIELDVAL_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetClientFieldListValues',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getReloPolicies = () => {
  return {
    [CALL_API]: {
      types: [GET_RELO_POLICY_REQUEST, GET_RELO_POLICY_SUCCESS, GET_RELO_POLICY_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetReloPolicies',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getBusCodes = () => {
  return {
    [CALL_API]: {
      types: [GET_BUS_CODES_REQUEST, GET_BUS_CODES_SUCCESS, GET_BUS_CODES_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetBusCodes',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const updateAuthorization = (Data) => {
  return {
    [CALL_API]: {
      types: [UPDATE_AUTH_REQUEST, UPDATE_AUTH_SUCCESS, UPDATE_AUTH_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/UpdateAuthorization',
      method: 'POST',
      payload: Data,
    },
  };
};

export const getAuthDocuments = (AuthorizationID) => {
  return {
    [CALL_API]: {
      types: [GET_AUTH_DOCS_REQUEST, GET_AUTH_DOCS_SUCCESS, GET_AUTH_DOCS_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/GetDocuments',
      method: 'POST',
      payload: { AuthorizationID },
    },
  };
};

export const insertAuthorization = (Data) => {
  return {
    [CALL_API]: {
      types: [INSERT_AUTH_REQUEST, INSERT_AUTH_SUCCESS, INSERT_AUTH_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/InsertAuthorization',
      method: 'POST',
      payload: Data,
    },
  };
};

export const getDuplicateAuths = (Data) => {  
  return {
    [CALL_API]: {
      types: [GET_DUPLICATE_AUTHS_REQUEST, GET_DUPLICATE_AUTHS_SUCCESS, GET_DUPLICATE_AUTHS_FAILURE],
      authenticated: true,
      endpoint: `Authorization/GetDuplicateAuths?clientID=${Data.clientID}&lastName=${Data.lastName}&authorizationID=${Data.authorizationID}`,
      method: 'GET',    
    },
  };
};

export const submitAuthorization = (Data) => {
  return {
    [CALL_API]: {
      types: [SUBMIT_AUTH_REQUEST, SUBMIT_AUTH_SUCCESS, SUBMIT_AUTH_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/SubmitAuthorization',
      method: 'POST',
      payload: Data,
    },
  };
};

export const submitAuthorizationForApproval = (Data) => {
  return {
    [CALL_API]: {
      types: [SUBMIT_AUTH_FOR_APPROVAL_REQUEST, SUBMIT_AUTH_FOR_APPROVAL_SUCCESS, SUBMIT_AUTH_FOR_APPROVAL_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/SubmitAuthorizationForApproval',
      method: 'POST',
      payload: Data,
    },
  };
};

export const getAuthorizationCountByProgram = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTH_COUNT_PROGRAM_REQUEST, GET_AUTH_COUNT_PROGRAM_SUCCESS, GET_AUTH_COUNT_PROGRAM_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetAuthorizationByProgram',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getAuthorizationCountByMonth = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTH_COUNT_MONTH_REQUEST, GET_AUTH_COUNT_MONTH_SUCCESS, GET_AUTH_COUNT_MONTH_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GetAuthorizationByMonth',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const cancelAuthorization = (id) => {
  return {
    [CALL_API]: {
      types: [CANCEL_AUTHORIZATION_REQUEST, CANCEL_AUTHORIZATION_SUCCESS, CANCEL_AUTHORIZATION_FAILURE],
      authenticated: true,
      endpoint: 'Authorization/CancelAuthorization',
      method: 'POST',
      payload: { AuthorizationID: id },
    },
  };
};