import * as Sentry from '@sentry/react';
import * as firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

export const createImage = async (file) => {
  const storageRef = firebase.storage().ref();
  const imageId = uuidv4();
  const imageRef = storageRef.child(`/benefit_images/${imageId}`);
  await imageRef.put(file);
  return imageId;
};

export const downloadImage = (imageId) => {
  try {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`/benefit_images/${imageId}`);
    return imageRef.getDownloadURL();
  } catch (e) {
    Sentry.captureException(new Error(`Download Image Error: ${JSON.stringify(e)}`));
    throw e;
  }
};
