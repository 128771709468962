import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { RequestStatusEnum } from 'index.types';
import PolicyContext from '../policyContext';
import ProgressBar from 'common/progressBar.component';
import Spinner from 'common/logoSpinner.component';

const BotUpdateProgress = (props) => {
  const [updateBotProgress, setUpdateBotProgress] = useState(0);
  const [updateBotProgressMessage, setUpdateBotProgressMessage] = useState('Loading...');

  const { setShowBotUpdateProgress } = props;
  const { updateBotStatus } = useContext(PolicyContext);

  useEffect(() => {

    if (updateBotStatus !== RequestStatusEnum.PENDING && (updateBotProgress === 0)) {
      return;
    }

    const interval = 2000 - (updateBotProgress < 60 ? (2000 * (updateBotProgress / 100)) : 700);
    const randomProgressFactor = Math.floor(Math.random() * (5 - 1 + 1) + 1) * 0.01;

    switch (true){
      case (updateBotProgress < 10):
        setUpdateBotProgressMessage('Cleaning up old versions...');
        break;
      case (updateBotProgress >= 10 && updateBotProgress < 20): 
        setUpdateBotProgressMessage('Creating Policy...');
        break;
      case (updateBotProgress >= 20 && updateBotProgress < 30):
        setUpdateBotProgressMessage('Adding Benefits...');
        break;
      case (updateBotProgress >= 30 && updateBotProgress < 40):
        setUpdateBotProgressMessage('Adding Questions...');
        break;
      case (updateBotProgress >= 40 && updateBotProgress < 50):
        setUpdateBotProgressMessage('Adding Answers...');
        break;
      case (updateBotProgress >= 50 && updateBotProgress < 60):
        setUpdateBotProgressMessage('Adding Options...');
        break;
      case (updateBotProgress >= 60 && updateBotProgress < 100):
        setUpdateBotProgressMessage('Training Mobi...');
        break;
      case (updateBotProgress === 100):
        setUpdateBotProgressMessage('All Done!');
        setTimeout(() => {
          setShowBotUpdateProgress(false);
          setUpdateBotProgress(0); 
        }, 1000);
        break;
      default:
        break;
    }

    if (updateBotStatus === RequestStatusEnum.SUCCESS)
      setUpdateBotProgress(100);
    else {
      setTimeout(() => {
        setUpdateBotProgress((prevProgress) => (prevProgress + ((100 - prevProgress) * randomProgressFactor)));
      }, interval);
    }
    
  }, [updateBotProgress, updateBotStatus, setShowBotUpdateProgress]);

  return (
    <Grid container direction="column">
      <Grid item container direction="row" alignItems="center">
        <Grid item><Spinner /></Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">{updateBotProgressMessage}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <ProgressBar variant="determinate" value={updateBotProgress} />
      </Grid>
    </Grid>
  );
};

BotUpdateProgress.propTypes = {
  setShowBotUpdateProgress: PropTypes.func.isRequired,
};

export default BotUpdateProgress;