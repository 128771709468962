import { CALL_API } from '../../middleware/api';

export const GET_CLIENT_FIELDVAL_REQUEST = 'GET_CLIENT_FIELDVAL_REQUEST';
export const GET_CLIENT_FIELDVAL_SUCCESS = 'GET_CLIENT_FIELDVAL_SUCCESS';
export const GET_CLIENT_FIELDVAL_FAILURE = 'GET_CLIENT_FIELDS_FAILURE';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const GET_BUS_CODES_REQUEST = 'GET_BUS_CODES_REQUEST';
export const GET_BUS_CODES_SUCCESS = 'GET_BUS_CODES_SUCCESS';
export const GET_BUS_CODES_FAILURE = 'GET_BUS_CODES_FAILURE';

export const RENDER_HTML_REPORT_REQUEST = 'RENDER_HTML_REPORT_REQUEST';
export const RENDER_HTML_REPORT_SUCCESS = 'RENDER_HTML_REPORT_SUCCESS';
export const RENDER_HTML_REPORT_FAILURE = 'RENDER_HTML_REPORT_FAILURE';

export const GET_REPORT_URL_REQUEST = 'GET_REPORT_URL_REQUEST';
export const GET_REPORT_URL_SUCCESS = 'GET_REPORT_URL_SUCCESS';
export const GET_REPORT_URL_FAILURE = 'GET_REPORT_URL_FAILURE';


export const getClientFieldListValues = () => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_FIELDVAL_REQUEST, GET_CLIENT_FIELDVAL_SUCCESS, GET_CLIENT_FIELDVAL_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetClientFieldListValues',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getCountries = () => {
  return {
    [CALL_API]: {
      types: [GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetCountries',
      method: 'GET',
    },
  };
};

export const getBusCodes = () => {
  return {
    [CALL_API]: {
      types: [GET_BUS_CODES_REQUEST, GET_BUS_CODES_SUCCESS, GET_BUS_CODES_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetBusCodes',
      method: 'GET',
      options: {
        isClientSpecific: true,
      },
    },
  };
};

export const renderHtmlReport = (payload) => {
  return {
    [CALL_API]: {
      types: [RENDER_HTML_REPORT_REQUEST, RENDER_HTML_REPORT_SUCCESS, RENDER_HTML_REPORT_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/RenderHtmlReport',
      method: 'POST',
      payload,
    },
  };
};

export const generateReportURL = (payload) => {
  return {
    [CALL_API]: {
      types: [GET_REPORT_URL_REQUEST, GET_REPORT_URL_SUCCESS, GET_REPORT_URL_FAILURE],
      authenticated: true,
      endpoint: 'Reporting/GenerateReportURL',
      method: 'POST',
      payload,
    },
  };
};