import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';

import { EditCommandCell } from '../../common/tableEditCommandCell';
import { dateFormatter } from '../../utilities/dateFormatter';
import ResponsiveDialog from '../../common/responsiveDialog.component';

import { Getter } from '@devexpress/dx-react-core';

import {
  DataTypeProvider,
  EditingState,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { getSelectedClientInformation } from 'utilities/authUtils';

const DIALOG_CANCEL = 'DIALOG_CANCEL';

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

const VipTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={(params) => {
        return params.row.VIP ? <FontAwesomeIcon icon="check" /> : '';
      }}
      {...props}
    />
  );
};

const getRowId = (row) => row.AuthorizationID;

class PendingAuthorizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'VIP', title: 'VIP' },
        { name: 'FirstName', title: 'First Name' },
        { name: 'LastName', title: 'Last Name' },
        { name: 'LastUpdated', title: 'Last Updated' },
      ],
      tableColumnExtensions: [{ columnName: 'VIP', width: 100 }],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['LastUpdated'],
      vipColumns: ['VIP'],
      sorting: [{ columnName: 'LastUpdated', direction: 'desc' }],
      modal: {
        modalConfirmOpen: false,
        contentText: '',
        type: null,
        selectedRow: null,
      },
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.TableRow = ({ row, handleEditAuthorization, ...restProps }) => {
      return (
        <Table.Row
          {...restProps}
          onClick={() => props.handleEditAuthorization(row.AuthorizationID)}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    };

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.commitChanges = this.commitChanges.bind(this);
    this.cancelAuth = this.cancelAuth.bind(this);
    this.handleModalResponse = this.handleModalResponse.bind(this);
  }

  cancelAuth(row) {
    this.setState({
      modal: {
        modalConfirmOpen: true,
        contentText: 'Cancel This Authorization?',
        type: DIALOG_CANCEL,
        selectedRow: row,
        title: 'Cancel Authorization',
      },
    });
  }

  // required for editing state
  commitChanges() {}

  handleModalResponse(response, type, row) {
    switch (type) {
      case DIALOG_CANCEL:
        if (response) {
          // do cancel
          this.props.handleCancelAuthorization(row.row.AuthorizationID);
        } else {
          // do nothing
        }
        break;

      default:
        break;
    }
    this.setState({ modal: { modalConfirmOpen: false } });
  }

  actionsCell = (props) => {
    return (
      <TableCell>
        <EditCommandCell
          id="cancel"
          text="Cancel"
          style={{ border: 'none' }}
          onExecute={this.cancelAuth}
          tableRow={props.tableRow}
        >
          {React.Children.toArray(props.children)}
        </EditCommandCell>
      </TableCell>
    );
  };

  render() {
    const { rows, handleNewAuthorization, allowAddNew } = this.props;
    const {
      columns,
      pageSize,
      pageSizes,
      currentPage,
      dateColumns,
      vipColumns,
      sorting,
      tableColumnExtensions,
    } = this.state;
    const { modalConfirmOpen, contentText, type, selectedRow, title } =
      this.state.modal;

    return (
      <div>
        {(allowAddNew && getSelectedClientInformation().id !== '238')  && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleNewAuthorization}
            style={{ float: 'right', marginTop: '1rem', marginRight: '1rem' }}
          >
            <Add />
            <Hidden mdUp>New</Hidden>
            <Hidden smDown>Add New</Hidden>
          </Button>
        )}

        <ResponsiveDialog
          fullScreen={false}
          open={modalConfirmOpen}
          contentText={contentText}
          type={type}
          handleResponse={this.handleModalResponse}
          selectedRow={selectedRow}
          title={title}
          cancelText={'No'}
          confirmText={'Yes'}
        />

        <Paper style={{ padding: '0.5rem' }}>
          <div style={{ padding: '0.3rem', fontSize: '1.25rem' }}>
            Pending Authorizations
          </div>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <DateTypeProvider for={dateColumns} />
            <VipTypeProvider for={vipColumns} />
            <EditingState
              onEditingRowIdsChange={this.changeEditingRowIds}
              onCommitChanges={this.commitChanges}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedSorting />
            <IntegratedPaging />
            <Table
              rowComponent={this.TableRow}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow showSortingControls />
            <TableEditColumn
              style={{ marginRight: '2rem' }}
              cellComponent={this.actionsCell}
            />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                return [
                  ...tableColumns.filter(
                    (c) => c.type !== TableEditColumn.COLUMN_TYPE
                  ),
                  {
                    key: 'editCommand',
                    type: TableEditColumn.COLUMN_TYPE,
                    width: 150,
                  },
                ];
              }}
            />
            {rows && rows.length > 5 && <PagingPanel pageSizes={pageSizes} />}
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default PendingAuthorizations;
