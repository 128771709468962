import { initLocalStorageDivisions } from '../../utilities/authUtils';
import Immutable from 'immutable';

import {
  CLEAR_BACK_BUTTON_PATH,
  GET_CLIENT_DIVISIONS_FAILURE,
  GET_CLIENT_DIVISIONS_REQUEST,
  GET_CLIENT_DIVISIONS_SUCCESS,
  GET_INEO_COST_ESTIMATOR_URL_FAILURE,
  GET_INEO_COST_ESTIMATOR_URL_REQUEST, 
  GET_INEO_COST_ESTIMATOR_URL_SUCCESS,
  GET_NS_URL_FAILURE,
  GET_NS_URL_REQUEST,
  GET_NS_URL_SUCCESS,
  HIDE_TOAST_MESSAGE,
  SET_BACK_BUTTON_PATH,
  SET_CONTENT_WIDTH,
  SET_DRAWER_OPEN,
  SET_MOBILE_DRAWER_OPEN, 
  SET_PAGE_TITLE, 
  SET_PENDING_EXCEPTION_COUNT,
  SET_PENDING_TRANSFEREE_COUNT, SET_RESPONSIVE_BREAKPOINT,
  SET_USER_INFO, SHOW_TOAST_MESSAGE, TOAST_MESSAGE_SEVERITY_INFO,
} from './layout.actions';

const initialState = Immutable.Map({
  pageTitle: 'Dashboard',
  responsiveBreakpoint: '',
  numPendingTransferees: 0,
  numPendingExceptions: 0,
  shouldShowToast: false,
  toastMessage: '',
  toastSeverity: TOAST_MESSAGE_SEVERITY_INFO,
  toastPosition: '0',
  userMustDismissToast: null,
  drawerOpen: true,
  mobileDrawerOpen: false,
  userInformation: null,
  clientDivisions: [],
  isLoadingDivisions: true,
  backButtonPath: null,
  showLoadingOverlay: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return state.set('pageTitle', action.payload);

    case SET_CONTENT_WIDTH:
      return state.set('contentWidth', action.payload);

    case SET_PENDING_TRANSFEREE_COUNT:
      return state.set('numPendingTransferees', action.payload);

    case SET_PENDING_EXCEPTION_COUNT:
      return state.set('numPendingExceptions', action.payload);

    case SET_RESPONSIVE_BREAKPOINT: {
      return state.set('responsiveBreakpoint', action.payload);
    }

    case SET_DRAWER_OPEN: {
      return state.set('drawerOpen', action.payload);
    }

    case SET_MOBILE_DRAWER_OPEN: {
      return state.set('mobileDrawerOpen', action.payload);
    }

    case SHOW_TOAST_MESSAGE:
      return state.set('shouldShowToast', true)
        .set('toastMessage', action.message)
        .set('userMustDismissToast', action.userMustDismiss)
        .set('toastSeverity', action.severity|| TOAST_MESSAGE_SEVERITY_INFO);

    case HIDE_TOAST_MESSAGE:
      return state.set('shouldShowToast', false);

    case SET_USER_INFO:
      return state.set('userInformation', action.payload);

    case GET_CLIENT_DIVISIONS_REQUEST:
      return state.set('isLoadingDivisions', true);

    case GET_CLIENT_DIVISIONS_SUCCESS: {
      let clientDivisions = action.response;
      // init client divisions in localstorage before completing action
      initLocalStorageDivisions(clientDivisions);
      return state.set('clientDivisions', clientDivisions).set('isLoading', false).set('isLoadingDivisions', false);
    }

    case GET_INEO_COST_ESTIMATOR_URL_REQUEST:
    case GET_NS_URL_REQUEST:
      return state.set('showLoadingOverlay', true);

    case GET_CLIENT_DIVISIONS_FAILURE:
      return state.set('isLoading', false);

    case GET_INEO_COST_ESTIMATOR_URL_FAILURE:
    case GET_INEO_COST_ESTIMATOR_URL_SUCCESS:
    case GET_NS_URL_SUCCESS:
    case GET_NS_URL_FAILURE:
      return state.set('showLoadingOverlay', false);

    case SET_BACK_BUTTON_PATH:
      return state.set('backButtonPath', action.payload);

    case CLEAR_BACK_BUTTON_PATH:
      return state.set('backButtonPath', null);

    default:
      return state;
  }
}
