import { connect } from 'react-redux';
import React, { Component } from 'react';

import { getBlogData } from './dashboard.actions';
import { getBlogImage } from '../../utilities/blogUtils';
import { withStyles } from '@material-ui/core/styles';
import LogoSpinner from '../../common/logoSpinner.component';
import Paper from '@material-ui/core/Paper';
import SocialIcons from './socialIcons.component';

import blogCoverImage from '../../images/PaperAirplanes.png';

const TRC_BLOG_FEED_URL = 'https://trcglobalmobility.com/blog/feed/';
const TRC_BLOG_URL = 'https://trcglobalmobility.com/blog/';

const styles = {
  cover: {
    position: 'relative',
    height: '12rem',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  coverTextBox: {
    position: 'absolute',
    width: '10rem',
    color: 'white',
    left: '1rem',
    top: '0.5rem',
    height: '7rem',
    padding: '1rem',
  },
  coverText: {
    marginBottom: '0.25rem',
    fontSize: '1.75rem',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
  feedContainer: {
    marginLeft: '0.5rem',
    padding: '0.25rem',
  },
  feedItem: {
    fontSize: '1rem',
    marginBottom: '1rem',
    width: '100%',
    color: '#8a8a8a',
  },
  socialIcons: {
    position: 'absolute',
    left: 5,
    bottom: 5,
  },
};

class Blog extends Component {
  componentWillMount() {
    this.props.getBlogData(TRC_BLOG_FEED_URL);
  }

  navigate = (url) => {
    window.open(url, '_blank');
  }

  render() {
    const { loading, feed, err, classes } = this.props;
    let items = [];
    // limit to first 3 items
    if (feed) {
      items = feed.items.slice(0, 3);
    }

    return (
      <Paper style={{ height: '100%', borderRadius: '0.5rem' }}>
        <div className={classes.cover} style={{ backgroundImage: `url(${blogCoverImage})` }}>
          <div className={classes.coverTextBox}>
            <div className={classes.coverText}>TALENT</div>
            <div className={classes.coverText}>MOBILITY</div>
            <div className={classes.coverText}>REALIZED</div>
          </div>
          <div className={classes.socialIcons}>
            <SocialIcons />
          </div>
        </div>
        <div style={{ padding: '0.25rem', height: 'calc(100% - 13rem)', overflow: 'scroll' }}>
          {loading && !items.length > 0 &&
            <div style={{ margin: '1rem' }}>
              <LogoSpinner />
            </div>
          }
          {err &&
            <div>Could not retrieve blog data</div>
          }
          {items.length > 0 &&
            <div>
              <div className={classes.feedContainer}>
                {items.map((item, i) => {
                  const imageAttribs = getBlogImage(item['content:encoded']);
                  return (
                    <div style={{ display: 'inline-flex' }} key={i}>
                      {imageAttribs &&
                        <div style={{ marginBottom: '0.5rem', flex: '0 1 auto' }}>
                          <img src={imageAttribs.src} alt={imageAttribs.alt} style={{ height: '6rem', width: '6rem', cursor: 'pointer' }} onClick={() => this.navigate(item.link)} />
                        </div>
                      }
                      <div style={{ marginLeft: '1rem', flex: '0 1 auto' }}>
                        {/* eslint-disable-next-line */}
                        <a onClick={() => this.navigate(item.link)} style={{ cursor: 'pointer' }}>
                          <div className={classes.feedItem}>{item.title}</div>
                        </a>
                      </div>
                    </div>
                  );
                })}
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                  <hr /> {/* eslint-disable-next-line */}
                  <a onClick={() => this.navigate(TRC_BLOG_URL)} style={{ textDecoration: 'none', cursor: 'pointer', color: '#8a8a8a' }}>Read More Blog Posts</a>
                </div>
              </div>
            </div>
          }
        </div>
      </Paper>
    );
  }
}

Blog = withStyles(styles)(Blog);

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.get('blogLoading'),
    feed: state.dashboard.get('blogFeed'),
    err: state.dashboard.get('blogError'),
  };
};

export default connect(mapStateToProps, {
  getBlogData,
})(Blog);