import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { PositiveIntegerFormat } from 'common/numberFormatCustom.component';
import { getObjectId, mergeClientAndMasterOption } from './versionUtil';
import BenefitOptionCard from './benefitOptionCard.component';
import BenefitOptionDialog from './benefitOptionDialog.component';
import ClientBenefitTextInput from 'common/form/ClientBenefitTextInput';
import ConfirmationModal from 'common/confirmModal.component';

const useStyles = makeStyles((theme) => ({
  placeholderContent: {},
  multiOptionContainer:{
    borderColor: 'rgba(21, 88, 94, 0.5)',
    maxWidth: '40%',
    position: 'relative',
    padding: theme.spacing(2),
  },
  multiOptionEditButton:{
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    padding: '10px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgb(247 249 249)',
    },
  },
  quantityDescriptionTooltip: {
    minWidth: '400px',
  },
}));

export const BenefitOptions = (props) => {
  const { version, mergedVersion, setVersion, isReadOnly, hasAttemptedPublish, canAddOption, apiCostLookupOptionFields, apiCostLookupAuthorizationFields, tableCostLookupAuthorizationFields, tableCostLookupOptionFields } = props;
  const [openBenefitOptionDialog, setOpenBenefitOptionDialog] = useState(false);
  const [benefitOptions, setBenefitOptions] = useState(version.optionDetails);
  const [selectedOpt, setSelectedOpt] = useState(null);
  const [otherOpts, setOtherOpts] = useState([]);
  const [confirmDeleteCallback, setConfirmDeleteCallback] = useState(null);

  const classes = useStyles(props);
  const theme = useTheme();

  const isInheritedBenefit = !isEmpty(version.masterVersion);
  const multiSelectHasOverride = isInheritedBenefit && version.canSelectMultipleOptions != null;

  const findSelectedOptIndex = useCallback((selectedOption) => {
    const targetOptId = getObjectId(selectedOption);
    return benefitOptions.findIndex((opt) => {
      const optId = getObjectId(opt);
      return optId === targetOptId;
    });
  }, [benefitOptions]);

  const editBenefitOption = (targetOpt, newOpt, vrsn) => {
    const optIdx = findSelectedOptIndex(targetOpt);
    vrsn.optionDetails.splice(optIdx, 1, newOpt);
    setVersion({ ...vrsn, optionDetails: [...vrsn.optionDetails] });
  };

  const moveOptionUp = (optIdx) => {
    const targetOpt = benefitOptions[optIdx];
    const optToReplace = benefitOptions[optIdx - 1];
    targetOpt.order = optIdx - 1;
    optToReplace.order = optIdx;
    // have now overridden the master list order, so set order for each option
    benefitOptions.forEach((opt, idx) => {
      if (opt.order == null) {
        opt.order = idx;
      }
    });
    setVersion({ ...version, optionDetails: cloneDeep(benefitOptions) });
  };

  const moveOptionDown = (optIdx) => {
    const targetOpt = benefitOptions[optIdx];
    const optToReplace = benefitOptions[optIdx + 1];
    targetOpt.order = optIdx + 1;
    optToReplace.order = optIdx;
    benefitOptions.forEach((opt, idx) => {
      if (opt.order == null) {
        opt.order = idx;
      }
    });
    setVersion({ ...version, optionDetails: cloneDeep(benefitOptions) });
  };

  const revertMultiSelect = () => {
    setVersion((v) => ({
      ...v,
      canSelectMultipleOfAnOption: null,
      canSelectMultipleOptions: null,
      maxOptionQuantity: null,
      quantityDescription: null,
    }));
  };

  const overrideMultiSelect = () => {
    setVersion((v) => ({
      ...v,
      canSelectMultipleOfAnOption: mergedVersion.canSelectMultipleOfAnOption,
      canSelectMultipleOptions: mergedVersion.canSelectMultipleOptions,
      maxOptionQuantity: mergedVersion.maxOptionQuantity,
      quantityDescription: mergedVersion.quantityDescription,
    }));
  };

  useEffect(() => {
    if (selectedOpt) {
      const optIdx = findSelectedOptIndex(selectedOpt);
      const optsCopy = [...benefitOptions];
      optsCopy.splice(optIdx, 1);
      setOtherOpts(optsCopy.map((opt) => mergeClientAndMasterOption(opt)));
    } else {
      setOtherOpts(benefitOptions.map((opt) => mergeClientAndMasterOption(opt)));
    }
  }, [selectedOpt, benefitOptions, findSelectedOptIndex]);

  useEffect(() => {
    const copyOfOptions = [...version.optionDetails];
    if (mergedVersion.optionDetails && mergedVersion.optionDetails.length) {
      copyOfOptions.sort((opt1, opt2) => {
        const matchingMergedOpt1 = mergedVersion.optionDetails.find((mergedOpt) => getObjectId(opt1) === getObjectId(mergedOpt));
        const matchingMergedOpt2 = mergedVersion.optionDetails.find((mergedOpt) => getObjectId(opt2) === getObjectId(mergedOpt));
        if (matchingMergedOpt1 && matchingMergedOpt2) {
          return matchingMergedOpt1.order - matchingMergedOpt2.order;
        } else {
          return 0;
        }
      });
    }
    setBenefitOptions(copyOfOptions);
  }, [version.optionDetails, mergedVersion.optionDetails]);

  return (
    <>
      <div className="px-2 py-2">
        <Grid container justify="space-between" spacing={0}>
          <Grid item className="p2">
            <Typography variant="h5">Benefit Options</Typography>
          </Grid>
          <Grid item className="p2">
            <Button
              color="primary"
              variant="outlined"
              style={{ visibility: isReadOnly ? 'hidden' : 'visible' }}
              disabled={!canAddOption}
              onClick={() => setOpenBenefitOptionDialog(true)}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Box px={4}>
          <Paper variant="outlined" className={classes.multiOptionContainer}>
            {isInheritedBenefit && !multiSelectHasOverride && !isReadOnly &&
              <IconButton
                className={classes.multiOptionEditButton}
                color="primary"
                size="large"
                onClick={overrideMultiSelect}
              >
                <FontAwesomeIcon
                  icon={['fas', 'pen']}
                />
              </IconButton>
            }
            {isInheritedBenefit && multiSelectHasOverride && !isReadOnly &&
              <IconButton
                className={classes.multiOptionEditButton}
                color="primary"
                size="large"
                onClick={revertMultiSelect}
              >
                <FontAwesomeIcon
                  icon={['fas', 'undo']}
                />
              </IconButton>
            }
            <Grid direction="column" container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergedVersion.canSelectMultipleOptions != null ? mergedVersion.canSelectMultipleOptions : false}
                      onChange={(e) => {
                        const newVersion = { ...version, canSelectMultipleOptions: e.target.checked };
                        setVersion(newVersion);
                      }}
                      disabled={isReadOnly || (!multiSelectHasOverride && isInheritedBenefit)}
                    />
                  }
                  label="Allow Selection of Multiple Options For This Benefit"
                />
              </Grid>
              <Grid item container direction="column" spacing={0}>
                <Grid item >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mergedVersion.canSelectMultipleOfAnOption != null ? mergedVersion.canSelectMultipleOfAnOption : false}
                        onChange={(e) => {
                          const newVersion = { ...version, canSelectMultipleOfAnOption: e.target.checked, maxOptionQuantity: null, quantityDescription: null };
                          setVersion(newVersion);
                        }}
                        disabled={isReadOnly || (!multiSelectHasOverride && isInheritedBenefit)}
                      />
                    }
                    label="Allow Selection Of A Single Option Multiple Times"
                  />
                </Grid>
                {
                  mergedVersion.canSelectMultipleOfAnOption &&
                    <>
                      <Grid item>
                        <ClientBenefitTextInput
                          clientVersion={version}
                          masterVersion={mergedVersion.canSelectMultipleOfAnOption && !multiSelectHasOverride ? version.masterVersion : undefined}
                          versionField="maxOptionQuantity"
                          label="Max Quantity"
                          name="maxQuantity"
                          disabled={!mergedVersion.canSelectMultipleOfAnOption}
                          onChange={(value) => {
                            const newVersion = { ...version, maxOptionQuantity: value };
                            setVersion(newVersion);
                          }}
                          onBlur={(value) => {
                            const newVersion = { ...version, maxOptionQuantity: value };
                            setVersion(newVersion);
                          }}
                          InputProps={{ readOnly: isReadOnly, inputComponent: PositiveIntegerFormat }}
                          validateOnBlur={hasAttemptedPublish}
                          validateOnChange={hasAttemptedPublish}
                          required={mergedVersion.canSelectMultipleOfAnOption}
                          showAdornment={false}
                        />
                      </Grid>
                      <Grid item container direction="row" alignItems="center">
                        <Grid item xs={11}>
                          <ClientBenefitTextInput
                            clientVersion={version}
                            masterVersion={mergedVersion.canSelectMultipleOfAnOption && !multiSelectHasOverride ? version.masterVersion : undefined}
                            versionField="quantityDescription"
                            label="Quantity Description"
                            name="quantityDescription"
                            disabled={!mergedVersion.canSelectMultipleOfAnOption}
                            onChange={(value) => {
                              const newVersion = { ...version, quantityDescription: value };
                              setVersion(newVersion);
                            }}
                            onBlur={(value) => {
                              const newVersion = { ...version, quantityDescription: value };
                              setVersion(newVersion);
                            }}
                            InputProps={{ readOnly: isReadOnly }}
                            validateOnBlur={hasAttemptedPublish}
                            validateOnChange={hasAttemptedPublish}
                            required={mergedVersion.canSelectMultipleOfAnOption}
                            showAdornment={false}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip 
                            arrow 
                            placement="top-end" 
                            classes={{ tooltip: classes.quantityDescriptionTooltip }} 
                            title={
                              <>
                                <Typography color="inherit">This will be what's used to prompt the transferee for how many of the option they'd like. It could be different for different types of benefits.  For example:</Typography>
                                <Box fontStyle="italic" mt={2}>
                                  <Typography>How many pets will you be relocating?</Typography>
                                  <Box mt={1}>
                                    <Typography>How many people, including you, will be traveling?</Typography>
                                  </Box>
                                  <Box mt={1}>
                                    <Typography>How many times would you like the property cleaned?</Typography>
                                  </Box>
                                </Box>
                              </>
                            }
                          >
                            <div>
                              <FontAwesomeIcon color={theme.palette.primary.light} icon={['fas', 'question-circle']} />
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                }
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Grid className="flex-grow" container spacing={0}>
          {benefitOptions.length === 0 && (
            <Grid item xs={3} className="row p2">
              <Paper className={classNames('center-container', 'px-1', classes.placeholderContent)} elevation={4}>
                <Typography align="center" variant="h5" color="textSecondary">
                  No Options Added
                </Typography>
              </Paper>
            </Grid>
          )}
          {benefitOptions.length > 0 &&
            benefitOptions.map((opt, idx) => {
              const mergedOpt = mergeClientAndMasterOption(opt);
              return (
                <Grid item key={getObjectId(mergedOpt)} className="p2">
                  <BenefitOptionCard
                    benefitOption={mergedOpt}
                    costType={mergedVersion.costType}
                    onEdit={() => {
                      setOpenBenefitOptionDialog(true);
                      setSelectedOpt(opt);
                    }}
                    onDelete={() => setConfirmDeleteCallback(() => () => {
                      const targetOptionId = getObjectId(opt);
                      const optionIdx = version.optionDetails.findIndex((optionInList) => {
                        const optionId = getObjectId(optionInList);
                        return optionId === targetOptionId;
                      });
                      version.optionDetails.splice(optionIdx, 1);
                      setVersion({ ...version, optionDetails: [...version.optionDetails] });
                      setConfirmDeleteCallback(null);
                    })}
                    apiCostLookupOptionFields={apiCostLookupOptionFields}
                    tableCostLookupOptionFields={tableCostLookupOptionFields}
                    isReadOnly={isReadOnly}
                    optionsLength={benefitOptions.length}
                    index={idx}
                    moveUp={() => moveOptionUp(idx)}
                    moveDown={() => moveOptionDown(idx)}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
      <BenefitOptionDialog
        open={openBenefitOptionDialog}
        close={() => {
          setOpenBenefitOptionDialog(false);
          setSelectedOpt(null);
        }}
        costType={mergedVersion.costType}
        costLookupKey={mergedVersion.costLookupKey}
        apiCostLookupOptionFields={apiCostLookupOptionFields}
        apiCostLookupAuthorizationFields={apiCostLookupAuthorizationFields}
        tableCostLookupAuthorizationFields={tableCostLookupAuthorizationFields}
        otherOpts={otherOpts}
        selectedOpt={selectedOpt}
        setBenefitOption={(newOpt) => {
          if (selectedOpt) {
            editBenefitOption(selectedOpt, newOpt, version);
          } else {
            const hasOverriddenOrder = benefitOptions[0] && benefitOptions[0].order != null;
            newOpt.order = hasOverriddenOrder ? benefitOptions.length + 1 : null;
            setVersion({ ...version, optionDetails: [...benefitOptions, newOpt] });
          }
        }}
      />
      {!!confirmDeleteCallback &&
        <ConfirmationModal
          titleText="Delete Option?"
          cancelText="Cancel"
          confirmText="Delete"
          handleClose={() => {
            setConfirmDeleteCallback(null);
          }}
          handleConfirm={confirmDeleteCallback}
          bodyText="Are you sure you'd like to delete this option?"
        />
      }
    </>
  );
};

BenefitOptions.propTypes = {
  version: PropTypes.object.isRequired,
  mergedVersion: PropTypes.object.isRequired,
  setVersion: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  canAddOption: PropTypes.bool.isRequired,
  hasAttemptedPublish: PropTypes.bool.isRequired,
  apiCostLookupOptionFields: PropTypes.array,
  apiCostLookupAuthorizationFields: PropTypes.array,
  tableCostLookupOptionFields: PropTypes.array,
  tableCostLookupAuthorizationFields: PropTypes.array,
};

export default BenefitOptions;
