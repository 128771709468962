import 'firebase/storage';
import 'index.scss';
import * as Sentry from '@sentry/react';
import * as firebase from 'firebase/app';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';
import React from 'react';
import Script from 'react-load-script';
import Root from './root.container';
import configureStore from './store/configureStore';
import fastclick from 'fastclick';
// import registerServiceWorker from './registerServiceWorker';

import history from './history';

// import { setLoggedIn } from './modules/login/login.actions';
const PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyAooLxByqjSSXBhpIpbMpKtw0GeY-JvBMg';
const firebaseConfig = {
  projectId: PROJECT_ID,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  apiKey: process.env.REACT_APP_FB_API_KEY,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Init Sentry
const sentryKey = process.env.REACT_APP_SENTRY_KEY;
if (sentryKey) {
  const environment = process.env.REACT_APP_ANALYTICS_ENV;
  Sentry.init({ dsn: sentryKey });
  Sentry.setTag('environment', environment);
}

fastclick.attach(document.body);

const basename = process.env.PUBLIC_URL || '/';

const App = () => (
  <Router basename={basename} history={history}>
    <Script
      url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`}
    />
    <Root store={store} />
  </Router>
);

function startApp() {
  render(<App />, document.getElementById('root'));
}

const store = configureStore();

// store.dispatch(setLoggedIn(true));

startApp();

// registerServiceWorker();
