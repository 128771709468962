import { BASE_URL } from '../../../middleware/api';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS, showToast } from '../../layout/layout.actions';
import { connect } from 'react-redux';
import { getAuthToken } from '../../../utilities/authUtils';
import { getSearchCommentResults } from '../transferees.actions';
import { isFileExtensionBlocked, maxFileUploadSize } from '../../../utilities/fileUtils';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const request = require('superagent');

const styles = (theme) => ({
  dialogPaper: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  row: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
});

const initialFormState = {
  Service: '', // category
  Subject: '',
  CommentText: '',
};

class NoteCreateDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: initialFormState,
      fileAttachment: null,
      fileAttachmentName: null,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.clearFileAttachment = this.clearFileAttachment.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
  }

  handleFormFieldChange = (name) => (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: event.target.value,
      },
    });
  };

  handleClose() {
    this.props.handleResponse(this.props.type);
    this.setState({
      formData: initialFormState,
      fileAttachment: null,
      fileAttachmentName: null,
    });
  }

  handleFileDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      let file = acceptedFiles[0]; // allow one
      let fileName = file.name;
      let fileNameAry = fileName.split('.');
      let fileExtension = fileNameAry.length > 1 ? fileNameAry.pop() : '';

      const isFileBlocked = isFileExtensionBlocked(fileExtension);

      if (!isFileBlocked) {
        this.setState({
          fileAttachment: file,
          fileAttachmentName: fileName,
        });
      }
    }
  }

  clearFileAttachment() {
    this.setState({
      fileAttachment: null,
      fileAttachmentName: null,
    });
  }

  isFormInvalid() {
    if (this.state && this.state.formData) {
      const { CommentText, Service, Subject } = this.state.formData;
      return CommentText === '' || Service === '' || Subject === '';
    }
    return true;
  }

  async handleSubmit(formData, AuthorizationID, fileAttachment) {
    // do post and evaluate response
    // format: {"CommentDate":"2018-03-09T19:03:33.394Z","Subject":"test","CommentText":"test","Service":"Destination Buyer","AuthorizationID":"649-253"}
    let postObj = {
      AuthorizationID,
      Service: formData.Service,
      CommentDate: new Date().toISOString(),
      Subject: formData.Subject,
      CommentText: formData.CommentText,
    };

    const req = request.post(`${BASE_URL  }AuthorizationFile/AddComment`)
      .set({ 'Authorization': `Bearer ${  getAuthToken()}` })
      .field('Comment', JSON.stringify(postObj))
      .attach('AttachmentFile', fileAttachment);

    req.then((res) => {
      if (res.statusCode === 200) {
        this.props.showToast('Successfully added note to file.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      } else {
        this.props.showToast('Error adding note to file.  Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
      this.props.getSearchCommentResults(AuthorizationID);
      this.handleClose();
    })
      .catch((e) => {
        this.props.showToast('Error adding note to file.  Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      });
  }

  render() {
    const { open, title, classes, submittedAuthDetail, busCodes } = this.props;
    const { fileAttachment, fileAttachmentName } = this.state;
    let { Service, Subject, CommentText } = this.state.formData;

    const { AuthorizationID } = submittedAuthDetail;
    const isFormInvalid = this.isFormInvalid();
    const serviceOptions = busCodes ? busCodes.filter((item) => item.BusCodeType === 'NOTESSERVICE') : [];

    return (
      <div>
        <Dialog
          classes={{
            paper: classes.dialogPaper,
          }}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <div className={classes.row}>
              {serviceOptions &&
                <TextField
                  id="Service"
                  select
                  label="Category"
                  className={classes.textField}
                  value={Service ? Service : ''}
                  onChange={this.handleFormFieldChange('Service')}
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      className: classes.textField,
                    },
                  }}
                  required
                >
                  {serviceOptions.map((svc, i) => {
                    return (<MenuItem key={i} value={svc.BusCode}>
                      {svc.BusCodeDesc}
                    </MenuItem>);
                  })}
                </TextField>
              }
            </div>
            <div className={classes.row}>
              <TextField
                id="Subject"
                label="Subject"
                multiline
                value={Subject ? Subject : ''}
                margin="normal"
                className={classes.textField}
                onChange={this.handleFormFieldChange('Subject')}
                required
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              <span>
                <span>Attachment</span>
                <span style={{ float: 'right' }}>
                  {fileAttachmentName &&
                    <Button color="primary" size="small" onClick={this.clearFileAttachment}>Clear</Button>
                  }
                </span>
              </span>
              <Dropzone
                // accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/plain, application/pdf"
                maxSize={maxFileUploadSize}
                onDrop={this.handleFileDrop}
                style={{
                  borderColor: 'black',
                  borderStyle: 'dashed',
                  borderRadius: '0.5rem',
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer',
                  marginTop: '0.5rem',
                }}
              >
                <div style={{ margin: '0.5rem' }}>{fileAttachmentName ? fileAttachmentName : 'Drop file here, or click to select file to upload.'}</div>
              </Dropzone>
            </div>
            <div className={classes.row}>
              <TextField
                id="Note"
                label="Note"
                multiline
                value={CommentText ? CommentText : ''}
                margin="normal"
                className={classes.textField}
                onChange={this.handleFormFieldChange('CommentText')}
                required
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleSubmit(this.state.formData, AuthorizationID, fileAttachment)}
              disabled={isFormInvalid}
              color="primary"
              autoFocus
            >
              Submit
            </Button>
            <Button onClick={() => this.handleClose()} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

// NoteCreateDialog.propTypes = {
//   fullScreen: PropTypes.bool.isRequired,
//   open: PropTypes.bool.isRequired,
// };

NoteCreateDialog = withStyles(styles, { withTheme: true })(NoteCreateDialog);

const mapStateToProps = (state) => {
  return {
    submittedAuthDetail: state.transferees.get('submittedAuthDetail'),
  };
};

export default connect(mapStateToProps, {
  showToast,
  getSearchCommentResults,
})(withMobileDialog()(NoteCreateDialog));