import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS, setNumPendingTransferees, setPageTitle, showToast } from '../layout/layout.actions';
import {
  cancelAuthorization, FAILURE_TYPES,
  getApprovalPendingAuthorizations,
  getAuthorizationCountByMonth,
  getAuthorizationCountByProgram,
  getPendingAuthorization,
  getPendingAuthorizations,
  getSubmittedAuthorizations,
} from './authorizations.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import { getSelectedClientInformation, isClientSelected } from '../../utilities/authUtils';
import ApprovalPendingAuthorizations from './approvalPendingAuthorizations.component';
import AuthorizationChart from './authorizationChart.component';
import AuthorizationEditCreate from './authorizationEditCreate/authorizationEditCreate.component';
import PendingAuthorizations from './pendingAuthorizations.component';
import SubmittedAuthorizations from './submittedAuthorizations.component';

import { userHasPermission } from '../../utilities/authUtils';

class AuthorizationsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAuthorization: null,
    };

    this.handleEditAuthorization = this.handleEditAuthorization.bind(this);
    this.handleNewAuthorization = this.handleNewAuthorization.bind(this);
    this.getPendingAuthorization = this.getPendingAuthorization.bind(this);
    this.handleCancelAuthorization = this.handleCancelAuthorization.bind(this);
  }

  async componentDidMount() {
    this.props.setPageTitle('My Authorizations');

    let actionsList = [
      this.props.getSubmittedAuthorizations(),
      this.props.getPendingAuthorizations(),
      this.props.getApprovalPendingAuthorizations(),
    ];

    if (getSelectedClientInformation().id !== '238') {
      actionsList.push(this.props.getAuthorizationCountByProgram());
      actionsList.push(this.props.getAuthorizationCountByMonth());
    }

    const actions = await Promise.all(actionsList);
    if (actions.some(action => FAILURE_TYPES.includes(action.type))) {
      this.props.showToast('Failed to load some of the authorizations', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
    this.props.setNumPendingTransferees(this.props.pendingAuthorizations.length);
  }

  getPendingAuthorization(id) {
    return this.props.getPendingAuthorization(id);
  }

  handleEditAuthorization(id) {
    this.props.history.push(`/authorizations/${  id}`);
  }

  handleNewAuthorization() {
    this.props.history.push('/authorizations/new');
  }

  async handleCancelAuthorization(id) {
    let response = await this.props.cancelAuthorization(id);
    if (response.type === 'CANCEL_AUTHORIZATION_SUCCESS') {
      this.props.showToast('Successfully canceled authorization.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      await this.props.getPendingAuthorizations();
      this.props.setNumPendingTransferees(this.props.pendingAuthorizations.length);
    } else {
      this.props.showToast('Failed to cancel authorization. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  render() {
    let { pendingAuthorizations, pendingApprovalAuthorizations, clientDivisions, authCountsByProgram, authCountsByMonth, submittedAuths } = this.props;
    let { selectedAuthorization } = this.state;

    // TODO: check for user permission to modify authorizations
    // const isReadOnly = !userHasPermission('create:authorizations');
    const isReadOnly = false;

    const allowAddAuthorization = isClientSelected();

    if (!submittedAuths) {
      submittedAuths = [];
    }

    const chartData = {
      authCountsByProgram,
      authCountsByMonth,
    };

    return (
      <div style={{ padding: '2rem' }}>
        {selectedAuthorization &&
          <AuthorizationEditCreate selectedAuthorization={selectedAuthorization} />
        }

        {!selectedAuthorization &&
          <div>
            {!isReadOnly &&
              <div style={{ marginBottom: '1rem' }}>
                <PendingAuthorizations
                  rows={pendingAuthorizations}
                  allowAddNew={allowAddAuthorization}
                  isReadOnly={isReadOnly}
                  handleEditAuthorization={this.handleEditAuthorization}
                  handleNewAuthorization={this.handleNewAuthorization}
                  handleCancelAuthorization={this.handleCancelAuthorization}
                />
              </div>
            }
            {
              userHasPermission('authorization:requiresApproval', clientDivisions) && 
                <ApprovalPendingAuthorizations
                  rows={pendingApprovalAuthorizations}
                  allowAddNew={allowAddAuthorization}
                  isReadOnly={isReadOnly}
                  handleEditAuthorization={this.handleEditAuthorization}
                  handleNewAuthorization={this.handleNewAuthorization}
                  handleCancelAuthorization={this.handleCancelAuthorization}
                />
            }
            
            {getSelectedClientInformation().id !== '238' && (
              <div style={{ marginBottom: '1rem' }}>
                <AuthorizationChart chartData={chartData} />
              </div>
            )}

            <div style={{ marginBottom: '1rem' }}>
              <SubmittedAuthorizations rows={submittedAuths} handleViewAuthorization={this.handleEditAuthorization} />
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pendingAuthorizations: state.authorizations.get('pendingAuthorizations'),
    pendingApprovalAuthorizations: state.authorizations.get('pendingApprovalAuthorizations'),
    pendingAuthorization: state.authorizations.get('pendingAuthorization'),
    authCountsByProgram: state.authorizations.get('authCountsByProgram'),
    authCountsByMonth: state.authorizations.get('authCountsByMonth'),
    submittedAuths: state.authorizations.get('submittedAuths'),
    clientDivisions: state.layout.get('clientDivisions'),
  };
};

export default withRouter(connect(mapStateToProps, {
  setPageTitle,
  getPendingAuthorizations,
  getApprovalPendingAuthorizations,
  getPendingAuthorization,
  getAuthorizationCountByProgram,
  getAuthorizationCountByMonth,
  cancelAuthorization,
  showToast,
  setNumPendingTransferees,
  getSubmittedAuthorizations,
})(AuthorizationsContainer));
