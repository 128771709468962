export const phoneTypeMap = [
  {
    type: 'Mobile',
    field: 'OldMobile',
  },
  {
    type: 'Work',
    field: 'OldOfficePhone',
  },
  {
    type: 'Home',
    field: 'OldHomePhone',
  },
];

export default { phoneTypeMap };