import { Badge, Box, Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { memoize } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';

import { dateFormatter } from 'utilities/dateFormatter';
import { getMasterBenefitRecentChanges } from './benefit.actions';

const useStyles = makeStyles((theme) => (
  {
    list: {
      maxHeight: 500,
      overflow: 'auto',
      width: '100%',
    },
  }
));

const sortChanges = memoize((changes) => {
  return [...changes].sort((change1, change2) => {
    if (change1.name.toLowerCase() < change2.name.toLowerCase()) {
      return -1;
    } else if (change1.name.toLowerCase() > change2.name.toLowerCase()) {
      return 1;
    } else {
      return new Date(change2.publishedDate) - new Date(change1.publishedDate);
    }
  });
});

const MasterBenefitChanges = (props) => {
  const { isLoading, masterBenefitRecentChanges } = props;
  
  const classes = useStyles();

  useEffect(() => {
    props.getMasterBenefitRecentChanges();
  }, []); //eslint-disable-line

  if (isLoading || masterBenefitRecentChanges.length === 0) {
    return '';
  }
  return (
    <ExpansionPanel className="my-2">
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />}>
        <Badge badgeContent={masterBenefitRecentChanges.length} color="primary">
          <Typography variant="h6" color="primary">TRC has updated the benefits recently.</Typography>
        </Badge>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <List className={classes.list}>
          {
            masterBenefitRecentChanges.map((change, idx) => (
              <Fragment key={idx}>
                <ListItem>
                  <Grid container>
                    <Grid item xs={3}>
                      <Box fontWeight="500">
                        {change.name}
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box color="secondary.main" fontWeight="fontWeightLight">
                        {dateFormatter({ value: change.publishedDate })}
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      {change.publishNotes}
                    </Grid>
                  </Grid>
                </ListItem>
                {idx + 1 < masterBenefitRecentChanges.length && <Divider />}
              </Fragment>
            ))
          }
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

MasterBenefitChanges.propTypes = {
  masterBenefitRecentChanges: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  getMasterBenefitRecentChanges: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    masterBenefitRecentChanges: sortChanges(state.benefit.masterBenefitRecentChanges || []),
    isLoading: state.benefit.isLoadingMasterBenefitChanges,
  };
};

export default connect(
  mapStateToProps,
  { getMasterBenefitRecentChanges },
)(MasterBenefitChanges);
