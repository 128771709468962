import 'react-awesome-lightbox/build/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Subtract from '@material-ui/icons/Remove';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

import { bareCurrencyFormatter } from '../../../utilities/currencyFormatter';
import { formatDateWithSlashes } from '../../../utilities/dateFormatter';
import { generateForm } from '../../../utilities/reportingUtils';
import { getAuthToken } from '../../../utilities/authUtils';
import { getReceiptAttachment } from '../transferees.actions';
import LogoSpinner from '../../../common/logoSpinner.component';
import ResponsiveDialog from '../../../common/responsiveDialog.component';

const reportTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: '2px solid #15585e',
      },
      paddingDense: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  },
});

const styles = (theme) => ({
  summary: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  panel: {
    flexDirection: 'column',
  },
  table: {
    marginBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  headerItem: {
    marginBottom: theme.spacing(0.5),
  },
  paidAmount: {
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'left',
    },
  },
  thumbnailContainer: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  thumbnailIcon: {
    marginBottom: theme.spacing(2),
  },
  fileCaption: {
    width: '100%',
    overflowWrap: 'break-word',
  },
  denied: {
    color: theme.palette.error.main,
  },
  tableContainer: {
    overflowX: 'scroll',
  },
  mobileCardHeader: {
    borderBottom: '1px solid rgb(91, 155, 157)',
    marginBottom: theme.spacing(2),
  },
  mobileCardItem: {
    paddingBottom: theme.spacing(2),
  },
  expansionPanelRoot: {
    boxShadow: 'none',
  },
});

class Vendor extends Component {
  static defaultProps = {
    disableCollapse: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: props.disableCollapse,
      selectedImage: null,
      selectedLineItem: null,
    };
  }

  handleChange = () => {
    if (!this.props.disableCollapse) {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  selectImage = async (selectedImage) => {
    this.setState({ selectedImage });

    if (!this.props.receiptAttachmentImages[selectedImage.AttachmentId]) {
      await this.props.getReceiptAttachment(selectedImage.AttachmentId);
    }
    const fullImage = this.props.receiptAttachmentImages[selectedImage.AttachmentId];
    this.props.handleImageSelected(fullImage.FileBase64);
  };

  handleImageClose = () => this.setState({ selectedImage: null });

  getFullImageElement(image) {
    if (!image) {
      return null;
    }

    if (this.props.isLoadingReceiptImage) {
      return <LogoSpinner />;
    }
    const fullImage = this.props.receiptAttachmentImages[image.AttachmentId];
    if (fullImage && fullImage.FileBase64) {
      return <img style={{ margin: 'auto', display: 'block', width: '75%' }} src={fullImage.FileBase64} alt="" />;
    }
    return <div>Full Image Not Available</div>;
  }

  handleAttachmentClick = (attachment) => () => {
    const { AttachID, FileType, FileBinary } = attachment;
    if ((FileType === 'png' || FileType === 'jpg' || FileType === 'jpeg') && FileBinary) {
      this.selectImage(attachment);
    } else {
      generateForm('AuthorizationFile/GetExpenseDetailAttachment', {
        attachmentId : AttachID,
        access_token: getAuthToken(),
      });
    }
  };

  selectLineItem = (selectedLineItem) => () => {
    this.setState({ selectedLineItem });
  }

  handleLineItemClose = () => this.setState({ selectedLineItem: null });

  getFullLineItemElement(item) {
    if (!item) {
      return null;
    }
    const { classes } = this.props;
    const {
      ExpDate,
      ExpDesc,
      PayAmount,
      PayInCurrencyCode,
      ReportCategory,
      VendorName,
    } = item;
    const payAmount = bareCurrencyFormatter({ value: PayAmount });

    return (
      <React.Fragment>
        <Grid container className={classes.mobileCardHeader}>
          <Grid item xs={12} className={classes.mobileCardItem}>
            {ReportCategory}
          </Grid>
          <Grid item xs={12} className={classes.mobileCardItem}>
            {formatDateWithSlashes(ExpDate)}
          </Grid>
        </Grid>
        <Grid container className={classes.mobileCardItem}>
          <Grid item xs={7}>
            Description:
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            {ExpDesc}
          </Grid>
        </Grid>
        <Grid container className={classes.mobileCardItem}>
          <Grid item xs={7}>
            Amount:
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            {payAmount} {PayInCurrencyCode}
          </Grid>
        </Grid>
        <Grid container className={classes.mobileCardItem}>
          <Grid item xs={7}>
            Vendor:
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            {VendorName} 
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderReportLines() {
    const { classes, report, contentWidth } = this.props;

    if (contentWidth <= 960) { // md screen size
      return this.renderReportLinesMobile();
    }

    return (
      <Grid container className={classes.tableContainer}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="dense">Date</TableCell>
                <TableCell padding="dense">Category</TableCell>
                <TableCell padding="dense">Description</TableCell>
                <TableCell padding="dense" align="right">Amount</TableCell>
                <TableCell padding="dense">Expense Type</TableCell>
                <TableCell padding="dense">Vendor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderLineItem(report)}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  renderLineItem = (item) => {
    // const { classes } = this.props;
    const {
      ExpID,
      ExpDate,
      ExpDesc,
      ReportCategory,
      VendorName,
      PayAmount,
      PayInCurrencyCode,
    } = item;
    const payAmount = bareCurrencyFormatter({ value: PayAmount });

    return (
      <TableRow key={ExpID}>
        <TableCell padding="dense">{formatDateWithSlashes(ExpDate)}</TableCell>
        <TableCell padding="dense">{ReportCategory}</TableCell>
        <TableCell padding="dense">{ExpDesc}</TableCell>
        <TableCell padding="dense" align="right">{payAmount} {PayInCurrencyCode}</TableCell>
        <TableCell padding="dense" align="left">Vendor</TableCell>
        <TableCell padding="dense" align="left">{VendorName} </TableCell>
      </TableRow>
    );
  };

  renderReportLinesMobile() {
    const { classes, report } = this.props;

    return (
      <Grid container className={classes.tableContainer}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="dense">Category</TableCell>
                <TableCell padding="dense">Description</TableCell>
                <TableCell padding="dense" align="right">Amount</TableCell>
                <TableCell padding="dense">Expense Type</TableCell>
                <TableCell padding="dense" />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderLineItemMobile(report)}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  renderLineItemMobile = (item) => {
    const {
      ExpID,
      ExpDesc,
      ReportCategory,
      PayAmount,
      PayInCurrencyCode,
    } = item;
    const payAmount = bareCurrencyFormatter({ value: PayAmount });

    return (
      <TableRow key={ExpID}>
        <TableCell padding="dense" align="right">{ReportCategory}</TableCell>
        <TableCell padding="dense" align="right">{ExpDesc}</TableCell>
        <TableCell padding="dense" align="right">{payAmount} {PayInCurrencyCode}</TableCell>
        <TableCell padding="dense" align="right">Vendor</TableCell>
        <TableCell padding="dense" align="right">
          <IconButton onClick={this.selectLineItem(item)}>
            <FontAwesomeIcon icon="ellipsis-h" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  renderAttachments() {
    const { classes, report, isLoadingReceiptImage } = this.props;
    const { selectedImage } = this.state;
    const { Attachments } = report;

    if (Attachments.length === 0) {
      return null;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Attachments</Typography>
        </Grid>
        {Attachments.map((attachment) => {
          const { AttachmentId, FileType, FileBase64 } = attachment;
          let thumbnail;
          //image has been clicked and is loading for lightbox
          if ((selectedImage && selectedImage.AttachmentId === AttachmentId) && isLoadingReceiptImage){
            thumbnail = <LogoSpinner />;
          }
          else if ((FileType === 'png' || FileType === 'jpg' || FileType === 'jpeg') && FileBase64) {
            thumbnail = <img src={FileBase64} alt="Expense thumbnail" />;
          } 
          else {
            thumbnail = (
              <React.Fragment>
                <FontAwesomeIcon
                  className={classes.thumbnailIcon}
                  icon="file-download"
                  size="3x"
                />
                <Typography className={classes.fileCaption} variant="caption">{attachment.FileName}</Typography>
              </React.Fragment>
            );
          }

          return (
            <Grid item xs={6} sm={3} md={2} key={AttachmentId} role="button" onClick={this.handleAttachmentClick(attachment)}>
              <div className={classes.thumbnailContainer}>
                { thumbnail}
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  }



  render() {
    const { classes, report, disableCollapse } = this.props;
    const { expanded, selectedLineItem } = this.state;
    const {
      ExpID,
      ExpDate,
    } = report;
    const icon = disableCollapse ? null : expanded ? <Subtract /> : <Add />;

    return (
      <MuiThemeProvider theme={reportTheme}>
        <ExpansionPanel expanded={expanded} onChange={this.handleChange} classes={{ root: classes.expansionPanelRoot }}>
          <ExpansionPanelSummary
            expandIcon={icon}
            style={{
              cursor: disableCollapse ? 'auto' : 'pointer',
              userSelect: 'auto',
            }}
          >
            <div className={classes.summary}>
              <Typography variant="h5">Expense #{ExpID} - {formatDateWithSlashes(ExpDate)}</Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panel}>
            {this.renderReportLines()}
            {this.renderAttachments()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        
        <ResponsiveDialog
          fullScreen={false}
          open={!!selectedLineItem}
          contentElem={this.getFullLineItemElement(selectedLineItem)}
          handleResponse={this.handleLineItemClose}
          title={selectedLineItem ? selectedLineItem.ReportCategory : ''}
          onBackdropClick={this.handleLineItemClose}
          cancelText="Close"
          cancelButtonOnly
          minWidth={300}
        />
      </MuiThemeProvider>
    );
  }
}

Vendor.propTypes = {
  report: PropTypes.object.isRequired,
  submittedAuthDetail: PropTypes.object.isRequired,
  disableCollapse: PropTypes.bool,
  handleImageSelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    receiptAttachmentImages: state.transferees.get('receiptAttachmentImages'),
    isLoadingReceiptImage: state.transferees.get('isLoadingReceiptImage'),
    contentWidth: state.layout.get('contentWidth'),
  };
};

export default compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, {
    getReceiptAttachment,
  }),
)(Vendor);
