import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { EditCommandCell } from '../../common/tableEditCommandCell';
import { dateFormatter } from '../../utilities/dateFormatter';
import { generateForm } from '../../utilities/reportingUtils';
import { getAuthToken } from '../../utilities/authUtils';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import TableCell from '@material-ui/core/TableCell';

import { GET_CLIENT_NOTES_FAILURE } from './documents.types';
import { TOAST_MESSAGE_SEVERITY_ERROR, setPageTitle, showToast } from 'modules/layout/layout.actions';

import { getClientNotes } from './documents.actions';

import {
  Getter,
} from '@devexpress/dx-react-core';

import {
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';


const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={dateFormatter}
    {...props}
  />
);

class ClientDocumentsGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'NoteCategory', title: 'Category' },
        { name: 'FullName', title: 'Added By' },
        { name: 'NoteDate', title: 'Date' },
        { name: 'NoteSubject', title: 'Subject' },
      ],
      columnWidths: [
        { columnName: 'NoteCategory', width: 200 },
        { columnName: 'FullName', width: 275 },
        { columnName: 'NoteDate', width: 150 },
        { columnName: 'NoteSubject', width: 500 },
      ],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['NoteDate'],
      sorting: [{ columnName: 'NoteDate', direction: 'desc' }],
      filters: [],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });

    this.changeFilters = (filters) => this.setState({ filters });

    this.changeColumnWidths = (columnWidths) => {
      this.setState({ columnWidths });
    };

    this.commitChanges = this.commitChanges.bind(this);

    this.TableRow = ({ row, handleViewSubmittedAuth, ...restProps }) => {
      return (
        <Table.Row
          {...restProps}
          style={{
            cursor: 'default',
          }}
        />);
    };
  }

  async componentWillMount() {
    this.props.setPageTitle('My Documents');

    const action = await this.props.getClientNotes();
    if (action.type === GET_CLIENT_NOTES_FAILURE) {
      this.props.showToast('Failed to load documents', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  downloadAttachment(ClientNotesKey) {
    let postObj = {
      access_token: getAuthToken(),
      ClientNotesKey,
    };

    generateForm('Client/DownloadClientNoteAttachment', postObj);
  }

  actionsCell = (props) => {
    return (
      props.tableRow.row.HasAttachment > 0
        ? (
          <TableCell>
            <EditCommandCell
              id="attachment"
              text="attachment"
              icon="paperclip"
              onExecute={() => this.downloadAttachment(props.tableRow.row.ClientNotesKey)}
              tableRow={props.tableRow}
            >{React.Children.toArray(props.children)}
            </EditCommandCell>
          </TableCell>
        ) : <TableCell />
    );
  }

  //needed for grid
  commitChanges() {}

  render() {
    const { columns, pageSize, pageSizes, currentPage, dateColumns, filters, sorting, columnWidths } = this.state;
    const { clientNotes, isLoading } = this.props;

    if (isLoading || !clientNotes) {
      return <FullscreenSpinner />;
    }
    else
      return (
        <Paper style={{ padding: '1rem' }}>
          <div style={{ padding: '1rem', fontSize: '1.5rem', width: '50%' }}>Documents</div>
          <Grid
            rows={clientNotes}
            columns={columns}
          >
            <DateTypeProvider
              for={dateColumns}
            />
            <EditingState
              onEditingRowIdsChange={this.changeEditingRowIds}
              onCommitChanges={this.commitChanges}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedSorting />
            <FilteringState
              filters={filters}
              onFiltersChange={this.changeFilters}
            />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table rowComponent={this.TableRow} />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={this.changeColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableEditColumn
              style={{ marginRight: '2rem' }}
              cellComponent={this.actionsCell}
            />
            <TableFilterRow />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => [
                ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
                { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 150 },
              ]}
            />
            <PagingPanel
              pageSizes={pageSizes}
            />
          </Grid>
        </Paper>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    clientNotes: state.documents.clientNotes,
    isLoading: state.documents.isLoadingNotes,
  };
};

ClientDocumentsGrid.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clientNotes: PropTypes.array,
  getClientNotes: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { setPageTitle, showToast, getClientNotes },
)(ClientDocumentsGrid);