import {
  GET_POLICIES_SUCCESS,
  GET_POLICY_BENEFITS_FAILURE,
  GET_POLICY_BENEFITS_REQUEST,
  GET_POLICY_BENEFITS_SUCCESS,
  GET_POLICY_FAILURE,
  GET_POLICY_REQUEST,
  GET_POLICY_SUCCESS,
  GET_POLICY_SUMMARIES_FAILURE,
  GET_POLICY_SUMMARIES_REQUEST,
  GET_POLICY_SUMMARIES_SUCCESS,
  UPDATE_POLICY_FAILURE,
  UPDATE_POLICY_REQUEST,
  UPDATE_POLICY_SUCCESS,
} from './policy.types';
import produce from 'immer';

const initialState = {
  isLoadingBenefits: false,
  isLoadingSummaries: false,
  isLoadingPolicySummary: false,
  isUpdatingPolicy: false,
  benefits: null,
  policies: [],
  policySummaries: null,
};


export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_POLICY_BENEFITS_REQUEST:
      draft.isLoadingBenefits = true;
      break;

    case GET_POLICY_BENEFITS_SUCCESS:
      draft.isLoadingBenefits = false;
      draft.benefits = action.response;
      break;

    case GET_POLICY_BENEFITS_FAILURE:
      draft.isLoadingBenefits = false;
      break;

    case GET_POLICY_SUMMARIES_REQUEST:
      draft.isLoadingSummaries = true;
      break;

    case GET_POLICY_SUMMARIES_FAILURE:
      draft.isLoadingSummaries = false;
      break;

    case GET_POLICY_SUMMARIES_SUCCESS:
      draft.isLoadingSummaries = false;
      draft.policySummaries = action.response;
      break;

    case GET_POLICIES_SUCCESS:
      draft.policies = action.response;
      break;

    case UPDATE_POLICY_REQUEST:
      draft.isUpdatingPolicy = true;
      break;

    case UPDATE_POLICY_SUCCESS:
    case UPDATE_POLICY_FAILURE:
      draft.isUpdatingPolicy = false;
      break;

    case GET_POLICY_REQUEST:
      draft.isLoadingPolicySummary = true;
      break;

    case GET_POLICY_SUCCESS:
      draft.isLoadingPolicySummary = false;
      break;

    case GET_POLICY_FAILURE:
      draft.isLoadingPolicySummary = false;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
