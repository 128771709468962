import {
  CLONE_MANY_FAILURE,
  CLONE_MANY_REQUEST,
  CLONE_MANY_SUCCESS,
  CLONE_MASTER_FAILURE,
  CLONE_MASTER_REQUEST,
  CLONE_MASTER_SUCCESS,
  GET_BENEFITS_FAILURE,
  GET_BENEFITS_REQUEST,
  GET_BENEFITS_SUCCESS,
  GET_BENEFIT_FAILURE,
  GET_BENEFIT_REQUEST,
  GET_BENEFIT_SUCCESS,
  GET_COST_LOOKUP_APIS_FAILURE,
  GET_COST_LOOKUP_APIS_REQUEST,
  GET_COST_LOOKUP_APIS_SUCCESS,
  GET_COST_LOOKUP_API_FAILURE,
  GET_COST_LOOKUP_API_REQUEST,
  GET_COST_LOOKUP_API_SUCCESS,
  GET_COST_LOOKUP_FAILURE,
  GET_COST_LOOKUP_REQUEST,
  GET_COST_LOOKUP_SUCCESS,
  GET_LOOKUP_TABLES_FAILURE,
  GET_LOOKUP_TABLES_REQUEST,
  GET_LOOKUP_TABLES_SUCCESS,
  GET_MASTER_BENEFIT_RECENT_CHANGES_FAILURE,
  GET_MASTER_BENEFIT_RECENT_CHANGES_REQUEST,
  GET_MASTER_BENEFIT_RECENT_CHANGES_SUCCESS,
} from './benefit.types';
import { RequestStatusEnum } from 'index.types';
import produce from 'immer';

const initialState = {
  isLoadingBenefits: false,
  benefitSummaries: null,
  masterBenefitRecentChanges: null,
  lookupTables: [],
  isLoadingLookupTables: false,
  isLoadingCostLookupApis: false,
  isLoadingMasterBenefitChanges: false,
  isCloning: false,
  costLookupApis: [],
  costLookupByTable: {},
  costLookupByApi: {},
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_BENEFITS_REQUEST:
      draft.isLoadingBenefits = true;
      break;

    case GET_BENEFITS_SUCCESS:
      draft.isLoadingBenefits = false;
      draft.benefitSummaries = action.response;
      break;

    case GET_BENEFITS_FAILURE:
      draft.isLoadingBenefits = false;
      break;

    case GET_BENEFIT_REQUEST:
      draft.isLoadingBenefit = true;
      break;

    case GET_BENEFIT_SUCCESS:
      draft.isLoadingBenefit = false;
      break;

    case GET_BENEFIT_FAILURE:
      draft.isLoadingBenefit = false;
      break;

    case GET_MASTER_BENEFIT_RECENT_CHANGES_REQUEST:
      draft.isLoadingMasterBenefitChanges = true;
      break;

    case GET_MASTER_BENEFIT_RECENT_CHANGES_SUCCESS:
      draft.isLoadingMasterBenefitChanges = false;
      draft.masterBenefitRecentChanges = action.response;
      break;

    case GET_MASTER_BENEFIT_RECENT_CHANGES_FAILURE:
      draft.isLoadingMasterBenefitChanges = false;
      break;

    case CLONE_MASTER_REQUEST:
    case CLONE_MANY_REQUEST:
      draft.isCloning = true;
      break;

    case CLONE_MASTER_SUCCESS: {
      const idx = draft.benefitSummaries.findIndex((summary) => {
        return summary.id === action.payload.masterBenefitId;
      });
      draft.benefitSummaries.splice(idx, 1, action.response);
      draft.isCloning = false;
      break;
    }

    case CLONE_MANY_SUCCESS:
      draft.isCloning = false;
      break;

    case CLONE_MANY_FAILURE:
    case CLONE_MASTER_FAILURE:
      draft.isCloning = false;
      break;

    case GET_LOOKUP_TABLES_REQUEST:
      draft.isLoadingLookupTables = true;
      break;

    case GET_LOOKUP_TABLES_SUCCESS:
      draft.isLoadingLookupTables = false;
      draft.lookupTables = action.response;
      break;

    case GET_LOOKUP_TABLES_FAILURE:
      draft.isLoadingLookupTables = false;
      break;

    case GET_COST_LOOKUP_APIS_REQUEST:
      draft.isLoadingCostLookupApis = true;
      break;

    case GET_COST_LOOKUP_APIS_SUCCESS:
      draft.isLoadingCostLookupApis = false;
      draft.costLookupApis = action.response;
      break;

    case GET_COST_LOOKUP_APIS_FAILURE:
      draft.isLoadingCostLookupApis = false;
      break;

    case GET_COST_LOOKUP_REQUEST:
      draft.costLookupByTable[action.context.tableName] = { status: RequestStatusEnum.PENDING };
      break;

    case GET_COST_LOOKUP_SUCCESS: {
      // this is to make the authorizationField objects look the same as those for the api cost lookup, making the component logic easier
      const copyOfResponse = { ...action.response };
      copyOfResponse.authorizationFields = copyOfResponse.authorizationFields.map((field) => {
        return { ...field, name: field.authorizationFieldName };
      });
      draft.costLookupByTable[action.context.tableName] = { response: copyOfResponse, status: RequestStatusEnum.SUCCESS };
      break;
    }
    case GET_COST_LOOKUP_FAILURE:
      draft.costLookupByTable[action.context.tableName] = { status: RequestStatusEnum.FAILED };
      break;

    case GET_COST_LOOKUP_API_REQUEST:
      draft.costLookupByApi[action.context.apiId] = { status: RequestStatusEnum.PENDING };
      break;

    case GET_COST_LOOKUP_API_SUCCESS:
      draft.costLookupByApi[action.context.apiId] = { response: action.response, status: RequestStatusEnum.SUCCESS };
      break;

    case GET_COST_LOOKUP_API_FAILURE:
      draft.costLookupByApi[action.context.apiId] = { status: RequestStatusEnum.FAILED };
      break;

    default:
      break;
  }

  return draft;
}, initialState);

