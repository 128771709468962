import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Lightbox from 'react-awesome-lightbox';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { userHasPermission } from '../../../utilities/authUtils';
import ExceptionsGrid from './exceptionsGrid.component';
import ExpensesSummary from './expensesSummary.component';
import Report from './report.component';
import ReportsGrid from './reportsGrid.component';
import ResponsiveDialog from '../../../common/responsiveDialog.component';
import VendorDetails from './vendorDetails.component';
import VendorExpensesGrid from './vendorExpensesGrid.component';

const styles = (theme) => ({
  paperRoot: {
    minWidth: '75%',
  },
});

class ExpensesView extends Component {
  state = {
    selectedReport: null,
    selectedVendorExpense: null,
    selectedImageData: null,
  };

  handleReportLineSelected = (line) => {
    const { reports } = this.props;
    const selectedReport = reports.find((instance) => instance.ReportID === line.ReportID);
    this.setState({ selectedReport });
  };

  handleVendorLineSelected = (line) => {
    const { vendorExpenses } = this.props;
    const selectedVendorExpense = vendorExpenses.find((instance) => instance.ExpID === line.ExpID);
    this.setState({ selectedVendorExpense });
  }

  handleImageSelected = (imageData) => {
    this.setState({ selectedImageData: imageData });
  }

  handleLightboxClose = () => {
    this.setState({ selectedImageData: null });
  }

  getReportElement = (submittedAuthDetail, report) => {
    return report ?
      <Report submittedAuthDetail={submittedAuthDetail} report={report} handleImageSelected={this.handleImageSelected} disableCollapse /> :
      null;
  };

  getVendorExpenseElement = (submittedAuthDetail, vendorExpense) => {
    return vendorExpense ?
      <VendorDetails submittedAuthDetail={submittedAuthDetail} report={vendorExpense} handleImageSelected={this.handleImageSelected} disableCollapse /> :
      null;
  }

  render() {
    const { exceptions, clientDivisions, reports, vendorExpenses, submittedAuthDetail, classes, moveCap } = this.props;
    const expensesAreEnabled = userHasPermission('expenses:read', clientDivisions);
    const budgetActualEnabled = userHasPermission('expenses:budgetVsActual', clientDivisions);
    const { selectedReport, selectedVendorExpense, selectedImageData } = this.state;
    return (
      <div>
        {moveCap && budgetActualEnabled &&
          <ExpensesSummary moveCap={moveCap} vendorExpenses={vendorExpenses} reports={reports} />
        }
        {
          selectedImageData && 
            <Lightbox 
              image={this.state.selectedImageData} 
              showTitle="false"
              onClose={this.handleLightboxClose}
            />
        }
        {expensesAreEnabled &&
          <Fragment>
            <ReportsGrid
              reports={reports}
              onLineSelected={this.handleReportLineSelected}
            />
            <ResponsiveDialog
              fullScreen={false}
              open={!!selectedReport}
              contentElem={this.getReportElement(submittedAuthDetail, selectedReport)}
              handleResponse={() => this.setState({ selectedReport: null })}
              onBackdropClick={() => this.setState({ selectedReport: null })}
              cancelText="Close"
              cancelButtonOnly
              maxWidth={false}
              PaperProps={{ classes: { root: classes.paperRoot } }}
            />
          </Fragment>
        }

        {expensesAreEnabled &&
          <Fragment>
            <VendorExpensesGrid 
              rows={vendorExpenses} 
              onLineSelected={this.handleVendorLineSelected}
            />
            <ResponsiveDialog
              fullScreen={false}
              open={!!selectedVendorExpense}
              contentElem={this.getVendorExpenseElement(submittedAuthDetail, selectedVendorExpense)}
              handleResponse={() => this.setState({ selectedVendorExpense: null })}
              onBackdropClick={() => this.setState({ selectedVendorExpense: null })}
              cancelText="Close"
              cancelButtonOnly
              maxWidth={false}
              PaperProps={{ classes: { root: classes.paperRoot } }}
            />
          </Fragment>
        }

        <div>
          <ExceptionsGrid rows={exceptions} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientDivisions: state.layout.get('clientDivisions'),
    submittedAuthDetail: state.transferees.get('submittedAuthDetail'),
  };
};

ExpensesView.propTypes = {
  exceptions: PropTypes.array,
  reports: PropTypes.array,
  vendorExpenses: PropTypes.array,
  moveCap: PropTypes.number,
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {}),
)(ExpensesView);

