import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const ContactCard = (props) => {
  let { FirstName, LastName, Title, EmailAddress, OfficePhone, Image, MobilePhone } = props.contact;

  return (
    <Card style={{ height: '17rem' }}>
      <CardContent>
        {Image &&
          <Grid container>
            <Grid item xs={6} md={4}>
              <div><img style={{ maxHeight: '12rem' }} src={`data:image/jpeg;base64,${  Image}`} alt="" /></div>
            </Grid>
            <Grid item xs={6} md={8}>
              <div>
                <div style={{ fontSize: '1.25rem' }}><strong>{`${FirstName  } ${  LastName}`}</strong></div>
                <div style={{ marginTop: '1rem', fontSize: '1.1rem' }}>{Title}</div>
                <div style={{ marginTop: '1rem', wordWrap: 'break-word' }}><a href={`mailto:${  EmailAddress}`}>{EmailAddress}</a></div>
                <div style={{ marginTop: '1rem', wordWrap: 'break-word' }}>Office: <a href={`tel:${  OfficePhone}`}>{OfficePhone}</a></div>
                <div style={{ marginTop: '1rem', wordWrap: 'break-word' }}>Mobile: <a href={`tel:${  MobilePhone}`}>{MobilePhone}</a></div>
              </div>
            </Grid>
          </Grid>
        }
        {!Image &&
          <Grid container>
            <Grid item xs={12}>
              <div style={{ fontSize: '1.25rem' }}><strong>{`${FirstName  } ${  LastName}`}</strong></div>
              <div style={{ marginTop: '1rem', fontSize: '1.1rem' }}>{Title}</div>
              <div style={{ marginTop: '1rem', wordWrap: 'break-word' }}><a href={`mailto:${  EmailAddress}`}>{EmailAddress}</a></div>
              <div style={{ marginTop: '1rem', wordWrap: 'break-word' }}>Office: <a href={`tel:${  OfficePhone}`}>{OfficePhone}</a></div>
              <div style={{ marginTop: '1rem', wordWrap: 'break-word' }}>Mobile: <a href={`tel:${  MobilePhone}`}>{MobilePhone}</a></div>
            </Grid>
          </Grid>
        }
      </CardContent>
    </Card >
  );
};

export default ContactCard;