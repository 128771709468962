import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { getAuthDocuments } from '../authorizations.actions';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TextField from '@material-ui/core/TextField';

import ResponsiveDialog from '../../../common/responsiveDialog.component';

import Dropzone from 'react-dropzone';

import { BASE_URL } from '../../../middleware/api';
import { generateForm } from '../../../utilities/reportingUtils';
import { getAuthToken } from '../../../utilities/authUtils';
import { isFileExtensionBlocked, maxFileUploadSize } from '../../../utilities/fileUtils';

const request = require('superagent');

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  menu: {
    width: 200,
  },
  paperSection: {
    width: '100%',
    padding: '1rem',
    marginBottom: '0.75rem',
  },
  listItemTextRoot: {
    wordWrap: 'break-word',
  },
});

const DIALOG_CONFIRM_DELETE = 'DIALOG_CONFIRM_DELETE';

class NotesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        modalConfirmOpen: false,
        contentText: '',
        type: null,
        selectedDoc: null,
      },
    };

    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.getAuthDocuments = this.getAuthDocuments.bind(this);
    this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
    this.handleModalResponse = this.handleModalResponse.bind(this);
    this.doDocumentDelete = this.doDocumentDelete.bind(this);
    this.download = this.download.bind(this);
  }

  componentWillMount() {
    this.getAuthDocuments();
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDeleteDocument(id) {
    this.setState({
      modal: {
        modalConfirmOpen: true,
        contentText: 'Delete this document?',
        type: DIALOG_CONFIRM_DELETE,
        selectedDoc: id,
        title: 'Confirm Deletion',
      },
    });
  }

  handleModalResponse(response, type) {
    switch (type) {
      case DIALOG_CONFIRM_DELETE:
        if (response) {
          this.doDocumentDelete(this.state.modal.selectedDoc);
        }
        break;

      default:
        break;
    }
    this.setState({ modal: { modalConfirmOpen: false, contentText: '', type: null, title: '' } });
  }

  doDocumentDelete(id) {
    request.post(`${BASE_URL  }Authorization/DeleteDocument`)
      .set({ 'Authorization': `Bearer ${  getAuthToken()}` })
      .send({
        'AuthorizationID': this.props.formData.selectedAuth.AuthorizationID,
        'RecordID': id,
      })
      .then((res) => {
        this.getAuthDocuments();
      });
  }

  getAuthDocuments() {
    if (this.props.formData) {
      let authId = this.props.formData.selectedAuth.AuthorizationID;
      if (authId) {
        this.props.getAuthDocuments(authId);
      }
    }
  }

  download(RecordID, AuthorizationID) {
    let postObj = {
      access_token: getAuthToken(),
      AuthorizationID,
      RecordID,
    };

    generateForm('Authorization/GetDocument', postObj);
  }

  handleFileDrop(accepted, rejectedFile) {
    let authId = this.props.formData.selectedAuth.AuthorizationID;

    accepted.forEach((file) => {
      let fileName = file.name;
      let fileNameAry = fileName.split('.');
      let fileExtension = fileNameAry.length > 1 ? fileNameAry.pop() : '';

      const isFileBlocked = isFileExtensionBlocked(fileExtension);

      if (!isFileBlocked) {
        const req = request.post(`${BASE_URL  }Authorization/UploadFile`)
          .set({ 'Authorization': `Bearer ${  getAuthToken()}` })
          .field('AuthorizationId', authId)
          .attach('File', file);

        req.then((res) => {
          this.getAuthDocuments();
        });
      }
    });
  }

  render() {
    const { classes, handleFormChange, formData, authDocuments, readonly } = this.props;
    const { Comment, AuthorizationID } = formData.selectedAuth;
    const { modalConfirmOpen, contentText, type, selectedDoc, title } = this.state.modal;

    if (!formData) {
      return <div />;
    }

    return (
      <form className={classes.container} noValidate autoComplete="off">
        <ResponsiveDialog
          fullScreen={false}
          open={modalConfirmOpen}
          contentText={contentText}
          type={type}
          handleResponse={this.handleModalResponse}
          selectedDoc={selectedDoc}
          title={title}
        />

        <Paper className={classes.paperSection}>
          <h3>Notes</h3>
          <Grid container>
            <TextField
              id="Comment"
              label="Notes"
              multiline
              className={classes.textField}
              value={Comment ? Comment : ''}
              onChange={handleFormChange('Comment')}
              margin="normal"
              disabled={readonly}
            />
          </Grid>
        </Paper>

        <Paper className={classes.paperSection}>
          <Grid container alignItems="flex-start">
            {!readonly &&
              <Grid xs={12} md={6} item>
                <h3>Upload Documents</h3>
                <Dropzone
                  // accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/plain, application/pdf"
                  maxSize={maxFileUploadSize}
                  onDrop={this.handleFileDrop}
                  style={{
                    borderColor: 'black',
                    borderStyle: 'dashed',
                    borderRadius: '0.5rem',
                    height: '100%',
                    width: '100%',
                    cursor: 'pointer',
                  }}
                >
                  <div style={{ margin: '0.5rem' }}>Drop files here, or click to select files to upload.</div>
                </Dropzone>
              </Grid>
            }
            <Grid xs={12} md={6} item>
              <h3>Uploaded Documents</h3>
              <List style={{ border: '1px solid', marginTop: 10, minHeight: 100 }} >
                {authDocuments &&
                  authDocuments.map((d) =>
                    (<ListItem key={d.RecordID}>
                      <ListItemAvatar>
                        <IconButton onClick={() => this.download(d.RecordID, AuthorizationID)}>
                          <FontAwesomeIcon icon="file-download" />
                        </IconButton>
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ root: classes.listItemTextRoot }}
                        primary={d.FileName + (d.FileExtension ? d.FileExtension : '')}
                      // secondary='Secondary text'
                      />
                      {!readonly &&
                        <ListItemSecondaryAction>
                          <IconButton aria-label="Delete" onClick={() => this.handleDeleteDocument(d.RecordID)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>),
                  )
                }
              </List>
            </Grid>
          </Grid>
        </Paper>
      </form>
    );
  }
}

NotesForm.propTypes = {

};

const mapStateToProps = (state) => {
  return {
    authDocuments: state.authorizations.get('authDocuments'),
  };
};

NotesForm = withStyles(styles)(NotesForm);

export default connect(mapStateToProps, {
  getAuthDocuments,
})(NotesForm);

