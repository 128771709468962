import {
  GET_ALL_USERS_FAILURE, GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS,
} from './admin.actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  adminUserList: [],
});

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_ALL_USERS_REQUEST:
      return state.set('isLoading', true);

    case GET_ALL_USERS_SUCCESS:
      return state.set('adminUserList', action.response).set('isLoading', false);
    
    case GET_ALL_USERS_FAILURE:
      return state.set('isLoading', false);

    default:
      return state;
  }
}
