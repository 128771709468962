import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

import { EditCommandCell } from '../../../common/tableEditCommandCell';
import { generateForm } from '../../../utilities/reportingUtils';
import { getAuthToken } from '../../../utilities/authUtils';
import TableCell from '@material-ui/core/TableCell';

import {
  Getter,
} from '@devexpress/dx-react-core';

import {
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import { dateFormatter } from '../../../utilities/dateFormatter';

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={dateFormatter}
    {...props}
  />
);

class DocumentsGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'AddedBy', title: 'Added By' },
        { name: 'AddedOn', title: 'Added On' },
        { name: 'FileName', title: 'Document' },
      ],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['AddedOn'],
      sorting: [{ columnName: 'AddedOn', direction: 'desc' }],
      filters: [],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });

    this.changeFilters = (filters) => this.setState({ filters });

    this.commitChanges = this.commitChanges.bind(this);

    this.TableRow = ({ row, handleViewSubmittedAuth, ...restProps }) => {
      return (<Table.Row
        {...restProps}
        style={{
          cursor: 'default',
        }}
              />);
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  componentWillMount() {
    this.setState({ rows: this.props.rows });
  }

  downloadFile(row){
    let recordID = row.row.RecordID;
    let postObj = {
      access_token: getAuthToken(),
      recordID,
    };

    generateForm('AuthorizationFile/GetDocumentFile', postObj);
  }

  actionsCell = (props) => {
    return (
      <TableCell>
        <EditCommandCell
          id="attachment"
          text=""
          icon="paperclip"
          onExecute={this.downloadFile}
          tableRow={props.tableRow}
        >{React.Children.toArray(props.children)}
        </EditCommandCell>
      </TableCell>
    );
  }

  commitChanges() {

  }

  render() {
    const { rows, columns, pageSize, pageSizes, currentPage, dateColumns, filters, sorting } = this.state;

    return (
      <Paper style={{ padding: '1rem' }}>
        <div style={{ padding: '1rem', fontSize: '1.5rem', width: '50%' }}>Documents</div>
        <Grid
          rows={rows}
          columns={columns}
        >
          <DateTypeProvider
            for={dateColumns}
          />
          <EditingState
            onEditingRowIdsChange={this.changeEditingRowIds}
            onCommitChanges={this.commitChanges}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <IntegratedSorting />
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table rowComponent={this.TableRow} />
          {/* <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={this.changeColumnWidths}
          /> */}
          <TableHeaderRow showSortingControls />
          <TableEditColumn
            style={{ marginRight: '2rem' }}
            cellComponent={this.actionsCell}
          />
          <TableFilterRow />
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => [
              ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
              { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 150 },
            ]}
          />
          <PagingPanel
            pageSizes={pageSizes}
          />
        </Grid>
      </Paper>
    );
  }
}

export default DocumentsGrid;