export const CostUnit = Object.freeze({
  POINTS: 'points',
  CURRENCY: 'currency',
});

export const displayCost = (cost, costUnit) => {
  switch (costUnit) {
    case CostUnit.CURRENCY: {
      const delimitedCost = getDelimitedValue(cost, cost % 1 === 0 ? 0 : 2, 3);
      return `$${delimitedCost}`;
    }
    case CostUnit.POINTS:
    default:{
      const delimitedCost = getDelimitedValue(cost, 0, 3);
      return `${delimitedCost} ${parseInt(delimitedCost) === 1 ? 'pt' : 'pts'}`;
    }
  }
};
// n=number of decimals
// x=number of comma-delimited digits (default 3), ie 123,123
const format = (value, n, x) => {
  let re = `\\d(?=(\\d{${  x || 3  }})+${  n > 0 ? '\\.' : '$'  })`;
  return value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

export const getDelimitedValue = (val, dec, delimitDigits) => {
  return val === 0 || val === undefined || val === null ? 0 : format(val, dec, delimitDigits);
};

export const currencyFormatter = ({ value }) => {
  if (value || value === 0) {
    if (Number.isInteger(value)) {
      return `$${  format(value)}`;
    } else {
      return `$${  format(value, 2, 3)}`;
    }
  }
  return '';
};

// copied from transferee, should refactor currencyFormatter into single function
export const bareCurrencyFormatter = ({ value, showDollarSign }) => {
  if (value || value === 0) {
    return showDollarSign ? '$' : `${  format(value, 2, 3)}`;
  }
  return '';
};

// allow search for currency with dollar signs
// return true if match found for either $value or value
export const currencyFilterPredicate = (value, filter) => {
  return filter.value ?
    value.toString().indexOf(filter.value) > -1 || (`$${  value}`).indexOf(filter.value) > -1
    : false;
};

export default { currencyFormatter, getDelimitedValue, currencyFilterPredicate };
