import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tooltip, useTheme } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { TwoDecimalFormat } from 'common/numberFormatCustom.component';
import { getAutoCompleteChipProps, useRefCallback } from 'utilities/formUtils';
import PolicyBenefits from './policyBenefits.component';
import PolicyContext from './policyContext';
import TRCInputValidator from 'common/form/TRCInputValidator';

const PolicyForm = (props) => {
  const { version, setVersion, reloPolicies, isReadOnly, hasValidated, toggleStepStatus } = useContext(PolicyContext);
  const { formStep } = props;

  const [isFormValid, setIsFormValid] = useState(true);
  const [formRef, setFormRef] = useRefCallback();
  const theme = useTheme();

  const getChipProps = useMemo(() => {
    return getAutoCompleteChipProps(isReadOnly);
  }, [isReadOnly]);

  const moveTrackPolicyNames = useMemo(() => {
    return reloPolicies.map((reloPol) => reloPol.PolicyCode);
  }, [reloPolicies]);

  const selectedMoveTrackPolicyNames = useMemo(() => {
    return version.moveTrackPolicies.map((mtPol) => mtPol.name);
  }, [version.moveTrackPolicies]);

  // force form to mark invalid fields after publish attempted 
  useEffect(() => { 
    if (formRef && hasValidated) {
      formRef.isFormValid(false);
    }
  }, [hasValidated, formRef]);

  useEffect(() => {
    (async () => {
      if (!formRef) {
        return;
      }
      setIsFormValid(await formRef.isFormValid());
    })();
  });

  useEffect(() => {
    toggleStepStatus(formStep, isFormValid);
  }, [isFormValid, toggleStepStatus, formStep]);

  return (
    <ValidatorForm ref={setFormRef} onSubmit={() => {}} instantValidate={false}>
      <Box m={3} mr={0}>
        <Grid container>
          <Grid item xs={6}>
            <TRCInputValidator
              component={TextValidator}
              InputProps={{ readOnly: isReadOnly }}
              label="Self Service Policy"
              name="policyName"
              onChange={(value) => setVersion({ ...version, name: value })}
              value={version.name}
              required
              validateOnBlur={hasValidated}
              validateOnChange={hasValidated}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} container>
            <Grid item xs={9}>
              <TRCInputValidator
                component={TextValidator}
                InputProps={{ readOnly: isReadOnly, inputComponent: TwoDecimalFormat }}
                label="Budget"
                name="budget"
                onChange={(value) => setVersion({ ...version, budget: value })}
                value={version.budget}
                validateOnBlur={hasValidated}
                validateOnChange={hasValidated}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TRCInputValidator
              component={TextValidator}
              InputProps={{ readOnly: isReadOnly }}
              label="Description"
              name="benefitDescription"
              onChange={(value) => setVersion({ ...version, description: value })}
              value={version.description}
              required
              validateOnBlur={hasValidated}
              validateOnChange={hasValidated}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={6} container>
            <Grid item xs={9}>
              <Autocomplete
                value={selectedMoveTrackPolicyNames}
                multiple
                filterSelectedOptions
                options={moveTrackPolicyNames}
                disabled={isReadOnly}
                onChange={(e, newVal) => {
                  const selectedPols = reloPolicies.filter((reloPol) => newVal.includes(reloPol.PolicyCode));
                  setVersion({ ...version, moveTrackPolicies: selectedPols.map((pol) => ({ name: pol.PolicyCode })) });
                }}
                renderInput={(params) => {
                  params.disabled = false; // removes the styling added when disabled = true
                  return (
                    <TRCInputValidator
                      {...params}
                      component={TextValidator}
                      InputProps={{ ...params.InputProps, readOnly: isReadOnly }}
                      label="Relo Policies"
                      value={selectedMoveTrackPolicyNames}
                    />
                  );
                }}
                ChipProps={getChipProps}
              />
            </Grid>
            <Grid item xs={3} container spacing={0}>
              <Box p={2}>
                <Tooltip arrow title="These are the policies that a new authorization should be assigned to in order to access this self service policy">
                  <Box>
                    <FontAwesomeIcon size="lg" color={theme.palette.primary.light} icon={['fas', 'question-circle']} />
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <PolicyBenefits />
    </ValidatorForm>);
};

PolicyForm.propTypes = {
  formStep: PropTypes.number.isRequired,
};

export default PolicyForm;