/* eslint-disable react/jsx-no-duplicate-props */
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  underline: {
    'pointer-events': 'none',
    '&:before': {
      left: 0,
      top: '2px',
      // Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      height: 2,
      position: 'absolute',
      right: 0,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shorter,
      }),
    },
  },
  input: {
    cursor: 'default',
    textOverflow: 'ellipsis',
  },
  inputLabel: {
    color: '#15585e',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
});

let ReadOnlyText = (props) => {
  const { classes, inputComponent, label, multiline, name, value } = props;
  let { textColor } = props;
  if (!textColor) {
    textColor = '#15585e';
  }

  return (
    <TextField
      inputProps={{
        readOnly: true,
      }}
      InputProps={{
        classes: {
          underline: classes.underline,
          input: classes.input,
        },
        inputComponent,
        style: { 'underline:before': { color: textColor } },
      }}
      InputLabelProps={{
        classes: {
          disabled: classes.inputLabel,
        },
        disabled: true,
        style: { color: textColor, width: 'max-content' },
      }}
      multiline={(multiline) ? true : false}
      fullWidth={true}
      label={label}
      name={name}
      margin="normal"
      value={value}
    />
  );
};

ReadOnlyText = withStyles(styles)(ReadOnlyText);

export default ReadOnlyText;