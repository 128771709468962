import { authorizedRouteMap } from '../auth/authorizedRouteMap';
import { matchPath } from 'react-router-dom';
import auth from '../auth/auth';
import jwtDecode from 'jwt-decode';

const TRC_APP_CLIENT_ID = 'TRC_APP_CLIENT_ID';
const TRC_APP_CLIENT_NAME = 'TRC_APP_CLIENT_NAME';
const TRC_APP_SELECTED_DIVISIONS = 'TRC_APP_SELECTED_DIVISIONS';
const TRC_APP_LOGIN_REDIRECT = 'TRC_APP_LOGIN_REDIRECT';

const TRC_APP_AUTH0_KEY = 'http://relosourceapi.trcgm.com/';

const clearClientInformation = () => {
  window.localStorage.removeItem(TRC_APP_CLIENT_ID);
  window.localStorage.removeItem(TRC_APP_CLIENT_NAME);
};

export const clearDivisionInformation = () => {
  window.localStorage.removeItem(TRC_APP_SELECTED_DIVISIONS);
};

export const getSelectedClientInformation = () => {
  let clientId = window.localStorage.getItem(TRC_APP_CLIENT_ID) || null;
  let clientName = window.localStorage.getItem(TRC_APP_CLIENT_NAME) || null;

  return clientId === 'null' || clientId === null ?
    { id: null, description: null } :
    { id: clientId, description: clientName };
};

export const getSelectedDivisions = () => {
  let divisions = window.localStorage.getItem(TRC_APP_SELECTED_DIVISIONS) || null;
  return divisions === 'null' || divisions === null ? [] : divisions.split(',');
};

export const setClientInformation = (client) => {
  window.localStorage.setItem(TRC_APP_CLIENT_ID, client && client.id ? client.id : null);
  window.localStorage.setItem(TRC_APP_CLIENT_NAME, client && client.description ? client.description : null);
};

// accept array of divisions and set in local storage
export const setSelectedDivisions = (divisions) => {
  window.localStorage.setItem(TRC_APP_SELECTED_DIVISIONS, divisions && divisions.length > 0 ? divisions.join(',') : null);
};

export const getAuthToken = () => {
  let token = window.localStorage.getItem('access_token');
  return token ? token : null;
};

export const initLocalStorageDivisions = (clientDivisions) => {
  const availableClients = getUserClients(clientDivisions);

  // if user only has 1 client, set it as selected client
  // if user has more than 1, client must select from modal
  let defaultClientId = null;
  if (availableClients.length === 1) {
    defaultClientId = availableClients[0].id;

    // store values in local storage
    setClientInformation({
      id: defaultClientId,
      description: availableClients[0].description,
    });

  } else if (availableClients.length === 0) {
    // if user has no clients/divisions, logout
    alert('This user isn\'t assigned to any clients and cannot proceed.  Please resolve this and try again.');
    logout();
  }

  // check whether divisions are already selected
  let selectedDivisions = getSelectedDivisions();

  // if default client was chosen and no divisions are selected, set default divisions (all divisions)
  if (defaultClientId && !selectedDivisions.length > 0) {
    let divisions = getUserDivisions(defaultClientId, clientDivisions);
    if (divisions.length > 0) {
      setSelectedDivisions(divisions.map((d) => d.id));
    }
  }
};

const parseToken = (token) => {
  try {
    const tokenData = jwtDecode(token);
    return tokenData ? tokenData : null;
  } catch (e) {
    console.log('auth token parse error: ', e);
    return null;
  }
};

export const getIdToken = () => {
  let token = window.localStorage.getItem('id_token');
  return token ? token : null;
};

export const getParsedToken = () => {
  const token = parseToken(getAuthToken());
  if (token) {
    token.userId = token.sub;
  }
  return token;
};

export const getUserPermissionsList = () => {
  const token = parseToken(getAuthToken());
  if (token) {
    const data = token[`${TRC_APP_AUTH0_KEY  }permissions`];
    return data ? data : [];
  }
  return [];
};

export const getUserRoleList = () => {
  const token = parseToken(getAuthToken());
  if (token) {
    const data = token[`${TRC_APP_AUTH0_KEY  }roles`];
    return data ? data : [];
  }
  return [];
};

export const getUserMetadata = () => {
  const token = parseToken(getAuthToken());
  if (token) {
    const data = token[`${TRC_APP_AUTH0_KEY  }user_metadata`];
    return data ? data : [];
  }
  return [];
};

export const getUserClients = (clientDivisions) => {
  if (clientDivisions && clientDivisions.length > 0) {
    let output = [];
    clientDivisions.forEach((item) => {
      if (output.findIndex((o) => o.id === item.clientID) === -1) {
        output.push({
          id: item.clientID,
          description: item.clientDescription,
        });
      }
    });

    return output.sort((a, b) => {
      if (a.description < b.description) { return -1; }
      if (a.description > b.description) { return 1; }
      return 0;
    });
  }
  return [];
};

export const getUserDivisions = (clientID, clientDivisions) => {
  if (clientDivisions && clientDivisions.length > 0) {
    let output = [];
    let data = clientDivisions.filter((cd) => cd.clientID === clientID);
    data.forEach((item) => {
      if (output.findIndex((o) => o.id === item.divisionID) === -1) {
        // exclude top level -1 division from options
        if (item.divisionID !== '-1') {
          output.push({
            id: item.divisionID,
            description: item.divisionDescription,
          });
        }
      }
    });
    return output;
  }
  return [];
};

export const getUserName = () => {
  const token = parseToken(getAuthToken());
  if (token) {
    const data = token['sub'];
    return data ? data : [];
  }
  return [];
};

export const getUserEmail = () => {
  const token = parseToken(getIdToken());
  if (token) {
    const data = token['name'];
    return data ? data : [];
  }
  return [];
};

// return true if user is allowed to visit the requested path
// return true by default if no required permission is provided
export const isPathAuthorized = (path, clientDivisions) => {
  const requiredPermissionKey = Object.keys(authorizedRouteMap)
    .find((key) => matchPath(path, {
      path: key,
      exact: true,
      strict: false,
    }));

  if (requiredPermissionKey) {
    return userHasPermission(authorizedRouteMap[requiredPermissionKey], clientDivisions);
  }

  return true;
};

// return whether a user has selected a client from client list
export const isClientSelected = () => {
  return !!getSelectedClientInformation().id;
};

// check for presence of a certain user permission
// can be either:
// user permission from the token
// client permission from clientDivisions object
export const userHasPermission = (permission, clientDivisions) => {
  const userPermissions = getUserPermissionsList();
  let hasUserPermission = userPermissions.some((up) => up === `user:${permission}`);
  if (hasUserPermission) {
    return true;
  }

  let selectedClient = getSelectedClientInformation();
  if (selectedClient) {
    let filteredClientDivisions = clientDivisions && clientDivisions.filter((cd) => cd.clientID === selectedClient.id);
    if (filteredClientDivisions && filteredClientDivisions.length > 0) {
      // check first instance for userClientPermissions
      let { userClientPermissions } = filteredClientDivisions[0];
      if (!userClientPermissions) userClientPermissions = [];
      return userClientPermissions.some((ucp) => ucp === `client:${permission}`);
    }
  } else {
    return false;
  }
};

// check for presence of a certain role
export const userHasRole = (role) => {
  const roles = getUserRoleList();
  return roles.findIndex((r) => r === role) > -1;
};

// token must exist
// must be able to decode token
// token must not be expired
export const isAuthenticated = () => {
  let lsExpires = window.localStorage.getItem('expires_at');
  let token = getAuthToken();

  try {
    const tokenData = jwtDecode(token);
    if (lsExpires && token && tokenData) {
      return new Date().getTime() < lsExpires;
    } else {
      return false;
    }
  } catch (e) {
    console.log('error decoding auth token: ', e);
    return false;
  }
};

export const setSession = (authResult) => {
  // Set the time that the Access Token will expire at
  let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
  window.localStorage.setItem('access_token', authResult.accessToken);
  window.localStorage.setItem('id_token', authResult.idToken);
  window.localStorage.setItem('expires_at', expiresAt);
};

export const logout = () => {
  // Clear Access Token and ID Token from local storage
  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('id_token');
  window.localStorage.removeItem('expires_at');
  clearClientInformation();
  clearDivisionInformation();
  auth.logout();
};

export const setRedirectPath = (path) => {
  window.localStorage.setItem(TRC_APP_LOGIN_REDIRECT, path === '/callback' ? '/' : path);
  console.log('setRedirectPath', path);
};

export const getRedirectPath = () => {
  let lsPath = window.localStorage.getItem(TRC_APP_LOGIN_REDIRECT);
  console.log('getRedirectPath', lsPath);
  return lsPath === '/callback' ? '/' : lsPath;
};

//Added via AB#652 to handle custom field requirement for Client "AirBorn Inc."
export const isAirBornClient = () => {
  let results = false;
  let environment = process.env.REACT_APP_ANALYTICS_ENV;
  let clientId = getSelectedClientInformation().id;
  if(environment === 'dev' && clientId === '313') {
    results = true;
  } else if(environment === 'test' && clientId === '304') {
    results = true;
  } else if(environment === 'production' && clientId === '314') {
    results = true;
  }
  return results;
};

//Added via AB#666 to handle custom rules for Client "Helmerich and Payne" (aka H&P)
export const isHPClient = () => {
  let results = false;
  let environment = process.env.REACT_APP_ANALYTICS_ENV;
  let clientId = getSelectedClientInformation().id;
  if(environment === 'dev' && clientId === 'XXX') { //At the time of AB#666, this Client did not exist in the DEV db yet.
    results = true;
  } else if(environment === 'test' && clientId === '301') {
    results = true;
  } else if(environment === 'production' && clientId === '312') {
    results = true;
  }
  return results;
};

//Added via AB#869 to handle custom rules for Client "KLA Tax"
export const isKlaTaxClient = () => {
  let results = false;
  let environment = process.env.REACT_APP_ANALYTICS_ENV;
  let clientId = getSelectedClientInformation().id;
  if(environment === 'dev' && clientId === '313') { //At the time of AB#869, this Client did not exist in the DEV db yet.
    results = true;
  } else if(environment === 'test' && clientId === '313') {
    results = true;
  } else if(environment === 'production' && clientId === '320') {
    results = true;
  }
  return results;
};

//Added via AB#869 to handle custom rules for Client "Battelle Energy Alliance, LLC" (aka "BEA")
export const isBattelleEnergyAllianceClient = () => {
  let results = false;
  let environment = process.env.REACT_APP_ANALYTICS_ENV;
  let clientId = getSelectedClientInformation().id;
  if(environment === 'dev' && clientId === '292') {
    results = true;
  } else if(environment === 'test' && clientId === '292') {
    results = true;
  } else if(environment === 'production' && clientId === '292') {
    results = true;
  }
  return results;
};

//Added via AB#943 to handle custom rules for Client "Southwire Company"
export const isSouthwireClient = () => {
  let results = false;
  let environment = process.env.REACT_APP_ANALYTICS_ENV;
  let clientId = getSelectedClientInformation().id;
  if(environment === 'dev' && clientId === '52') {
    results = true;
  } else if(environment === 'test' && clientId === '52') {
    results = true;
  } else if(environment === 'production' && clientId === '52') {
    results = true;
  }
  return results;
};

//Added via AB#1031 to handle custom rules for Client "CRH Americas Materials"
export const isCRHClient = () => {
  let results = false;
  let environment = process.env.REACT_APP_ANALYTICS_ENV;
  let clientId = getSelectedClientInformation().id;
  if(environment === 'dev' && clientId === '232') {
    results = true;
  } else if(environment === 'test' && clientId === '232') {
    results = true;
  } else if(environment === 'production' && clientId === '232') {
    results = true;
  }
  return results;
};