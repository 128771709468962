import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
const styles = (theme) => ({
  headerPaper: {
    height: '40px',
    width: '90%',
    position: 'absolute',
    top: '-20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    zIndex: 100,
    borderRadius: '0.5rem',
    background: 'linear-gradient(45deg,#6B6A6A,#393939)',
  },
  headerText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  paperSection: {
    height: '100%',
    position: 'relative',
    borderRadius: '0.5rem',
    cursor: 'pointer',
  },
  cardIcon: {
    height: '85px',
    width: '85px',
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      height: '55px',
      width: '55px',
    },
    borderRadius: '0.5rem',
  },
});

const MPCCard = (props) => {
  const { classes, title, icon, bgColor, to, history } = props;
  console.log('typeof', typeof classes);
  return (
    <Paper className={classes.paperSection} onClick={() => history.push(to)}>
      <Paper className={classes.headerPaper}>
        <div className={classes.headerText}>{title}</div>
      </Paper>
      <Paper
        className={classes.cardIcon}
        style={{
          background: bgColor,
        }}
      >
        <FontAwesomeIcon icon={icon} color="white" size="3x" />
      </Paper>
    </Paper>
  );
};

MPCCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  bgColor: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(MPCCard);
