import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

import { CurrencyFormat } from '../../../common/numberFormatCustom.component';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS, showToast } from '../../layout/layout.actions';
import { requiredField } from '../../../utilities/fieldValidation';
import { updateExceptionApprovalStatus } from '../exceptions.actions';
import ConfirmModal from '../../../common/confirmModal.component';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    'max-width': '200px',
    width: '100%',
  },
  textFieldWide: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    'max-width': '400px',
    width: '60%',
  },
  paperSection: {
    width: '100%',
    padding: '1rem',
    marginBottom: '0.75rem',
  },
  approveButton: {
    float: 'right',
    '&:hover': {
      'background-color': '#fff',
      '& > span': {
        color: '#15585e',
      },
    },
    '& > span': {
      color: '#fff',
    },
  },
  denyButton: {
    'background-color': '#fff',
    '&:hover': {
      'background-color': '#15585e',
      '& > span': {
        color: '#fff',
      },
    },
    '& > span': {
      color: '#15585e',
    },
  },
  '@media (min-width: 1381px)': {
    approveButton: {
      float: 'none',
      position: 'absolute',
      bottom: '8px',
      right: 0,
    },
    denyButton: {
      position: 'absolute',
      bottom: '8px',
      right: '120px',
      'background-color': '#fff',
    },
    typeItem: {
      width: '50%',
    },
  },
  '@media (max-width: 1380px)': {
    typeItem: {
      width: '100%',
    },
  },
});

class ApprovalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedException: {
        Comment: '',
        ApprovalAmount: '',
        ExceptionId: '',
        IsApproved: '',
      },
      modalState: false,
      modalType: '',
    };

    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
    this.handleExceptionApproval = this.handleExceptionApproval.bind(this);
    this.handleExceptionDeny = this.handleExceptionDeny.bind(this);
    this.handleDenyModalToggle = this.handleDenyModalToggle.bind(this);
    this.handleApprovalModalToggle = this.handleApprovalModalToggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedException: {
        ExceptionId: this.props.selectedException.ExceptID,
      },
    });
  }

  handleFormFieldChange = (name) => (event) => {
    this.setState({
      ...this.state,
      selectedException: {
        ...this.state.selectedException,
        [name]: event.target.value,
      },
    });
  }

  handleApprovalModalToggle() {
    this.setState((prevState) => ({
      modalState: !prevState.modalState,
      modalType: 'approve',
    }));
  }

  handleDenyModalToggle() {
    this.setState((prevState) => ({
      modalState: !prevState.modalState,
      modalType: 'deny',
    }));
  }

  getFormErrors() {
    let errors = [];
    let fieldValidations = [
      {
        name: 'Comment',
        validators: [requiredField],
        friendlyName: 'Comment',
      },
      {
        name: 'ApprovedAmount',
        validators: [requiredField],
        friendlyName: 'Approved Amount',
      },
    ];

    Object.keys(this.state.selectedException).forEach((key) => {
      let foundKey = fieldValidations.find((item) => item.name === key);

      if (foundKey) {
        foundKey.validators.forEach((v) => {
          let error = v(this.state.selectedException[key]);
          if (error) {
            errors.push(`${foundKey.friendlyName  }: ${  error}`);
          }
        });
      }
    });

    return errors;
  }

  async handleExceptionApproval() {
    const approved = Object.assign({}, this.state.selectedException, { IsApproved: true });

    if (approved.ApprovalAmount == null) {
      approved.ApprovalAmount = Number(this.props.requestAmount).toFixed(2).toString();
    }

    let response = await this.props.updateExceptionApprovalStatus(approved);
    if (response.type === 'UPDATE_EXCEPTION_APPROVAL_SUCCESS') {
      this.props.showToast('Successfully approved exception.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      this.props.history.push('/exceptions');
    } else {
      this.props.showToast('Error approving exception. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  async handleExceptionDeny() {
    const unapproved = Object.assign({}, this.state.selectedException, { IsApproved: false });

    let response = await this.props.updateExceptionApprovalStatus(unapproved);
    if (response.type === 'UPDATE_EXCEPTION_APPROVAL_SUCCESS') {
      this.props.showToast('Successfully denied exception.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      this.props.history.push('/exceptions');
    } else {
      this.props.showToast('Error denying exception. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  }

  render() {
    let { Comment, ApprovalAmount } = this.state.selectedException;
    let { modalState, modalType } = this.state;

    const { classes } = this.props;

    const formErrors = this.getFormErrors();
    const formHasErrors = formErrors.length > 0;

    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off">
          <Paper className={classes.paperSection}>
            <h3>Exception Note</h3>
            <Grid>
              <TextField
                className={classes.textFieldWide}
                id="Comment"
                label="Comment"
                margin="normal"
                multiline
                onChange={this.handleFormFieldChange('Comment')}
                placeholder="Comment"
                value={Comment ? Comment : ''}
              />
            </Grid>
            <Grid container>
              <Grid item className={classes.typeItem}>
                <TextField
                  className={classes.textField}
                  id="ApprovalAmount"
                  label="Approval Amount"
                  margin="normal"
                  onChange={this.handleFormFieldChange('ApprovalAmount')}
                  placeholder="Approved Amount"
                  InputProps={{ inputComponent: CurrencyFormat }}
                  value={ApprovalAmount ? ApprovalAmount : ''}
                />
              </Grid>
              <Grid item className={classes.typeItem} style={{ position: 'relative' }}>
                <Button
                  className={classes.denyButton}
                  disabled={formHasErrors}
                  onClick={this.handleDenyModalToggle}
                  variant="contained"
                >Deny</Button>
                <Button
                  className={classes.approveButton}
                  color="primary"
                  disabled={formHasErrors}
                  onClick={this.handleApprovalModalToggle}
                  variant="contained"
                >Approve</Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
        {modalState && modalType === 'approve' &&
          <ConfirmModal
            cancelText="Cancel"
            confirmText="Confirm"
            handleClose={this.handleApprovalModalToggle}
            handleConfirm={this.handleExceptionApproval}
            modalState
            titleText="Approve Exception"
          />
        }
        {modalState && modalType === 'deny' &&
          <ConfirmModal
            cancelText="Cancel"
            confirmText="Confirm"
            handleClose={this.handleDenyModalToggle}
            handleConfirm={this.handleExceptionDeny}
            modalState
            titleText="Deny Exception"
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedException: state.exceptions.get('pendingException'),
  };
};

ApprovalForm = withStyles(styles)(ApprovalForm);

export default withRouter(connect(mapStateToProps, {
  showToast,
  updateExceptionApprovalStatus,
})(ApprovalForm));

