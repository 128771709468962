import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

class FormGetReportButton extends Component {
  render() {
    const { classes, disabled, onClick } = this.props;

    return (
      <div style={{ margin: 'auto' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={disabled}
          className={classes.button}
        >Run Report
        </Button>
      </div>
    );
  }
}

FormGetReportButton = withStyles(styles)(FormGetReportButton);

export default FormGetReportButton;