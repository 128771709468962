import ReactHtmlParser from 'react-html-parser';

const getImageNodes = (html) => {
  let imageNodes = [];
  let transform = (node) => {
    if (node.type === 'tag' && node.name === 'img') {
      imageNodes.push(node);
    }
  };
  ReactHtmlParser(html, { transform });
  return imageNodes;
};

export const getBlogImage = (htmlString) => {
  const elems = getImageNodes(htmlString);
  let mappedImages = [];
  if (elems) {
    mappedImages = elems.map((elem) => {
      if (elem.attribs) {
        const { src, alt } = elem.attribs;
        return { src, alt };
      }
      return null;
    });
    // return first image in dom
    return mappedImages[0];
  }
  return null;
};

export default { getBlogImage };