export const emailTypeMap = [
  {
    type: 'Work',
    field: 'OldEmail',
  },
  {
    type: 'Spouse',
    field: 'SpouseEmail',
  },
  {
    type: 'Personal',
    field: 'AltEmail',
  },
];

export default { emailTypeMap };