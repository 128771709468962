import { changePassword } from '../../auth/auth';
import { connect } from 'react-redux';
import { getUserEmail } from '../../utilities/authUtils';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = (theme) => ({
  dialogPaper: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  row: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  listItemTextRoot: {
    whiteSpace: 'pre-line',
  },
});

class ChangePasswordDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordWasReset: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.setState({ passwordWasReset: false }, this.props.handleClose);
  }

  handleSubmit() {
    changePassword(getUserEmail(), () => {
      this.setState({ passwordWasReset: true });
    });
  }

  render() {
    const { title, open, classes, handleClose, showAsModal, contentText } = this.props;

    return (
      <div>
        <Dialog
          classes={{
            paper: classes.dialogPaper,
          }}
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown={showAsModal}
          disableBackdropClick={showAsModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent style={{ paddingBottom: '0px' }}>
            <div>{this.state.passwordWasReset ? 'Your password has been reset.  You will receive an email shortly with instructions on how to change it.' : contentText}</div>
          </DialogContent>
          {
            this.state.passwordWasReset ?
              <DialogActions>
                {!showAsModal && <Button onClick={() => this.handleClose()} color="primary" autoFocus>
                  Ok
                </Button>}
              </DialogActions>
              :
              <DialogActions>
                {!showAsModal && <Button onClick={() => this.handleClose()} color="primary" autoFocus>
                  Cancel
                </Button>}
                <Button onClick={() => this.handleSubmit()} color="primary" autoFocus>
                  Reset Password
                </Button>
              </DialogActions>
          }
        </Dialog>
      </div >
    );
  }
}

ChangePasswordDialog = withStyles(styles, { withTheme: true })(ChangePasswordDialog);

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {

})(withMobileDialog()(ChangePasswordDialog));