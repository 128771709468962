import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    zIndex: '2000',
    width: '280px',
    maxWidth: 'calc(95vw)',
  },
  modalBackdrop: {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '2',
    position: 'fixed',
    willChange: 'opacity',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  confirmButton: {
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#fff',
      '& > span': {
        color: '#15585e',
      },
    },
    '& > span': {
      color: '#fff',
    },
  },
  cancelButton: {
    marginTop: '20px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#15585e',
      '& > span': {
        color: '#fff',
      },
    },
    '& > span': {
      color: '#15585e',
    },
  },
});

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalHeight: '0',
      modalWidth: '0',
    };
  }

  componentDidMount() {
    this.setState({
      modalHeight: this.modal.clientHeight,
      modalWidth: this.modal.clientWidth,
    });
  }

  render() {
    const {
      cancelText,
      hideCancel,
      classes,
      confirmText,
      handleClose,
      handleConfirm,
      bodyText,
      titleText } = this.props;

    return (
      <div
        // className="MuiModal-root-320"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={classes.modalBackdrop}
          aria-hidden="true"
        />
        <div
          className={classes.paper}
          ref={(elem) => { this.modal = elem; }}
          style={{ margin: `-${(this.state.modalWidth / 2) - 37}px 0 0 -${(this.state.modalHeight / 2) + 37}px` }}
        >
          <h3>{titleText}</h3>
          {bodyText &&
            <Grid>
              <Typography component="div">
                {bodyText}
              </Typography>
            </Grid>
          }
          <div className="row justify-space-around">
            {!hideCancel &&
              <Button
                className={classes.cancelButton}
                onClick={handleClose}
                variant="contained"
              >
                {cancelText}
              </Button>
            }
            <Button
              className={classes.confirmButton}
              color="primary"
              onClick={handleConfirm}
              variant="contained"
            >{confirmText}</Button>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmModal = withStyles(styles)(ConfirmModal);

export default ConfirmModal;