import { createSelector } from 'reselect';

import { RequestStatusEnum } from 'index.types';

const isLoadingBenefitsSelector = (state) => state.benefit.isLoadingBenefits;
const isLoadingBenefitSelector = (state) => state.benefit.isLoadingBenefit;
const isLoadingLookupTablesSelector = (state) => state.benefit.isLoadingLookupTables;
const isLoadingCostLookupApisSelector = (state) => state.benefit.isLoadingCostLookupApis;
const isLoadingCostLookupTableSelector = (state) => Object.values(state.benefit.costLookupByTable).some((lookup) => lookup.status === RequestStatusEnum.PENDING);
const isLoadingCostLookupApiSelector = (state) => Object.values(state.benefit.costLookupByApi).some((lookup) => lookup.status === RequestStatusEnum.PENDING);
const isCloningSelector = (state) => state.benefit.isCloning;

export const isLoadingSelector = createSelector(
  isLoadingBenefitsSelector,
  isLoadingBenefitSelector,
  isLoadingLookupTablesSelector,
  isLoadingCostLookupApisSelector,
  isLoadingCostLookupTableSelector,
  isLoadingCostLookupApiSelector,
  isCloningSelector,
  (...isLoadingBooleans) => isLoadingBooleans.some((isLoading) => isLoading),
);