import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useFocus } from 'common/customHooks';

const useStyles = makeStyles((theme) => ({
  input : {
    flex:1,
    border: 'none',
    background: 'none',
    '&:focus': {
      outline: 'none',
    },
    fontFamily: '\'Open Sans\', Arial, Helvetica, sans-serif',
    fontSize: '13px',
  },
}));

const MessageCompose = (props) => {
  const [message, setMessage] = useState('');
  const { addMessageFromUser, disabled } = props;
  const classes = useStyles(props);

  const [messageInputRef, setMessageInputFocus] = useFocus();

  useEffect(() => {
    if (!disabled) setMessageInputFocus();
  }, [disabled, setMessageInputFocus]); 

  const onKeyPress = (event) => {
    if (event.key === 'Enter'){
      addMessageFromUser(message);
      setMessage('');
    }
  };

  const onChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <input
      ref={messageInputRef}
      className={classes.input}
      type="text"
      placeholder="Type a message"
      onKeyPress={onKeyPress}
      onChange={onChange}
      disabled={disabled}
      value={message}
    />
  );
};

MessageCompose.propTypes = {
  disabled : PropTypes.bool.isRequired,
  addMessageFromUser : PropTypes.func.isRequired,
};

export default MessageCompose;