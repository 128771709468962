import { connect } from 'react-redux';
import {
  getClientContacts,
} from './contacts.actions';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import ContactCard from './contactCard.component';
import Grid from '@material-ui/core/Grid';


class ContactsContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('My Contacts');
    this.props.getClientContacts();
  }

  render() {
    let { contacts } = this.props;

    return (
      <div style={{ padding: '2rem' }}>
        <Grid container>
          {contacts.map((contact, i) => {
            return (
              <Grid item xs={12} sm={8} md={8} lg={6} key={i}>
                <ContactCard key={i} contact={contact} />
              </Grid>
            );
          },
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts.get('contacts'),
  };
};

export default withRouter(connect(mapStateToProps, {
  setPageTitle,
  getClientContacts,
})(ContactsContainer));
