import { Card, CardContent, CardMedia, Chip, Divider, Grid, Switch, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { downloadImage } from 'utilities/firebase';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { COST_TYPES } from 'modules/benefits/constants';
import { calculateBenefitCost } from './costUtil';
import { canToggleOption, isOptionEnabled } from './policyBenefitUtil';
import { getCostLookupByApi, getCostLookupByTable } from 'modules/benefits/benefit.actions';
import { getDelimitedValue } from 'utilities/currencyFormatter';
import { isRequestComplete } from 'index.types';
import Spinner from 'common/logoSpinner.component';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => (
  {
    card: {
      width: 400,
      flexGrow: 1,
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipClickable: {
      cursor: 'pointer',
    },
  }
));

const PolicyBenefitCard = (props) => {
  const {
    toggleEnabled,
    toggleOption,
    mergedBenefit,
    isReadOnly,
    benefitIsToggleable,
    enabled,
    costList,
    costLookupApi,
    attemptedCostLookup,
    getCostLookupByTable: propsGetCostLookupByTable,
    getCostLookupByApi: propsGetCostLookupByApi,
  } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const classes = useStyles();

  const renderOption = (opt) => {
    const toggleable = canToggleOption(mergedBenefit, opt);
    const optionEnabled = isOptionEnabled(opt);
    if (isReadOnly) {
      return (
        <Chip
          key={opt.id}
          className={classes.chip}
          label={opt.name}
          color={optionEnabled ? 'primary' : 'default'}
        />
      );
    } else if (toggleable) {
      return (
        <Chip
          key={opt.id}
          className={classNames(classes.chip, classes.chipClickable)}
          color={optionEnabled ? 'primary' : 'default'}
          label={opt.name}
          onClick={() => toggleOption(opt, !optionEnabled)}
        />
      );
    } else {
      return (
        <Tooltip title="Option is not available." key={opt.id}>
          <Chip
            className={classes.chip}
            label={opt.name}
          />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setImageUrl(await downloadImage(mergedBenefit.imageId));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    })();
  }, [mergedBenefit.imageId]);

  useEffect(() => {
    if (mergedBenefit.costType === COST_TYPES.TABLE_LOOKUP && mergedBenefit.costLookupKey && isEmpty(costList) && !attemptedCostLookup) {
      propsGetCostLookupByTable(mergedBenefit.costLookupKey);
    }
  }, [mergedBenefit.costLookupKey, mergedBenefit.costType, costList, attemptedCostLookup, propsGetCostLookupByTable]);

  useEffect(() => {
    if (mergedBenefit.costType === COST_TYPES.API_CALC && mergedBenefit.costLookupKey && isEmpty(costLookupApi) && !attemptedCostLookup) {
      propsGetCostLookupByApi(mergedBenefit.costLookupKey);
    }
  }, [mergedBenefit.costLookupKey, mergedBenefit.costType, costLookupApi, attemptedCostLookup, propsGetCostLookupByApi]);

  const displayCostRange = (range) => {
    if (!range) {
      return null;
    }
    const lowestFormatted = getDelimitedValue(range.lowest, 0, 3);
    if (range.highest !== range.lowest) {
      const highestFormatted = getDelimitedValue(range.highest, 0, 3);
      return `${lowestFormatted} - ${highestFormatted}`;
    } else {
      return lowestFormatted;
    }
  };

  return (
    <Card className={classes.card}>
      {imageUrl ?
        <CardMedia image={imageUrl} className="media" /> :
        <div className="center-container">
          <Spinner />
        </div>
      }
      <CardContent>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={isReadOnly ? 12 : 10}>
            <Typography variant="h5">
              {mergedBenefit.name}
            </Typography>
            <Box height={30}>
              <Typography variant="h6">
                {displayCostRange(calculateBenefitCost(mergedBenefit, { costList, api: costLookupApi }))}
              </Typography>
            </Box>
          </Grid>
          {
            !isReadOnly &&
              <Grid item xs={2}>
                {benefitIsToggleable ?
                  <Switch
                    checked={enabled}
                    onChange={() => toggleEnabled(!enabled)}
                    value={enabled}
                    color={enabled ? 'primary' : 'default'}
                  /> :
                  <Tooltip title="Benefit is not available.">
                    <div>
                      <Switch
                        checked={false}
                        value={false}
                        disabled={true}
                      />
                    </div>
                  </Tooltip>
                }
              </Grid>
          }
        </Grid>
      </CardContent>
      <Divider variant="middle" />
      <CardContent>
        <Box mb={2}>
          <Typography variant="subtitle1">
            Benefit Option(s)
          </Typography>
        </Box>
        {mergedBenefit.options.map(renderOption)}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, { mergedBenefit }) => {
  if (mergedBenefit.costType === COST_TYPES.TABLE_LOOKUP) {
    const costLookupByTable = state.benefit.costLookupByTable[mergedBenefit.costLookupKey];
    if (costLookupByTable) {
      return {
        costList: costLookupByTable.response ? costLookupByTable.response.costList : [],
        attemptedCostLookup: isRequestComplete(costLookupByTable.status),
      };
    }
  } else if (mergedBenefit.costType === COST_TYPES.API_CALC) {
    const costLookupByApi = state.benefit.costLookupByApi[mergedBenefit.costLookupKey];
    if (costLookupByApi) {
      return {
        costLookupApi: costLookupByApi.response ? costLookupByApi.response : {},
        attemptedCostLookup: isRequestComplete(costLookupByApi.status),
      };
    }
  }
  return {};
};

PolicyBenefitCard.propTypes = {
  toggleEnabled: PropTypes.func.isRequired,
  toggleOption: PropTypes.func.isRequired,
  mergedBenefit: PropTypes.object.isRequired,
  benefitIsToggleable: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  costList: PropTypes.array,
  costLookupApi: PropTypes.object,

  getCostLookupByTable: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getCostLookupByTable, getCostLookupByApi })(PolicyBenefitCard);

