import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import * as LayoutActions from 'modules/layout/layout.actions';
import * as PolicyActions from './policy.actions';
import { CALC_POLICY_COSTS_FAILURE } from './policy.types';
import {
  getAuthorizationFieldsForBenefit,
  getDynamicCostBenefits,
} from './policyBenefitUtil';
import { toFlattenedVersion } from './policy.actions';
import { useRefCallback } from 'utilities/formUtils';
import CityAutocompleteTextField from 'common/form/cityAutocompleteTextField.component';
import PolicyContext from './policyContext';
import SpinnerButton from 'common/spinnerButton.component';
import TRCInputValidator from 'common/form/TRCInputValidator';

const authFields = ['departureStateCode', 'destinationCity'];

const AuthInfo = (props) => {
  const { onAuthInfoChange, sessionId, costsNeedCalculating, setCostsNeedCalculating, costLookupByApi, costLookupByTable } = props;
  const dispatch = useDispatch();
  const { version, benefitsInUse } = useContext(PolicyContext);
  const [authInfo, setAuthInfo] = useState(localStorage.getItem('authInfo') ? JSON.parse(localStorage.getItem('authInfo')) : {});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCalculatingCosts, setIsCalculatingCosts] = useState(false);
  const [formRef, setFormRef] = useRefCallback();

  const isAuthFieldRequired = useCallback((authField) => {
    const relevantBenefits = getDynamicCostBenefits(benefitsInUse);
    return relevantBenefits.some((ben) => {
      return getAuthorizationFieldsForBenefit(ben, { costLookupByTable, costLookupByApi }).some((field) => field.name === authField);
    });
  }, [costLookupByApi, costLookupByTable, benefitsInUse]);

  useEffect(() => {
    onAuthInfoChange();
    localStorage.setItem('authInfo', JSON.stringify(authInfo));
  }, [authInfo, onAuthInfoChange]);

  useEffect(() => {
    (async () => {
      if (formRef) {
        let formValid = await formRef.isFormValid();
        formValid = formValid && authFields.every((authField) => (!isAuthFieldRequired(authField) || authInfo[authField]));
        setIsFormValid(formValid);
      }
    })();
  }, [formRef, authInfo, isAuthFieldRequired]);

  return (
    <ValidatorForm ref={setFormRef} onSubmit={() => {}} instantValidate={false}>
      <Paper>
        <Box p={2}>
          <Typography variant="h6" gutterBottom>Authorization Information for a person with {version.name}</Typography>
          <Grid container>
            {(isAuthFieldRequired('destinationCity') || isAuthFieldRequired('destinationStateCode')) &&
              <Grid item xs={8}>
                <CityAutocompleteTextField
                  label="Destination City (US Only)"
                  onChange={(value) => {
                    setAuthInfo({ ...authInfo, destinationCity: value ? value.city : '', destinationStateCode: value ? value.state : '' });}
                  }
                  value={authInfo.destinationCity ? `${authInfo.destinationCity}, ${authInfo.destinationStateCode}` : ''}
                  required
                />
              </Grid>
            }
            {(isAuthFieldRequired('departureCity') || isAuthFieldRequired('departureStateCode')) &&
              <Grid item xs={8}>
                <CityAutocompleteTextField
                  label="Departure City (US Only)"
                  onChange={(value) => {
                    setAuthInfo({ ...authInfo, departureCity: value ? value.city : '', departureStateCode: value ? value.state : '' });}
                  }
                  value={authInfo.departureCity ? `${authInfo.departureCity}, ${authInfo.departureStateCode}` : ''}
                  required
                />
              </Grid>
            }
            {isAuthFieldRequired('authorizationDate') &&
              <Grid item xs={8}>
                <TRCInputValidator
                  component={TextValidator}
                  label="Authorization Date"
                  onChange={(value) => setAuthInfo({ ...authInfo, authorizationDate: value })}
                  name="jobStartDate"
                  value={authInfo.authorizationDate || ''}
                  fullWidth
                  variant="outlined"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            }
            <Grid item xs={12} container justify="flex-end">
              <Button onClick={() => setAuthInfo({})} color="primary">Clear</Button>
              <Box ml={2}>
                <SpinnerButton
                  isLoading={isCalculatingCosts}
                  buttonComponent={
                    <Button disabled={!costsNeedCalculating || !isFormValid} variant="outlined" color="primary" onClick={async () => {
                      setIsCalculatingCosts(true);
                      const flattenedVersion = toFlattenedVersion(version);
                      const action = await dispatch(PolicyActions.calculatePolicyCosts(sessionId, flattenedVersion, authInfo));
                      if (action.type === CALC_POLICY_COSTS_FAILURE) {
                        dispatch(LayoutActions.showToast('Failed to calculate benefit costs. Please try again.', { severity: LayoutActions.TOAST_MESSAGE_SEVERITY_ERROR }));
                      } else {
                        setCostsNeedCalculating(false);
                      }
                      setIsCalculatingCosts(false);
                    }}
                    >
                      Update
                    </Button>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </ValidatorForm>
  );
};

AuthInfo.propTypes = {
  costLookupByApi: PropTypes.object.isRequired,
  costLookupByTable: PropTypes.object.isRequired,
  onAuthInfoChange: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
  costsNeedCalculating: PropTypes.bool.isRequired,
  setCostsNeedCalculating: PropTypes.func.isRequired,
};

export default AuthInfo;
