import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TimelineTask from './timelineTask.component.js';
import Typography from '@material-ui/core/Typography';

const CARD_STATUS_COMPLETED = 'Completed';

const styles = {
  timelineContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  avatarRoot: {
    height: '2rem',
    width: '2rem',
  },
  actionButton: {
    display: 'block',
    border: '2px solid white',
  },
  buttonLabel: {
    textTransform: 'capitalize',
    color: 'white',
    fontSize: '1.2rem',
  },
  todayContainer: {
    position: 'sticky',
    bottom: -1,
    backgroundColor: '#707070',
    fontSize: '1.2rem',
    height: '2.2rem',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem 0.5rem 1rem',
  },
};

class TimelineList extends Component {
  constructor(props) {
    super(props);

    this.firstOverdueElem = null;

    this.handleScrollToOverdue = this.handleScrollToOverdue.bind(this);
    this.setFirstOverdueElem = this.setFirstOverdueElem.bind(this);
    this.handleTaskAction = this.handleTaskAction.bind(this);
  }

  // will be called if an item is flagged as the first one to be overdue
  setFirstOverdueElem = (elem) => {
    this.firstOverdueElem = elem;
  }

  handleScrollToOverdue() {
    if (this.firstOverdueElem) {
      const domNode = ReactDOM.findDOMNode(this.firstOverdueElem);
      domNode.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  handleTaskAction = (action, evt) => {
    evt.preventDefault();
    switch (action.type) {
      case 'internalLink':
        const pushObj = {
          pathname: action.link,
          state: action.state ? action.state : null,
        };
        if (action.link) this.props.history.push(pushObj);
        break;

      case 'externalLink':
        if (action.link) window.open(action.link, '_blank');
        break;

      case 'video':
        if (action.link) this.props.showVideoModal(action);
        break;

      case 'moreInfo':
        if (action.content || action.contentList) this.props.showInfoModal(action);
        break;

      case 'fileDownload':
        if (action.url) window.open(action.url, '_blank');
        break;

      default:
        break;
    }
  }

  render() {
    const { classes, data, handleMarkComplete, daysLeft, totalDays } = this.props;
    // count num overdue and numbers for today
    let numOverdue = 0, numToday = 0, numCompletedToday = 0;
    const today = new moment().utc();

    // filter out items whose endDates have passed
    const timelineData = data.filter((d) => {
      if (d.endDate) {
        const end = new moment(d.endDate).utc();
        return !end.isBefore(today, 'day');
      }
      return true;
    }).sort((a, b) => { // sort by date past -> future
      a = new moment(a.dueDate ? a.dueDate : a.startDate);
      b = new moment(b.dueDate ? b.dueDate : b.startDate);
      return a > b ? 1 : a < b ? -1 : 0;
    });

    // find first overdue item and set proper flags on timeline items
    let firstOverDueItemIndex = -1;

    timelineData.forEach((item, i) => {
      if (item.dueDate) {
        const d = new moment(item.dueDate).utc();

        const isToday = d.isSame(today, 'day');
        const isBeforeToday = d.isBefore(today, 'day');
        if (isBeforeToday && !item.status === CARD_STATUS_COMPLETED) {
          item.isBeforeToday = true;
          numOverdue++;
          // overwrite on each overdue item
          firstOverDueItemIndex = i;
        } else if (isToday) {
          numToday++;
          item.isToday = true;
          if (item.status === CARD_STATUS_COMPLETED) {
            numCompletedToday++;
          }
        }
      }
    });

    // set first overdue if found
    if (firstOverDueItemIndex > -1) {
      const firstOverdueItem = timelineData[firstOverDueItemIndex];
      firstOverdueItem.isFirstOverdueItem = true;
      timelineData.splice(firstOverDueItemIndex, 1, firstOverdueItem);
    }

    const progressWidth = (daysLeft && totalDays) ? (100 - ((daysLeft / totalDays) * 100)).toFixed(0) : null;

    return (
      <div style={{ position: 'relative' }}>
        {timelineData.length <= 0 ?
          <Typography variant="h4">No Tasks Available</Typography> :
          <Typography variant="h4" gutterBottom>Timeline</Typography>
        }

        {timelineData.length > 0 && progressWidth &&
          <div style={{ width: '100%', height: '3rem', backgroundColor: '#191919', display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: '1 1 auto', borderRadius: '1rem', backgroundColor: '#313131', height: '2rem', marginRight: '0.5rem', marginLeft: '0.5rem' }}>
              <div style={{ borderRadius: '1rem', backgroundColor: '#5B9B9D', width: `${progressWidth}%`, height: '2rem' }} />
            </div>
            <div style={{ color: '#5B9B9D', fontSize: '1.4rem', paddingRight: '0.5rem' }}>{`${daysLeft} Day${daysLeft === 1 ? '' : 's'} Left`}</div>
          </div>
        }

        {numOverdue > 0 &&
          <div style={{ backgroundColor: 'red', height: '2.5rem', display: 'flex', alignItems: 'center', padding: '0.5rem 1rem 0.5rem 1rem' }}>
            <Avatar
              classes={{ root: classes.avatarRoot }}
              style={{ backgroundColor: 'white', color: 'black' }}
            >
              <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'red' }}>{'!'}</div>
            </Avatar>
            <div style={{ flex: '1 1 auto', marginLeft: '1rem', fontSize: '1.5rem', color: 'white' }}>{`${numOverdue} Overdue Task${numOverdue > 1 ? 's' : ''}`}</div>
            <Button
              disableRipple
              onClick={() => this.handleScrollToOverdue()}
              className={classes.actionButton}
              classes={{ label: classes.buttonLabel }}
            >
              View
            </Button>
          </div>
        }

        {timelineData.length > 0 &&
          <div className={classes.timelineContainer}>
            {timelineData.map((item, i) => {
              return (
                <TimelineTask
                  cardId={item.cardId}
                  text={item.text}
                  taskDate={item.dueDate ? new moment(item.dueDate) : new moment(item.startDate)} // date shown on left of task card
                  showEventTime={item.displayTime}
                  eventDate={item.eventDate ? new moment(item.eventDate) : null} // date used to display event time on card
                  key={i}
                  isToday={item.isToday}
                  isBeforeToday={item.isBeforeToday}
                  colorKey={i}
                  completed={item.status === CARD_STATUS_COMPLETED}
                  icon={item.icon}
                  title={item.title}
                  actions={item.actions}
                  setFirstOverdueElem={this.setFirstOverdueElem}
                  isFirstOverdueItem={item.isFirstOverdueItem}
                  handleAction={this.handleTaskAction}
                  handleMarkComplete={handleMarkComplete}
                  isLastItem={i === timelineData.length - 1}
                />
              );
            })}
          </div>
        }

        {numToday > 0 &&
          <Paper className={classes.todayContainer}>
            Today: {`${numCompletedToday} of ${numToday} Complete`}
          </Paper>
        }
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(TimelineList);
