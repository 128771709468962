import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

import { currencyFormatter } from '../../../utilities/currencyFormatter';
import { dateFormatter } from '../../../utilities/dateFormatter';

import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={currencyFormatter}
    {...props}
  />
);

const getRowId = (row) => row.id;

class OtherExceptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'ExceptDate', title: 'Date' },
        { name: 'ExceptAmount', title: 'Requested Amt' },
        { name: 'Program', title: 'Program Name' },
        { name: 'ExceptDesc', title: 'Description' },
        { name: 'ExceptReason', title: 'Reason' },
        { name: 'RequestType', title: 'Request Type' },
        { name: 'Status', title: 'Status' },
        { name: 'ExceptApprovedBy', title: 'Approved By' },
        { name: 'ApprovedAmount', title: 'Approved Amount' },
        { name: 'ApprovedDate', title: 'Approved Date' },
      ],
      rows: this.props.rows,
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['ExceptDate', 'ApprovedDate'],
      currencyColumns: ['ApprovedAmount', 'ExceptAmount'],
      sorting: [{ columnName: 'ExceptDate', direction: 'desc' }],
      filters: [],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeFilters = (filters) => this.setState({ filters });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });

    this.TableRow = ({ row, ...restProps }) => {
      return (
        <Table.Row
          {...restProps}
        />
      );
    };
  }

  render() {
    const { rows, columns, pageSize, pageSizes, currentPage, dateColumns, sorting, filters, currencyColumns } = this.state;

    return (
      <Paper style={{ padding: '1rem' }}>
        <div style={{ padding: '1rem', fontSize: '1.25rem' }}>Other Exceptions</div>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <DateTypeProvider for={dateColumns} />
          <CurrencyTypeProvider
            for={currencyColumns}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <IntegratedSorting />
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table rowComponent={this.TableRow} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Paper>
    );
  }
}

export default OtherExceptions;
