export const GET_POLICY_BENEFITS_REQUEST = 'GET_POLICY_BENEFITS_REQUEST';
export const GET_POLICY_BENEFITS_SUCCESS = 'GET_POLICY_BENEFITS_SUCCESS';
export const GET_POLICY_BENEFITS_FAILURE = 'GET_POLICY_BENEFITS_FAILURE';

export const GET_POLICY_SUMMARIES_REQUEST = 'GET_POLICY_SUMMARIES_REQUEST';
export const GET_POLICY_SUMMARIES_SUCCESS = 'GET_POLICY_SUMMARIES_SUCCESS';
export const GET_POLICY_SUMMARIES_FAILURE = 'GET_POLICY_SUMMARIES_FAILURE';

export const GET_POLICIES_REQUEST = 'GET_POLICIES_REQUEST';
export const GET_POLICIES_SUCCESS = 'GET_POLICIES_SUCCESS';
export const GET_POLICIES_FAILURE = 'GET_POLICIES_FAILURE';

export const UPDATE_POLICY_REQUEST = 'UPDATE_POLICY_REQUEST';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';
export const UPDATE_POLICY_FAILURE = 'UPDATE_POLICY_FAILURE';

export const PUBLISH_POLICY_REQUEST = 'PUBLISH_POLICY_REQUEST';
export const PUBLISH_POLICY_SUCCESS = 'PUBLISH_POLICY_SUCCESS';
export const PUBLISH_POLICY_FAILURE = 'PUBLISH_POLICY_FAILURE';

export const UPDATE_POLICY_VERSION_REQUEST = 'UPDATE_POLICY_VERSION_REQUEST';
export const UPDATE_POLICY_VERSION_SUCCESS = 'UPDATE_POLICY_VERSION_SUCCESS';
export const UPDATE_POLICY_VERSION_FAILURE = 'UPDATE_POLICY_VERSION_FAILURE';

export const UPDATE_POLICY_BOT_REQUEST = 'UPDATE_POLICY_BOT_REQUEST';
export const UPDATE_POLICY_BOT_SUCCESS = 'UPDATE_POLICY_BOT_SUCCESS';
export const UPDATE_POLICY_BOT_FAILURE = 'UPDATE_POLICY_BOT_FAILURE';

export const DELETE_POLICY_BOT_REQUEST = 'DELETE_POLICY_BOT_REQUEST';
export const DELETE_POLICY_BOT_SUCCESS = 'DELETE_POLICY_BOT_SUCCESS';
export const DELETE_POLICY_BOT_FAILURE = 'DELETE_POLICY_BOT_FAILURE';

export const GET_POLICY_REQUEST = 'GET_POLICY_REQUEST';
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS';
export const GET_POLICY_FAILURE = 'GET_POLICY_FAILURE';

export const CREATE_POLICY_REQUEST = 'CREATE_POLICY_REQUEST';
export const CREATE_POLICY_SUCCESS = 'CREATE_POLICY_SUCCESS';
export const CREATE_POLICY_FAILURE = 'CREATE_POLICY_FAILURE';

export const CLONE_POLICY_REQUEST = 'CLONE_POLICY_REQUEST';
export const CLONE_POLICY_SUCCESS = 'CLONE_POLICY_SUCCESS';
export const CLONE_POLICY_FAILURE = 'CLONE_POLICY_FAILURE';

export const CREATE_POLICY_DRAFT_REQUEST = 'CREATE_POLICY_DRAFT_REQUEST';
export const CREATE_POLICY_DRAFT_SUCCESS = 'CREATE_POLICY_DRAFT_SUCCESS';
export const CREATE_POLICY_DRAFT_FAILURE = 'CREATE_POLICY_DRAFT_FAILURE';

export const DELETE_POLICY_DRAFT_REQUEST = 'DELETE_POLICY_DRAFT_REQUEST';
export const DELETE_POLICY_DRAFT_SUCCESS = 'DELETE_POLICY_DRAFT_SUCCESS';
export const DELETE_POLICY_DRAFT_FAILURE = 'DELETE_POLICY_DRAFT_FAILURE';

export const DELETE_POLICY_REQUEST = 'DELETE_POLICY_REQUEST';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_FAILURE = 'DELETE_POLICY_FAILURE';

export const GET_POLICY_HISTORY_REQUEST = 'GET_POLICY_HISTORY_REQUEST';
export const GET_POLICY_HISTORY_SUCCESS = 'GET_POLICY_HISTORY_SUCCESS';
export const GET_POLICY_HISTORY_FAILURE = 'GET_POLICY_HISTORY_FAILURE';

export const CALC_POLICY_COSTS_REQUEST = 'CALC_POLICY_COSTS_REQUEST';
export const CALC_POLICY_COSTS_SUCCESS = 'CALC_POLICY_COSTS_SUCCESS';
export const CALC_POLICY_COSTS_FAILURE = 'CALC_POLICY_COSTS_FAILURE';