import { SelectValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core';
import ClientBenefitInput from './ClientBenefitInput';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => (
  {
    selectIcon: {
      display: 'none',
    },
    readOnlySelect: {
      cursor: 'auto',
    },
  }
));

const ClientBenefitSelectInput = (props) => {
  const classes = useStyles();

  return (
    <ClientBenefitInput
      component={SelectValidator}
      SelectProps={{
        classes: { icon: classes.selectIcon, select: props.InputProps.readOnly ? classes.readOnlySelect : '' },
      }}
      {...props}
    />
  );
};

ClientBenefitSelectInput.defaultProps = {
  InputProps: {},
};

ClientBenefitSelectInput.propTypes = {
  clientVersion: PropTypes.object.isRequired,
  masterVersion: PropTypes.object,
  versionField: PropTypes.string.isRequired,
  InputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ClientBenefitSelectInput;

