import { connect } from 'react-redux';
import { setBackButtonPath, setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import queryString from 'query-string';

import { Box } from '@material-ui/core';
import { getReportByLink, reports } from './reportDefinitions.js';
import { getSelectedClientInformation, userHasPermission, isHPClient, isSouthwireClient, isCRHClient } from '../../utilities/authUtils';
import TableauReportWrapper from './tableauReportWrapper.component';

class ReportPage extends Component {
  constructor(props) {
    super(props);

    const reportLink = props.match.params.name;
    const clientInfo = getSelectedClientInformation();
    this.query = queryString.parse(props.location.search);
    this.currentClientId = clientInfo ? clientInfo.id : null;
    this.currentReport = getReportByLink(reportLink, clientInfo ? clientInfo.id : null);

    if (this.currentReport) {
      this.props.setPageTitle(`${this.currentReport.name} Report`);
    }
    this.props.setBackButtonPath('/reporting');

    this.getReportForm = this.getReportForm.bind(this);
    this.isFormAuthorized = this.isFormAuthorized.bind(this);
  }

  getReportForm(reportName) {
    const report = reports.find((report) => report.link === reportName);
    if (report) {
      return report.form ? report.form : <div />;
    }
    return <div>Error: Form not found.</div>;
  }

  // authorize by permission name
  isFormAuthorized(report, clientDivisions) {
    if (report) {

      if (report.permissionName) {
        return userHasPermission(report.permissionName, clientDivisions);
      }

      return true;
    }
    return false;
  }

  handleCustomReportPaths(reportPath = '', clientDivisions) {

    let environment = process.env.REACT_APP_ANALYTICS_ENV;
    let newReportPath = reportPath;

    //AB#403 - Handle custom report path for FMP Auth Report (by Auth0 permission)
    if (this.currentReport.tableauReport && this.currentReport.link === 'authorizations' && userHasPermission('reports:fmpauthreport', clientDivisions)) {
      newReportPath = 'views/Authorizations{{env}}/Dashboard';
    }

    //AB#486 - Handle custom report paths for McCarthy reports (by Auth0 permissions)
    if (this.currentReport.tableauReport && this.currentReport.link === 'authorizations'   && userHasPermission('reports:McCarthyAuthReport', clientDivisions)) {
      newReportPath = 'views/McCarthyAuthorizations{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'closedproperty'   && userHasPermission('reports:McCarthyClosedPropertyReport', clientDivisions)) {
      newReportPath = 'views/McCarthyClosedPropertiesDetail{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'costreport'       && userHasPermission('reports:McCarthyCostReport', clientDivisions)) {
      newReportPath = 'views/McCarthyCost{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'activeproperties' && userHasPermission('reports:McCarthyActivePropertyReport', clientDivisions)) {
      newReportPath = 'views/McCarthyActiveProperties{{env}}/Dashboard';
    }

    //AB#666 - Handle custom report paths for H&P reports (by ClientId; No Auth0 permissions)
    if (this.currentReport.tableauReport && this.currentReport.link === 'authorizations'   && isHPClient() === true) {
      newReportPath = 'views/AuthorizationsOnly-HP{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'activeproperties' && isHPClient() === true) {
      newReportPath = 'views/ActiveProperties-HP{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'closedproperty'   && isHPClient() === true) {
      newReportPath = 'views/ClosedPropertiesDetail-HP{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'costreport'       && isHPClient() === true) {
      newReportPath = 'views/Cost-HP{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'policyexceptions' && isHPClient() === true) {
      newReportPath = 'views/PolicyExceptions-HP{{env}}/Dashboard';
    }
    if (this.currentReport.tableauReport && this.currentReport.link === 'evalbytransferee' && isHPClient() === true) {
      newReportPath = 'views/ServiceEvaluation-HP{{env}}/Dashboard';
    }

    //AB#1031 - Handle custom report paths for CRH reports (by Environment; by ClientId; No Auth0 permissions)
    if (isCRHClient() === true) {
      if (environment === 'production') {
        if (this.currentReport.tableauReport && this.currentReport.link === 'authorizations') {
          newReportPath = 'views/AuthorizationsOnlyCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'activeproperties') {
          newReportPath = 'views/ActivePropertiesCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'closedproperty') {
          newReportPath = 'views/ClosedPropertiesDetailCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'costreport') {
          newReportPath = 'views/CostCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'policyexceptions') {
          newReportPath = 'views/PolicyExceptionsCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'evalbytransferee') {
          newReportPath = 'views/ServiceEvaluationCRH/Dashboard';
        }
      } else {
        if (this.currentReport.tableauReport && this.currentReport.link === 'authorizations') {
          newReportPath = 'views/AuthorizationsOnlyTestCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'activeproperties') {
          newReportPath = 'views/ActivePropertiesTestCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'closedproperty') {
          newReportPath = 'views/ClosedPropertiesDetailTestCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'costreport') {
          newReportPath = 'views/Cost-CRHTest/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'policyexceptions') {
          newReportPath = 'views/PolicyExceptionsTestCRH/Dashboard';
        }
        if (this.currentReport.tableauReport && this.currentReport.link === 'evalbytransferee') {
          newReportPath = 'views/ServiceEvaluationTestCRH/Dashboard';
        }
      }
    }

    //AB#943 - Handle custom Cost Report paths for "Southwire Company" Client reports (by ClientId; No Auth0 permissions)
    if (this.currentReport.tableauReport && this.currentReport.link === 'costreport' && isSouthwireClient() === true) {
      if(environment === 'dev') {
        newReportPath = 'views/CostDev-Southwire/Dashboard';
      } else if(environment === 'test') {
        newReportPath = 'views/CostTestSouthwire/Dashboard';
      } else if(environment === 'production') {
        newReportPath = 'views/Cost-Southwire/Dashboard';
      }
    }

    return newReportPath;

  }
  
  render() {
    let { clientDivisions } = this.props;
    // if no report was found for the selected path
    // redirect to list of reports
    if (!this.currentReport || !this.isFormAuthorized(this.currentReport, clientDivisions)) {
      this.props.history.push('/reporting');
      return null;
    }
    const reportParameters = {
      ...(this.query ? this.query : null),
    };

    let reportPath = this.currentReport.reportPath;
    reportPath = this.handleCustomReportPaths(reportPath, clientDivisions);

    return (
      this.currentReport.tableauReport ?
        <Box padding="2rem" height="100%">
          <TableauReportWrapper reportPath={reportPath} reportParameters={reportParameters} />
        </Box>
        :
        <Box padding="2rem">
          <h4>Select the parameters below to filter your report.  You may leave any of the non-required items blank to return full set of information</h4>
          {this.getReportForm(this.currentReport.link)}
        </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientDivisions: state.layout.get('clientDivisions'),
  };
};

export default withRouter(connect(mapStateToProps, {
  setPageTitle,
  setBackButtonPath,
})(ReportPage));
