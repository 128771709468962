import { Line } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import ChartDetailGridByMonth from './chartDetailGridByCategory.component';
import ChartDetailGridByProgram from './chartDetailGridByProgram.component';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const defaultDataset = {
  fill: true,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: 'Total Exceptions',
    fontSize: 20,
  },
  scales: {
    xAxes: [{
      display: true,
      scaleLabel: {
        display: false,
        labelString: 'Month',
      },
    }],
    yAxes: [{
      display: true,
      ticks: {
        min: 0,
        callback: (value, index, values) => {
          if (Math.floor(value) === value) {
            return value;
          }
        },
      },
      scaleLabel: {
        display: true,
        labelString: 'Exception Totals (USD)',
      },
    }],
  },
};

const styles = (theme) => ({
  chartDetailPaper: {
    height: 'calc(100% - 48px)',
  },
  tab: {
    width: 150,
  },
});

class ExceptionChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedChartTabIndex: 0,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.getChartDataSets = this.getChartDataSets.bind(this);
  }

  handleTabChange = (event, value) => {
    this.setState({ selectedChartTabIndex: value });
  };

  getMaxValue(ary) {
    return Math.max(...ary);
  }

  getChartDataSets(rows) {
    if (rows) {
      rows.sort(function (a, b) {
        return a.MonthNumber - b.MonthNumber;
      });
      const labels = rows.map((row) => row.MonthName);
      const prevData = rows.map((row) => row.PreviousYear);
      const curData = rows.map((row) => row.CurrentYear);

      // set max y axis value dynamically
      const maxValue = this.getMaxValue(prevData.concat(curData));
      options.scales.yAxes[0].ticks.max = maxValue + Math.ceil(maxValue * .1);
      if (maxValue === 0) {
        options.scales.yAxes[0].ticks.stepSize = 1;
      } else {
        delete options.scales.yAxes[0].ticks.stepSize;
      }

      const datasets = [
        {
          ...defaultDataset,
          label: 'Current Year',
          backgroundColor: 'rgba(	54, 156, 144,0.4)',
          borderColor: 'rgba(54, 156, 144, 1)',
          pointBorderColor: 'rgba(54, 156, 144, 1)',
          pointHoverBackgroundColor: 'rgba(54, 156, 144, 1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: curData,
        },
        {
          ...defaultDataset,
          label: 'Prior Year',
          backgroundColor: 'rgba(7.8%, 27.5%, 29.8%, 0.4)',
          borderColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointBorderColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointHoverBackgroundColor: 'rgba(7.8%, 27.5%, 29.8%, 1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: prevData,
        },
      ];

      return {
        datasets,
        labels,
      };
    }
  }

  render() {
    const { chartData, classes } = this.props;
    const { selectedChartTabIndex } = this.state;

    if (!chartData.exceptCountByCategory || !chartData.exceptCountByProgram || !chartData.exceptCountByMonth) {
      return <div />;
    }

    const categoryRows = chartData.exceptCountByCategory;
    const programRows = chartData.exceptCountByProgram;
    const monthRows = chartData.exceptCountByMonth;

    const chartDataSets = this.getChartDataSets(monthRows);

    return (
      <Paper style={{ padding: '1rem' }}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Paper style={{ width: '100%', height: '100%', minHeight: '225px', overflow: 'hidden', position: 'relative' }}>
              <Line data={chartDataSets} options={options} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppBar position="static">
              <Tabs
                value={selectedChartTabIndex}
                onChange={this.handleTabChange}
                style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}
              >
                <Tab label="By Category" classes={{ root: classes.tab }} />
                <Tab label="By Program" />
              </Tabs>
            </AppBar>
            {selectedChartTabIndex === 0 &&
              <Paper className={classes.chartDetailPaper}>
                <ChartDetailGridByMonth rows={categoryRows} />
              </Paper>
            }
            {selectedChartTabIndex === 1 &&
              <Paper className={classes.chartDetailPaper}>
                <ChartDetailGridByProgram rows={programRows} />
              </Paper>
            }
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ExceptionChart = withStyles(styles, { withTheme: true })(ExceptionChart);

export default ExceptionChart;