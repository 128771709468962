import LogoSpinner from '../../common/logoSpinner.component';
import React, { Component } from 'react';

class Callback extends Component {
  render() {
    return (
      <div style={styles.container}>
        <LogoSpinner size="large" />
      </div>
    );
  }
}

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
};

export default Callback;