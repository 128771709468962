import {
  GET_CONTACTS_FAILURE, GET_CONTACTS_REQUEST, GET_CONTACTS_SUCCESS,
} from './contacts.actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  contacts: [],
});

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_CONTACTS_REQUEST:
      return state.set('isLoading', true);

    case GET_CONTACTS_SUCCESS:
      return state.set('contacts', action.response).set('isLoading', false);

    case GET_CONTACTS_FAILURE:
      return state.set('isLoading', false);
    
    default:
      return state;
  }
}
