import { CALL_API } from '../../middleware/api';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE';

export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';

export const getAllUsers = () => {
  return {
    [CALL_API]: {
      types: [GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAILURE],
      authenticated: true,
      endpoint: 'Account/GetAllUsers',
      method: 'GET',
    },
  };
};

export const updateUser = (userData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
      authenticated: true,
      endpoint: 'Account/UpdateUser',
      method: 'POST',
      payload: userData,
    },
  };
};

export const addUser = (userData) => {
  return {
    [CALL_API]: {
      types: [CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE],
      authenticated: true,
      endpoint: 'Account/AddUser',
      method: 'POST',
      payload: userData,
    },
  };
};

export const deactivateUserAccount = (userData) => {
  return {
    [CALL_API]: {
      types: [DEACTIVATE_USER_REQUEST, DEACTIVATE_USER_SUCCESS, DEACTIVATE_USER_FAILURE],
      authenticated: true,
      endpoint: 'Account/DeactivateUserAccount',
      method: 'POST',
      payload: userData,
    },
  };
};

export const activateUserAccount = (userData) => {
  return {
    [CALL_API]: {
      types: [ACTIVATE_USER_REQUEST, ACTIVATE_USER_SUCCESS, ACTIVATE_USER_FAILURE],
      authenticated: true,
      endpoint: 'Account/ActivateUserAccount',
      method: 'POST',
      payload: userData,
    },
  };
};