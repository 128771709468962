import React, { Component } from 'react';

import { EditCommandCell } from '../../../common/tableEditCommandCell';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';

import NoteCreateDialog from './noteCreateDialog.component';
import NoteDetailDialog from './noteDetailDialog.component';
// import { BASE_URL } from '../../../middleware/api';

import { generateForm } from '../../../utilities/reportingUtils';
import { getAuthToken } from '../../../utilities/authUtils';

import {
  Getter,
} from '@devexpress/dx-react-core';

import {
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import { dateFormatter } from '../../../utilities/dateFormatter';

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={dateFormatter}
    {...props}
  />
);

class NotesGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'Service', title: 'Category' },
        { name: 'FullName', title: 'Added By' },
        { name: 'AddedOn', title: 'Date' },
        { name: 'Subject', title: 'Subject' },
      ],
      columnWidths: [
        { columnName: 'Service', width: 200 },
        { columnName: 'FullName', width: 275 },
        { columnName: 'AddedOn', width: 300 },
        { columnName: 'Subject', width: 500 },
      ],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['AddedOn'],
      sorting: [{ columnName: 'AddedOn', direction: 'desc' }],
      filters: [],
      noteViewDialog: {
        dialogOpen: false,
        selectedRow: null,
      },
      noteCreateDialog: {
        dialogOpen: false,
      },
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });

    this.changeFilters = (filters) => this.setState({ filters });

    this.handleOpenNoteDetails = this.handleOpenNoteDetails.bind(this);
    this.handleDialogResponse = this.handleDialogResponse.bind(this);
    this.handleNewNote = this.handleNewNote.bind(this);

    this.changeColumnWidths = (columnWidths) => {
      this.setState({ columnWidths });
    };

    this.TableRow = ({ row, handleViewSubmittedAuth, ...restProps }) => {
      return (<Table.Row
        {...restProps}
        onClick={() => this.handleOpenNoteDetails(row)}
        style={{
          cursor: 'pointer',
        }}
              />);
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  componentWillMount() {
    this.setState({ rows: this.props.rows });
  }

  handleOpenNoteDetails(row) {
    this.setState({
      noteViewDialog: {
        dialogOpen: true,
        selectedRow: row,
      },
    });
  }

  handleNewNote() {
    this.setState({
      noteCreateDialog: {
        dialogOpen: true,
      },
    });
  }

  handleDialogResponse(type) {
    if (type === 'NOTE_DETAIL_DIALOG') {
      this.setState({
        ...this.state,
        noteViewDialog: {
          dialogOpen: false,
          selectedRow: null,
        },
      });
    } else if (type === 'NOTE_CREATE_DIALOG') {
      this.setState({
        ...this.state,
        noteCreateDialog: {
          dialogOpen: false,
        },
      });
    }
  }

  // required for editing state
  commitChanges() {

  }

  actionsCell = (props) => {
    return (
      props.tableRow.row.HasAttachment > 0
        ? (
          <TableCell>
            <EditCommandCell
              id="attachment"
              text="attachment"
              icon="paperclip"
              onExecute={() => this.downloadAttachment(props.tableRow.row.CommentID)}
              tableRow={props.tableRow}
            >{React.Children.toArray(props.children)}
            </EditCommandCell>
          </TableCell>
        ) : <TableCell />
    );
  }

  downloadAttachment(CommentID) {
    let postObj = {
      access_token: getAuthToken(),
      CommentID,
    };

    generateForm('AuthorizationFile/GetCommentFile', postObj);
  }

  render() {
    const { rows, columns, pageSize, pageSizes, currentPage, dateColumns, filters, sorting, noteViewDialog, noteCreateDialog, columnWidths } = this.state;
    const { busCodes } = this.props;

    return (
      <div>
        <div style={{ float: 'right', marginTop: '0.25rem', marginRight: '0.25rem' }}>
          <Fab color="secondary" aria-label="add" onClick={this.handleNewNote}>
            <AddIcon />
          </Fab>
        </div>

        <NoteDetailDialog
          fullScreen={false}
          open={noteViewDialog.dialogOpen}
          noteData={noteViewDialog.selectedRow}
          type={'NOTE_DETAIL_DIALOG'}
          handleResponse={this.handleDialogResponse}
          title={'Authorization Note Detail'}
          downloadAttachment= {this.downloadAttachment}
        />

        <NoteCreateDialog
          fullScreen={false}
          open={noteCreateDialog.dialogOpen}
          type={'NOTE_CREATE_DIALOG'}
          handleResponse={this.handleDialogResponse}
          title={'Add Note to Authorization File'}
          busCodes={busCodes}
        />

        <Paper style={{ padding: '1rem' }}>
          <div style={{ padding: '1rem', fontSize: '1.5rem', width: '50%' }}>Notes</div>
          <Grid
            rows={rows}
            columns={columns}
          >
            <DateTypeProvider
              for={dateColumns}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedSorting />
            <FilteringState
              filters={filters}
              onFiltersChange={this.changeFilters}
            />
            <EditingState
              onEditingRowIdsChange={this.changeEditingRowIds}
              onCommitChanges={this.commitChanges}
            />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table rowComponent={this.TableRow} />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={this.changeColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableEditColumn
              style={{ marginRight: '2rem' }}
              cellComponent={this.actionsCell}
            />
            <TableFilterRow />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                return [
                  ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
                  { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 150 },
                ];
              }}
            />
            <PagingPanel
              pageSizes={pageSizes}
            />
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default NotesGrid;