export const defaultFieldsBasedOnReloPolicy = {
  '242': (reloPolicy, handleFormChange) => {
    switch (reloPolicy) {
      case 'Renter':
        handleFormChange('Ownership')('Renter');
        break;
      case 'HO GBO':
        handleFormChange('Ownership')('Homeowner');
        break;
      default:
        break;
    }
  },
};
