import React, { Component } from 'react';

import {
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

const monthNumbers = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

const compareMonths = (a, b) => {
  const numA = monthNumbers[a];
  const numB = monthNumbers[b];
  if (numA === numB) {
    return 0;
  }
  return (numA < numB) ? -1 : 1;
};

class ChartDetailGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'MonthName', title: 'Month' },
        { name: 'CurrentYear', title: 'This Year' },
        { name: 'PreviousYear', title: 'Last Year' },
      ],
      columnWidths: [
        { columnName: 'MonthName', width: 150 },
        { columnName: 'CurrentYear', width: 88 },
        { columnName: 'PreviousYear', width: 88 },
      ],
      rows: [],
      sorting: [],
      integratedSortingColumnExtensions: [
        { columnName: 'MonthName', compare: compareMonths },
      ],
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.TableRow = ({ row, handleViewSubmittedAuth, ...restProps }) => {
      return (<VirtualTable.Row
        {...restProps}
        style={{
          cursor: 'default',
        }}
              />);
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows });
  }

  componentWillMount() {
    this.setState({ rows: this.props.rows });
  }

  render() {
    const { rows, columns, sorting, integratedSortingColumnExtensions, columnWidths } = this.state;

    if (!rows) {
      return <div />;
    }

    return (
      <Grid
        rows={rows}
        columns={columns}
      >
        <SortingState
          sorting={sorting}
          onSortingChange={this.changeSorting}
        />
        <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
        <VirtualTable rowComponent={this.TableRow} height={300} />
        <TableColumnResizing
          columnWidths={columnWidths}
          // onColumnWidthsChange={this.changeColumnWidths}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    );
  }
}

export default ChartDetailGrid;