import { Button, Grid, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import {
  CREATE_POLICY_DRAFT_FAILURE,
  CREATE_POLICY_FAILURE,
  DELETE_POLICY_DRAFT_FAILURE,
  DELETE_POLICY_FAILURE,
  GET_POLICY_BENEFITS_FAILURE,
  GET_POLICY_FAILURE,
  PUBLISH_POLICY_FAILURE,
  UPDATE_POLICY_VERSION_FAILURE,
} from './policy.types';
import { GET_RELO_POLICY_FAILURE, getReloPolicies } from 'modules/authorizations/authorizations.actions';
import { RequestStatusEnum } from 'index.types';
import {
  TOAST_MESSAGE_SEVERITY_ERROR,
  TOAST_MESSAGE_SEVERITY_SUCCESS,
  setPageTitle,
  showToast,
} from 'modules/layout/layout.actions';
import { clearBackButtonPath, setBackButtonPath } from '../layout/layout.actions';
import {
  createPolicyDraft,
  deletePolicy,
  deletePolicyDraft,
  getBenefits,
  getPolicy,
  getPolicyHistory,
  publishPolicy,
  toDialogFlowVersion,
  updatePolicyVersion,
} from './policy.actions';
import { getMergedBenefit, isBenefitInUse, mergeAndSortClientAndPolicyBenefits } from './policyBenefitUtil';
import { getVersionFromSummaryById } from './summaryUtil';
import { isLoadingSelector } from './policy.selectors';
import ConfirmModal from 'common/confirmModal.component';
import ConflictDialog, {
  CONFLICT_CHANGED_DRAFT_MESSAGE,
  CONFLICT_DRAFT_DELETED_MESSAGE,
  CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE,
  CONFLICT_DRAFT_EXISTS_MESSAGE,
  CONFLICT_NOT_IN_USE_MESSAGE,
  CONFLICT_PUBLISHED_DRAFT_MESSAGE,
} from 'common/conflictDialog.component';
import FormButtons from 'common/form/formButtons.component';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import PolicyBotContainer from './policyBot.container';
import PolicyContext from './policyContext';
import PolicyForm from './policyForm.component';
import PolicyHistoryDialog from './policyHistoryDialog.component';
import PolicyRulesContainer from './policyRules.component';
import PolicyStep from './policyStep.container';
import PolicyStepper from './policyStepper.component';
import PublishDialog from 'common/form/publishDialog.component';
import SummaryMetaData from 'common/summaryMetadata.component';

const STEPS = [
  {
    label: 'Benefits',
    path: '',
    isComplete: true,
    hasError: false,
  },
  {
    label: 'Rules',
    path: '/rules',
    isComplete: true,
    hasError: false,
  },
  {
    label: 'Preview Mobi',
    path: '/bot',
    isComplete: true,
    hasError: false,
  },
];

const getCurrentStepIdx = (location, match) => {
  const subRoute = location.pathname.split(match.url)[1];
  return STEPS.findIndex((step) => step.path === subRoute);
};

const PolicyWizardContainer = (props) => {
  const {
    match,
    location,
    history,
    isLoading,
    showToast,
    setBackButtonPath: propsSetBackButtonPath,
    clearBackButtonPath: propsClearBackButtonPath,
    deletePolicyDraft: propsDeletePolicyDraft,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [policySummary, setPolicySummary] = useState(null);
  const [reloPolicies, setReloPolicies] = useState(null);
  const [allStepsComplete, setAllStepsComplete] = useState(false);
  const [version, setVersion] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [draftWasSaved, setDraftWasSaved] = useState(false);
  const [isBotUpToDate, setIsBotUpToDate] = useState(false);
  const [updateBotStatus, setUpdateBotStatus] = useState(RequestStatusEnum.NONE);
  const [updateBotResponse, setUpdateBotResponse] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteDraftConfirmation, setShowDeleteDraftConfirmation] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [enableOnPublish, setEnableOnPublish] = useState(false);
  const [openPolicyHistoryDialog, setOpenPolicyHistoryDialog] = useState(false);
  const [conflictHandlingDialog, setConflictHandlingDialog] = useState({
    isOpen: false,
    message: null,
  });
  const [hasValidated, setHasValidated] = useState(false);
  const [steps, setSteps] = useState(STEPS.map((step) => ({ ...step, isComplete: step.isComplete || false })));

  const benefitsInUse = useMemo(() => {
    return version ? version.benefits.filter(isBenefitInUse) : [];
  }, [version]);

  const isReady = useCallback(() => {
    return !isLoading && 
      !isEmpty(policySummary) &&
      !isEmpty(reloPolicies) &&
      !isEmpty(version);
  }, [isLoading, policySummary, version, reloPolicies]);

  const previous = () => {
    const currentStepIdx = getCurrentStepIdx(location, match);
    history.push(`${match.url}${steps[currentStepIdx - 1].path}`);
  };

  const next = () => {
    const currentStepIdx = getCurrentStepIdx(location, match);
    history.push(`${match.url}${steps[currentStepIdx + 1].path}`);
  };

  const toggleStepStatus = useCallback((formStep, isComplete) => {
    setSteps((stps) => stps.map(
      (step, idx) => {
        if (idx === formStep) {
          step.isComplete = isComplete;
        }
        step.hasError = !isReadOnly && (!step.isComplete && hasValidated);
        return step;
      }));
  }, [hasValidated, isReadOnly]);

  const modifyBenefit = useCallback((benefit) => {
    const newBenefit = { ...benefit };
    setVersion((v) => {
      const idx = v.benefits.findIndex((ben) => ben.id === newBenefit.id);
      v.benefits.splice(idx, 1, newBenefit);
      return { ...v };
    });
  }, []);

  const validateSteps = useCallback(() => {
    setHasValidated(true);
    setSteps((stps) => stps.map(
      (step, idx) => {
        step.hasError = !step.isComplete;
        return step;
      }));
    return allStepsComplete;
  }, [allStepsComplete]);

  const attemptPublish = () => {
    let errorMessage;
    let userMustDismiss = false;
    const allStepsValid = validateSteps();
    if (!allStepsValid) {
      errorMessage = 'Please fix errors before publishing';
    }
    if (benefitsInUse.length === 0) {
      errorMessage = 'One or more benefits must be enabled on a policy.';
      userMustDismiss = true;
    }
    const benefitNames = benefitsInUse.map((ben) => getMergedBenefit(ben).name);
    const noDuplicateBenefits = new Set(benefitNames).size === benefitNames.length;
    if (!noDuplicateBenefits) {
      errorMessage = 'Two or more benefits have the same name. Please disable or change the name of any duplicate benefits.';
      userMustDismiss = true;
    }
    if (errorMessage) {
      showToast(errorMessage, { severity: TOAST_MESSAGE_SEVERITY_ERROR, userMustDismiss });
      return;
    }
    setOpenPublishDialog(true);
  };

  const publish = async () => {
    setIsSubmitting(true);
    let dialogFlowVersion;
    try {
      dialogFlowVersion = await toDialogFlowVersion(version);
    } catch (e) {
      setIsSubmitting(false);
      showToast('Failed to publish policy. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    const action = await props.publishPolicy(version, dialogFlowVersion, enableOnPublish);
    if (action.type === PUBLISH_POLICY_FAILURE || action.type === CREATE_POLICY_FAILURE) {
      setIsSubmitting(false);
      if (action.isCollision) {
        handleSaveCollision(action);
      } else {
        showToast('Failed to publish policy. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setIsDirty(false);
      history.push('/policies');
      showToast('Policy published.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const handleSaveCollision = (action) => {
    const isPublishedError = action.messages[0].indexOf('published') >= 0;
    const isDeletedError = action.messages[0].indexOf('deleted') >= 0;
    if (isPublishedError) {
      setConflictHandlingDialog({
        isOpen: true,
        message: CONFLICT_PUBLISHED_DRAFT_MESSAGE,
      });
    } else if (isDeletedError) {
      setConflictHandlingDialog({
        isOpen: true,
        message: CONFLICT_DRAFT_DELETED_MESSAGE,
      });
    } else {
      setConflictHandlingDialog({
        isOpen: true,
        message: CONFLICT_CHANGED_DRAFT_MESSAGE,
      });
    }
  };

  const saveDraft = async () => {
    setIsSubmitting(true);
    const action = await props.updatePolicyVersion(version);
    setIsSubmitting(false);
    if (action.type === UPDATE_POLICY_VERSION_FAILURE || action.type === CREATE_POLICY_FAILURE) {
      if (action.isCollision) {
        handleSaveCollision(action);
      } else {
        showToast('Failed to save policy. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setVersion((v) => {
        v.modifiedDate = action.response.modifiedDate;
        return v;
      });
      setIsDirty(false);
      setDraftWasSaved(true);
      showToast('Policy saved.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const createDraft = async () => {
    setIsSubmitting(true);
    const action = await props.createPolicyDraft(policySummary.id);
    if (action.type === CREATE_POLICY_DRAFT_FAILURE) {
      setIsSubmitting(false);
      if (action.isCollision) {
        setConflictHandlingDialog({
          isOpen: true,
          message: CONFLICT_DRAFT_EXISTS_MESSAGE,
        });
      } else {
        showToast('Failed to create a new draft. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setIsSubmitting(false);
      const subRoute = location.pathname.split(match.url)[1];
      history.push(`/policies/${policySummary.id}/v/${action.response.versionId}${subRoute}`);
      window.location.reload();
    }
  };

  const editDraft = () => {
    const subRoute = location.pathname.split(match.url)[1];
    history.push(`/policies/${policySummary.id}/v/${policySummary.draft.versionId}${subRoute}`);
    window.location.reload();
  };

  const deletePolicy = async () => {
    setIsSubmitting(true);
    setShowDeleteConfirmation(false);
    const response = await props.deletePolicy(policySummary.id);
    if (response.type === DELETE_POLICY_FAILURE) {
      setIsSubmitting(false);
      props.showToast('Failed to delete policy. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setIsDirty(false);
      history.push('/policies');
      props.showToast('Policy deleted.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const deleteDraft = useCallback(async () => {
    const handleSuccess = () => {
      setIsDirty(false);
      history.push('/policies');
      showToast('Policy Draft deleted.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    };
    setIsSubmitting(true);
    setShowDeleteDraftConfirmation(false);
    const response = await propsDeletePolicyDraft(version);
    if (response.type === DELETE_POLICY_DRAFT_FAILURE) {
      setIsSubmitting(false);
      if (response.isCollision) {
        const isPublishedError = response.messages[0].indexOf('published') >= 0;
        if (isPublishedError) {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE,
          });
        } else {
          // treat this like a success. the draft has already been deleted
          handleSuccess();
        }
      } else {
        showToast('Failed to delete policy draft. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      handleSuccess();
    }
  }, [history, showToast, version, propsDeletePolicyDraft]);

  const updateVersionBenefits = useCallback((benefits) => {
    setVersion((v) => {
      const newVersion = { ...v };
      newVersion.benefits = benefits;
      //append any unused benefits and set their order to 0
      let unusedBenefits = v.benefits.filter((b) => !isBenefitInUse(b));
      unusedBenefits = unusedBenefits.map((b) => {return { ...b, order: 0 };});
      newVersion.benefits = newVersion.benefits.concat(unusedBenefits);
      return newVersion;
    });
  }, []);

  useEffect(() => {
    props.setPageTitle('Policy');
  }, []); // eslint-disable-line
  
  useEffect(() => {
    if (isReadOnly) {
      propsSetBackButtonPath('/policies');
    } else {
      propsClearBackButtonPath();
    }
  }, [isReadOnly, propsClearBackButtonPath, propsSetBackButtonPath]);

  useEffect(() => {
    const removeListener = history.listen((newLocation) => {
      const newBaseRoute = newLocation.pathname.split(match.url)[0];
      const oldBaseRoute = location.pathname.split(match.url)[0];
      if (newBaseRoute !== oldBaseRoute) {
        if (isAdding && !draftWasSaved) {
          deleteDraft();
        }
      }
    });
    return removeListener;
  }, [isAdding, draftWasSaved, deleteDraft, history, location, match, version]);

  useEffect(() => {
    if (isReady()) {
      const hasVersionChanged = version !== getVersionFromSummaryById(policySummary, version.versionId);
      // new drafts are always "dirty"
      setIsDirty(isAdding || hasVersionChanged);
      setIsBotUpToDate(version.isBotUpToDate && !hasVersionChanged);
    }
  }, [version, policySummary, isReady, isAdding]);

  useEffect(() => {
    setAllStepsComplete(steps.every((step) => step.isComplete));
  }, [steps]);

  useEffect(() => {
    (async () => {
      const isAdd = match.url.includes('/add');
      setIsAdding(isAdd);
      const getBenefitsAction = await props.getBenefits();
      if (getBenefitsAction.type === GET_POLICY_BENEFITS_FAILURE) {
        sessionStorage.setItem('redirectUrl', location.pathname);
        showToast('Failed to load benefits. Please make sure you have the correct client selected.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
      const clientBenefits = getBenefitsAction.response;
      const getReloPoliciesAction = await props.getReloPolicies();
      if (getReloPoliciesAction.type === GET_RELO_POLICY_FAILURE) {
        showToast('Failed to load relo policies. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
      setReloPolicies(getReloPoliciesAction.response);
      const getPolicyAction = await props.getPolicy(match.params.id);
      if (getPolicyAction.type === GET_POLICY_FAILURE) {
        sessionStorage.setItem('redirectUrl', location.pathname);
        showToast('Failed to load policy. Please make sure you have the correct client selected.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
      const polSum = getPolicyAction.response;
      setPolicySummary(polSum);
      const v = getVersionFromSummaryById(polSum, match.params.versionId);
      if (!v) {
        if (polSum.mostRecentPublish) {
          history.push(`/policies/${polSum.id}/v/${polSum.mostRecentPublish.versionId}`);
        } else {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_NOT_IN_USE_MESSAGE,
          });
          setTimeout(() => {
            history.push('/policies');
          }, 5000);
        }
      } else {
        v.benefits = mergeAndSortClientAndPolicyBenefits(v.benefits, clientBenefits);
        setIsReadOnly(v.isPublished);
        setVersion(v);
      }
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    const stepIdx = getCurrentStepIdx(location, match);
    setActiveStep(stepIdx);
  }, [match, location]);

  if (!isReady()) {
    return <FullscreenSpinner />;
  }

  return (
    <>
      <Box my={2} mx={2}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            {!isEmpty(policySummary) &&
              <Grid container spacing={0} alignItems="center">
                <Grid item>
                  <SummaryMetaData summary={policySummary} />
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setOpenPolicyHistoryDialog(true);
                    }}
                  >
                    <FontAwesomeIcon icon={['fas', 'history']} size="1x" />
                  </IconButton>
                </Grid>
              </Grid>
            }
          </Grid>
          <Grid item xs={7}>
            <FormButtons
              summary={policySummary}
              version={version}
              isSubmitting={isSubmitting}
              createDraftAction={createDraft}
              editDraftAction={editDraft}
              enableSave
              enablePublish
              publishAction={() => {
                attemptPublish();
                setEnableOnPublish(false);
              }}
              publishAndEnableAction={() => {
                attemptPublish();
                setEnableOnPublish(true);
              }}
              saveAction={saveDraft}
              deleteAction={() => {setShowDeleteConfirmation(true);}}
              deleteDraftAction={() => {setShowDeleteDraftConfirmation(true);}}
              cancelAction={isReadOnly ? null : () => {history.push('/policies');}}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mx={16}>
        <PolicyStepper activeStep={activeStep} steps={steps} basePath={match.url} isAddEdit={isAdding || !isReadOnly} />
      </Box>
      <PolicyContext.Provider
        value={{ version, setVersion, basePath: match.url, reloPolicies, isReadOnly, isDirty, toggleStepStatus, modifyBenefit, updateVersionBenefits, benefitsInUse, hasValidated, validateSteps, isBotUpToDate, setIsBotUpToDate, updateBotStatus, updateBotResponse, setUpdateBotStatus, setUpdateBotResponse }}
      >
        <PolicyStep activeStep={activeStep} formStep={0} component={PolicyForm} />
        <PolicyStep activeStep={activeStep} formStep={1} component={PolicyRulesContainer} />
        <PolicyStep activeStep={activeStep} formStep={2} component={PolicyBotContainer} />
      </PolicyContext.Provider>
      <Grid spacing={0} container justify="space-between">
        <Grid item style={{ visibility: activeStep === 0 ? 'hidden' : 'visible' }}>
          <Box mx={2} mb={2} minWidth={100} clone>
            <Button size="large" variant="outlined" color="primary" onClick={previous}>Previous</Button>
          </Box>
        </Grid>
        <Grid item  style={{ visibility: activeStep === STEPS.length-1 ? 'hidden' : 'visible' }}>
          <Box mx={2} mb={2} minWidth={100} clone>
            <Button size="large" variant="contained" color="primary" onClick={next}>Next</Button>
          </Box>
        </Grid>
      </Grid>
      <PublishDialog
        open={openPublishDialog}
        title="Publish Policy"
        version={version}
        setVersion={setVersion}
        isSubmitting={isSubmitting}
        publishAction={publish}
        cancelAction={() => setOpenPublishDialog(false)}
      />
      <Prompt
        when={isDirty}
        message={({ pathname }) => {
          const newBaseRoute = pathname.split(match.url)[0];
          const oldBaseRoute = location.pathname.split(match.url)[0];
          return oldBaseRoute === newBaseRoute || 'You have unsaved changes, are you sure you want to leave?';
        }}
      />
      {
        showDeleteConfirmation &&
          <ConfirmModal
            titleText="Delete Policy?"
            cancelText="Cancel"
            confirmText="Delete"
            handleClose={() => {
              setShowDeleteConfirmation(false);
            }}
            handleConfirm={deletePolicy}
            bodyText="Are you sure you'd like to delete this policy?"
          />
      }
      {
        showDeleteDraftConfirmation &&
          <ConfirmModal
            titleText="Delete Draft?"
            cancelText="Cancel"
            confirmText="Delete"
            handleClose={() => {
              setShowDeleteDraftConfirmation(false);
            }}
            handleConfirm={deleteDraft}
            bodyText = "Are you sure you'd like to delete this draft?"
          />
      }
      {
        conflictHandlingDialog.isOpen &&
          <ConflictDialog
            handleConfirm={() => {
              setConflictHandlingDialog({
                isOpen: false,
                message: null,
              });
            }}
            message={conflictHandlingDialog.message}
          />
      }
      {
        version.clientPolicyId &&
          <PolicyHistoryDialog
            open={openPolicyHistoryDialog}
            onClose={() => {
              setOpenPolicyHistoryDialog(false);
            }}
            policyId={version.clientPolicyId}
          />
      }
    </>
  );
};

PolicyWizardContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getPolicy: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getBenefits: PropTypes.func.isRequired,
  publishPolicy: PropTypes.func.isRequired,
  getPolicyHistory: PropTypes.func.isRequired,
  updatePolicyVersion: PropTypes.func.isRequired,

  createPolicyDraft: PropTypes.func.isRequired,
  deletePolicy: PropTypes.func.isRequired,
  deletePolicyDraft: PropTypes.func.isRequired,
  getReloPolicies: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  setBackButtonPath: PropTypes.func.isRequired,
  clearBackButtonPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoading: isLoadingSelector(state),
  };
};

export default connect(mapStateToProps, { 
  getPolicy, getBenefits, getReloPolicies, publishPolicy, getPolicyHistory, updatePolicyVersion, deletePolicy, deletePolicyDraft,
  createPolicyDraft, showToast, setPageTitle, clearBackButtonPath, setBackButtonPath })(PolicyWizardContainer);
