import { connect } from 'react-redux';
import React, { Component } from 'react';

import BackIcon from '@material-ui/icons/ChevronLeft';
import EndIcon from '@material-ui/icons/LastPage';
import ForwardIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import StartIcon from '@material-ui/icons/FirstPage';

import renderHTML from 'react-render-html';

import { getReportParams } from '../../../utilities/reportingUtils';
import { renderHtmlReport } from '../reporting.actions';
import LogoSpinner from '../../../common/logoSpinner.component';

import reportingImage from '../../../images/reportingicon.png';

const styles = {
  reportFormPaper: {
    padding: '2rem',
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    marginBottom: '2rem',
    // height: '25rem',
    overflow: 'auto',
  },
  reportingImage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  reportingText: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    padding: '40px',
  },
};

class RenderHtmlForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      htmlForm: '',
      numberOfPages: 1,
      isLoading: false,
      isEmpty: false,
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillMount() {
    this.handleGetReport(this.state.currentPage);
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      htmlForm: null,
      currentPage: 1,
    });
    this.handleGetReport(1, nextProps);
  }

  async handleGetReport(page, nextProps, type) {
    this.setState({
      isLoading: true,
    });

    let ReportParameters = nextProps ? getReportParams(nextProps.formData) : getReportParams(this.props.formData);
    let ReportPath = this.props.reportPath;

    let payload = {
      ReportParameters,
      PageNumber: page,
      ReportPath,
    };

    // console.log(JSON.stringify(payload));

    let renderResponse = await this.props.renderHtmlReport(payload);

    const isEmpty = (renderResponse && renderResponse.response) ? renderResponse.response.Html.includes('~~~NoRows~~~') : true;

    if (renderResponse.type === 'RENDER_HTML_REPORT_SUCCESS' && !isEmpty) {
      this.setState({
        htmlForm: renderResponse.response.Html,
        numberOfPages: renderResponse.response.NumberOfPages,
        isLoading: false,
        isEmpty: false,
      });
    } else {
      this.props.handleEmptyResult();
      this.setState({
        htmlForm: null,
        numberOfPages: null,
        isLoading: false,
        isEmpty: true,
      });
    }
  }

  onPageChange(newPage) {
    if (newPage >= 1 && newPage <= this.state.numberOfPages) {
      this.setState({
        currentPage: newPage,
      });
      this.handleGetReport(newPage);
    }
  }

  render() {
    const { currentPage, htmlForm, numberOfPages, isLoading, isEmpty } = this.state;

    if (isLoading) {
      return <LogoSpinner />;
    }

    return (
      <div>
        {
          !isLoading && !isEmpty && htmlForm
            ? (
              <Paper style={styles.reportFormPaper}>
                <span style={{ position: 'absolute' }}>
                  <span>
                    <IconButton onClick={() => this.onPageChange(1)}>
                      <StartIcon />
                    </IconButton>
                    <IconButton onClick={() => this.onPageChange(currentPage - 1)}>
                      <BackIcon />
                    </IconButton>
                  </span>
                  <span>{currentPage} / {numberOfPages}</span>
                  <span>
                    <IconButton onClick={() => this.onPageChange(currentPage + 1)}>
                      <ForwardIcon />
                    </IconButton>
                    <IconButton onClick={() => this.onPageChange(numberOfPages)}>
                      <EndIcon />
                    </IconButton>
                  </span>
                </span>
                <div style={{ marginTop: '4rem' }}>{renderHTML(htmlForm)}</div>
              </Paper>
            )
            : ''
        }
        {
          !isLoading && isEmpty && !htmlForm
            ? (
              <div style={styles.reportingImage}>
                <div style={styles.reportingText}>No reporting data was found, please change your criteria and try again.</div>
                <img style={{ maxHeight: '400px', width: '100%', objectFit: 'scale-down' }} src={reportingImage} alt="" />
              </div>
            )
            : ''
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps, {
  renderHtmlReport,
})(RenderHtmlForm);