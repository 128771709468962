import { CALL_SELF_SERVE_API } from 'middleware/selfServeApi';
import {
  DETECT_INTENT_FAILURE,
  DETECT_INTENT_REQUEST,
  DETECT_INTENT_SUCCESS,
} from './dialogFlow.types';

export const detectIntent = (sessionId, intentName, request) => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [DETECT_INTENT_REQUEST, DETECT_INTENT_SUCCESS, DETECT_INTENT_FAILURE],
      authenticated: true,
      endpoint: `dialogFlow/detectIntent?sessionId=${sessionId}&intentName=${intentName}`,
      method: 'POST',
      payload: request,
    },
  };
};