import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper } from '@material-ui/core';
import React from 'react';

let ReportCard = (props) => {
  let { showComingSoon } = props;
  let { name, description, link, icon, backgroundColor } = props.report;

  return (
    <Paper
      style={{ 
        cursor: link && !showComingSoon ? 'pointer' : 'default',
        borderRadius: '0.5rem',
        height: '100%',
        position: 'relative',
      }} 
      onClick={link && !showComingSoon ? () => props.handleNavigation(link) : null}
    >
      <Paper 
        style={{ 
          background: backgroundColor,
          height: '45px',
          width: '45px',
          position: 'absolute',
          left: '5%',
          top: '-10%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          '& > svg': {
            height: '30px',
            width: '30px',
          },
          borderRadius: '0.5rem',
        }}
      >
        <FontAwesomeIcon icon={icon} style={{ color: '#fff', fontSize: '2rem' }} />
      </Paper>
      <div>        
        <div
          style={{
            position: 'absolute',
            bottom: '65px',
            width: '100%',
            fontWeight: 'bold',
            height: '8rem',
            padding: '0.5rem 0.5rem 0.5rem 5%',
            fontSize: '1.4rem',
          }}
        >
          <p
            style={{
              color: 'gray',
              marginBottom: '0',
            }}
          >
            {name}
          </p>
          <hr 
            style={{
              margin: 0,
              border: '0.5px solid #ccc',
            }} 
          />
        </div>
        <div 
          style={{
            height: '13.5rem', 
          }}
        >
          <div 
            style={{ 
              padding: '0.5rem 0.5rem 0rem 5%',
              overflow: 'auto',
              position: 'absolute',
              bottom: '8px',
            }}
          >
            {showComingSoon ? 'Coming Soon!' : description}</div>
        </div>
      </div>
    </Paper>
  );
};

export default ReportCard;