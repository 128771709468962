import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import React, { Component } from 'react';

import Fab from '@material-ui/core/Fab';

const styles = {
  linkedIn: {
    color: '#3e6b52',
    backgroundColor: '#67a783',
    marginRight: '0.25rem',
  },
  twitter: {
    color: '#15585e',
    backgroundColor: '#37939b',
    marginRight: '0.25rem',
  },
  facebook: {
    color: '#104780',
    backgroundColor: '#3986d4',
    marginRight: '0.25rem',
  },
  trcblog: {
    color: '#ba8a00',
    backgroundColor: '#f0b11d',
    marginRight: '0.25rem',
  },
};

class SocialIcons extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ padding: '0.25rem' }}>
        <Link href="https://www.linkedin.com/company/trc-global-mobility-inc-?trk=company_logo" target="_blank" rel="noopener">
          <Fab size="small" className={classes.linkedIn}>
            <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="lg" />
          </Fab>
        </Link>
        <Link href="https://twitter.com/TRCGlobal" target="_blank" rel="noopener">
          <Fab size="small" className={classes.twitter}>
            <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />
          </Fab>
        </Link>
        <Link href="https://www.facebook.com/TRC.Global.Mobility" target="_blank" rel="noopener">
          <Fab size="small" className={classes.facebook}>
            <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" />
          </Fab>
        </Link>
        <Link href="https://trcglobalmobility.com/blog/" target="_blank" rel="noopener">
          <Fab size="small" className={classes.trcblog}>
            <FontAwesomeIcon icon={['fab', 'wordpress']} size="lg" />
          </Fab>
        </Link>
      </div>
    );
  }
}

SocialIcons = withStyles(styles)(SocialIcons);
export default SocialIcons;
