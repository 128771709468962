import {
  GET_CLIENT_NOTES_FAILURE,
  GET_CLIENT_NOTES_REQUEST,
  GET_CLIENT_NOTES_SUCCESS,
} from './documents.types';

import { CALL_API } from '../../middleware/api';

export const getClientNotes = () => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_NOTES_REQUEST, GET_CLIENT_NOTES_SUCCESS, GET_CLIENT_NOTES_FAILURE],
      authenticated: true,
      endpoint: 'Client/GetClientNotes',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};