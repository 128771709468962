import { createSelector } from 'reselect';

import { isLoadingSelector as benefitStateIsLoading } from 'modules/benefits/benefit.selectors';

const isLoadingBenefitsSelector = (state) => state.policy.isLoadingBenefits;
const isLoadingPolicySummarySelector = (state) => state.policy.isLoadingPolicySummary;

export const isLoadingSelector = createSelector(
  benefitStateIsLoading,
  isLoadingBenefitsSelector,
  isLoadingPolicySummarySelector,
  (...isLoadingBooleans) => isLoadingBooleans.some((isLoading) => isLoading),
);