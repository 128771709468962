import { TextValidator } from 'react-material-ui-form-validator';
import ClientBenefitInput from './ClientBenefitInput';
import PropTypes from 'prop-types';
import React from 'react';

const ClientBenefitTextInput = (props) => (
  <ClientBenefitInput
    component={TextValidator}
    {...props}
  />
);

ClientBenefitTextInput.propTypes = {
  clientVersion: PropTypes.object.isRequired,
  masterVersion: PropTypes.object,
  versionField: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default ClientBenefitTextInput;
