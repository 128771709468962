import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import GooglePlacesAutocomplete from './googlePlacesAutocomplete.component';

export default class CityAutocompleteTextField extends PureComponent {

  render() {
    const { className, disabled, required, label, value, onChange } = this.props;
    return (
      <div className={className}>
        <GooglePlacesAutocomplete
          value={value || ''}
          label={label}
          onSelect={(event, value) => {
            onChange(value);
          }}
          onInputClear={() => {
            onChange();
          }}
          disabled={disabled}
          required={required}
        />
      </div>
    );
  }
}

CityAutocompleteTextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
