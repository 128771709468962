import { withStyles } from '@material-ui/core/styles';
import DocumentsGrid from './documentsGrid.component';
import NotesGrid from './notesGrid.component';
import React, { Component } from 'react';

const styles = (theme) => ({

});

class DocumentsNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentWillMount() {

  }

  render() {
    let { documents, comments, busCodes } = this.props;

    documents = documents.map((doc) => {
      return {
        ...doc,
        FileName: doc.ReportDesc + doc.ReportType,
      };
    });

    return (
      <div>
        <div style={{ marginBottom: '1rem' }}>
          <DocumentsGrid rows={documents} />
        </div>
        <div>
          <NotesGrid rows={comments} busCodes={busCodes} />
        </div>
      </div>
    );
  }
}

DocumentsNotes.propTypes = {

};

DocumentsNotes = withStyles(styles)(DocumentsNotes);

export default DocumentsNotes;