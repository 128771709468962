import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import memoize from 'memoize-one';

import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';

import { bareCurrencyFormatter } from '../../../utilities/currencyFormatter';
import { formatDateWithSlashes } from '../../../utilities/dateFormatter';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    fontSize: '1.5rem',
    padding: '1rem',
  },
  viewButton: {
    whiteSpace: 'nowrap',
  },
});

const getAmountFormatter = (amountKey, currencyKey, paidOnly = false) => (row) => {
  if (paidOnly && row.ClaimStatus !== 'Paid') {
    return '';
  }
  const amount = bareCurrencyFormatter({ value: row[amountKey] });
  const currency = currencyKey ? row[currencyKey] || '' : 'USD';
  return `${amount} ${currency}`;
};

class ReportsGrid extends Component {
  static defaultProps = {
    reports: [],
  };

  state = {
    columns: [
      { name: '', title: '', getCellValue: (row) => (
        row.ReportID ? 
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={this.props.classes.viewButton}
            onClick={() => this.props.onLineSelected(row)}
          >
            View Details
          </Button> : <Fragment />
      ) },
      { name: 'ExpenseCodeDescription', title: 'Category' },
      { name: 'LineDate', title: 'Date', getCellValue: (row) => formatDateWithSlashes(row.LineDate) },
      { name: 'LineDescription', title: 'Description' },
      { name: 'LineAmount', title: 'Submitted Amount', getCellValue: getAmountFormatter('ReceiptAmount', 'ReceiptCurrencyCode') },
      { name: 'ApprovedAmount', title: 'Approved', getCellValue: getAmountFormatter('ApprovedAmount', 'PaidCurrencyCode') },
      { name: 'DeniedAmount', title: 'Denied', getCellValue: getAmountFormatter('DeniedAmount', 'PaidCurrencyCode') },
      { name: 'PaidAmount', title: 'Paid', getCellValue: getAmountFormatter('PaidAmount', 'PaidCurrencyCode', true) },
      { name: 'PaidAmountUSD', title: 'Paid (USD)', getCellValue: getAmountFormatter('PaidAmountUSD', null, true) },
    ],
    tableColumnExtensions: [
      { columnName: '', width: 150 },
    ],
    currentPage: 0,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    filters: [],
    sorting: [{ columnName: 'LineDate', direction: 'desc' }]
  };

  changeCurrentPage = (currentPage) => this.setState({ currentPage });
  changePageSize = (pageSize) => this.setState({ pageSize });
  changeFilters = (filters) => this.setState({ filters });
  changeSorting = (sorting) => this.setState({ sorting });

  flatten = memoize((reports) => {
    const items = [];
    reports.forEach((report) => {
      const { ReportLines, ...base } = report;
      ReportLines.forEach((reportLine) => {
        items.push({ ...base, ...reportLine });
      });
    });
    return items;
  });

  filterCell = (props) => {
    if (props.column.name === 'ExpenseCodeDescription') {
      return <TableFilterRow.Cell {...props} />;
    }
    return <TableCell />;
  };

  render() {
    const { classes, reports } = this.props;
    const { columns, currentPage, pageSize, pageSizes, filters, sorting, tableColumnExtensions } = this.state;
    const flatItems = this.flatten(reports);

    return (
      <Paper className={classes.container}>
        <div className={classes.header}>Employee Expenses</div>
        <Grid
          rows={flatItems}
          columns={columns}
        >
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <IntegratedSorting/>
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table columnExtensions={tableColumnExtensions} />
          <TableHeaderRow showSortingControls/>
          <TableFilterRow
            cellComponent={this.filterCell}
          />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Paper>
    );
  }
}

ReportsGrid.propTypes = {
  reports: PropTypes.array,
  onLineSelected: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
)(ReportsGrid);
