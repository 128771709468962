import * as Colors from '@material-ui/core/colors';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Fab,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { TOAST_MESSAGE_SEVERITY_ERROR, showToast } from 'modules/layout/layout.actions';
import {
  canToggleBenefit,
  canToggleOption,
  getVersionFromSummary,
  mergeOptionsWithOptionDetails,
} from 'modules/benefits/summaryUtil';
import { connect } from 'react-redux';
import { downloadImage } from 'utilities/firebase';
import { isClientSpecificBenefit, isModifiedClientBenefit } from './versionUtil';
import Ribbon from 'common/ribbon.component';
import Spinner from 'common/logoSpinner.component';
import SummaryMetaData from '../../common/summaryMetadata.component';

const useStyles = makeStyles((theme) => (
  {
    card: {
      width: 400,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipClickable: {
      cursor: 'pointer',
    },
    iconOverlayImg: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    footer: {
      marginBottom: theme.spacing(2),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
  }
));

const nonMUIStyles = {
  ribbon: {
    position: 'absolute',
    top: 8,
  },
};

const BenefitCard = (props) => {
  const { summary, onEdit, onToggleOption, onToggleEnabled } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const theme = useTheme();
  const classes = useStyles();
  const version = getVersionFromSummary(summary);
  const benefitIsToggleable = canToggleBenefit(summary);

  const [enabled, setEnabled] = useState(summary.enabled);
  const [options, setOptions] = useState(mergeOptionsWithOptionDetails(summary.options, version.optionDetails));
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const renderOption = (opt) => {
    const toggleable = canToggleOption(summary, opt);
    if (toggleable) {
      return (
        <Chip
          key={opt.id}
          className={classNames(classes.chip, classes.chipClickable)}
          color={opt.enabled ? 'primary' : 'default'}
          label={opt.name}
          onClick={() => toggleBenefitOption(opt, !opt.enabled)}
        />
      );
    } else {
      return (
        <Tooltip title="Option is not available." key={opt.id}>
          <Chip
            className={classes.chip}
            label={opt.name}
          />
        </Tooltip>
      );
    }
  };

  const getStatusLabel = () => {
    if (isClientSpecificBenefit(version)) {
      return <Ribbon text="Created" classes={{ container: nonMUIStyles.ribbon, image: { fill: Colors.yellow['700'] } }} />;
    } else if (isModifiedClientBenefit(version)) {
      return <Ribbon text="Modified" classes={{ container: nonMUIStyles.ribbon, image: { fill: Colors.cyan['700'] } }} />;
    }
    return '';
  };

  const toggleBenefitOption = (opt, enabledNewValue) => {
    opt.enabled = enabledNewValue;
    setOptions([...options]);
    const errorCallback = () => {
      props.showToast(`Failed to ${(enabledNewValue ? 'enable ' : 'disable ')}${opt.name} option`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      opt.enabled = !enabledNewValue;
      setOptions([...options]);
    };
    onToggleOption(opt.id, enabledNewValue, errorCallback);
  };

  const toggleBenefit = () => {
    const enabledNewValue = !enabled;
    setEnabled(enabledNewValue);
    const optionsToggled = {};
    setOptions(options.map((opt) => {
      let shouldToggle = opt.enabled !== enabledNewValue;
      if (opt.masterOption) {
        shouldToggle = shouldToggle && opt.masterOption.enabled;
      }
      if (shouldToggle) {
        opt.enabled = enabledNewValue;
        optionsToggled[opt.id] = opt;
      }
      return opt;
    }));
    const errorCallback = () => {
      props.showToast(`Failed to ${(enabledNewValue ? 'enable ' : 'disable ')}${version.name}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setEnabled(!enabledNewValue);
      setOptions(options.map((opt) => {
        if (optionsToggled[opt.id]) {
          opt.enabled = !enabledNewValue;
        }
        return opt;
      }));
    };
    onToggleEnabled(enabledNewValue, errorCallback);
  };

  useEffect(() => {
    (async () => {
      try {
        if (version.imageId) {
          setImageUrl(await downloadImage(version.imageId));
        }
      } catch (e) {
        setImageLoadFailed(true);
      }
    })();
  }, [version.imageId]);

  return (
    <Card className={classes.card}>
      <Box>
        {!version.imageId &&
          <Box position="relative">
            <Fab className={classes.iconOverlayImg} color="primary" size="medium" onClick={() => onEdit(version)}>
              <FontAwesomeIcon size="2x" icon={['fas', 'pen']} color={theme.palette.common.white} />
            </Fab>
          </Box>
        }
        {version.imageId && !imageUrl && !imageLoadFailed && (
          <div className="center-container">
            <Spinner />
          </div>
        )}
        {imageUrl &&
          <CardActionArea onClick={() => onEdit(version)}>
            {getStatusLabel()}
            <Fab className={classes.iconOverlayImg} color="primary" size="medium" >
              <FontAwesomeIcon size="2x" icon={['fas', 'pen']} color={theme.palette.common.white} />
            </Fab>
            <CardMedia image={imageUrl} className="media" />
          </CardActionArea>
        }
        <CardContent>
          <div className="row justify-space-between align-center">
            <Typography variant="h5">
              {version.name}
            </Typography>
            {summary.mostRecentPublish &&
              <>
                {benefitIsToggleable &&
                  <Switch
                    checked={enabled}
                    onChange={toggleBenefit}
                    value={enabled}
                    color={enabled ? 'primary' : 'default'}
                  />
                }
                {!benefitIsToggleable &&
                  <Tooltip title="Benefit is not available.">
                    <div> {/* div necessary here to make the hovering action display the tooltip */}
                      <Switch
                        checked={false}
                        value={false}
                        disabled={true}
                      />
                    </div>
                  </Tooltip>
                }
              </>
            }
            {imageLoadFailed &&
              <Fab color="primary" size="medium" onClick={() => onEdit(version)}>
                <FontAwesomeIcon size="2x" icon={['fas', 'pen']} color={theme.palette.common.white} />
              </Fab>
            }
          </div>
        </CardContent>
        <Divider variant="middle" />
        <CardContent>
          <Typography variant="subtitle1" className={classes.mb2}>
            Benefit Option(s)
          </Typography>
          <div className={classes.mb2}>
            {options.map(renderOption)}
          </div>
        </CardContent>
      </Box>
      <Box>
        <Box py={2}>
          <Divider />
        </Box>
        <SummaryMetaData summary={summary} classes={{ root: classes.footer }} />
      </Box>
    </Card>
  );
};

BenefitCard.propTypes = {
  summary: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onToggleOption: PropTypes.func.isRequired,
  onToggleEnabled: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(
  null,
  { showToast },
)(BenefitCard);
