import { GET_FIREBASE_TOKEN_FAILURE, GET_FIREBASE_TOKEN_REQUEST, GET_FIREBASE_TOKEN_SUCCESS } from './index.types';
import { CALL_SELF_SERVE_API } from './middleware/selfServeApi';

export const getFirebaseToken = () => {
  return {
    [CALL_SELF_SERVE_API]: {
      types: [GET_FIREBASE_TOKEN_REQUEST, GET_FIREBASE_TOKEN_SUCCESS, GET_FIREBASE_TOKEN_FAILURE],
      authenticated: true,
      endpoint: 'firebase/token',
      method: 'GET',
    },
  };
};
