import { BASE_URL } from '../middleware/api';
import { getAuthToken, getSelectedClientInformation, getSelectedDivisions } from './authUtils';
import moment from 'moment';

export const downloadReport = (actionURL, reportParams = {}, reportPath, fileName) => {
  let postObj = {
    access_token: getAuthToken(),
    fileName,
    reportParameters: getReportParams(reportParams),
    reportPath,
  };

  generateForm(actionURL, postObj);
};

export const generateForm = (formActionURL, inputs, formMethod = 'post') => {
  const f = document.createElement('form');
  f.setAttribute('encType', 'application/x-www-form-urlencoded');
  f.setAttribute('method', formMethod);
  f.setAttribute('action', BASE_URL + formActionURL);

  Object.keys(inputs).forEach((key) => {
    let i = document.createElement('input');
    i.setAttribute('type', 'text');
    i.setAttribute('name', key);
    i.setAttribute('value', inputs[key] instanceof Array ? JSON.stringify(inputs[key]) : inputs[key]);
    f.appendChild(i);
  });

  const s = document.createElement('input');
  s.setAttribute('type', 'submit');
  s.setAttribute('value', 'Submit');
  f.appendChild(s);

  const bodyNode = document.getElementsByTagName('body')[0];
  bodyNode.appendChild(f);

  f.submit();

  bodyNode.removeChild(bodyNode.childNodes[bodyNode.childNodes.length - 1]);
};

export const getReportParams = (reportParams) => {
  let buildReportParams = [];

  reportParams.ClientID = getSelectedClientInformation().id;
  reportParams.DivisionList = getSelectedDivisions().join(',');

  Object.keys(reportParams).forEach((key) => {
    let val = reportParams[key];
    if (val.length > 0) {
      if (key === 'StartDate' || key === 'EndDate') {
        val = moment(val).format('l');
      }
      buildReportParams.push({
        Name: key,
        Value: val,
      });
    }
  });

  return buildReportParams;
};

export default { generateForm, getReportParams };