import {
  GET_BUS_CODES_FAILURE, GET_BUS_CODES_REQUEST, GET_BUS_CODES_SUCCESS,
  GET_CLIENT_FIELDVAL_FAILURE, GET_CLIENT_FIELDVAL_REQUEST, GET_CLIENT_FIELDVAL_SUCCESS,
  GET_COUNTRIES_FAILURE, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS,
} from './reporting.actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  clientFieldValues: [],
});

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_CLIENT_FIELDVAL_REQUEST:
      return state.set('isLoading', true);

    case GET_CLIENT_FIELDVAL_SUCCESS:
      return state.set('clientFieldValues', action.response).set('isLoading', false);

    case GET_CLIENT_FIELDVAL_FAILURE:
      return state.set('isLoading', false);

    case GET_COUNTRIES_REQUEST:
      return state.set('isLoading', true);

    case GET_COUNTRIES_SUCCESS:
      return state.set('countries', action.response).set('isLoading', false);

    case GET_COUNTRIES_FAILURE:
      return state.set('isLoading', false);

    case GET_BUS_CODES_REQUEST:
      return state.set('isLoading', true);

    case GET_BUS_CODES_SUCCESS:
      return state.set('busCodes', action.response).set('isLoading', false);

    case GET_BUS_CODES_FAILURE:
      return state.set('isLoading', false);

    default:
      return state;
  }
}
