import React from 'react';
import PropTypes from 'prop-types';
import {Typography, useTheme} from "@material-ui/core";

const useStyles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.common.white,
    position: 'absolute',
    zIndex: theme.zIndex.mobileStepper,
  }
});

const Ribbon = (props) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const {classes, text} = props;
  const containerStyles = classes.container || {};

  return (
    <div style={{...styles.container, ...containerStyles}}>
      <Typography variant="button" style={styles.text}>{text}</Typography>
      <svg width="88.523" height="30" viewBox="0 0 88.523 30">
        <path style={classes.image} d="M311.694,469.74H223.171v-30h88.523l-3.761,15.331Z" transform="translate(-223.171 -439.74)"/>
      </svg>
    </div>
  );
};

Ribbon.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.exact({
    container: PropTypes.object,
    image: PropTypes.shape({
      fill: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Ribbon;

