import { Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getPolicyHistory } from './policy.actions';

import Dialog from 'common/dialog.component';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import VersionHistoryRows, { prepareHistoryEntries } from 'common/form/versionHistoryRows.component';

const PolicyHistoryDialog = (props) => {
  const { open: propsOpen, onClose: propsOnClose, policyId, getPolicyHistory: propsGetPolicyHistory } = props;
  const [history, setHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);


  useEffect(() => {
    (async () => {
      if (propsOpen && !history) {
        const r = await propsGetPolicyHistory(policyId);
        const historyEntries = r.response;
        if (isEmpty(historyEntries)) {
          setErrorMessage('There was a problem loading the history. Please try again');
        } else {
          setHistory(prepareHistoryEntries(historyEntries));
        }
        setIsLoading(false);
      }
    })();
  }, [propsOpen, history, policyId, propsGetPolicyHistory]);

  return (
    <Dialog
      open={propsOpen}
      title="History"
      showTitleClose={true}
      maxWidth="lg"
      onClose={propsOnClose}
      onExited={() => {
        setErrorMessage(null);
        setHistory(null);
      }}
      content={
        <Box width={700} px={1}>
          {isLoading ? (
            <FullscreenSpinner />
          ) : errorMessage ? (
            <Typography variant="h5" color="error">
              {errorMessage}
            </Typography>
          ) : (
            <>
              <Box mb={1} mt={2} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                Updates
              </Box>
              <VersionHistoryRows history={history} />
            </>
          )}
        </Box>
      }
    />
  );
};

PolicyHistoryDialog.propTypes = {
  policyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  getPolicyHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    getPolicyHistory: state.getPolicyHistory,
  };
};

export default connect(mapStateToProps, {
  getPolicyHistory,
})(PolicyHistoryDialog);
