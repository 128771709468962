import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';

import { getRecents } from '../../utilities/trackRecents';

const styles = {
  headerPaper: {
    height: '40px',
    width: '90%',
    position: 'absolute',
    top: '-20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    left: 0,
    right: 0,
    zIndex: 100,
    borderRadius: '0.5rem',
    background: 'linear-gradient(45deg,#6B6A6A,#393939)',
  },
  headerText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  recentsContainer: {
    paddingTop: '2.2rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '100%',
    width: 'calc(100% - 2rem)',
  },
  cardIcon: {
    height: '50px',
    width: '50px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      height: '35px',
      width: '35px',
    },
    borderRadius: '0.5rem 0 0 0.5rem',
  },
};

class RecentList extends Component {
  navigate = (path) => {
    this.props.history.push(path);
  }

  render() {
    let { classes, userInformation } = this.props;

    let recents = [];
    if (userInformation) {
      let { UserName } = userInformation;
      recents = getRecents(UserName);
    }

    return (
      <Paper style={{ height: '100%', borderRadius: '0.5rem', position: 'relative' }}>
        <Paper className={classes.headerPaper}>
          <div className={classes.headerText}>Recently Viewed</div>
        </Paper>
        <div className={classes.recentsContainer}>
          {recents.length > 0 && recents.map((item, i) => {
            let type = '';
            if (item.type === 'pendingAuthorization') {
              type = 'Pending Authorization';
            } else if (item.type === 'submittedAuthDetail') {
              type = 'Transferee';
            }
            return (<div style={{ marginBottom: '1rem' }} key={i}>
              <Paper style={{ borderRadius: '0.5rem', cursor: 'pointer' }} onClick={() => this.navigate(item.path)}>
                <div style={{ display: 'flex' }}>
                  <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #67a783, #4d8766)' }}>
                    <FontAwesomeIcon icon="star" color="white" size="2x" />
                  </Paper>
                  <div style={{ display: 'block', marginLeft: '1rem', fontWeight: 'bold', width: '100%', color: '#8a8a8a' }}>
                    <div style={{ height: '24px' }}>{`${type  }:`}</div>
                    <div style={{ height: '24px', width: 'calc(100% - 55px)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {item.name}
                    </div>
                  </div>
                </div>
              </Paper>
            </div>);
          })}
          {recents.length <= 0 &&
            <div style={{ marginBottom: '1rem' }}>
              <Paper style={{ borderRadius: '0.5rem', cursor: 'pointer' }} onClick={() => this.navigate('/transferees')}>
                <div style={{ display: 'flex' }}>
                  <Paper className={classes.cardIcon} style={{ background: 'linear-gradient(45deg, #67a783, #4d8766)' }}>
                    <FontAwesomeIcon icon="star" color="white" size="2x" />
                  </Paper>
                  <div style={{ display: 'block', marginLeft: '1rem', fontWeight: 'bold', width: '100%', color: '#8a8a8a' }}>
                    <div style={{ height: '24px' }}>No Recently Viewed Items</div>
                    <div style={{ height: '24px', width: 'calc(100% - 55px)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      View Transferees
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          }
        </div>
      </Paper>
    );
  }
}

RecentList = withStyles(styles)(RecentList);
export default withRouter(RecentList);
