import NumberFormat from 'react-number-format';
import React from 'react';

import { INTEGER_MAX } from './constants';

export const CurrencyFormat = (props) => {
  const { onChange, inputRef, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

export const TwoDecimalFormat = (props) => {
  const { onChange, inputRef, emitAsEvent, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        const toEmit = values.floatValue !== undefined ? values.floatValue : null;
        if (emitAsEvent) {
          onChange({
            target: {
              value: toEmit
            }
          })
        } else {
          onChange(toEmit)
        }
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

export const IntegerFormat = (props) => {
  const { onChange, inputRef, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      prefix=""
      decimalScale={0}
      fixedDecimalScale={true}
    />
  );
};

export const PositiveIntegerFormat = (props) => {
  const { onChange, inputRef, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue !== undefined ? Math.min(values.floatValue, INTEGER_MAX) : null);
      }}
      prefix=""
      decimalScale={0}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

export const PhoneNumberFormat = (props) => {
  const { onChange, inputRef, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="(###) ###-####" mask="_"
    />
  );
};

export const PhoneNumberFormatText = (props) => {
  let { value } = props;
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      renderText={(value) => <div>{value}</div>}
      format="(###) ###-####"
    />
  );
};

export default { CurrencyFormat, PhoneNumberFormat, PhoneNumberFormatText, IntegerFormat };