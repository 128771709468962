import { memoize } from 'lodash';

import { COST_TYPES } from '../benefits/constants';
import { mergeClientAndMaster, mergeClientAndMasterQuestion } from 'modules/benefits/versionUtil';

export const mergeAndSortClientAndPolicyBenefits = (policyBenefits, clientBenefits) => {
  clientBenefits = clientBenefits.filter((clientBenefit) => {
    return policyBenefits.every((policyBenefit) => policyBenefit.clientBenefitVersion.versionId !== clientBenefit.clientBenefitVersion.versionId);
  });
  //enabled benefits first
  const sortedPolicyBenefits = policyBenefits.sort((x, y)=> (x.enabled === y.enabled)  ? 0 : x.enabled? -1 : 1 );
  return [...sortedPolicyBenefits, ...clientBenefits];
};

export const getMergedBenefit = memoize((policyBenefit) => {
  const toReturn = {};
  const clientAndMasterMerged = mergeClientAndMaster(policyBenefit.clientBenefitVersion);
  Object.keys(policyBenefit).forEach((prop) => {
    if (policyBenefit[prop] != null && policyBenefit[prop] !== '') {
      toReturn[prop] = policyBenefit[prop];
    } else if (
      clientAndMasterMerged[prop] != null &&
      clientAndMasterMerged[prop] !== ''
    ) {
      toReturn[prop] = clientAndMasterMerged[prop];
    }
  });
  // todo this is to help support old benefits that do not store the imageUrl.
  //  Can be removed once all benefits have an imageUrl and zero policies are storing the imageUrl
  if (clientAndMasterMerged.imageUrl) {
    toReturn.imageUrl = clientAndMasterMerged.imageUrl;
  }
  toReturn.options = policyBenefit.options.map((opt) => {
    const matchingClientAndMasterMergedOpt = clientAndMasterMerged.optionDetails.find((optDetail) => optDetail.id === opt.benefitOptionDetail.id);
    return getMergedOption(opt, matchingClientAndMasterMergedOpt);
  });
  toReturn.options.sort((opt1, opt2) =>  opt1.order - opt2.order);
  return toReturn;
});

export const getMergedOption = (opt, clientAndMasterMerged) => {
  const toReturn = {};
  Object.keys(opt).forEach((prop) => {
    if (opt[prop] != null && opt[prop] !== '') {
      toReturn[prop] = opt[prop];
    } else if (
      clientAndMasterMerged[prop] != null &&
      clientAndMasterMerged[prop] !== ''
    ) {
      toReturn[prop] = clientAndMasterMerged[prop];
    }
  });
  // todo this is to help support old benefits that do not store the imageUrl.
  //  Can be removed once all benefits have an imageUrl and zero policies are storing the imageUrl
  if (clientAndMasterMerged.imageUrl) {
    toReturn.imageUrl = clientAndMasterMerged.imageUrl;
  }
  toReturn.rules = opt.rules.map((rule) => {
    return {
      ...rule,
      questions: rule.questions.map((q) => ({ ...q, benefitQuestionDetail: mergeClientAndMasterQuestion(q.benefitQuestionDetail) })),
    };
  });
  return toReturn;
};

export const canToggleOption = (policyBenefit, policyOpt) => {
  let canToggle = policyBenefit.enabled && policyOpt.benefitOption.enabled;
  if (!policyOpt.benefitOption.masterOption) {
    return canToggle;
  }
  canToggle = canToggle && policyOpt.benefitOption.masterOption.enabled;
  return canToggle;
};

export const isOptionEnabled = (policyOpt) => {
  let isEnabled = policyOpt.enabled && policyOpt.benefitOption.enabled;
  if (!policyOpt.benefitOption.masterOption) {
    return isEnabled;
  }
  isEnabled = isEnabled && policyOpt.benefitOption.masterOption.enabled;
  return isEnabled;
};

export const canToggleBenefit = (policyBenefit) => {
  let canToggle = policyBenefit.clientBenefit.enabled;
  if (!policyBenefit.clientBenefit.masterBenefit) {
    return canToggle;
  }
  canToggle = canToggle && policyBenefit.clientBenefit.masterBenefit.enabled;
  return canToggle;
};

export const isBenefitEnabled = (policyBenefit) => {
  let isEnabled = policyBenefit.enabled && policyBenefit.clientBenefit.enabled;
  if (!policyBenefit.clientBenefit.masterBenefit) {
    return isEnabled;
  }
  isEnabled = isEnabled && policyBenefit.clientBenefit.masterBenefit.enabled;
  return isEnabled;
};

export const isBenefitInUse = (policyBenefit) => {
  return isBenefitEnabled(policyBenefit) && policyBenefit.options.some(isOptionEnabled);
};

export const getDynamicCostBenefits = (benefits) => {
  return benefits.map((ben) => getMergedBenefit(ben)).filter((ben) => !!ben.costLookupKey);
};

export const getAuthorizationFieldsForBenefit = (ben, { costLookupByApi, costLookupByTable }) => {
  let lookupObject;
  switch (ben.costType) {
    case COST_TYPES.API_CALC:
      lookupObject = costLookupByApi;
      break;
    case COST_TYPES.TABLE_LOOKUP:
      lookupObject = costLookupByTable;
      break;
    default:
      throw new Error(`no authorization fields exist for cost type ${  ben.costType}`);
  }
  if (!lookupObject[ben.costLookupKey] || !lookupObject[ben.costLookupKey].response) {
    return [];
  }
  return lookupObject[ben.costLookupKey].response.authorizationFields;
};
