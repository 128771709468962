export const RequestStatusEnum = {
  NONE : 'NONE',
  PENDING : 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const isRequestComplete = (status) => status === RequestStatusEnum.SUCCESS || status === RequestStatusEnum.FAILED;

export const GET_FIREBASE_TOKEN_REQUEST = 'GET_FIREBASE_TOKEN_REQUEST';
export const GET_FIREBASE_TOKEN_SUCCESS = 'GET_FIREBASE_TOKEN_SUCCESS';
export const GET_FIREBASE_TOKEN_FAILURE = 'GET_FIREBASE_TOKEN_FAILURE';