import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Callback from './modules/layout/authCallback';
import MainLayout from './modules/layout/main.layout';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import auth from './auth/auth';

ReactGA.initialize('UA-80271114-8');
ReactGA.pageview(window.location.pathname + window.location.search);

const handleAuthentication = (routeProps) => {
  if (/access_token|id_token|error/.test(routeProps.location.hash)) {
    auth.handleAuthentication();
  }
};

const Root = ({ store }) => (
  <Provider store={store}>
    <div style={{ height: '100%' }}>
      <Switch>
        <Route path="/callback" render={(routeProps) => {
          handleAuthentication(routeProps);
          return <Callback {...routeProps} />;
        }}
        />
        <Route path="/" render={(props) => <MainLayout {...props} auth={auth} />} />
      </Switch>
    </div>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
export default Root;