import {
  GET_CLIENT_NOTES_FAILURE,
  GET_CLIENT_NOTES_REQUEST,
  GET_CLIENT_NOTES_SUCCESS,
} from './documents.types';
  
import produce from 'immer';
  
const initialState = {
  isLoadingNotes: false,
  clientNotes: [],
};
  

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_NOTES_REQUEST:
      draft.isLoadingNotes = true;
      break;

    case GET_CLIENT_NOTES_SUCCESS:
      draft.isLoadingNotes = false;
      draft.clientNotes = action.response;
      break;

    case GET_CLIENT_NOTES_FAILURE:
      draft.isLoadingNotes = false;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
