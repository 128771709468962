import { Grid, Paper, Typography } from '@material-ui/core';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import memoize from 'memoize-one';

import { bareCurrencyFormatter } from '../../../utilities/currencyFormatter';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    fontSize: '1.5rem',
  },
  footnote:{
    fontSize: '0.75rem',
    fontStyle: 'italic',
    color: '#A1A1A1',
  },
});


class ExpensesSummary extends Component {

  reportsTotal = memoize((reports) => {
    let total = 0.0;
    reports.forEach((report) => {
      if(report.ReportID){
        const { ReportLines } = report;
        total += ReportLines.reduce((s, r) => s + r.PaidAmountUSD, 0);
      }
    });
    return total;
  });

  render() {

    const { classes, moveCap, reports, vendorExpenses } = this.props;

    const expenseTotal = this.reportsTotal(reports);
    const vendorTotal = vendorExpenses.reduce((s, r) => s + r.PayAmount, 0);

    return (
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item container direction="row">
            <Grid item xs={1} />
            <Grid item container xs={3} direction="column">
              <Grid item><Typography align="center"><div className={classes.header}>Total Budget</div></Typography></Grid>
              <Grid item><Typography align="center">${bareCurrencyFormatter({ value: moveCap })}</Typography></Grid>
            </Grid>
            <Grid item container xs={4} direction="column">
              <Grid item><Typography align="center"><div className={classes.header}>Total Expenses To Date</div></Typography></Grid>
              <Grid item><Typography align="center">${bareCurrencyFormatter({ value: expenseTotal + vendorTotal })}</Typography></Grid>
            </Grid>
            <Grid item container xs={3} direction="column">
              <Grid item><Typography align="center"><div className={classes.header}>Remaining Budget</div></Typography></Grid>
              <Grid item><Typography align="center">${bareCurrencyFormatter({ value: moveCap - (expenseTotal + vendorTotal) })}</Typography></Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item container justify="center">
            <Typography className={classes.footnote}>*Items recently submitted and not approved may not be accounted for in this total</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ExpensesSummary.propTypes = {
  moveCap: PropTypes.array.isRequired,
  reports: PropTypes.array.isRequired,
  vendorExpenses: PropTypes.array.isRequired,
};

export default compose(
  withStyles(styles),
)(ExpensesSummary);
