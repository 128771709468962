import { CALL_API } from '../../middleware/api';

export const SET_LOGGED_IN = 'SET_LOGGED_IN';

export const GET_HEALTH_REQUEST = 'GET_HEALTH_REQUEST';
export const GET_HEALTH_SUCCESS = 'GET_HEALTH_SUCCESS';
export const GET_HEALTH_FAILURE = 'GET_HEALTH_FAILURE';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

export const setLoggedIn = (status = false) => {
  return {
    type: SET_LOGGED_IN,
    payload: status,
  };
};

export const authenticate = (payload) => {
  return {
    [CALL_API]: {
      types: [AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE],
      authenticated: true,
      endpoint: 'Account/Login',
      method: 'POST',
      payload,
    },
  };
};

export const getHealth = () => {
  return {
    [CALL_API]: {
      types: [GET_HEALTH_REQUEST, GET_HEALTH_SUCCESS, GET_HEALTH_FAILURE],
      authenticated: true,
      endpoint: 'health',
      method: 'GET',
    },
  };
};
