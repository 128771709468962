import { Box, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  getAuthorizationFieldsForBenefit,
  getDynamicCostBenefits,
} from './policyBenefitUtil';
import AuthInfo from './authInfo.component';
import MessengerContainer from './bot/messenger.container';
import PolicyContext from './policyContext';

const PolicyBotContainer = (props) => {
  const { activeStep, formStep, costLookupByApi, costLookupByTable } = props;
  const [isValid, setIsValid] = useState(false);
  const [costsNeedCalculating, setCostsNeedCalculating] = useState(true);
  const [areAnyAuthFieldsRequired, setAreAnyAuthFieldsRequired] = useState(false);
  const [sessionId, setSessionId] = useState(uuidv4());
  const { validateSteps, benefitsInUse, isBotUpToDate } = useContext(PolicyContext);
  const isActive = activeStep === formStep;

  const onAuthInfoChange = useCallback(() => {
    setCostsNeedCalculating(true);
    setSessionId(uuidv4());
  }, []);

  const resetSessionId = useCallback(() => setSessionId(uuidv4()), []);

  useEffect(() => {
    if (isActive) {
      setIsValid(validateSteps());
    }
  }, [isActive, validateSteps]);

  useEffect(() => {
    setCostsNeedCalculating(areAnyAuthFieldsRequired);
  }, [sessionId, areAnyAuthFieldsRequired]);

  useEffect(() => {
    if (!isBotUpToDate) {
      setCostsNeedCalculating(true);
    }
  }, [isBotUpToDate]);

  useEffect((() => {
    const relevantBenefits = getDynamicCostBenefits(benefitsInUse);
    setAreAnyAuthFieldsRequired(relevantBenefits.some((ben) => {
      return getAuthorizationFieldsForBenefit(ben, { costLookupByApi, costLookupByTable }).length;
    }));
  }), [costLookupByApi, costLookupByTable, benefitsInUse]);

  return (
    <Box p={2}>
      <Grid container>
        {areAnyAuthFieldsRequired &&
          <Grid item xs={6} container justify="center">
            <AuthInfo
              onAuthInfoChange={onAuthInfoChange}
              costsNeedCalculating={costsNeedCalculating}
              setCostsNeedCalculating={setCostsNeedCalculating}
              sessionId={sessionId}
              costLookupByApi={costLookupByApi}
              costLookupByTable={costLookupByTable}
            />
          </Grid>
        }
        <Grid item xs={areAnyAuthFieldsRequired ? 6 : 12} container justify="center">
          <MessengerContainer isActive={isActive} isValid={isValid} areAnyBenefitsInUse={benefitsInUse.length > 0} costsNeedCalculating={costsNeedCalculating} sessionId={sessionId} resetSessionId={resetSessionId} />
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    costLookupByApi: state.benefit.costLookupByApi,
    costLookupByTable: state.benefit.costLookupByTable,
  };
};

export default connect(mapStateToProps, null)(PolicyBotContainer);