import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
// import { Link } from 'react-router-dom';

const styles = (theme) => ({  
  paper: {
    position: 'absolute',
    top: '15%',
    left: '20%',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    zIndex: '2000',
    width: '75%',
    maxWidth: 'calc(95vw)',
    maxHeight: (window.innerHeight - 100) + 'px !important',
  },
  modalBackdrop: {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '2',
    position: 'fixed',
    willChange: 'opacity',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  
  },
  viewAuthButton:{
    color: '#FFF',
    backgroundColor: '#8AACB5',
    '&:hover': {
      backgroundColor: '#15585E',
 },     
  },
  tableHeader: {
    color: '#FFF',
    backgroundColor: '#15585E'
  },
  tableStripedRow: {
    "&:nth-child(even)": {
      backgroundColor: '#EEEDF8'
    },
  },
  closeButton: {
    marginTop: '20px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#15585e',
      '& > span': {
        color: '#fff',
      },
    },
    '& > span': {
      color: '#15585e',
    },
  }
});

class DuplicateAuthsModal extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      columns: [
        { field: 'FirstName', headerName: 'First Name' },
        { field: 'LastName', headerName: 'Last Name' },
      ],
      modalHeight: '0',
      modalWidth: '0',
      selectedAuthorizationID: undefined    
    };
  }

  componentDidMount() {
    this.setState({
      // modalHeight: this.modal.clientHeight,
      modalWidth: this.modal.clientWidth,
    });
  }

  getDate(addedOn){  
    let date = new Date(addedOn); 
    return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
  }

  handleCloseAuthorizationModal(){
    this.setState({authorizationID:  undefined});
  }

  handleDisplayingAuthorizationViewButton(authorizationID, viewAuthButton, handleSetSelectedAuthorization){
    if(authorizationID){
      return (
        <Button 
        className={viewAuthButton}
        onClick={() => handleSetSelectedAuthorization(authorizationID)} title="View authorization" 
        >View Auth
      </Button>
      )
    }
    return "Unavailable for viewing in ReloSource" + String.fromCharCode(8482);
  }

  // displayAuthorization(authorizationID, displayAuthsTable){
  //   selectedAuthorizationID = authorizationID;
  // }
  
  render() {
    const {
      classes,
      handleClose,
      auths,
      handleSetSelectedAuthorization
    } = this.props;

    return (
      <div
        // className="MuiModal-root-320"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={classes.modalBackdrop}
          aria-hidden="true"
        />
        <div
          className={classes.paper}
          ref={(elem) => { this.modal = elem; }}
        >
          <h2><center>Similar active authorizations were found</center></h2>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ width: '100%' }} style={{ maxHeight: window.innerHeight - (window.innerHeight) * .40 }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>File #</TableCell>
                    <TableCell className={classes.tableHeader} align="left">Transferee</TableCell>
                    <TableCell className={classes.tableHeader} align="left">Policy</TableCell>
                    <TableCell className={classes.tableHeader} align="left">Origin State</TableCell>
                    <TableCell className={classes.tableHeader} align="left">Destination State</TableCell>
                    <TableCell className={classes.tableHeader} align="left">Initiated</TableCell>
                    <TableCell className={classes.tableHeader} align="left">View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auths.map((row) => (
                    <TableRow key={row.AuthorizationID} className={classes.tableStripedRow}>
                      <TableCell component="th" scope="row">{row.AuthorizationID === null || row.AuthorizationID === '' ? row.MTAuthorizationID : row.AuthorizationID}</TableCell>
                      <TableCell align="left">{row.FirstName} {row.LastName} {row.NickName && `(${row.NickName})`} </TableCell>
                      <TableCell align="left">{row.ReloPolicy}</TableCell>
                      <TableCell align="left">{row.OldState}</TableCell>
                      <TableCell align="left">{row.NewState}</TableCell>
                      <TableCell align="left">{this.getDate(row.AddedOn)}</TableCell>
                      <TableCell align="left">
                        {this.handleDisplayingAuthorizationViewButton(row.AuthorizationID, classes.viewAuthButton, handleSetSelectedAuthorization)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>   
          </Paper>       
          <div className="row justify-space-around">
            <Button
                className={classes.closeButton}
                onClick={handleClose}
                variant="contained"
            >
              Close
            </Button>
          </div>          
        </div>  
      </div>
    );
  }
}

DuplicateAuthsModal = withStyles(styles)(DuplicateAuthsModal);

export default DuplicateAuthsModal;