import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const generateRuleQuestion = () => {
  return { tempId: uuidv4(), benefitQuestionDetail: { answers: [] }, answers: [] };
};

export const generateRule = () => {
  return { tempId: uuidv4(), questions: [generateRuleQuestion()] };
};

export const sanitizeRule = (rule, idx) => {
  return {
    ...rule,
    order: idx,
    tempId: undefined,
    questions: rule.questions
      .filter((question) => (
        !isEmpty(question.benefitQuestionDetail) && !isEmpty(question.answers)
      ))
      .map((question, idx) => (
        {
          ...question,
          masterQuestionDetail: question.benefitQuestionDetail.masterQuestionDetail,
          tempId: undefined,
          order: idx,
        }
      )),
  };
};

export const isRuleValid = (rule) => rule.questions.length > 0;