import 'react-awesome-lightbox/build/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Subtract from '@material-ui/icons/Remove';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import memoize from 'memoize-one';
import withWidth from '@material-ui/core/withWidth';

import { bareCurrencyFormatter } from '../../../utilities/currencyFormatter';
import { formatDateWithSlashes } from '../../../utilities/dateFormatter';
import { generateForm } from '../../../utilities/reportingUtils';
import { getAuthToken } from '../../../utilities/authUtils';
import { getReceiptAttachment } from '../transferees.actions';
import LogoSpinner from '../../../common/logoSpinner.component';
import ResponsiveDialog from '../../../common/responsiveDialog.component';

const reportTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: '2px solid #15585e',
      },
      paddingDense: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  },
});

const styles = (theme) => ({
  summary: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  panel: {
    flexDirection: 'column',
  },
  table: {
    marginBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  headerItem: {
    marginBottom: theme.spacing(0.5),
  },
  paidAmount: {
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'left',
    },
  },
  thumbnailContainer: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  thumbnailIcon: {
    marginBottom: theme.spacing(2),
  },
  fileCaption: {
    width: '100%',
    overflowWrap: 'break-word',
  },
  denied: {
    color: theme.palette.error.main,
  },
  reason: {
    textAlign: 'right',
  },
  mobileReason: {
    textAlign: 'center',
  },
  tableContainer: {
    overflowX: 'scroll',
  },
  mobileCardHeader: {
    borderBottom: '1px solid rgb(91, 155, 157)',
    marginBottom: theme.spacing(2),
  },
  mobileCardItem: {
    paddingBottom: theme.spacing(2),
  },
  expansionPanelRoot: {
    boxShadow: 'none',
  },
});

class Report extends Component {
  static defaultProps = {
    disableCollapse: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: props.disableCollapse,
      selectedImage: null,
      selectedLineItem: null,
    };
  }

  handleChange = () => {
    if (!this.props.disableCollapse) {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  selectImage = async (selectedImage) => {
    this.setState({ selectedImage });

    if (!this.props.receiptAttachmentImages[selectedImage.AttachmentId]) {
      await this.props.getReceiptAttachment(selectedImage.AttachmentId);
    }
    const fullImage = this.props.receiptAttachmentImages[selectedImage.AttachmentId];
    this.props.handleImageSelected(fullImage.FileBase64);
  };

  handleImageClose = () => this.setState({ selectedImage: null });

  getFullImageElement(image) {
    if (!image) {
      return null;
    }

    if (this.props.isLoadingReceiptImage) {
      return <LogoSpinner />;
    }
    const fullImage = this.props.receiptAttachmentImages[image.AttachmentId];
    if (fullImage && fullImage.FileBase64) {
      return <img style={{ margin: 'auto', display: 'block', width: '75%' }} src={fullImage.FileBase64} alt="" />;
    }
    return <div>Full Image Not Available</div>;
  }

  handleAttachmentClick = (attachment) => () => {
    const { AttachmentId, FileType, FileBase64 } = attachment;
    if ((FileType === 'png' || FileType === 'jpg' || FileType === 'jpeg') && FileBase64) {
      this.selectImage(attachment);
    } else {
      generateForm('AuthorizationFile/GetExpenseDetailAttachment', {
        attachmentId : AttachmentId,
        access_token: getAuthToken(),
      });
    }
  };

  selectLineItem = (selectedLineItem) => () => {
    this.setState({ selectedLineItem });
  }

  handleLineItemClose = () => this.setState({ selectedLineItem: null });

  getFullLineItemElement(item) {
    if (!item) {
      return null;
    }
    const { classes } = this.props;
    const {
      ApprovedAmount,
      DeniedAmount,
      LineDate,
      LineDescription,
      PaidAmount,
      PaidCurrencyCode,
      ReceiptCurrencyCode,
      ReceiptAmount
    } = item;
    const hasDeniedAmount = DeniedAmount > 0;
    const receiptAmount = bareCurrencyFormatter({ value: ReceiptAmount });
    const approvedAmount = bareCurrencyFormatter({ value: ApprovedAmount });
    const deniedAmount = bareCurrencyFormatter({ value: DeniedAmount });
    const paidAmount = bareCurrencyFormatter({ value: PaidAmount });

    return (
      <React.Fragment>
        <Grid container className={classes.mobileCardHeader}>
          <Grid item xs={12} className={classes.mobileCardItem}>
            {LineDescription}
          </Grid>
          <Grid item xs={12} className={classes.mobileCardItem}>
            {formatDateWithSlashes(LineDate)}
          </Grid>
        </Grid>
        <Grid container className={classes.mobileCardItem}>
          <Grid item xs={7}>
            Submitted:
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            {receiptAmount} {ReceiptCurrencyCode}
          </Grid>
        </Grid>
        <Grid container className={classes.mobileCardItem}>
          <Grid item xs={7}>
            Approved:
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            {approvedAmount} {PaidCurrencyCode}
          </Grid>
        </Grid>
        {hasDeniedAmount &&
          <Grid container className={classes.mobileCardItem}>
            <Grid item xs={7}>
              Denied:
            </Grid>
            <Grid item xs={5} style={{ textAlign: 'right' }} className={classes.denied}>
              {deniedAmount} {PaidCurrencyCode}
            </Grid>
          </Grid>
        }
        <Grid container className={classes.mobileCardItem}>
          <Grid item xs={7}>
            Paid:
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            {paidAmount} {PaidCurrencyCode}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderPaymentInfo(payment) {
    const { classes, report, submittedAuthDetail } = this.props;
    const { PaymentBank, ClaimStatus } = report;
    const { ShortClientDescription } = submittedAuthDetail;

    const paymentDateContent = payment && payment.apPostedDate
      ? <Typography className={classes.headerItem}>Payment Date: {formatDateWithSlashes(payment.apPostedDate)}</Typography>
      : null;

    let paidAmountContent = null;
    let trackingAmountContent = null;
    if (payment && payment.isTrackingExpense) {
      paidAmountContent = <Typography variant="h6" className={classes.headerItem}>Amount submitted to client: {payment.amount} {payment.currencyCode}</Typography>;
      trackingAmountContent = <Typography className={classes.headerItem}>Paid by {ShortClientDescription}</Typography>;
    } else if (payment) {
      paidAmountContent = <Typography variant="h6">Paid: {payment.amount} {payment.currencyCode}</Typography>;
    }

    return (
      <div className={classes.header}>
        <div>
          <Typography className={classes.headerItem}>Claim Status: {ClaimStatus}</Typography>
          {paymentDateContent}
          {PaymentBank &&
            <React.Fragment>
              <Typography className={classes.headerItem}>{PaymentBank.BankName}</Typography>
              <Typography className={classes.headerItem}>********{PaymentBank.AccountNumberLast4}</Typography>
            </React.Fragment>
          }
        </div>
        <div className={classes.paidAmount}>
          {paidAmountContent}
          {trackingAmountContent}
        </div>
      </div>
    );
  }

  renderReportLines() {
    const { classes, report, contentWidth } = this.props;
    const { ReportLines } = report;

    if (contentWidth <= 960) { // md screen size
      return this.renderReportLinesMobile();
    }

    const hasDeniedAmount = !!ReportLines.find((line) => line.DeniedAmount > 0);

    return (
      <Grid container className={classes.tableContainer}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="dense">Category</TableCell>
                <TableCell padding="dense">Date</TableCell>
                <TableCell padding="dense">Description</TableCell>
                <TableCell padding="dense" align="right">Submitted</TableCell>
                <TableCell padding="dense" align="right">Approved</TableCell>
                {hasDeniedAmount &&
                  <TableCell padding="dense" align="right">Denied</TableCell>
                }
                <TableCell padding="dense" align="right">Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ReportLines.map((line) => this.renderLineItem(line, hasDeniedAmount))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  renderLineItem = (item, hasDeniedAmount) => {
    const { classes } = this.props;
    const {
      ExpenseLineId,
      LineDate,
      LineDescription,
      ExpenseCodeDescription,
      ApprovedAmount,
      DeniedAmount,
      ReceiptCurrencyCode,
      ReceiptAmount,
      PaidAmount,
      PaidCurrencyCode,
      Reason,
    } = item;
    const receiptAmount = bareCurrencyFormatter({ value: ReceiptAmount });
    const approvedAmount = bareCurrencyFormatter({ value: ApprovedAmount });
    const deniedAmount = bareCurrencyFormatter({ value: DeniedAmount });
    const paidAmount = bareCurrencyFormatter({ value: PaidAmount });

    return (
      <>
      <TableRow key={ExpenseLineId}>
        <TableCell padding="dense">{ExpenseCodeDescription}</TableCell>
        <TableCell padding="dense">{formatDateWithSlashes(LineDate)}</TableCell>
        <TableCell padding="dense">{LineDescription}</TableCell>
        <TableCell padding="dense" align="right">{receiptAmount} {ReceiptCurrencyCode}</TableCell>
        <TableCell padding="dense" align="right">{approvedAmount} {PaidCurrencyCode}</TableCell>
        {hasDeniedAmount &&
          <TableCell padding="dense" align="right" className={DeniedAmount > 0 ? classes.denied : ''}>
            {deniedAmount} {PaidCurrencyCode}
          </TableCell>
        }
        <TableCell padding="dense" align="right">{paidAmount} {PaidCurrencyCode}</TableCell>
      </TableRow>
      {Reason && Reason.length > 0 && hasDeniedAmount &&
        <TableRow key={ExpenseLineId + '-reason'}>
          <TableCell padding="dense" colSpan={6} className={classes.denied + ' ' + classes.reason}>{Reason}</TableCell>
          <TableCell>{''}</TableCell>
        </TableRow>
      }
      </>
    );
  };

  renderReportLinesMobile() {
    const { classes, report } = this.props;
    const { ReportLines } = report;
    const hasDeniedAmount = !!ReportLines.find((line) => line.DeniedAmount > 0);

    return (
      <Grid container className={classes.tableContainer}>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="dense" align="right">Submitted</TableCell>
                {hasDeniedAmount &&
                  <TableCell padding="dense" align="right">Denied</TableCell>
                }
                <TableCell padding="dense" align="right">Paid</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {ReportLines.map((line) => this.renderLineItemMobile(line, hasDeniedAmount))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  renderLineItemMobile = (item, hasDeniedAmount) => {
    const { classes } = this.props;
    const {
      ExpenseLineId,
      DeniedAmount,
      ReceiptCurrencyCode,
      PaidAmount,
      PaidCurrencyCode,
      ReceiptAmount,
      Reason,
    } = item;
    const receiptAmount = bareCurrencyFormatter({ value: ReceiptAmount });
    const deniedAmount = bareCurrencyFormatter({ value: DeniedAmount });
    const paidAmount = bareCurrencyFormatter({ value: PaidAmount });

    return (
      <>
        <TableRow key={ExpenseLineId + '-mobile'}>
          <TableCell padding="dense" align="right">{receiptAmount} {ReceiptCurrencyCode}</TableCell>
          {hasDeniedAmount &&
            <TableCell padding="dense" align="right" className={DeniedAmount > 0 ? classes.denied : ''}>
              {deniedAmount} {PaidCurrencyCode}
            </TableCell>
          }
          <TableCell padding="dense" align="right">{paidAmount} {PaidCurrencyCode}</TableCell>
          <TableCell padding="dense" align="right">
            <IconButton onClick={this.selectLineItem(item)}>
              <FontAwesomeIcon icon="ellipsis-h" />
            </IconButton>
          </TableCell>
        </TableRow>
        {Reason && Reason.length > 0 && hasDeniedAmount &&
          <TableRow key={ExpenseLineId + '-reasonMobile'}>
            <TableCell padding="dense" colSpan={4} className={classes.denied + ' ' + classes.mobileReason}>{Reason}</TableCell>
          </TableRow>
        }
      </>
    );
  };

  renderAttachments() {
    const { classes, report, isLoadingReceiptImage } = this.props;
    const { selectedImage } = this.state;
    const { Attachments } = report;

    if (Attachments.length === 0) {
      return null;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Attachments</Typography>
        </Grid>
        {Attachments.map((attachment) => {
          const { AttachmentId, FileType, FileBase64 } = attachment;
          let thumbnail;
          //image has been clicked and is loading for lightbox
          if ((selectedImage && selectedImage.AttachmentId === AttachmentId) && isLoadingReceiptImage){
            thumbnail = <LogoSpinner />;
          }
          else if ((FileType === 'png' || FileType === 'jpg' || FileType === 'jpeg') && FileBase64) {
            thumbnail = <img src={FileBase64} alt="Expense thumbnail" />;
          } 
          else {
            thumbnail = (
              <React.Fragment>
                <FontAwesomeIcon
                  className={classes.thumbnailIcon}
                  icon="file-download"
                  size="3x"
                />
                <Typography className={classes.fileCaption} variant="caption">{attachment.FileName}</Typography>
              </React.Fragment>
            );
          }

          return (
            <Grid item xs={6} sm={3} md={2} key={AttachmentId} role="button" onClick={this.handleAttachmentClick(attachment)}>
              <div className={classes.thumbnailContainer}>
                { thumbnail}
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  calculatePayment = memoize(
    (reportLines) => {
      let currencyCode = null;
      let amount = 0;
      let apPostedDate = null;
      let isTrackingExpense = false;
      reportLines.forEach((line) => {
        const { PaidCurrencyCode, PaidAmount, APPostedDate, IsTrackingExpense } = line;
        currencyCode = currencyCode || PaidCurrencyCode;

        if (currencyCode !== PaidCurrencyCode) {
          console.warn('found multiple payment currencies', currencyCode, PaidCurrencyCode);
        }

        if (PaidAmount && PaidCurrencyCode) {
          amount += PaidAmount;
        }

        apPostedDate = apPostedDate || APPostedDate;
        isTrackingExpense = isTrackingExpense || IsTrackingExpense;
      });

      amount = bareCurrencyFormatter({ value: amount });

      return currencyCode ? { currencyCode, amount, apPostedDate, isTrackingExpense } : null;
    },
  );

  render() {
    const { classes, report, width, disableCollapse } = this.props;
    const { expanded, selectedLineItem } = this.state;
    const {
      ReportID,
      CreatedOn,
      ReportLines,
      ClaimStatus,
    } = report;
    const payment = this.calculatePayment(ReportLines);
    const isXs = width === 'xs';
    const icon = disableCollapse ? null : expanded ? <Subtract /> : <Add />;

    return (
      <MuiThemeProvider theme={reportTheme}>
        <ExpansionPanel expanded={expanded} onChange={this.handleChange} classes={{ root: classes.expansionPanelRoot }}>
          <ExpansionPanelSummary
            expandIcon={icon}
            style={{
              cursor: disableCollapse ? 'auto' : 'pointer',
              userSelect: 'auto',
            }}
          >
            <div className={classes.summary}>
              <Typography variant="h5">Report #{ReportID} ({ClaimStatus}) - {formatDateWithSlashes(CreatedOn)}</Typography>
              {!expanded && payment && !isXs &&
                <Typography variant="h6">{payment.amount} {payment.currencyCode}</Typography>
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panel}>
            {this.renderPaymentInfo(payment)}
            {this.renderReportLines()}
            {this.renderAttachments()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        
        <ResponsiveDialog
          fullScreen={false}
          open={!!selectedLineItem}
          contentElem={this.getFullLineItemElement(selectedLineItem)}
          handleResponse={this.handleLineItemClose}
          title={selectedLineItem ? selectedLineItem.ExpenseCodeDescription : ''}
          onBackdropClick={this.handleLineItemClose}
          cancelText="Close"
          cancelButtonOnly
          minWidth={300}
        />
      </MuiThemeProvider>
    );
  }
}

Report.propTypes = {
  report: PropTypes.object.isRequired,
  submittedAuthDetail: PropTypes.object.isRequired,
  disableCollapse: PropTypes.bool,
  handleImageSelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    receiptAttachmentImages: state.transferees.get('receiptAttachmentImages'),
    isLoadingReceiptImage: state.transferees.get('isLoadingReceiptImage'),
    contentWidth: state.layout.get('contentWidth'),
  };
};

export default compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, {
    getReceiptAttachment,
  }),
)(Report);
