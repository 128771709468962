import React from 'react';
import spinner from '../images/logospinner.gif';

const SPINNER_SIZE = {
  large: '100px',
  small: '36px',
};

const LogoSpinner = (props) => {
  const size = SPINNER_SIZE[props.size] || SPINNER_SIZE.small;
  return (
    <div style={{ ...props.style, ...styles.container }}>
      <img src={spinner} style={{ display: 'inline-block', height: size, width: size }} alt="loading" />
    </div>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
};

export default LogoSpinner;
