import { connect } from 'react-redux';
import React, { Component } from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { setDrawerOpen, setMobileDrawerOpen } from './layout.actions';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SidebarDrawer from './sidebarDrawer.component.js';

const mobileDrawerWidth = 240;

const styles = (theme) => ({
  drawerPaperMobile: {
    height: '100%',
    width: mobileDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#15585e',
    },
    secondary: {
      main: '#4d8766',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class MobileDrawer extends Component {
  constructor(props) {
    super(props);

    this.handleMobileDrawerToggle = this.handleMobileDrawerToggle.bind(this);
  }

  handleMobileDrawerToggle() {
    this.props.setMobileDrawerOpen(!this.props.mobileDrawerOpen);
  }

  render() {
    const { classes, mobileDrawerOpen, userInformation } = this.props;
    return (
      <MuiThemeProvider theme={darkTheme}>
        <Drawer
          variant="temporary"
          // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileDrawerOpen}
          classes={{
            paper: classes.drawerPaperMobile,
          }}
          onClose={this.handleMobileDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SidebarDrawer
            open={mobileDrawerOpen}
            handleDrawerClose={this.handleMobileDrawerToggle}
            handleDrawerOpen={this.handleMobileDrawerToggle}
            userName={userInformation ? userInformation.FullName : null}
          />
        </Drawer>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileDrawerOpen: state.layout.get('mobileDrawerOpen'),
    drawerOpen: state.layout.get('drawerOpen'),
    userInformation: state.layout.get('userInformation'),
    pageTitle: state.layout.get('pageTitle'),
  };
};

MobileDrawer = withStyles(styles, { withTheme: true })(MobileDrawer);

export default connect(mapStateToProps, {
  setMobileDrawerOpen,
  setDrawerOpen,
})(MobileDrawer);