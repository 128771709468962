import { connect } from 'react-redux';
import React, { Component } from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { drawerWidth, drawerWidthClose } from './sidebarDrawer.component';
import { setDrawerOpen } from './layout.actions';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SidebarDrawer from './sidebarDrawer.component.js';
import classNames from 'classnames';

const styles = (theme) => ({
  drawerPaper: {
    height: '100%',
    overflow: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: drawerWidthClose,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#15585e',
    },
    secondary: {
      main: '#4d8766',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class DesktopDrawer extends Component {
  constructor(props) {
    super(props);

    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
  }

  handleDrawerClose() {
    this.props.setDrawerOpen(false);
    this.props.onDrawerClose();
  }

  handleDrawerOpen() {
    this.props.setDrawerOpen(true);
    this.props.onDrawerOpen();
  }

  render() {
    const { classes, drawerOpen, userInformation } = this.props;
    return (
      <MuiThemeProvider theme={darkTheme}>
        <Drawer
          variant="permanent"
          open={drawerOpen}
          classes={{
            paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
          }}
        >
          <SidebarDrawer
            open={drawerOpen}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
            userName={userInformation ? userInformation.FullName : null}
          />
        </Drawer>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.layout.get('drawerOpen'),
    userInformation: state.layout.get('userInformation'),
    pageTitle: state.layout.get('pageTitle'),
  };
};

DesktopDrawer = withStyles(styles, { withTheme: true })(DesktopDrawer);

export default connect(mapStateToProps, {
  setDrawerOpen,
})(DesktopDrawer);